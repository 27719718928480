import { Box, Button, ButtonProps } from "@chakra-ui/react"
import { ModalType } from "component/Modal/type"
import { useModal } from "container/modal"
import React, { ForwardedRef, useCallback } from "react"
import { Web3WalletContainer } from "../../container/Web3WalletContainer"

interface DisconnectButtonComponentProps extends ButtonProps {}

function DisconnectButtonComponent(props: DisconnectButtonComponentProps, ref: ForwardedRef<ButtonProps>) {
    const {
        actions: { logout },
    } = Web3WalletContainer.useContainer()

    const { close: closeModal } = useModal(ModalType.WALLET_CONNECTION)

    const handleOnClick = useCallback(() => {
        logout()
        closeModal()
    }, [logout, closeModal])

    return (
        <Button {...props} width="full" onClick={handleOnClick}>
            <Box color="red.500">Disconnect</Box>
        </Button>
    )
}

export const DisconnectButton = React.forwardRef(DisconnectButtonComponent)
