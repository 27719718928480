import { formatPercentage } from "util/format"

import { useAccountBalance } from "sdk-react/accountBalance/useAccountBalance"

export enum RiskLevel {
    HIGH = "High",
    MEDIUM = "Medium",
    LOW = "Low",
}

export const riskLevelValue = {
    high: 0.0625, // 6.25%
    medium: 0.5, // 50%
}

const PLACEHOLDER = "-,---"

export function useAccountBalanceStrings() {
    const { accountMarginRatio: marginRatio, accountLeverage: leverage } = useAccountBalance()
    let riskLevel = RiskLevel.LOW
    if (marginRatio?.lt(riskLevelValue.high)) {
        riskLevel = RiskLevel.HIGH
    } else if (marginRatio?.lt(riskLevelValue.medium)) {
        riskLevel = RiskLevel.MEDIUM
    }
    const marginRatioStr = marginRatio ? `${formatPercentage(marginRatio)}%` : PLACEHOLDER
    const marginRatioVal = marginRatio ? marginRatio.toNumber() : 0
    const leverageStr = leverage ? `${formatPercentage(leverage, false)}×` : "—"

    return {
        marginRatioStr,
        marginRatioVal,
        leverageStr,
        riskLevel,
    }
}
