const styles = {
    global: (props: any) => ({
        ".js-focus-visible :focus:not([data-focus-visible-added])": {
            outline: "none",
            boxShadow: "none",
        },

        "html body": {
            fontFamily: "body",
            fontVariantNumeric: "slashed-zero",
            backgroundColor: "subBackground.dark",
            webkitOverflowScrolling: "touch",
            webkitTapHighlightColor: "rgba(0,0,0,0)",
            webkitTouchCallout: "none",
        },
        ".markdown": {
            h1: {
                fontSize: "3xl",
                fontWeight: "bold",
                mb: 5,
                lineHeight: "1.2",
                letterSpacing: "tight",
            },
            h2: {
                fontSize: "2xl",
                fontWeight: "bold",
                mb: 5,
                lineHeight: "1.2",

                letterSpacing: "tight",
            },
            h3: {
                fontSize: "xl",
                fontWeight: "bold",
                mb: 5,
                lineHeight: "1.2",

                letterSpacing: "tight",
            },
            h4: {
                fontSize: "lg",
                fontWeight: "bold",
                mb: 5,
                lineHeight: "1.2",

                letterSpacing: "tight",
            },
            h5: {
                fontSize: "md",
                fontWeight: "bold",
                mb: 5,
                lineHeight: "1.2",

                letterSpacing: "tight",
            },
            p: {
                fontSize: "md",
                lineHeight: 1.6,
                mb: 3,
                color: "gray.400",
            },
            a: {
                color: props.colorMode === "dark" ? "perpetual.300" : "perpetual.800",
                textDecoration: "none",
            },
            "a:hover": {
                color: props.colorMode === "dark" ? "perpetual.300" : "perpetual.800",
                textDecoration: "underline",
            },
            code: {
                fontFamily: "mono",
                fontSize: "sm",
                fontWeight: "bold",
                opacity: 1,
                bg: "none",
            },
            li: {
                ml: 0,
                color: "gray.400",
            },
            "ol, ul": {
                my: 6,
                ml: 6,
            },
        },
    }),
}

export default styles
