import Bugsnag from "@bugsnag/js"
import { isSDKError } from "@perp/sdk-curie"
import { IS_DEVELOPMENT } from "constant/envVariables"

/* NOTE: LogMetadataSet: { [tabName: string]: { [propName: string]: any }} */
export type LogMetadataSet = Record<string, Record<string, any>>

enum TabName {
    SDK_ERROR = "SDK Error",
    NATIVE_ERROR = "Native Error",
}

// TODO: merge with BugsnagService class.
class Logger {
    private static instance: Logger | null = null
    private constructor() {}
    static init() {
        if (this.instance === null) {
            this.instance = new Logger()
        }
        return this.instance
    }
    setUser(id?: string) {
        Bugsnag.setUser(id)
    }
    log(...args: any[]) {
        if (IS_DEVELOPMENT) {
            console.log(...args)
        }
    }
    info(...args: any[]) {
        // NOTE: info will keep messages on all env
        console.info(...args)
    }
    warn(...args: any[]) {
        const msg = args.join(" ")
        Bugsnag.notify({
            name: "Warn",
            message: msg,
        })
        console.warn(...args)
    }
    error(err: Error) {
        Bugsnag.notify(err, event => {
            if (isSDKError(err)) {
                const { name, message, stack, ...sdkErrorProps } = err
                const { rawError, ...otherErrorProps } = sdkErrorProps
                if (rawError) {
                    event.addMetadata(TabName.SDK_ERROR, otherErrorProps)
                    const errorObj = Object.getOwnPropertyNames(rawError).reduce(
                        (errorObj, propName) => ({
                            ...errorObj,
                            [propName]: rawError[propName as keyof typeof rawError],
                        }),
                        {
                            // NOTE: Object.getOwnPropertyNames cannot get the name prop
                            name: rawError.name,
                            // NOTE: to make sure we didn't miss any error props, can remove this when our error system gets more stable
                            errorStr: JSON.stringify(rawError),
                        },
                    )
                    event.addMetadata(TabName.NATIVE_ERROR, errorObj)
                } else {
                    event.addMetadata(TabName.SDK_ERROR, sdkErrorProps)
                }
            }
        })
        console.error(err)
    }
}

export const logger = Logger.init()
