import { ClearingHouse, LiquidityDraft, TICK_MAX, TICK_MIN, TickPriceMode } from "@perp/sdk-curie"
import Big from "big.js"
import { clamp, debounce } from "lodash-es"
import { useCallback, useMemo, useState } from "react"
import { useAsyncFn } from "react-use"
import { useMarket } from "sdk-react/markets/useMarket"
import { usePerpConnectedCallback } from "sdk-react/perpetualProtocol/usePerpConnectedCallback"
import { usePerpTransactionSender } from "sdk-react/perpetualProtocol/usePerpTransactionSender"
import { TxLoading } from "sdk-react/tools/useTxLoading"

const DEBOUNCE_TIME = 500

type PriceRangeInput = {
    lowerPrice: Big
    upperPrice: Big
    baseAmount?: number
    quoteAmount?: number
} & (
    | {
          direction: "increase" | "decrease"
          rangeSide: "lower" | "upper"
      }
    | {
          direction?: never
          rangeSide?: never
      }
)

type LiquidityAmountInputs = { baseAmount: number; quoteAmount?: never } | { baseAmount?: never; quoteAmount: number }

type CreateLiquidityDraftParams = Omit<Parameters<ClearingHouse["createLiquidityDraft"]>[0], "tickerSymbol">

interface LiquidityAddInitialState {
    tickerSymbol: string
}

export function useLiquidityAdd({ tickerSymbol }: LiquidityAddInitialState) {
    const { market, marketPrice } = useMarket(tickerSymbol)
    const { isTxLoading } = TxLoading.useContainer()

    const getTick = useCallback(
        ({ price, mode = TickPriceMode.NEAREST }: { price: Big; mode?: TickPriceMode }) => {
            return market?.getTickFromPrice(price, mode)
        },
        [market],
    )

    const createLiquidityDraft = usePerpConnectedCallback<CreateLiquidityDraftParams, LiquidityDraft>(
        ({ clearingHouse }, params) => clearingHouse.createLiquidityDraft({ tickerSymbol, ...params }),
        [tickerSymbol],
    )

    // NOTE: fetchLiquidityData
    const [
        { value: liquidityData, loading: fetchingLiquidityData, error: errorFetchLiquidityData },
        _fetchLiquidityData,
    ] = useAsyncFn(async (liquidityDraft: LiquidityDraft, marketPrice: Big) => {
        // TODO: [429 issue] check if we can reduce contract calls
        const [baseAmount, quoteAmount, rangeType, liquidity] = await Promise.all([
            liquidityDraft.getBaseAmount(),
            liquidityDraft.getQuoteAmount(),
            liquidityDraft.getRangeType(),
            liquidityDraft.getLiquidity(),
        ])
        const totalAsQuoteAmount = marketPrice.mul(baseAmount).add(quoteAmount)
        return {
            baseAmount,
            quoteAmount,
            totalAsQuoteAmount,
            rangeType,
            liquidity,
        }
    }, [])
    const fetchLiquidityDataDebounced = useMemo(
        () => debounce(_fetchLiquidityData, DEBOUNCE_TIME),
        [_fetchLiquidityData],
    )
    const { baseAmount, quoteAmount, totalAsQuoteAmount, rangeType } = liquidityData || {}

    const [liquidityDraft, setLiquidityDraft] = useState<LiquidityDraft>()

    const updatePriceRange = useCallback(
        ({ lowerPrice, upperPrice, rangeSide, direction, baseAmount, quoteAmount }: PriceRangeInput) => {
            if (!market || !marketPrice) {
                return
            }
            const lowerTick = getTick({ price: lowerPrice })
            const upperTick = getTick({ price: upperPrice })
            if (!lowerTick || !upperTick) {
                return
            }
            let nextLowerTick = lowerTick
            let nextUpperTick = upperTick
            const tickSpacing = market.tickSpacing
            if (rangeSide && direction) {
                switch (rangeSide) {
                    case "lower": {
                        nextLowerTick = clamp(
                            direction === "increase" ? lowerTick + tickSpacing : lowerTick - tickSpacing,
                            TICK_MIN,
                            upperTick - tickSpacing,
                        )
                        break
                    }
                    case "upper": {
                        nextUpperTick = clamp(
                            direction === "increase" ? upperTick + tickSpacing : upperTick - tickSpacing,
                            lowerTick + tickSpacing,
                            TICK_MAX,
                        )
                        break
                    }
                }
            }

            const liquidityDraftNext = createLiquidityDraft({
                lowerTick: nextLowerTick,
                upperTick: nextUpperTick,
                ...(baseAmount && { rawBaseAmount: Big(baseAmount) }),
                ...(quoteAmount && { rawQuoteAmount: Big(quoteAmount) }),
            })

            setLiquidityDraft(liquidityDraftNext)
            if (liquidityDraftNext) {
                fetchLiquidityDataDebounced(liquidityDraftNext, marketPrice)
            }
        },
        [createLiquidityDraft, fetchLiquidityDataDebounced, getTick, marketPrice, market],
    )

    const updateLiquidityAmounts = useCallback(
        ({ baseAmount, quoteAmount }: LiquidityAmountInputs) => {
            if (!marketPrice || !liquidityDraft) {
                return
            }
            const { lowerTick: lowerTickPrev, upperTick: upperTickPrev } = liquidityDraft
            const liquidityDraftNext = createLiquidityDraft({
                lowerTick: lowerTickPrev,
                upperTick: upperTickPrev,
                ...(baseAmount && { rawBaseAmount: Big(baseAmount) }),
                ...(quoteAmount && { rawQuoteAmount: Big(quoteAmount) }),
            })

            setLiquidityDraft(liquidityDraftNext)
            if (liquidityDraftNext) {
                fetchLiquidityDataDebounced(liquidityDraftNext, marketPrice)
            }
        },
        [createLiquidityDraft, fetchLiquidityDataDebounced, liquidityDraft, marketPrice],
    )

    const isReadyToAddLiquidity = useMemo(() => {
        return !!liquidityDraft && !!totalAsQuoteAmount && totalAsQuoteAmount.gt(0) && !errorFetchLiquidityData
    }, [errorFetchLiquidityData, liquidityDraft, totalAsQuoteAmount])

    const _addLiquidity = usePerpTransactionSender<{ slippage: Big }>(
        ({ clearingHouse }, { slippage }) => {
            if (!liquidityDraft) {
                return
            }
            return clearingHouse.addLiquidity(liquidityDraft, slippage)
        },
        [liquidityDraft],
    )
    const addLiquidityDebounced = useMemo(() => debounce(_addLiquidity, DEBOUNCE_TIME), [_addLiquidity])

    const reset = useCallback(() => setLiquidityDraft(undefined), [])

    return {
        market,
        marketPrice,

        // NOTE: PRICE RANGE
        updatePriceRange,
        lowerTickPrice: liquidityDraft?.lowerTickPrice,
        upperTickPrice: liquidityDraft?.upperTickPrice,
        rangeType,

        // NOTE: LIQUIDITY AMOUNTS
        updateLiquidityAmounts,
        baseAmount,
        quoteAmount,
        totalAsQuoteAmount,

        // NOTE: ADD LIQUIDITY
        isReadyToAddLiquidity,
        addLiquidity: addLiquidityDebounced,

        reset,

        // NOTE: STATUS
        isCalculating: fetchingLiquidityData,
        isTxLoading,
        errorFetchLiquidityData,
    }
}
