import { EventName } from "module/analytics/types"
import { useMarketStrings } from "module/market/hook/useMarketStrings"
import { useNotificationTx } from "module/notification/hook/useNotificationTx"
import { PriceRangeStepList, getTickPriceStepList } from "module/pool/util/priceRange"
import { Slippage } from "module/settings/container/slippage"
import { formatNumberUSD } from "util/format"

import { Divider, Flex, HStack, Link, Spacer, Text, VStack, useBreakpointValue } from "@chakra-ui/react"
import { Liquidity, RangeType, TickPriceMode } from "@perp/sdk-curie"
import Big from "big.js"
import { AddDrop } from "component/Icon"
import { ModalRootProps, ModalType } from "component/Modal/type"
import { PerpModal } from "component/PerpModal"
import { PerpTabs } from "component/PerpTabs"
import { Stats } from "component/Stats"
import { TxButton } from "component/TxButton"
import { TextTitle } from "component/Typography"
import { useModal } from "container/modal"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLiquidityAdd } from "sdk-react/liquidity/useLiquidityAdd"
import { Vault } from "sdk-react/vault/useVault"

import { useRiskLevel } from "../../hook/useRiskLevel"
import { SlippagePopover } from "../LiquidityForms/SlippagePopover"
import { LiquidityAmountInputAdvanced } from "./LiquidityAmountInputAdvanced"
import { LiquidityAmountInputSimple } from "./LiquidityAmountInputSimple"
import { PriceRangePickerAdvanced, PriceRangePickerAdvancedProps, RangeSide } from "./PriceRangePickerAdvanced"
import { PriceRangePickerSimple, PriceRangePickerSimpleProps, getPriceRange } from "./PriceRangePickerSimple"
import { Strategy, StrategyPicker } from "./StrategyPicker"

const PLACEHOLDER = "-,--"
const PRICE_RANGE_INTERVALS = 3
const PRICE_RANGE_DELTA_RATIO_CAP = 0.6
const PRICE_RANGE_DELTA_RATIO_DEFAULT = 0.2

const PRICE_RANGE_INPUT_SYMBOL_MAX = "∞"
const PRICE_RANGE_INPUT_SYMBOL_MIN = "0"

const INIT_BASE_AMOUNT_INPUT = ""
const INIT_QUOTE_AMOUNT_INPUT = ""
const INIT_TOTAL_AS_QUOTE_AMOUNT_INPUT = ""
const INIT_LEVERAGE_INPUT = undefined

const StrategyToTickPriceMode: Record<Strategy, Exclude<TickPriceMode, TickPriceMode.NEAREST>> = {
    [Strategy.DOWN_UP]: TickPriceMode.LOWER,
    [Strategy.UP_DOWN]: TickPriceMode.UPPER,
}

// TODO: Temporary solution. Should get buyingPower for adding liquidity from SDK directly in the future. (via OrderDraft or a new Calculator class)
function useBuyingPower() {
    const { buyingPower: buyingPowerRaw } = Vault.useContainer()
    const buyingPower = useMemo(() => {
        if (buyingPowerRaw) {
            // NOTE: https://app.asana.com/0/1201436405090421/1202478218605802/f
            const deviation = 10 ** -18
            return buyingPowerRaw.gt(deviation) ? buyingPowerRaw.minus(deviation) : buyingPowerRaw
        }
    }, [buyingPowerRaw])
    return {
        buyingPower,
        buyingPowerStr: buyingPower ? formatNumberUSD({ num: buyingPower }) : undefined,
    }
}

enum MODE {
    SIMPLE = "SIMPLE",
    ADVANCED = "ADVANCED",
}
const ModeTabData = {
    [MODE.SIMPLE]: { index: 0, id: MODE.SIMPLE },
    [MODE.ADVANCED]: { index: 1, id: MODE.ADVANCED },
}

enum INPUT_FIELD {
    BASE = "BASE",
    QUOTE = "QUOTE",
    TOTAL_AS_QUOTE = "TOTAL_AS_QUOTE",
    LEVERAGE = "LEVERAGE",
}

export interface ModalLiquidityAddParams {
    baseSymbol: string
    tickerSymbol: string
    existingLiquidity?: Liquidity
}

interface ModalLiquidityAddDuoProps extends ModalRootProps<ModalLiquidityAddParams> {}

export function ModalLiquidityAdd({
    isOpen,
    close,
    params: { baseSymbol, tickerSymbol, existingLiquidity },
}: ModalLiquidityAddDuoProps) {
    const { marketPriceStr } = useMarketStrings(tickerSymbol)

    const hasExistingLiquidity = useMemo(() => !!existingLiquidity, [existingLiquidity])
    const titleText = `${hasExistingLiquidity ? "Increase" : "Add"} Liquidity`
    const submitButtonText = `${hasExistingLiquidity ? "Increase" : "Add"} Liquidity`

    const {
        market,
        marketPrice,

        updatePriceRange,
        lowerTickPrice,
        upperTickPrice,
        rangeType,

        updateLiquidityAmounts,
        quoteAmount,
        baseAmount,
        totalAsQuoteAmount,

        addLiquidity,
        reset: resetLiquidityAdd,

        // isCalculating,
        // errorFetchLiquidityData,
        isReadyToAddLiquidity,
        isTxLoading,
    } = useLiquidityAdd({ tickerSymbol })

    const { tickPriceMin, tickPriceMax } = useMemo(() => {
        if (!market) {
            return {}
        }
        return {
            tickPriceMin: market.getPriceFromTick(market.minTick),
            tickPriceMax: market.getPriceFromTick(market.maxTick),
        }
    }, [market])

    /* ========== INPUTS ========== */
    const [baseAmountInput, setBaseAmountInput] = useState<string>(INIT_BASE_AMOUNT_INPUT)
    const [quoteAmountInput, setQuoteAmountInput] = useState<string>(INIT_QUOTE_AMOUNT_INPUT)
    const [totalAsQuoteAmountInput, setTotalAsQuoteAmountInput] = useState<string>(INIT_TOTAL_AS_QUOTE_AMOUNT_INPUT)
    const [leverageInput, setLeverageInput] = useState<number | undefined>(INIT_LEVERAGE_INPUT)
    const [lastEditField, setLastEdit] = useState<INPUT_FIELD>()
    const baseAmountInputNumber = Number(baseAmountInput)
    const quoteAmountInputNumber = Number(quoteAmountInput)
    const totalAsQuoteAmountInputNumber = Number(totalAsQuoteAmountInput)
    const totalAmountAsQuoteStr = useMemo(
        () => (!totalAsQuoteAmount ? PLACEHOLDER : formatNumberUSD({ num: totalAsQuoteAmount })),
        [totalAsQuoteAmount],
    )

    const { freeCollateral } = Vault.useContainer()
    const leverageInputNext = useMemo(() => {
        if (!totalAsQuoteAmount || !freeCollateral || freeCollateral.lte(0)) {
            return INIT_LEVERAGE_INPUT
        }

        return totalAsQuoteAmount.div(freeCollateral).toNumber()
    }, [freeCollateral, totalAsQuoteAmount])
    /* ========== INPUTS ========== */

    const reset = useCallback(() => {
        setBaseAmountInput(INIT_BASE_AMOUNT_INPUT)
        setQuoteAmountInput(INIT_QUOTE_AMOUNT_INPUT)
        setTotalAsQuoteAmountInput(INIT_TOTAL_AS_QUOTE_AMOUNT_INPUT)
        setLeverageInput(INIT_LEVERAGE_INPUT)
        setLastEdit(undefined)
        resetLiquidityAdd()
    }, [resetLiquidityAdd])

    const [{ index: focusedModeIndex, id: focusedModeId }, setFocusedTab] = useState(
        hasExistingLiquidity ? ModeTabData.ADVANCED : ModeTabData.SIMPLE,
    )
    const handleModeChange = useCallback(
        (index: number, id: string) => {
            setFocusedTab({ index, id: id as MODE })
            reset()
        },
        [reset],
    )

    /* ========== UPDATE AMOUNTS ========== */
    const handleBaseAmountChange = useCallback(
        (inputNext: string) => {
            const baseAmountNextNumber = Number(inputNext)
            if (isNaN(baseAmountNextNumber)) {
                return
            }

            setBaseAmountInput(inputNext)
            setLastEdit(INPUT_FIELD.BASE)

            updateLiquidityAmounts({ baseAmount: baseAmountNextNumber })
        },
        [updateLiquidityAmounts],
    )

    const handleQuoteAmountChange = useCallback(
        (inputNext: string) => {
            const quoteAmountNextNumber = Number(inputNext)
            if (isNaN(quoteAmountNextNumber)) {
                return
            }

            setQuoteAmountInput(inputNext)
            setLastEdit(INPUT_FIELD.QUOTE)

            updateLiquidityAmounts({ quoteAmount: quoteAmountNextNumber })
        },
        [updateLiquidityAmounts],
    )

    const handleTotalAsQuoteAmountChange = useCallback(
        (inputNext: string) => {
            const totalAsQuoteAmountNextNumber = Number(inputNext)
            if (isNaN(totalAsQuoteAmountNextNumber)) {
                return
            }

            setTotalAsQuoteAmountInput(inputNext)
            setLastEdit(INPUT_FIELD.TOTAL_AS_QUOTE)

            switch (rangeType) {
                case RangeType.RANGE_AT_LEFT: {
                    updateLiquidityAmounts({ quoteAmount: totalAsQuoteAmountNextNumber })
                    break
                }
                case RangeType.RANGE_AT_RIGHT: {
                    if (!marketPrice) {
                        return
                    }
                    const baseAmountNextNumber = new Big(totalAsQuoteAmountNextNumber).div(marketPrice).toNumber()
                    updateLiquidityAmounts({ baseAmount: baseAmountNextNumber })
                    break
                }
            }
        },
        [marketPrice, rangeType, updateLiquidityAmounts],
    )

    const handleLeverageChange = useCallback(
        (inputNext: number) => {
            if (isNaN(inputNext) || !freeCollateral) {
                return
            }

            setLeverageInput(inputNext)
            setLastEdit(INPUT_FIELD.LEVERAGE)

            const totalAsQuoteAmountNext = new Big(inputNext).mul(freeCollateral)
            switch (rangeType) {
                case RangeType.RANGE_AT_LEFT: {
                    const quoteAmountNextNumber = totalAsQuoteAmountNext.toNumber()
                    updateLiquidityAmounts({ quoteAmount: quoteAmountNextNumber })
                    break
                }
                case RangeType.RANGE_AT_RIGHT: {
                    if (!marketPrice) {
                        return
                    }
                    const baseAmountNextNumber = totalAsQuoteAmountNext.div(marketPrice).toNumber()
                    updateLiquidityAmounts({ baseAmount: baseAmountNextNumber })
                    break
                }
                case RangeType.RANGE_INSIDE: {
                    // TODO: distribute total to quote & base
                    break
                }
            }
        },
        [freeCollateral, marketPrice, rangeType, updateLiquidityAmounts],
    )

    // NOTE: Handle amounts updated.
    // NOTE: Convert to string to be used as valid hook dependencies.
    const quoteAmountInputNext = quoteAmount?.toString()
    const baseAmountInputNext = baseAmount?.toString()
    const totalAsQuoteAmountInputNext = totalAsQuoteAmount?.toString()
    useEffect(() => {
        if (!lastEditField) {
            // NOTE: lastEditAmount is undefined: Don't change input When amount is never touched.
            return
        }

        const isOutOfRangeForBase = rangeType === RangeType.RANGE_AT_LEFT
        const isOutOfRangeForQuote = rangeType === RangeType.RANGE_AT_RIGHT

        const notLastEditQuote = lastEditField !== INPUT_FIELD.QUOTE
        if (
            notLastEditQuote ||
            isOutOfRangeForQuote // NOTE: Allow reset when out of range.
        ) {
            setQuoteAmountInput(quoteAmountInputNext || "")
        }

        const notLastEditBase = lastEditField !== INPUT_FIELD.BASE
        if (
            notLastEditBase ||
            isOutOfRangeForBase // NOTE: Allow reset when out of range.
        ) {
            setBaseAmountInput(baseAmountInputNext || "")
        }

        const notLastEditTotal = lastEditField !== INPUT_FIELD.TOTAL_AS_QUOTE
        if (notLastEditTotal) {
            setTotalAsQuoteAmountInput(totalAsQuoteAmountInputNext || "")
        }

        const notLastEditLeverage = lastEditField !== INPUT_FIELD.LEVERAGE
        if (notLastEditLeverage) {
            setLeverageInput(leverageInputNext)
        }
    }, [
        baseAmountInputNext,
        lastEditField,
        leverageInputNext,
        quoteAmountInputNext,
        rangeType,
        totalAsQuoteAmountInputNext,
    ])
    /* ========== UPDATE AMOUNTS ========== */

    /* ========= SIMPLE MODE =========== */
    const [strategy, setStrategy] = useState(Strategy.DOWN_UP)
    const handleStrategyChange = useCallback(
        (strategyNext: Strategy) => {
            setStrategy(strategyNext)
            reset()
        },
        [reset],
    )
    const tickPriceMode = StrategyToTickPriceMode[strategy]
    const [priceRangeStepList, setPriceRangeStepList] = useState<PriceRangeStepList>([])
    const [priceRangeDeltaStep, setPriceRangeStepDelta] = useState(1)

    // TODO: mark price could be outdated after awhile. Need UI to hint user to refresh value.
    // NOTE: Init price range.
    useEffect(() => {
        if (lowerTickPrice && upperTickPrice) {
            // NOTE: Already initialized
            return
        }

        if (focusedModeId !== MODE.SIMPLE || !market || !marketPrice) {
            return
        }

        const tickPriceStepList = getTickPriceStepList({
            market,
            marketPrice,
            tickPriceMode,
            interval: PRICE_RANGE_INTERVALS,
            deltaRatioCap: PRICE_RANGE_DELTA_RATIO_CAP,
        })
        setPriceRangeStepList(tickPriceStepList)

        const { lowerPrice, upperPrice } = getPriceRange({
            tickPriceStepList,
            deltaStep: priceRangeDeltaStep,
        })
        let amounts = {}
        switch (rangeType) {
            case RangeType.RANGE_AT_LEFT: {
                if (!isNaN(totalAsQuoteAmountInputNumber)) {
                    amounts = { quoteAmount: totalAsQuoteAmountInputNumber }
                }
                break
            }
            case RangeType.RANGE_AT_RIGHT: {
                if (totalAsQuoteAmount && marketPrice && !isNaN(totalAsQuoteAmountInputNumber)) {
                    const baseAmountNumberNext = totalAsQuoteAmount.div(marketPrice).toNumber()
                    amounts = { baseAmount: baseAmountNumberNext }
                }
                break
            }
            case RangeType.RANGE_INSIDE: {
                // TODO: distribute total to quote & base
                break
            }
        }
        updatePriceRange({ lowerPrice, upperPrice, ...amounts })
    }, [
        focusedModeId,
        lowerTickPrice,
        marketPrice,
        market,
        priceRangeDeltaStep,
        rangeType,
        tickPriceMode,
        totalAsQuoteAmount,
        totalAsQuoteAmountInputNumber,
        updatePriceRange,
        upperTickPrice,
    ])

    const handlePriceRangeChangeSimple = useCallback<PriceRangePickerSimpleProps["onRangeChange"]>(
        ({ lowerPrice, upperPrice, deltaStep }) => {
            setPriceRangeStepDelta(deltaStep)

            let amounts = {}
            switch (rangeType) {
                case RangeType.RANGE_AT_LEFT: {
                    if (!isNaN(totalAsQuoteAmountInputNumber)) {
                        amounts = { quoteAmount: totalAsQuoteAmountInputNumber }
                    }
                    break
                }
                case RangeType.RANGE_AT_RIGHT: {
                    if (totalAsQuoteAmount && marketPrice && !isNaN(totalAsQuoteAmountInputNumber)) {
                        const baseAmountNumberNext = totalAsQuoteAmount.div(marketPrice).toNumber()
                        amounts = { baseAmount: baseAmountNumberNext }
                    }
                    break
                }
                case RangeType.RANGE_INSIDE: {
                    // TODO: distribute total to quote & base
                    break
                }
            }
            updatePriceRange({ lowerPrice, upperPrice, ...amounts })
        },
        [marketPrice, rangeType, totalAsQuoteAmount, totalAsQuoteAmountInputNumber, updatePriceRange],
    )
    /* ========== SIMPLE MODE ========== */

    /* ========== ADVANCED MODE ========== */
    const [priceRangeLowerInput, setPriceRangeLowerInput] = useState("")
    const [priceRangeUpperInput, setPriceRangeUpperInput] = useState("")
    const [isPriceRangeTyping, setIsPriceRangeTyping] = useState(false)
    const priceRangeLowerInputNumber = Number(priceRangeLowerInput)
    const priceRangeUpperInputNumber = Number(priceRangeUpperInput)

    // TODO: mark price does could be outdated after awhile. Need UI to hint user to refresh value.
    // NOTE: Init price range.
    useEffect(() => {
        if (lowerTickPrice && upperTickPrice) {
            // NOTE: Already initialized
            return
        }
        if (focusedModeId !== MODE.ADVANCED || !market || !marketPrice) {
            return
        }

        const lowerPrice = existingLiquidity
            ? existingLiquidity?.lowerTickPrice
            : marketPrice.mul(1 - PRICE_RANGE_DELTA_RATIO_DEFAULT)
        const upperPrice = existingLiquidity
            ? existingLiquidity?.upperTickPrice
            : marketPrice.mul(1 + PRICE_RANGE_DELTA_RATIO_DEFAULT)
        let amounts = {}
        switch (lastEditField) {
            case INPUT_FIELD.BASE: {
                if (!isNaN(baseAmountInputNumber)) {
                    amounts = { baseAmount: baseAmountInputNumber }
                }
                break
            }
            case INPUT_FIELD.QUOTE: {
                if (!isNaN(quoteAmountInputNumber)) {
                    amounts = { quoteAmount: quoteAmountInputNumber }
                }
                break
            }
        }
        updatePriceRange({ lowerPrice, upperPrice, ...amounts })
    }, [
        baseAmountInputNumber,
        existingLiquidity,
        existingLiquidity?.lowerTickPrice,
        existingLiquidity?.upperTickPrice,
        focusedModeId,
        hasExistingLiquidity,
        lastEditField,
        lowerTickPrice,
        marketPrice,
        market,
        quoteAmountInputNumber,
        updatePriceRange,
        upperTickPrice,
    ])

    const handlePriceRangeChangeAdvanced = useCallback<PriceRangePickerAdvancedProps["onRangeChange"]>(
        ({ lowerPrice, upperPrice }) => {
            setIsPriceRangeTyping(true)
            setPriceRangeLowerInput(lowerPrice)
            setPriceRangeUpperInput(upperPrice)
        },
        [],
    )

    const lastEditAmounts = useMemo(() => {
        let amounts = {}
        switch (lastEditField) {
            case INPUT_FIELD.BASE: {
                if (!isNaN(baseAmountInputNumber)) {
                    amounts = { baseAmount: baseAmountInputNumber }
                }
                break
            }
            case INPUT_FIELD.QUOTE: {
                if (!isNaN(quoteAmountInputNumber)) {
                    amounts = { quoteAmount: quoteAmountInputNumber }
                }
                break
            }
        }
        return amounts
    }, [baseAmountInputNumber, lastEditField, quoteAmountInputNumber])

    const handlePriceRangeBlur = useCallback<PriceRangePickerAdvancedProps["onBlur"]>(() => {
        if (isNaN(priceRangeLowerInputNumber) || isNaN(priceRangeUpperInputNumber)) {
            return
        }
        updatePriceRange({
            lowerPrice: new Big(priceRangeLowerInputNumber),
            upperPrice: new Big(priceRangeUpperInputNumber),
            ...lastEditAmounts,
        })
        setIsPriceRangeTyping(false)
    }, [lastEditAmounts, priceRangeLowerInputNumber, priceRangeUpperInputNumber, updatePriceRange])

    const handlePriceRangeTickChange = useCallback(
        (rangeSide: RangeSide, direction: "increase" | "decrease") => {
            if (isNaN(priceRangeLowerInputNumber) || isNaN(priceRangeUpperInputNumber)) {
                return
            }
            updatePriceRange({
                lowerPrice: new Big(priceRangeLowerInputNumber),
                upperPrice: new Big(priceRangeUpperInputNumber),
                rangeSide,
                direction,
                ...lastEditAmounts,
            })
            setIsPriceRangeTyping(false)
        },
        [lastEditAmounts, priceRangeLowerInputNumber, priceRangeUpperInputNumber, updatePriceRange],
    )

    const setPriceRangeToFull = useCallback(() => {
        if (!tickPriceMin || !tickPriceMax) {
            return
        }
        updatePriceRange({ lowerPrice: tickPriceMin, upperPrice: tickPriceMax, ...lastEditAmounts })
    }, [lastEditAmounts, tickPriceMax, tickPriceMin, updatePriceRange])

    // NOTE: Handle price range updated.
    // NOTE: Convert to string to be valid hook dependencies.
    const priceRangeLowerInputNext = lowerTickPrice?.toString()
    const priceRangeUpperInputNext = upperTickPrice?.toString()
    const isPriceRangeLowerMinNext = tickPriceMin && lowerTickPrice?.eq(tickPriceMin)
    const isPriceRangeLowerMaxNext = tickPriceMax && upperTickPrice?.eq(tickPriceMax)
    useEffect(() => {
        if (
            focusedModeId !== MODE.ADVANCED ||
            isPriceRangeTyping ||
            !priceRangeLowerInputNext ||
            !priceRangeUpperInputNext
        ) {
            return
        }

        setPriceRangeLowerInput(isPriceRangeLowerMinNext ? PRICE_RANGE_INPUT_SYMBOL_MIN : priceRangeLowerInputNext)
        setPriceRangeUpperInput(isPriceRangeLowerMaxNext ? PRICE_RANGE_INPUT_SYMBOL_MAX : priceRangeUpperInputNext)
    }, [
        focusedModeId,
        isPriceRangeLowerMaxNext,
        isPriceRangeLowerMinNext,
        isPriceRangeTyping,
        priceRangeLowerInputNext,
        priceRangeUpperInputNext,
    ])
    /* ========== ADVANCED MODE ========== */

    /* ========== SUBMIT ========== */
    const { buyingPower, buyingPowerStr } = useBuyingPower()
    const isInsufficientBuyingPower = useMemo(() => {
        if (!buyingPower || !totalAsQuoteAmount) {
            return false
        }
        return buyingPower.lt(totalAsQuoteAmount)
    }, [buyingPower, totalAsQuoteAmount])

    const isLiquidityAmountDisabled = !buyingPower || !rangeType || rangeType === RangeType.RANGE_INVALID || isTxLoading
    const isSubmitDisabled = !isReadyToAddLiquidity || isInsufficientBuyingPower

    const { slippage } = Slippage.useContainer()
    const { notifyTxSucceed } = useNotificationTx()
    const { close: closeSelf } = useModal(ModalType.LIQUIDITY_ADD)

    const handleAddLiquidity = useCallback(() => {
        addLiquidity({
            slippage,
            options: {
                onSuccess: (receipt, contractFunctionName) => {
                    notifyTxSucceed(receipt, contractFunctionName)
                    closeSelf()
                },
            },
        })
    }, [addLiquidity, closeSelf, notifyTxSucceed, slippage])
    /* ========== SUBMIT ========== */

    // control button size
    const buttonSize = useBreakpointValue({ base: "md", lg: "sm" })

    // Calculate risk level
    const { overallRisk, estimatedWeeklyFees, estimatedWeeklyRewards } = useRiskLevel(
        priceRangeDeltaStep,
        leverageInput,
        totalAsQuoteAmountInput,
    )
    return (
        <PerpModal
            isOpen={isOpen}
            size="3xl"
            onClose={close}
            scrollBehavior="inside"
            motionPreset="slideInBottom"
            header={titleText}
            body={
                <Flex flexGrow={1} direction={{ base: "column", md: "row" }}>
                    <PerpTabs
                        defaultIndex={focusedModeIndex}
                        onChange={handleModeChange}
                        w="100%"
                        h="100%"
                        size={"sm"}
                        variant="soft-rounded"
                        colorScheme="gray"
                        tabListProps={{ pb: 2, px: 0, hidden: hasExistingLiquidity }}
                        baseTabPanelProps={{ px: 0 }}
                        tabs={[
                            {
                                tabProps: {
                                    children: "Simple",
                                    isDisabled: hasExistingLiquidity,
                                },
                                tabPanelProps: {
                                    id: ModeTabData.SIMPLE.id,
                                    children: (
                                        <VStack spacing={6} align="stretch" width="100%">
                                            <TextTitle>I think the price of {baseSymbol} will...</TextTitle>
                                            <StrategyPicker
                                                selectedStrategy={strategy}
                                                onSelectStrategy={handleStrategyChange}
                                            />

                                            <Divider />

                                            <VStack align="stretch">
                                                <TextTitle>Set Price Range</TextTitle>
                                                <PriceRangePickerSimple
                                                    mt={0}
                                                    tickPriceMode={tickPriceMode}
                                                    tickPriceRangeStepList={priceRangeStepList}
                                                    deltaStep={priceRangeDeltaStep}
                                                    onRangeChange={handlePriceRangeChangeSimple}
                                                    marketPriceStr={marketPriceStr}
                                                />
                                            </VStack>

                                            <Divider />

                                            <TextTitle>Provide Liquidity</TextTitle>
                                            <LiquidityAmountInputSimple
                                                isDisabled={isLiquidityAmountDisabled}
                                                isInvalid={isInsufficientBuyingPower}
                                                buyingPowerStr={buyingPowerStr}
                                                totalAsQuoteAmount={totalAsQuoteAmountInput}
                                                leverage={leverageInput}
                                                onTotalAsQuoteAmountChange={handleTotalAsQuoteAmountChange}
                                                onLeverageChange={handleLeverageChange}
                                            />
                                        </VStack>
                                    ),
                                },
                            },
                            {
                                tabProps: { children: "Advanced" },
                                tabPanelProps: {
                                    id: ModeTabData.ADVANCED.id,
                                    children: (
                                        <VStack spacing={6} align="stretch" width="100%">
                                            <TextTitle>Set Price Range</TextTitle>
                                            <PriceRangePickerAdvanced
                                                isDisabled={hasExistingLiquidity}
                                                rangeType={rangeType}
                                                lowerPrice={priceRangeLowerInput}
                                                upperPrice={priceRangeUpperInput}
                                                onRangeChange={handlePriceRangeChangeAdvanced}
                                                onIncrease={rangeSide =>
                                                    handlePriceRangeTickChange(rangeSide, "increase")
                                                }
                                                onDecrease={rangeSide =>
                                                    handlePriceRangeTickChange(rangeSide, "decrease")
                                                }
                                                onBlur={handlePriceRangeBlur}
                                                onFullRangeClick={setPriceRangeToFull}
                                                marketPriceStr={marketPriceStr}
                                            />

                                            <Divider />

                                            <TextTitle>Provide Liquidity</TextTitle>
                                            <LiquidityAmountInputAdvanced
                                                isDisabled={isLiquidityAmountDisabled}
                                                isInvalid={isInsufficientBuyingPower}
                                                baseAssetSymbol={baseSymbol}
                                                rangeType={rangeType}
                                                buyingPowerStr={buyingPowerStr}
                                                baseTokenAmount={baseAmountInput}
                                                quoteTokenAmount={quoteAmountInput}
                                                totalAmountAsQuote={totalAmountAsQuoteStr}
                                                onBaseAmountChange={handleBaseAmountChange}
                                                onQuoteAmountChange={handleQuoteAmountChange}
                                            />
                                        </VStack>
                                    ),
                                },
                            },
                        ]}
                        trackingProps={{ eventName: EventName.POOL_ADD_LIQUIDITY_MODE_TOGGLE }}
                    />

                    <Divider display={{ base: "block", md: "none" }} />

                    <VStack
                        pb={4}
                        pt={{ base: 6, md: 0 }}
                        pl={{ base: 0, md: 6 }}
                        ml={{ base: 0, md: 6 }}
                        borderLeftWidth={{ base: "none", md: "1px" }}
                        borderLeftColor="whiteAlpha.300"
                        minW="260px"
                        maxW={{ base: "100%", md: "260px" }}
                        spacing={6}
                        align="stretch"
                    >
                        {/* TODO: Add when BE ready */}
                        {/* <StatsPreview isSimpleMode={focusedModeId === MODE.SIMPLE} /> */}
                        {focusedModeId === MODE.SIMPLE ? (
                            <VStack align="stretch" spacing={4}>
                                <Text fontWeight="bold" fontSize="md">
                                    Easy LP
                                </Text>
                                <Text fontSize={"sm"} color="gray.400">
                                    If the price moves as you expected, you will earn transaction fees and Pool Party
                                    rewards without impermanent loss.
                                    <br /> <br />
                                    <Link
                                        color="perpetual.300"
                                        as="a"
                                        href="https://perpetualprotocolhelp.zendesk.com/hc/en-us/articles/5257563598617-Easy-LP"
                                        isExternal
                                    >
                                        Learn more ↗︎
                                    </Link>
                                </Text>
                                <Text fontWeight="bold" fontSize="md">
                                    Summary
                                </Text>
                                <VStack>
                                    <Stats label="Overall Risk" number={overallRisk} variant="left" size="xs" />
                                    <Stats
                                        label="Est. weekly Fees"
                                        number={"$" + estimatedWeeklyFees}
                                        variant="left"
                                        size="xs"
                                    />
                                    <Stats
                                        label="Est. weekly Rewards"
                                        number={"$" + estimatedWeeklyRewards}
                                        variant="left"
                                        size="xs"
                                    />
                                </VStack>
                            </VStack>
                        ) : (
                            <VStack align="stretch" spacing={4}>
                                <Text fontWeight="bold" fontSize="md">
                                    Advanced Mode
                                </Text>

                                <Text fontSize={"sm"} color="gray.400">
                                    Manually set price range and token allocation.
                                </Text>
                            </VStack>
                        )}
                        <Spacer />
                        <Divider />
                        <HStack>
                            <TxButton
                                size={buttonSize}
                                width="full"
                                leftIcon={<AddDrop boxSize="20px" />}
                                colorScheme="purple"
                                isDisabled={isSubmitDisabled}
                                onClick={handleAddLiquidity}
                            >
                                {submitButtonText}
                            </TxButton>
                            <SlippagePopover />
                        </HStack>
                    </VStack>
                </Flex>
            }
        />
    )
}
