import { AppNetwork } from "module/network/container/AppNetwork"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"

import { FeatureToggleContainer } from "container/service/FeatureToggleContainer"
import { Contract } from "ethers"
import { useCallback, useEffect, useState } from "react"
import { useAsync } from "react-use"
import { createContainer } from "unstated-next"

const abis = ["function getMyRefereeCode() view returns (string)", "function setReferralCode(string referralCode)"]

export const Referral = createContainer(useReferral)

function useReferral() {
    const { account, signer } = Web3WalletContainer.useContainer()
    const { isReferralEnable } = FeatureToggleContainer.useContainer()
    const { referralContractAddress = "" } = AppNetwork.useContainer()
    const [referralCode, setReferralCode] = useState<string>()
    const [referralContract, setReferralContract] = useState<Contract>()

    useEffect(() => {
        const referralContract = new Contract(referralContractAddress, abis, signer)
        setReferralContract(referralContract)
    }, [account, signer, referralContractAddress])

    useAsync(async () => {
        if (referralContract && isReferralEnable) {
            try {
                const referralCode: string = await referralContract.getMyRefereeCode()
                setReferralCode(referralCode)
            } catch (error) {
                // NOTE: https://optimistic.etherscan.io/address/0xfd849d5df5cb4b631ad595b0e9ff70a1a66c743f#code
                // NOTE: revert, You do not have a referral code
                setReferralCode(undefined)
            }
        } else {
            setReferralCode(undefined)
        }
    }, [referralContract, isReferralEnable])

    const registerReferralCode = useCallback(
        async (referralCode: string = "") => {
            if (referralContract) {
                try {
                    const txConfirmed = await referralContract.setReferralCode(referralCode)
                    const receipt = await txConfirmed.wait()
                    return receipt
                } catch (error: any) {
                    console.log("error", error)
                    return new Error(error?.data?.message)
                }
            }
        },
        [referralContract],
    )

    return { registerReferralCode, referralCode }
}
