import { Position, PositionSide } from "@perp/sdk-curie"
import Big from "big.js"
import { ModalType } from "component/Modal/type"
import { useModal } from "container/modal"
import { utils } from "ethers"
import { useNotification } from "module/notification/hook/useNotification"
import { useNotificationTx } from "module/notification/hook/useNotificationTx"
import { OpenPositionService } from "module/otcMaker/OpenPositionService"
import { OtcMakerClient } from "module/otcMaker/OtcMakerClient"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"
import { useCallback } from "react"
import { usePerpTransactionSender } from "sdk-react/perpetualProtocol/usePerpTransactionSender"
import { Handlers } from "sdk-react/tools/useHandlers"
import { TxLoading } from "sdk-react/tools/useTxLoading"
import { toWei } from "util/format"
import { isUserDeniedSignature } from "util/is"
import { usePositionDetail } from "./usePositionDetail"

interface ClosePositionParams {
    slippage: Big
    referralCode?: string
}

export function usePositionClose(position: Position) {
    const { notifyTxSucceed } = useNotificationTx()
    const { notifySuccess, notifyError } = useNotification()
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { isTxLoading, setTxLoading } = TxLoading.useContainer()

    const { close: closeModalPositionClose } = useModal(ModalType.POSITION_CLOSE)
    const { isUsingOtcExitPrice, exitPrice } = usePositionDetail(position)
    const { accountChainId, signer, account } = Web3WalletContainer.useContainer()

    const closeCorePosition = usePerpTransactionSender<ClosePositionParams>(
        ({ clearingHouse }, { slippage, referralCode }) => {
            return clearingHouse.closePosition(position, slippage, referralCode)
        },
        [],
    )

    const closeOtcMakerPosition = useCallback(
        async (params: ClosePositionParams) => {
            if (!exitPrice || !signer || !account || !accountChainId) {
                return
            }
            const otcMakerClient = new OtcMakerClient()
            const openPositionService = new OpenPositionService()
            const { slippage, referralCode } = params

            const isExitSideLong = !(position.side === PositionSide.LONG)
            const slippageWithFee = slippage.add(0.001)
            const oppositeAmountBound = openPositionService.calcOppositeAmountBound(
                isExitSideLong,
                position.sizeAbs,
                true,
                exitPrice,
                slippageWithFee,
            )

            const limitOrderParams = {
                trader: account,
                baseToken: position.market.baseAddress,
                orderType: 3,
                amount: toWei(position.sizeAbs),
                oppositeAmountBound: toWei(oppositeAmountBound),
                isBaseToQuote: !isExitSideLong,
                isExactInput: !isExitSideLong,
                salt: Math.floor(Math.random() * 1000),
                deadline: Math.floor(Date.now() / 1000 + 86400),
                reduceOnly: false,
                sqrtPriceLimitX96: Big(0),
                referralCode: utils.formatBytes32String(referralCode || ""),
                roundIdWhenCreated: Big(0),
                triggerPrice: Big(0),
            }

            try {
                setTxLoading(true)
                const signature = await otcMakerClient.sign(signer, limitOrderParams)
                const { isSuccess, txHash, message } = await otcMakerClient.openPosition(
                    accountChainId,
                    limitOrderParams,
                    signature,
                )
                if (isSuccess && txHash) {
                    // TODO: we need to update the graph to prevent updating the limit order history
                    closeModalPositionClose()
                    notifySuccess({
                        title: "Smart Maker Transaction Sent Successfully",
                        description: "Positions should be updated shortly.",
                        duration: 10 * 1000,
                    })
                } else {
                    notifyError({ title: "Smart Maker Transaction Failed", description: message })
                }
            } catch (err: any) {
                if (isUserDeniedSignature(err)) {
                    notifyError({ title: "Signing Smart Maker Transaction Rejected" })
                } else {
                    notifyError({ title: "Smart Maker Transaction Failed" })
                    onError(err as Error)
                }
            } finally {
                setTxLoading(false)
            }
        },
        [
            account,
            accountChainId,
            exitPrice,
            notifyError,
            notifySuccess,
            onError,
            position,
            setTxLoading,
            signer,
            closeModalPositionClose,
        ],
    )

    const closePosition = useCallback(
        (params: ClosePositionParams) => {
            if (isUsingOtcExitPrice) {
                closeOtcMakerPosition(params)
            } else {
                closeCorePosition({
                    ...params,
                    options: {
                        onSuccess: (receipt, contractFunctionName) => {
                            closeModalPositionClose()
                            notifyTxSucceed(receipt, contractFunctionName)
                        },
                    },
                })
            }
        },
        [closeCorePosition, closeModalPositionClose, closeOtcMakerPosition, isUsingOtcExitPrice, notifyTxSucceed],
    )

    return {
        closePosition,
        isTxLoading,
        isUsingOtcExitPrice,
    }
}
