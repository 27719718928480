import { NetworkSwitcher } from "module/network/component/NetworkSwitcher"
import { SwitchNetworkForMeButton } from "module/network/component/SwitchNetworkForMeButton"
import { AppNetwork } from "module/network/container/AppNetwork"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"

import { Box, HStack, Spacer, useColorModeValue } from "@chakra-ui/react"
import { Avatar } from "component/Avatar"
import { LogoBW, LogoColor } from "component/Icon"
import { Link as RouterLink } from "react-router-dom"

export function Home() {
    const { accountChainId } = Web3WalletContainer.useContainer()
    const { isAccountOnSupportedNetwork } = AppNetwork.useContainer()

    const AdaptiveLogo = useColorModeValue(LogoColor, LogoBW)

    return (
        <HStack align="center" width="100%" p={[6, 6]} pb={[6, 4]}>
            {!accountChainId && (
                <Box as={RouterLink} to="/">
                    <AdaptiveLogo boxSize={["32px", "32px"]} />
                </Box>
            )}
            {!accountChainId && (
                <Box as={RouterLink} to="/">
                    <AdaptiveLogo boxSize={["36px", "32px"]} />
                </Box>
            )}
            <Avatar />
            <Spacer />
            {!!accountChainId && (isAccountOnSupportedNetwork ? <NetworkSwitcher /> : <SwitchNetworkForMeButton />)}
        </HStack>
    )
}
