import { AppConfig } from "container/appConfig"
import { useAsync } from "react-use"
import { StatisticsService } from "service/appSync/statisticsService"
import { createContainer } from "unstated-next"

export const StatisticServiceContainer = createContainer(useStatisticService)

function useStatisticService() {
    const { appConfig } = AppConfig.useContainer()
    const { key, url, region } = appConfig?.statisticsServerConfig || {}

    const {
        value: statisticService,
        loading,
        error,
    } = useAsync(async () => {
        if (!key || !url || !region) {
            return
        }
        const service = new StatisticsService({ key, url, region })
        await service.hydrated()
        return service
    }, [key, url, region])

    return {
        statisticService,
        loading,
        error,
    }
}
