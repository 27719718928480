// import { EventName } from "module/analytics"

import { EventName } from "module/analytics/types"

import { Box, Button, Checkbox, Divider, ListItem, Text, UnorderedList, useColorModeValue } from "@chakra-ui/react"
import { PerpLink } from "component/PerpLink"
import { PerpModal } from "component/PerpModal"
import { StorageDataMap } from "constant/storage"
import { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useLocalStorage } from "react-use"

const { SHOW_GREETING_MODAL } = StorageDataMap

export function ModalGreeting() {
    const { t } = useTranslation()
    const cardSubBg = useColorModeValue("subBackground.light", "subBackground.dark")
    const [shouldShowGreeting, setShouldShowGreeting] = useLocalStorage(
        SHOW_GREETING_MODAL.name,
        SHOW_GREETING_MODAL.defaultValue,
    )
    const [hasAgreed, setHasAgreed] = useState(false)
    return (
        <PerpModal
            isOpen={shouldShowGreeting !== "false"}
            onClose={() => {}}
            isCloseButtonHidden={true}
            scrollBehavior="inside"
            motionPreset="slideInBottom"
            header={
                <Box textAlign="center" mt={2} color="perpetual.100">
                    {t("onboarding.title")}
                </Box>
            }
            body={
                <>
                    <Box position="relative" borderRadius="2xl" overflow="hidden" bg={cardSubBg} p={4}>
                        <UnorderedList>
                            <ListItem>
                                <Text>{t("onboarding.paragraph_1")}</Text>
                            </ListItem>

                            <ListItem>
                                <Trans i18nKey="onboarding.paragraph_3">
                                    <PerpLink
                                        trackingProps={{ eventName: EventName.LINK_ONBOARDING_SERVICE_DISCORD_CLICK }}
                                        href="https://discord.gg/mYKKRTn"
                                        style={{ textDecoration: "underline", fontWeight: "bold" }}
                                    >
                                        Discord
                                    </PerpLink>
                                </Trans>
                            </ListItem>
                            <ListItem>
                                <Trans i18nKey="onboarding.paragraph_4">
                                    <strong>BETA</strong>
                                </Trans>
                            </ListItem>
                        </UnorderedList>
                    </Box>
                    <Divider mt={4} mb={4} />
                    <Checkbox onChange={() => setHasAgreed(!hasAgreed)} mb={4}>
                        <Trans i18nKey="onboarding.term_of_service">
                            <PerpLink
                                trackingProps={{ eventName: EventName.LINK_TERMS_OF_SERVICE_CLICK }}
                                href="https://support.perp.com/hc/en-us/articles/7529352712729"
                                style={{ textDecoration: "underline", fontWeight: "bold" }}
                            >
                                Terms of Service
                            </PerpLink>
                        </Trans>
                    </Checkbox>
                    <Button
                        isDisabled={!hasAgreed}
                        onClick={() => setShouldShowGreeting("false")}
                        size="md"
                        colorScheme="perpetual"
                        width="full"
                    >
                        {t("onboarding.start_trading")}
                    </Button>
                </>
            }
        />
    )
}
