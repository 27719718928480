import { formatUnits, parseUnits } from "@ethersproject/units"
import { ERC20_DECIMAL_DIGITS, getLeastSignificantDigit } from "@perp/sdk-curie"
import Big from "big.js"
import { BASE_ASSET_GENERAL_PRECISION, FUNDING_PAYMENT_PRECISION, FUNDING_RATE_PRECISION } from "constant/number"
import { BigNumber, utils } from "ethers"

export function bigNum2Big(val: BigNumber, decimals: number = ERC20_DECIMAL_DIGITS): Big {
    return new Big(val.toString()).div(new Big(10).pow(decimals))
}

// NOTE: Big to...
export function big2BigNum(val: Big, decimals: number = ERC20_DECIMAL_DIGITS): BigNumber {
    return BigNumber.from(val.mul(new Big(10).pow(decimals)).toFixed(0))
}

export function fromWei(value: Big, decimals = 18): Big {
    return Big(formatUnits(value.toFixed(0), decimals).toString())
}

export function toWei(value: Big, decimals = 18): Big {
    return Big(parseUnits(value.toFixed(decimals), decimals).toString())
}

const isNumeric = (str: string) => {
    return !isNaN(parseFloat(str))
}

export const strToBig = (str: string) => {
    if (isNumeric(str)) {
        return new Big(str)
    }
    return undefined
}

/**
 * NOTE:
 * @example num = Big(1000.789), precisions = 2, return = 1,000.78
 * @example num = Big(1000), precisions = 3, return = 1,000.000
 * @example num = Big(0), precisions = 4, return = 0.0000
 */
export function formatNumber(num: Big, precisions: number, showSign = false) {
    const integer = num.toFixed(0, 0)
    const sign = showSign && num.gt(0) ? "+" : "" // NOTE: num come with '-' when negative; don't add sign when is 0.
    const decimal = num.sub(integer).abs()

    const integerStr = `${sign}${utils.commify(integer)}`
    if (precisions <= 0) {
        return integerStr
    }
    let decimalStr = decimal.toFixed().slice(1, 2 + precisions)
    for (let i = decimalStr.length; i <= precisions; i++) {
        if (i === 0) {
            decimalStr += "."
        } else {
            decimalStr += "0"
        }
    }
    return integerStr + decimalStr
}

interface formatNumberUSDArgs {
    num: Big
    ignoreDust?: boolean
    displayLength?: number
    minimal?: number
}

export function formatNumberUSD({ num, ignoreDust = false, displayLength = 6, minimal = 1 }: formatNumberUSDArgs) {
    if (ignoreDust && num.lt(0.01) && num.gt(-0.01)) {
        return `< 0.01`
    }
    return formatNumber(num, getLeastSignificantDigit(num, displayLength, minimal))
}

export function formatPercentage(num: Big, isRaw = true, showSign = false, precision = 1) {
    return formatNumber(num.mul(isRaw ? 100 : 1), precision, showSign)
}

export function formatNumberPositionSize(num: Big) {
    if (num.lt(0.0001) && num.gt(-0.0001)) {
        return `< 0.0001`
    }
    if (num.eq(0)) {
        return `0`
    }
    return formatNumber(num, getLeastSignificantDigit(num, BASE_ASSET_GENERAL_PRECISION))
}

export function formatNumberFundingRate(num: Big) {
    const numberStr = formatNumber(num, FUNDING_RATE_PRECISION)
    return num.gte(0) ? `+${numberStr}%` : `${numberStr}%` // NOTE: numberStr could be negative
}

export function formatNumberFundingPayment(num: Big, ignoreDust: boolean = true) {
    if (ignoreDust && num.lt(0.0001) && num.gt(-0.0001)) {
        return `< 0.0001`
    }
    if (num.eq(0)) {
        return `0`
    }
    return formatNumber(num, FUNDING_PAYMENT_PRECISION)
}

export function formatNumberWithHumanReadableUnit(num: Big) {
    const numAbs = num.abs()
    return numAbs.gte(1.0e9)
        ? `${numAbs.div(1.0e9).toFixed(2)}B`
        : numAbs.gte(1.0e6)
        ? `${numAbs.div(1.0e6).toFixed(1)}M`
        : numAbs.gte(1.0e3)
        ? `${numAbs.div(1.0e3).toFixed(0)}K`
        : numAbs.toFixed()
}

// TODO: check is valid number
// TODO: check is positive
// TODO: use in slippage if possible
// ex: if the input is 1.005 and the digits is 2, the function will return 1.00
export function formatInput(input: string, digits: number): string {
    const firstDotIndex = input.indexOf(".")
    const formattedString = input
        .split("")
        .filter((alphabet, index) => {
            return alphabet !== "." || index === firstDotIndex
        })
        .join("")
    return formattedString.includes(".") && formattedString.length > formattedString.indexOf(".") + (digits + 1)
        ? formattedString.substr(0, formattedString.indexOf(".") + (digits + 1))
        : formattedString
}

export function getFirstNonZeroLog(n: number): number {
    if (n >= 1) return -1
    return Math.floor(Math.abs(Math.log10(n)))
}

// format negative USD, use this if it is already a USD string and can be negative
export function formatUSD(usd: string) {
    if (usd.startsWith("-")) {
        return usd.replace("-", "-$")
    } else {
        return "$" + usd
    }
}
