import SegmentService, { EventGroup, EventTypeInteraction } from "module/analytics/service/SegmentService"
import { TrackingProps } from "module/analytics/types"

import { AnchorHTMLAttributes, ReactNode, useCallback } from "react"

interface PerpLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string
    children: ReactNode
    trackingProps?: TrackingProps
}
export function PerpLink({ href, children, trackingProps, ...props }: PerpLinkProps) {
    const handleClick = useCallback(() => {
        trackingProps &&
            SegmentService.track(EventGroup.INTERACTION, {
                eventType: EventTypeInteraction.LINK_CLICKED,
                ...trackingProps,
                payload: { href, ...trackingProps.payload },
            })
    }, [href, trackingProps])

    return (
        <a href={href} target="_blank" rel="noreferrer noopener" {...props} onClick={handleClick}>
            {children}
        </a>
    )
}
