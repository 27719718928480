import { MarketDataAll, MarketMap, MarketStatus, Pool } from "@perp/sdk-curie"
import { Big } from "big.js"
import _ from "lodash"
import { useState } from "react"
import { usePerpInitializedEffect } from "sdk-react/perpetualProtocol/usePerpInitializedEffect"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

export interface MarketData {
    status: MarketStatus
    marketPrice: Big
    markPrice: Big
    indexPrice: Big
    indexTwapPrice: Big
}
export type MarketDataMap = Record<string, MarketData>
export type PoolMetadataMap = Record<string, Pool | undefined>

export const MarketsContainer = createContainer(useMarkets)

function useMarkets() {
    const [marketMap, setMarketMap] = useState<MarketMap>()
    const [marketDataMap, setMarketDataMap] = useState<MarketDataMap>()
    const [poolMetadataMap, setPoolMetadataMap] = useState<PoolMetadataMap>()

    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()

    usePerpInitializedEffect(({ metadata, markets }) => {
        const { marketMap: _marketMap } = markets
        const poolMetadataMap = _.mapValues(_marketMap, market => {
            return metadata.rawPools.find(
                ({ baseAddress }) => baseAddress.toLowerCase() === market.baseAddress.toLowerCase(),
            )
        })
        setMarketMap(_marketMap)
        setPoolMetadataMap(poolMetadataMap)

        const unsubscribeUpdated = markets.on("updated", (marketDataAll: MarketDataAll) =>
            setMarketDataMap(marketDataAll),
        )

        const unsubscribeError = markets.on("updateError", ({ error }) => {
            onError(error)
        })

        return () => {
            unsubscribeUpdated()
            unsubscribeError()
        }
    }, [])

    return {
        marketMap,
        marketDataMap,
        poolMetadataMap,
    }
}
