import { PerpetualProtocolConnected } from "@perp/sdk-curie"
import { DependencyList, EffectCallback, useEffect, useRef } from "react"
import { Handlers } from "sdk-react/tools/useHandlers"

import { PerpSDK } from "./usePerpSDK"

type PerpConnectedEffect = (perp: PerpetualProtocolConnected) => ReturnType<EffectCallback>

export function usePerpConnectedEffect(effect: PerpConnectedEffect, deps: DependencyList) {
    const { perp, isConnected } = PerpSDK.useContainer()

    const savedEffect = useRef(effect)
    useEffect(() => {
        savedEffect.current = effect
    }, [effect])

    const { getHandlers } = Handlers.useContainer()

    useEffect(() => {
        const { onError } = getHandlers()
        if (!perp?.hasConnected() || !isConnected) {
            return
        }
        try {
            return savedEffect.current(perp)
        } catch (error) {
            onError(error)
        }
    }, [perp, isConnected, ...deps]) // eslint-disable-line react-hooks/exhaustive-deps
}
