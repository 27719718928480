import { EventName } from "module/analytics/types"

import {
    Box,
    Button,
    HStack,
    Icon,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Text,
    useColorModeValue,
} from "@chakra-ui/react"
import { PerpSlider } from "component/PerpSlider"
import { RiDropFill } from "react-icons/ri"

interface RemoveLiquidityProps {
    ratio: number
    onUpdateSlider: (value: number) => void
}

export default function RemoveLiquidity({ ratio, onUpdateSlider }: RemoveLiquidityProps) {
    const subText = useColorModeValue("gray.500", "gray.400")
    const inputBg = useColorModeValue("whiteAlpha.500", "blackAlpha.500")

    return (
        <Box>
            <HStack spacing={0}>
                <Text variant="label">Amount</Text>
            </HStack>
            <Box mt={3} borderRadius="2xl" bg={inputBg} p={4} py={3} w="100%">
                <HStack spacing={10} h="34px" pl={3}>
                    <PerpSlider
                        trackingProps={{
                            eventName: EventName.LIQUIDITY_REMOVE_CHANGE,
                            payload: { percentOfLiquidity: ratio },
                        }}
                        size={"lg"}
                        colorScheme="purple"
                        value={ratio}
                        onChange={onUpdateSlider}
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb boxSize={8} bg="purple.500">
                            <Icon as={RiDropFill} boxSize={"16px"} color="whiteAlpha.900" />
                        </SliderThumb>
                    </PerpSlider>
                    <Button size="xs" colorScheme="purple" variant="solid" onClick={() => onUpdateSlider(100)}>
                        Max
                    </Button>
                </HStack>

                <HStack align="stretch" w="100%" mt={2}>
                    <Text whiteSpace="nowrap" fontSize="xs" color={subText}>
                        {ratio.toString()}% of Liquidity
                    </Text>
                </HStack>
            </Box>
        </Box>
    )
}
