import { gql } from "@apollo/client"

export const LIST_CANDLE_STICKS = gql`
    query ListCandleStick($query: TableCandleStickQueryInput!, $limit: Int) {
        listCandleSticks(query: $query, limit: $limit) {
            items {
                market
                resolution
                startTime
                open
                high
                low
                close
                volume
                baseAssetVol
                txCount
                blockNumber
                version
            }
        }
    }
`

export const ON_UPSERT_CANDLE_STICK = gql`
    subscription OnUpsertCandleStick($market: String!, $resolution: String!) {
        onUpsertCandleStick(market: $market, resolution: $resolution) {
            baseAssetVol
            close
            high
            low
            market
            open
            resolution
            startTime
            txCount
            version
            volume
        }
    }
`

export const GET_STATISTICS = gql`
    query GetStatistics($ammAddress: String!) {
        getStatistics(ammAddr: $ammAddress) {
            volume24h
            priceChangeRate24h
            priceHigh24h
            priceLow24h
        }
    }
`

export const ON_UPSERT_STATISTICS = gql`
    subscription OnUpsertStatistics($ammAddress: String!) {
        onUpsertStatistics(ammAddr: $ammAddress) {
            volume24h
            priceChangeRate24h
            priceHigh24h
            priceLow24h
        }
    }
`

export const LIST_MARKET_APRS = gql`
    query ListMarketAprs($limit: Int!, $nextToken: String!) {
        listMarketAprs(limit: $limit, nextToken: $nextToken) {
            nextToken
            items {
                baseSymbol
                quoteSymbol
                marketSymbol
                lowerBaseApr
                upperBaseApr
                riskLevelBaseAprs
                lowerRewardApr
                upperRewardApr
                riskLevelRewardAprs
                lowerRewardOpApr
                upperRewardOpApr
                riskLevelRewardOpAprs
            }
        }
    }
`

export const LIST_MAKER_APRS = gql`
    query ListMakerAprs($address: String!) {
        listMakerAprs(address: $address) {
            items {
                baseSymbol
                quoteSymbol
                marketSymbol
                baseApr
                rewardApr
                rewardOpApr
            }
        }
    }
`

export const GET_CLAIMABLE_REWARDS = gql`
    query GetClaimableRewards($address: String!) {
        getClaimableRewards(address: $address) {
            totalBalance
        }
    }
`

export const GET_ACCOUNT = gql`
    query GetAccount($address: String!) {
        getAccount(address: $address) {
            claimableGasRebates {
                balance
            }
        }
    }
`

export const LIST_FUNDING_RATES = gql`
    query ListFundingRates($query: FundingRatesQueryInput!, $limit: Int, $nextToken: String) {
        listFundingRates(query: $query, limit: $limit, nextToken: $nextToken) {
            items {
                marketResolution
                timestamp
                fundingRate
                eventCount
                lastEventTimestamp
                lastEventBlockNumber
                version
            }
            nextToken
        }
    }
`

export const ACTIVE_LIMIT_ORDER_LIST_BY_TRADER_AND_BASE_TOKEN_QUERY = gql`
    query ListByTraderAndBaseToken($trader: String!, $baseToken: String!) {
        listByTraderAndBaseToken(trader: $trader, baseToken: $baseToken) {
            success
            error
            result {
                orderType
                salt
                trader
                baseToken
                isBaseToQuote
                isExactInput
                amount
                oppositeAmountBound
                deadline
                sqrtPriceLimitX96
                referralCode
                reduceOnly
                roundIdWhenCreated
                limitPriceForDisplay
                triggerPrice
                created
                hash
                lastSubmitFailReason
            }
        }
    }
`

export const CREATE_LIMIT_ORDER_MUTATION = gql`
    mutation CreateLimitOrder(
        $orderType: String!
        $hash: String!
        $salt: String!
        $trader: String!
        $baseToken: String!
        $isBaseToQuote: Boolean!
        $isExactInput: Boolean!
        $amount: String!
        $oppositeAmountBound: String!
        $deadline: String!
        $sqrtPriceLimitX96: String!
        $referralCode: String!
        $reduceOnly: Boolean!
        $roundIdWhenCreated: String!
        $triggerPrice: String!
        $limitPriceForDisplay: String!
        $sig: String!
        $version: Int!
    ) {
        createLimitOrder(
            input: {
                orderType: $orderType
                hash: $hash
                salt: $salt
                trader: $trader
                baseToken: $baseToken
                isBaseToQuote: $isBaseToQuote
                isExactInput: $isExactInput
                amount: $amount
                oppositeAmountBound: $oppositeAmountBound
                deadline: $deadline
                sqrtPriceLimitX96: $sqrtPriceLimitX96
                referralCode: $referralCode
                reduceOnly: $reduceOnly
                roundIdWhenCreated: $roundIdWhenCreated
                triggerPrice: $triggerPrice
                limitPriceForDisplay: $limitPriceForDisplay
                sig: $sig
                version: $version
            }
        ) {
            error
            success
        }
    }
`
