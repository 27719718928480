import Big from "big.js"
import { useEffect, useState } from "react"
import { useMarket } from "sdk-react/markets/useMarket"

import { StatisticServiceContainer } from "./StatisticsServiceContainer"

export const useAmmStatistics = ({ tickerSymbol }: { tickerSymbol: string }) => {
    const { statisticService } = StatisticServiceContainer.useContainer()
    const { poolMetadata } = useMarket(tickerSymbol)
    const { baseAddress: rawBaseAddress } = poolMetadata || {}

    const [priceChangeRate24h, setPriceChangeRate24h] = useState<Big>()
    const [volume24h, setVolume24h] = useState<Big>()
    useEffect(() => {
        async function getAmmStatistic() {
            if (!rawBaseAddress || !statisticService) {
                return
            }
            const ammStatistics = await statisticService.getAmmStatistics(rawBaseAddress)
            const { volume24h, priceChangeRate24h } = ammStatistics || {}
            if (volume24h) {
                setVolume24h(new Big(volume24h))
            }

            if (priceChangeRate24h) {
                setPriceChangeRate24h(new Big(priceChangeRate24h))
            }
        }

        getAmmStatistic()
    }, [rawBaseAddress, statisticService])

    useEffect(() => {
        if (!rawBaseAddress || !statisticService) {
            return
        }

        statisticService.subscribe(rawBaseAddress, ({ priceChangeRate24h, volume24h }) => {
            if (volume24h) {
                setVolume24h(new Big(volume24h))
            }

            if (priceChangeRate24h) {
                setPriceChangeRate24h(new Big(priceChangeRate24h))
            }
        })

        return () => {
            statisticService.unsubscribe(rawBaseAddress)
        }
    }, [rawBaseAddress, statisticService])

    return {
        priceChangeRate24h,
        volume24h,
    }
}
