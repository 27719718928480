import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Button, Icon, Menu, MenuButton, MenuItem, MenuList, MenuOptionGroup, Portal } from "@chakra-ui/react"
import { Optimism } from "component/Icon"
import { ChainId, CHAINS, VALID_APP_CHAIN_IDS } from "../constant"
import { AppNetwork } from "../container/AppNetwork"
import { switchNetwork } from "../util/switchNetwork"

const IconMap = {
    [ChainId.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI as number]: Optimism,
    [ChainId.OPTIMISTIC_ETHEREUM as number]: Optimism,
}

export function NetworkSwitcher() {
    const { appChainId } = AppNetwork.useContainer()
    const CurrentChainIcon = IconMap[appChainId]

    return (
        <Menu>
            <MenuButton as={Button} colorScheme={"gray"} size="sm" px={2} fontSize="xs">
                <Icon boxSize="22px" as={CurrentChainIcon} />
                <ChevronDownIcon />
            </MenuButton>
            <Portal>
                <MenuList minWidth="260px">
                    <MenuOptionGroup value={`${appChainId}`} type="radio">
                        {VALID_APP_CHAIN_IDS.map(chainId => {
                            const { alias } = CHAINS[chainId] || {}
                            const ChainIcon = IconMap[chainId]
                            return (
                                <MenuItem
                                    bg={appChainId === chainId ? "rgba(255,255,255,0.05)" : "transparent"}
                                    _hover={{ bg: "rgba(255,255,255,0.05)" }}
                                    key={`network-option-${chainId}`}
                                    value={`${chainId}`}
                                    onClick={() => switchNetwork(chainId)}
                                >
                                    <Box>
                                        <Icon mr={1} boxSize="24px" as={ChainIcon} />
                                        {alias}
                                    </Box>
                                </MenuItem>
                            )
                        })}
                    </MenuOptionGroup>
                </MenuList>
            </Portal>
        </Menu>
    )
}
