import { ChainId } from "../constant"

interface ConfigAdd {
    chainId: string
    chainName: string
    nativeCurrency: {
        name: string
        symbol: string
        decimals: number
    }
    rpcUrls: string[]
    blockExplorerUrls: string[]
}

const optimisticEthereumConfig: ConfigAdd = {
    chainId: "0xA",
    chainName: "Optimistic Ethereum",
    nativeCurrency: {
        name: "OETH",
        symbol: "OETH",
        decimals: 18,
    },
    rpcUrls: ["https://mainnet.optimism.io/"],
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
}

const optimisticEthereumTestnetGoerliConfig: ConfigAdd = {
    chainId: "0x1a4",
    chainName: "Optimistic Ethereum Testnet Goerli",
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
    },
    rpcUrls: ["https://goerli.optimism.io/"],
    blockExplorerUrls: ["https://goerli-optimistic.etherscan.io/"],
}

async function requestSwitch(config: any, method: "wallet_switchEthereumChain" | "wallet_addEthereumChain") {
    const ethereum: any = window.ethereum
    if (!ethereum) {
        return
    }
    return ethereum.selectedProvider
        ? ethereum.selectedProvider.request({ method, params: [config] })
        : ethereum.request({ method, params: [config] }) //  NOTE: if no selectedProvider, use default one
}

export async function switchNetworkForInjectedProvider(chainId: number) {
    try {
        switch (chainId) {
            case ChainId.OPTIMISTIC_ETHEREUM: {
                return await requestSwitch(optimisticEthereumConfig, "wallet_addEthereumChain")
            }
            case ChainId.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI: {
                return await requestSwitch(optimisticEthereumTestnetGoerliConfig, "wallet_addEthereumChain")
            }
            default: {
                throw Error(`Unsupported Chain ID: ${chainId}.`)
            }
        }
    } catch (error: any) {
        if (error.code === 4902) {
            // NOTE: should not happen, the chain should be one of the default chains.
            console.error("The chain has not been added to MetaMask.")
        } else if (error.code === 4001) {
            // EIP-1193 userRejectedRequest error
            console.error("We can encrypt anything without the key.")
        } else {
            console.error(error)
        }
    }
}
export async function switchNetwork(chainId: number) {
    switchNetworkForInjectedProvider(chainId)
}
