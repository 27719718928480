import { Stage, getStage } from "./stage"

const {
    REACT_APP_STAGE,
    REACT_APP_GITHUB_TAG,
    REACT_APP_SDK_CURIE_VERSION,
    REACT_APP_CURIE_DEPLOYMENTS_VERSION,
    REACT_APP_SUPPORTED_CHAIN_IDS,
    REACT_APP_CONFIG_ENDPOINT,
    REACT_APP_PROVIDER_GRAPH_SERVER_CONFIG_ENDPOINT,
    REACT_APP_FLAGSMITH_ENV_ID,
    REACT_APP_AIRTABLE_API_KEY,
    REACT_APP_SEGMENT_API_KEY,
    REACT_APP_BUGSNAG_API_KEY,
    REACT_APP_BRIDGE_SOCKET_API_KEY,
} = process.env

const STAGE = getStage(REACT_APP_STAGE)
const IS_DEVELOPMENT = STAGE === Stage.DEV1 || STAGE === Stage.DEV2
const IS_STAGING = STAGE === Stage.STAGING
const IS_PRODUCTION = STAGE === Stage.PRODUCTION

const APP_SUPPORTED_CHAIN_IDS = REACT_APP_SUPPORTED_CHAIN_IDS?.split(",").map(Number) || []

export {
    STAGE,
    IS_DEVELOPMENT,
    IS_STAGING,
    IS_PRODUCTION,
    APP_SUPPORTED_CHAIN_IDS,
    REACT_APP_GITHUB_TAG as GITHUB_TAG,
    REACT_APP_SDK_CURIE_VERSION as SDK_CURIE_VERSION,
    REACT_APP_CURIE_DEPLOYMENTS_VERSION as CURIE_DEPLOYMENTS_VERSION,
    REACT_APP_CONFIG_ENDPOINT as CONFIG_ENDPOINT,
    REACT_APP_PROVIDER_GRAPH_SERVER_CONFIG_ENDPOINT as PROVIDER_GRAPH_SERVER_CONFIG_ENDPOINT,
    REACT_APP_FLAGSMITH_ENV_ID as FLAGSMITH_ENV_ID,
    REACT_APP_AIRTABLE_API_KEY as AIRTABLE_API_KEY,
    REACT_APP_SEGMENT_API_KEY as SEGMENT_API_KEY,
    REACT_APP_BUGSNAG_API_KEY as BUGSNAG_API_KEY,
    REACT_APP_BRIDGE_SOCKET_API_KEY as BRIDGE_SOCKET_API_KEY,
}
