import { Box, VStack } from "@chakra-ui/react"

import { MarketListContainer } from "../../container/MarketListContainer"
import { PairList } from "./PairList"
import { Search } from "./Search"

export function MarketList() {
    return (
        <MarketListContainer.Provider>
            <Box pb={{ base: 40, md: 0 }}>
                <VStack pt={0} spacing={[2, 0]} px={[6, 0]} pr={[6, 2]} pb={[10, 3]}>
                    <Box w="100%" pt={3} pb={3} pl={{ base: 0, sm: 5 }} pr={{ base: 0, sm: "2px" }}>
                        <Search />
                    </Box>
                    <PairList />
                </VStack>
            </Box>
        </MarketListContainer.Provider>
    )
}

export default MarketList
