import { DEFAULT_SLIPPAGE_PERCENTAGE } from "./number"

interface StorageValue {
    name: string
    defaultValue?: string
}

type StorageKey =
    | "ONBOARDING_DONE"
    | "ACCOUNT"
    | "CONNECTOR_ID"
    | "GLOBAL_SLIPPAGE_PERCENTAGE"
    | "CHART_SETTING"
    | "IGNORE_GEO_BLOCKING"
    | "SHOW_GREETING_MODAL"
    | "HIDE_WALLET_BALANCE"
    | "LOW_USD_TIP"
    | "DYNAMIC_FAVICON"
    | "LATEST_FILLED_LIMIT_ORDER"

export const StorageDataMap: Record<StorageKey, StorageValue> = {
    ONBOARDING_DONE: {
        name: "onboarding-done",
        defaultValue: "false",
    },
    ACCOUNT: {
        name: "account",
        defaultValue: "",
    },
    CONNECTOR_ID: {
        name: "connector-id",
        defaultValue: "",
    },
    GLOBAL_SLIPPAGE_PERCENTAGE: {
        name: "global-slippage-percentage",
        defaultValue: DEFAULT_SLIPPAGE_PERCENTAGE,
    },
    CHART_SETTING: {
        name: "chart-setting",
    },
    IGNORE_GEO_BLOCKING: {
        name: "ignore-geo-blocking",
        defaultValue: "false",
    },
    SHOW_GREETING_MODAL: {
        name: "show-greeting-modal",
        defaultValue: "true",
    },
    HIDE_WALLET_BALANCE: {
        name: "hide-wallet-balance",
        defaultValue: "false",
    },
    LOW_USD_TIP: {
        name: "low-usd-tip",
        defaultValue: "true",
    },
    DYNAMIC_FAVICON: {
        name: "dynamic-favicon",
        defaultValue: "false",
    },
    LATEST_FILLED_LIMIT_ORDER: {
        name: "latest-filled-limit-order",
        defaultValue: "",
    },
}
