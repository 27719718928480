import { Signer, TypedDataField, TypedDataSigner } from "@ethersproject/abstract-signer"
import { SignTypedDataVersion, TypedDataUtils, TypedMessage } from "@metamask/eth-sig-util"
import { PositionSide, bigNumber2BigAndScaleDown } from "@perp/sdk-curie"
import Big from "big.js"
import { GQL_REQUEST_AMOUNT } from "constant/aws"
import { BigNumber } from "ethers"
import { DateTime } from "luxon"
import { OrderType, OrderTypeMap } from "sdk-react/limitOrder/types" // FIXME: direct import from "sdk-react" cause circular dependency

import { AppSyncService } from "./appSyncService"
import { ACTIVE_LIMIT_ORDER_LIST_BY_TRADER_AND_BASE_TOKEN_QUERY, CREATE_LIMIT_ORDER_MUTATION } from "./graphQueries"

interface MessageTypes {
    EIP712Domain: TypedDataField[]
    [additionalProperties: string]: TypedDataField[]
}

export interface GraphLimitOrder {
    orderType: string
    salt: string
    trader: string
    baseToken: string
    isBaseToQuote: boolean
    isExactInput: boolean
    amount: string
    oppositeAmountBound: string
    deadline: string
    sqrtPriceLimitX96: string
    referralCode: string
    reduceOnly: boolean
    roundIdWhenCreated: string
    limitPriceForDisplay: string
    triggerPrice: string
    created: number
    hash: string
    lastSubmitFailReason: string
}

export type ICreateLimitOrderParams = {
    orderType: number
    salt: number
    trader: string
    baseToken: string
    isBaseToQuote: boolean
    isExactInput: boolean
    amount: string
    oppositeAmountBound: string
    deadline: string
    sqrtPriceLimitX96: number
    referralCode: string
    reduceOnly: boolean
    limitPriceForDisplay: string
    roundIdWhenCreated: string
    triggerPrice: string
}

export interface GraphLimitOrders {
    listByTraderAndBaseToken: { result: GraphLimitOrder[] }
}

export interface CreateLimitOrdersResponse {
    error: string
    success: string
}

export enum ActiveLimitOrderErrorCode {
    // General Errors
    LOB_ROINS = "LOB_ROINS", // NOTE: No position to reduce
    LOB_OVTS = "LOB_OVTS", // NOTE: Order value too small (<$100)
    CH_NEFCI = "CH_NEFCI", // NOTE: Not enough free collateral
    AB_MNE = "AB_MNE", // NOTE: Market number exceeds (max:5 markets)

    // Scenarios:
    // 1. Trigger order (stop loss) not matched yet
    // 2. Trigger order (stop loss) matched but not filled
    // 3. Limit order not filled
    // 4. #2 and #3 would see the same message
    // - trigger price -> index price (chainlink)
    // - limit price -> market price(perp exchange)

    // Order not triggered (Trigger price not matched)
    LOB_SSLOTPNM = "LOB_SSLOTPNM",
    LOB_BSLOTPNM = "LOB_BSLOTPNM",
    LOB_STLOTPNM = "LOB_STLOTPNM",
    LOB_BTLOTPNM = "LOB_BTLOTPNM",

    // Limit price not matched
    CH_TLRS = "CH_TLRS",
    CH_TMRS = "CH_TMRS",
    CH_TLRL = "CH_TLRL",
    CH_TMRL = "CH_TMRL",
}

export class ActiveLimitOrder {
    readonly orderType: OrderType
    readonly positionSize: Big
    readonly positionNotional: Big
    readonly positionSide: PositionSide
    readonly price: Big
    readonly triggerPrice: Big
    readonly created: number
    readonly deadline: number
    readonly baseToken: string
    readonly reduceOnly: boolean
    readonly hash: string
    readonly errorCode: ActiveLimitOrderErrorCode

    readonly _rawData: GraphLimitOrder

    constructor(order: GraphLimitOrder) {
        this._rawData = order

        const { positionSize, positionNotional, triggerPrice, limitPriceForDisplay, errorCode } = this._parseData(order)
        this.orderType = OrderTypeMap[order.orderType]
        this.positionNotional = positionNotional
        this.positionSize = positionSize
        this.price = limitPriceForDisplay
        this.triggerPrice = triggerPrice
        this.positionSide = order.isBaseToQuote ? PositionSide.SHORT : PositionSide.LONG
        this.created = order.created
        this.deadline = Number(order.deadline)
        this.baseToken = order.baseToken
        this.reduceOnly = order.reduceOnly
        this.hash = order.hash
        this.errorCode = errorCode
    }

    public get normalizedData() {
        return {
            ...this._rawData,
            orderType: Number(this._rawData.orderType),
            salt: new Big(this._rawData.salt),
            amount: new Big(this._rawData.amount),
            oppositeAmountBound: new Big(this._rawData.oppositeAmountBound),
            deadline: new Big(this._rawData.deadline),
            sqrtPriceLimitX96: new Big(this._rawData.sqrtPriceLimitX96),
            roundIdWhenCreated: new Big(this._rawData.roundIdWhenCreated),
            triggerPrice: new Big(this._rawData.triggerPrice),
        }
    }

    private _parseData(order: GraphLimitOrder) {
        let positionSize, positionNotional
        if (order.isBaseToQuote) {
            if (order.isExactInput) {
                positionSize = order.amount
                positionNotional = order.oppositeAmountBound
            } else {
                positionSize = order.oppositeAmountBound
                positionNotional = order.amount
            }
        } else {
            if (order.isExactInput) {
                positionSize = order.oppositeAmountBound
                positionNotional = order.amount
            } else {
                positionSize = order.amount
                positionNotional = order.oppositeAmountBound
            }
        }
        return {
            positionSize: bigNumber2BigAndScaleDown(BigNumber.from(positionSize)),
            positionNotional: bigNumber2BigAndScaleDown(BigNumber.from(positionNotional)),
            triggerPrice: bigNumber2BigAndScaleDown(BigNumber.from(order.triggerPrice)),
            limitPriceForDisplay: bigNumber2BigAndScaleDown(BigNumber.from(order.limitPriceForDisplay)),
            errorCode: order.lastSubmitFailReason?.replace("execution reverted: ", "") as ActiveLimitOrderErrorCode,
        }
    }
}

export class LimitOrderService extends AppSyncService {
    readonly EIP_712_NAME = "PerpCurieLimitOrder"
    readonly EIP_712_VERSION = "1"
    readonly EIP_712_PRIMARY_TYPE = "LimitOrder"
    readonly VERSION = 1
    readonly ORDER_TYPE = {
        EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
        ],
        LimitOrder: [
            { name: "orderType", type: "uint8" },
            { name: "salt", type: "uint256" },
            { name: "trader", type: "address" },
            { name: "baseToken", type: "address" },
            { name: "isBaseToQuote", type: "bool" },
            { name: "isExactInput", type: "bool" },
            { name: "amount", type: "uint256" },
            { name: "oppositeAmountBound", type: "uint256" },
            { name: "deadline", type: "uint256" },
            { name: "sqrtPriceLimitX96", type: "uint160" },
            { name: "referralCode", type: "bytes32" },
            { name: "reduceOnly", type: "bool" },
            { name: "roundIdWhenCreated", type: "uint80" },
            { name: "triggerPrice", type: "uint256" },
        ],
    }

    async getActiveLimitOrders(
        trader: string,
        baseTokenAddress: string,
        limit: number = GQL_REQUEST_AMOUNT,
    ): Promise<ActiveLimitOrder[]> {
        const result = await this._client.query<GraphLimitOrders>({
            query: ACTIVE_LIMIT_ORDER_LIST_BY_TRADER_AND_BASE_TOKEN_QUERY,
            fetchPolicy: "no-cache",
            variables: {
                trader,
                baseToken: baseTokenAddress,
                limit,
            },
        })
        return (result.data.listByTraderAndBaseToken.result || []).map(order => new ActiveLimitOrder(order))
    }

    async createLimitOrder(signer: TypedDataSigner, params: ICreateLimitOrderParams, limitOrderBookAddress: string) {
        const chainId = await (signer as unknown as Signer).getChainId()
        const hash = this.generateHash(params, chainId, limitOrderBookAddress)
        const signature = await this.sign(signer, params, chainId, limitOrderBookAddress)
        const result = await this._client.mutate({
            mutation: CREATE_LIMIT_ORDER_MUTATION,
            variables: {
                ...params,
                hash,
                sig: signature,
                version: this.VERSION,
                created: DateTime.now(),
            },
        })
        return result
    }

    private generateHash(params: ICreateLimitOrderParams, chainId: number, limitOrderBookAddress: string) {
        return this.generateTypedHash({
            domain: this.createDomain(chainId, limitOrderBookAddress),
            types: this.ORDER_TYPE,
            message: params,
            primaryType: this.EIP_712_PRIMARY_TYPE,
        })
    }

    private createDomain(chainId: number, limitOrderBookAddress: string) {
        return {
            name: this.EIP_712_NAME,
            version: this.EIP_712_VERSION,
            chainId,
            verifyingContract: limitOrderBookAddress,
        }
    }

    private async sign(
        signer: TypedDataSigner,
        params: ICreateLimitOrderParams,
        chainId: number,
        limitOrderBookAddress: string,
    ): Promise<string> {
        const typesWithoutDomain = {
            LimitOrder: this.ORDER_TYPE.LimitOrder,
        }
        return await signer._signTypedData(
            this.createDomain(chainId, limitOrderBookAddress),
            typesWithoutDomain,
            params,
        )
    }

    private generateTypedHash(typedData: TypedMessage<MessageTypes>): string {
        return `0x${TypedDataUtils.eip712Hash(typedData, SignTypedDataVersion.V4).toString("hex")}`
    }
}
