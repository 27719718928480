import { Market, TickPriceMode } from "@perp/sdk-curie"
import Big from "big.js"

export type PriceRangeInterval = 1 | 2 | 3 | 4 | 5

const getStepDeltaRatio = ({
    direction,
    interval,
    deltaRatioCap,
    deltaStep,
}: {
    direction: "increase" | "decrease"
    interval: PriceRangeInterval
    deltaRatioCap: number
    deltaStep: number
}) => deltaStep * (deltaRatioCap / interval) * (direction === "increase" ? 1 : -1)

interface GetDeltaTickPriceParams {
    market: Market
    marketPrice: Big
    tickPriceMode: TickPriceMode.LOWER | TickPriceMode.UPPER
    interval: PriceRangeInterval
    deltaRatioCap: number
    deltaStep: number
}

export const getDeltaTickPrice = ({
    market,
    marketPrice,
    tickPriceMode,
    interval,
    deltaRatioCap,
    deltaStep,
}: GetDeltaTickPriceParams) => {
    const isLowerRange = tickPriceMode === TickPriceMode.LOWER
    const priceDeltaRatio = getStepDeltaRatio({
        direction: isLowerRange ? "decrease" : "increase",
        interval: interval,
        deltaRatioCap,
        deltaStep,
    })
    const deltaPrice = marketPrice.mul(1 + priceDeltaRatio)
    const tick = market.getTickFromPrice(deltaPrice, tickPriceMode)
    return market.getPriceFromTick(tick)
}

interface PriceRangeStep {
    tickPrice: Big
    deltaRatio: number
}
export type PriceRangeStepList = PriceRangeStep[]
type GetTickPriceStepListParams = Omit<GetDeltaTickPriceParams, "deltaStep">
export const getTickPriceStepList = ({
    market,
    marketPrice,
    tickPriceMode,
    interval,
    deltaRatioCap,
}: GetTickPriceStepListParams): PriceRangeStepList => {
    return new Array(interval + 1).fill(null).map((_, index) => {
        const tickPrice = getDeltaTickPrice({
            market,
            marketPrice,
            tickPriceMode,
            interval,
            deltaRatioCap,
            deltaStep: index,
        })
        const isLowerRange = tickPriceMode === TickPriceMode.LOWER
        const deltaRatio = getStepDeltaRatio({
            direction: isLowerRange ? "decrease" : "increase",
            interval,
            deltaRatioCap,
            deltaStep: index,
        })

        return { tickPrice, deltaRatio }
    })
}
