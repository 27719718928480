export enum ContractFunctionSignature {
    OPEN_POSITION = "0xb6b1b6c3",
    OPEN_POSITION_FOR = "0x00c2473e",
    CLOSE_POSITION = "0x00aa9a89",
    FILL_LIMIT_ORDER = "0x94184a55",

    ADD_LIQUIDITY = "0xc35eb28c",
    REMOVE_LIQUIDITY = "0xcde109b6",
    CANCEL_ALL_EXCESS_ORDERS = "0xcc3606dc",
    CANCEL_EXCESS_ORDERS = "0x80a98332",

    LIQUIDATE = "0x86b9d81f",
    LIQUIDATE_V2 = "0x26c01303",
    LIQUIDATE_V3 = "0x65d461bd",
}
