import { AppNetwork } from "module/network/container/AppNetwork"
import { useWalletStrings } from "module/wallet/hook/useWalletStrings"
import { formatNumberUSD } from "util/format"

import Big from "big.js"
import { FeatureToggleContainer } from "container/service/FeatureToggleContainer"
import { useMemo } from "react"
import { Vault } from "sdk-react/vault/useVault"
import { ICollateralTokenInfo, Wallet } from "sdk-react/wallet/useWallet"

import { useVaultStrings } from "./useVaultStrings"

export interface ICollateralStr extends Omit<ICollateralTokenInfo, "weight" | "depositCap" | "depositAmount"> {
    weight: string
    depositCap?: string
    depositAmount: string
    depositPercentage?: string
    walletBalance: Big
    walletBalanceStr: string
    vaultBalanceStr: string
    vaultBalanceUsdStr: string
    vaultBalanceUsdWeightedStr: string
    freeCollateral: Big
    freeCollateralStr: string
    freeCollateralUsdStr: string
    freeCollateralUsdWeightedStr: string
}

export const useCollateralStrings = () => {
    const { collateralList, balanceList: walletBalanceList } = Wallet.useContainer()
    const { balanceStrList: walletBalanceStrList } = useWalletStrings()

    const { freeCollateral, freeCollateralList } = Vault.useContainer()
    const { balanceDataStrList: vaultBalanceDataStrList } = useVaultStrings()

    const freeCollateralStr = freeCollateral
        ? formatNumberUSD({ num: freeCollateral, displayLength: 1, minimal: 1 })
        : undefined

    const _collateralStrList: ICollateralStr[] | undefined = useMemo(() => {
        // NOTE: since we merge multiple SDK returned arrays, we verify that all arrays are matched by their lengths.
        if (
            !collateralList ||
            !freeCollateralList ||
            !walletBalanceList ||
            !walletBalanceStrList ||
            !vaultBalanceDataStrList ||
            collateralList.length !== freeCollateralList.length ||
            collateralList.length !== walletBalanceList.length ||
            collateralList.length !== walletBalanceStrList.length ||
            collateralList.length !== vaultBalanceDataStrList.length
        ) {
            return
        }
        return collateralList.map(({ price, weight, depositCap, depositAmount, ...others }, index) => {
            const freeCollateral = freeCollateralList[index]
            const freeCollateralUsd = freeCollateral.mul(price)
            const freeCollateralUsdWeighted = freeCollateralUsd.mul(weight)

            return {
                ...others,
                weight: weight.toString(),
                depositCap:
                    depositCap && depositCap.gt(0)
                        ? formatNumberUSD({ num: depositCap, displayLength: 2, minimal: 2 })
                        : undefined,
                depositAmount: formatNumberUSD({ num: depositAmount, displayLength: 2, minimal: 2 }),
                depositPercentage:
                    depositCap && depositCap.gt(0)
                        ? formatNumberUSD({ num: depositAmount.div(depositCap).mul(100), displayLength: 2, minimal: 2 })
                        : undefined,

                walletBalance: walletBalanceList[index],
                walletBalanceStr: walletBalanceStrList[index],

                vaultBalanceStr: vaultBalanceDataStrList[index].balanceStr,
                vaultBalanceUsdStr: vaultBalanceDataStrList[index].balanceUsdStr,
                vaultBalanceUsdWeightedStr: vaultBalanceDataStrList[index].balanceUsdWeightedStr,

                freeCollateral,
                freeCollateralStr: formatNumberUSD({ num: freeCollateral, displayLength: 1, minimal: 1 }),
                freeCollateralUsdStr: formatNumberUSD({ num: freeCollateralUsd, displayLength: 1, minimal: 1 }),
                freeCollateralUsdWeightedStr: formatNumberUSD({
                    num: freeCollateralUsdWeighted,
                    displayLength: 1,
                    minimal: 1,
                }),
            }
        })
    }, [collateralList, freeCollateralList, walletBalanceList, walletBalanceStrList, vaultBalanceDataStrList])

    /* NOTE: Whitelisting Collaterals */
    const { getCollateralEnabledListByChainId } = FeatureToggleContainer.useContainer()
    const { appChainId } = AppNetwork.useContainer()
    const enabledCollateralSymbolList = getCollateralEnabledListByChainId(appChainId)
    const enabledCollateralStrList = useMemo(() => {
        if (!_collateralStrList) {
            return
        }
        return _collateralStrList.filter(collateral => enabledCollateralSymbolList.includes(collateral.symbol))
    }, [_collateralStrList, enabledCollateralSymbolList])

    return { freeCollateralStr, collateralStrList: enabledCollateralStrList }
}
