import Big from "big.js"

export class OpenPositionService {
    isCorePriceBetter(isLong: boolean, otcPrice: Big, corePrice: Big) {
        return isLong ? corePrice.lte(otcPrice) : corePrice.gte(otcPrice)
    }

    calcOppositeAmountBound(isLong: boolean, amount: Big, isAmountBase: boolean, price: Big, slippage: Big) {
        const slippageMultiplier = slippage.add(1)
        if (isAmountBase) {
            // if we want the exact baseAmount, the oppositeAmountBound should be quoteAmount
            // long, so we want to spend "at most" amount * quotePrice * slippageMultiplier
            // short, so we want to get "at least" amount * quotePrice / slippageMultiplier
            return isLong ? amount.mul(price).mul(slippageMultiplier) : amount.mul(price).div(slippageMultiplier)
        } else {
            // if we want the exact quoteAmount, the oppositeAmountBound should be baseAmount
            // long, so we want to get "at least" amount / quotePrice / slippageMultiplier
            // short, so we want to spend "at most" amount / quotePrice * slippageMultiplier
            return isLong ? amount.div(price).div(slippageMultiplier) : amount.div(price).mul(slippageMultiplier)
        }
    }

    calcOtcMakerPriceWithFee(isLong: boolean, entryPrice: Big) {
        // TODO: do we have constant for this?
        const feeMultiplier = Big(1.001)
        return isLong ? entryPrice.mul(feeMultiplier) : entryPrice.div(feeMultiplier)
    }
}
