import { PerpetualProtocolConnected } from "@perp/sdk-curie"
import { DependencyList, EffectCallback, useEffect, useRef } from "react"
import { useAsync } from "react-use"
import { Handlers } from "sdk-react/tools/useHandlers"

import { PerpSDK } from "./usePerpSDK"

type PerpConnectedEffect = (perp: PerpetualProtocolConnected) => Promise<ReturnType<EffectCallback>>

export function usePerpConnectedEffectAsync(effect: PerpConnectedEffect, deps: DependencyList) {
    const { perp, isConnected } = PerpSDK.useContainer()

    const savedEffect = useRef(effect)
    useEffect(() => {
        savedEffect.current = effect
    }, [effect])

    const { getHandlers } = Handlers.useContainer()

    return useAsync(async () => {
        const { onError } = getHandlers()

        if (!perp?.hasConnected() || !isConnected) {
            return
        }
        try {
            return await savedEffect.current(perp)
        } catch (error) {
            onError(error)
        }
    }, [perp, isConnected, ...deps]) // eslint-disable-line react-hooks/exhaustive-deps
}
