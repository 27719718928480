import { useCallback, useState } from "react"
import { createContainer } from "unstated-next"

export interface IHandlers {
    onError: (error: Error) => void
    onUninitialized: () => void
    onUnconnected: () => void
    onCheckNetwork: (payload: { isChecking: boolean; isUnsupported: boolean; chainId?: number }) => void
}

export const Handlers = createContainer(useHandlers)

function useHandlers(initialState?: Partial<IHandlers>) {
    const [presetHandlers, setHandlers] = useState<IHandlers>({
        onError: error => console.error(`SDK error: ${error}`),
        onUninitialized: () => console.error(`Please initialize PerpetualProtocol SDK first.`),
        onUnconnected: () => console.error(`Please connect wallet first.`),
        onCheckNetwork: ({ isUnsupported, chainId }) => {
            if (isUnsupported) {
                console.error(`SDK currently does not support the network.${chainId ? ` (Chain ID: ${chainId})` : ""}`)
            }
        },
        ...initialState,
    })

    const getHandlers = useCallback(
        (customizeHandlers: Partial<IHandlers> = {}): IHandlers => {
            return {
                ...presetHandlers,
                ...customizeHandlers,
            }
        },
        [presetHandlers],
    )

    const updateHandlers = useCallback(
        (customized: Partial<IHandlers> = {}) => {
            setHandlers(getHandlers(customized))
        },
        [getHandlers],
    )

    return {
        getHandlers,
        updateHandlers,
    }
}
