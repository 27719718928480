import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client"
import { createHttpLink } from "@apollo/client/link/http"
import { usePrevious } from "@chakra-ui/react"
import { isEqual } from "lodash-es"
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react"
import { getMergeListDedupe } from "./cachePolicies"

export interface GraphServerConfig {
    name: string
    url: string
    wsUrl: string
    healthUrl: string
}

interface Client {
    index: number
    name: string
    url: string
    healthUrl: string
    instance: ApolloClient<NormalizedCacheObject>
    isHealthy: boolean
}

export const MAX_ACCEPTABLE_BLOCK_NUMBER_DELAY = 500

interface GraphServerProviderProps {
    configs: GraphServerConfig[]
    LoadingUI: React.ReactElement
}

export const healthCheckApolloClient: {
    client: ApolloClient<NormalizedCacheObject>
    name?: string
} = {
    client: new ApolloClient({ cache: new InMemoryCache(), connectToDevTools: false }),
}

const updateHealthCheckApolloClient = (client: Client) => {
    healthCheckApolloClient.client.setLink(createHttpLink({ uri: client.healthUrl }))
    healthCheckApolloClient.name = client.name
}

export const GraphServerProvider = (props: PropsWithChildren<GraphServerProviderProps>) => {
    const { configs, children, LoadingUI } = props
    const [client, setClient] = useState<Client | null>()
    const previousConfigs = usePrevious(configs)

    // NOTE: we use this flag to prevent from initializing apolloClient unnecessary
    // cuz we keep polling node-monitor endpoint
    const shouldInitialize = useMemo(() => {
        if (!previousConfigs) {
            return true
        }
        return !isEqual(configs, previousConfigs)
    }, [configs, previousConfigs])

    // NOTE: re-init when configs changed
    useEffect(() => {
        if (shouldInitialize) {
            setClient(getClient({ index: 0, config: configs[0] }))
        }
    }, [configs, shouldInitialize])

    useEffect(() => {
        if (client && client?.name !== healthCheckApolloClient.name) {
            updateHealthCheckApolloClient(client)
        }
    }, [client])

    return client ? <ApolloProvider client={client.instance}>{children}</ApolloProvider> : LoadingUI
}

interface GetClientParams {
    index: number
    config: GraphServerConfig
}

function getClient({ index, config }: GetClientParams): Client {
    const httpLink = new HttpLink({ uri: config.url })

    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    positionChangeds: {
                        // NOTE: Differentiate PersonalTradeHistory & PositionLiquidations.
                        keyArgs: ["where", ["trader", "fromFunctionSignature_in", "fromFunctionSignature_not_in"]],
                        merge: getMergeListDedupe("blockNumberLogIndex"),
                    },
                    positionCloseds: {
                        keyArgs: ["where", ["trader"]],
                        merge: getMergeListDedupe("blockNumberLogIndex"),
                    },
                    fundingPaymentSettleds: {
                        keyArgs: ["where", ["trader"]],
                        merge: getMergeListDedupe("blockNumberLogIndex"),
                    },
                    depositeds: {
                        keyArgs: ["where", ["trader"]],
                        merge: getMergeListDedupe("blockNumberLogIndex"),
                    },
                    withdrawns: {
                        keyArgs: ["where", ["trader"]],
                        merge: getMergeListDedupe("blockNumberLogIndex"),
                    },
                    collateralLiquidateds: {
                        keyArgs: ["where", ["trader"]],
                        merge: getMergeListDedupe("blockNumberLogIndex"),
                    },
                    limitOrderFilleds: {
                        keyArgs: ["where", ["trader"]],
                        merge: getMergeListDedupe("blockNumberLogIndex"),
                    },
                    limitOrderCancelleds: {
                        keyArgs: ["where", ["trader"]],
                        merge: getMergeListDedupe("blockNumberLogIndex"),
                    },
                },
            },
        },
    })

    return {
        index,
        name: config.name,
        url: config.url,
        healthUrl: config.healthUrl,
        instance: new ApolloClient({ link: httpLink, cache, connectToDevTools: true }),
        isHealthy: true,
    }
}
