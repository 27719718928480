import { gql, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { MAX_ACCEPTABLE_BLOCK_NUMBER_DELAY, healthCheckApolloClient } from "sdk-react/graphServer"

const NETWORK_STATUS_INTERVAL = 5000

export default function useSubgraphNetworkStatus() {
    const [latestBlockNumber, setLatestBlockNumber] = useState(0)
    const [indexedBlockNumber, setIndexedBlockNumber] = useState(0)

    const { data } = useQuery(
        gql`
            ${getClientStatusQueryStr(healthCheckApolloClient.name || "")}
        `,
        {
            client: healthCheckApolloClient.client,
            pollInterval: NETWORK_STATUS_INTERVAL,
        },
    )

    useEffect(() => {
        if (data) {
            const info = data.indexingStatusForCurrentVersion
            const chain = info.chains[0]
            const latestBlockNumber = +chain.chainHeadBlock.number
            const indexedBlockNumber = +chain.latestBlock.number
            setLatestBlockNumber(latestBlockNumber)
            setIndexedBlockNumber(indexedBlockNumber)
        }
    }, [data])

    const blockToBeSynced = latestBlockNumber - indexedBlockNumber
    const isOutOfSync = blockToBeSynced > MAX_ACCEPTABLE_BLOCK_NUMBER_DELAY

    return {
        isOutOfSync,
        latestBlockNumber,
        indexedBlockNumber,
        blockToBeSynced,
    }
}

function getClientStatusQueryStr(clientName: string) {
    return `{
        indexingStatusForCurrentVersion(subgraphName: "${clientName}") {
            chains {
                chainHeadBlock {
                    number
                }
                latestBlock {
                    number
                }
            }
        }
   }`
}
