export const scrollbarStyle = {
    "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "24px",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        border: "1px solid rgba(255,255,255,0.2)",
    },
    "&::-webkit-scrollbar-corner": {
        borderRadius: "16px",
    },
}

export const scrollbarThinStyle = {
    "&::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        border: "1px solid rgba(255,255,255,0.2)",
    },
    "&::-webkit-scrollbar-corner": {
        borderRadius: "4px",
    },
}

export const hiddenScrollbar = {
    "&::-webkit-scrollbar": {
        width: "1px",
        height: "1px",
        opacity: 0,
    },
}
