import { ModalBiconomyWidget } from "component/ModalBiconomyWidget"
import { ModalGas } from "component/ModalGas"
import { ModalLifiWidget } from "component/ModalLifiWidget"
import { ModalSocketWidget } from "component/ModalSocketWidget"
import { ModalTransfer } from "component/ModalTransfer"
import { Modals } from "container/modal"
import { ModalLiquidityAdd } from "module/pool/component/ModalLiquidityAdd"
import { ModalLiquidityRemove } from "module/pool/component/ModalLiquidityRemove"
import { ModalPositionClose } from "module/position/component/ModalPositionClose"
import { ModalSettings } from "module/settings/component/ModalSettings"
import { ModalDeposit } from "module/vault/component/ModalDeposit"
import { ModalWithdraw } from "module/vault/component/ModalWithdraw"
import { ModalConnectWallet } from "module/wallet/component/ModalConnectWallet"
import { useCallback } from "react"
import { ModalOnboardingDismiss } from "./ModalOnboardingDismiss"
import { ModalParams, ModalType } from "./type"

export function ModalRoot() {
    const {
        state: { modalOpened, params },
        actions: { modalClose },
    } = Modals.useContainer()

    const closeModal = useCallback(() => {
        if (modalOpened) {
            modalClose(modalOpened)
        }
    }, [modalOpened, modalClose])

    switch (modalOpened) {
        case ModalType.ONBOARDING_DISMISS: {
            return (
                <ModalOnboardingDismiss
                    isOpen
                    close={closeModal}
                    params={params as ModalParams[ModalType.ONBOARDING_DISMISS]}
                />
            )
        }
        case ModalType.WALLET_CONNECTION: {
            return <ModalConnectWallet isOpen close={closeModal} />
        }
        case ModalType.DEPOSIT: {
            return <ModalDeposit isOpen close={closeModal} params={params as ModalParams[ModalType.DEPOSIT]} />
        }
        case ModalType.WITHDRAW: {
            return <ModalWithdraw isOpen close={closeModal} params={params as ModalParams[ModalType.WITHDRAW]} />
        }
        case ModalType.SETTINGS: {
            return <ModalSettings isOpen close={closeModal} />
        }
        case ModalType.TRANSFER: {
            return <ModalTransfer isOpen close={closeModal} />
        }
        case ModalType.LIQUIDITY_ADD: {
            return (
                <ModalLiquidityAdd isOpen close={closeModal} params={params as ModalParams[ModalType.LIQUIDITY_ADD]} />
            )
        }
        case ModalType.LIQUIDITY_REMOVE: {
            return (
                <ModalLiquidityRemove
                    isOpen
                    close={closeModal}
                    params={params as ModalParams[ModalType.LIQUIDITY_REMOVE]}
                />
            )
        }
        case ModalType.GAS: {
            return <ModalGas isOpen close={closeModal} />
        }
        case ModalType.LIFI_WIDGET: {
            return <ModalLifiWidget isOpen close={closeModal} params={params as ModalParams[ModalType.LIFI_WIDGET]} />
        }

        case ModalType.BICONOMY_WIDGET: {
            return <ModalBiconomyWidget isOpen close={closeModal} />
        }
        case ModalType.SOCKET_WIDGET: {
            return <ModalSocketWidget isOpen close={closeModal} />
        }
        case ModalType.POSITION_CLOSE: {
            // TODO: figure out how to link `type` with `params` so that it's automatically type-guarded.
            return (
                <ModalPositionClose
                    isOpen
                    close={closeModal}
                    params={params as ModalParams[ModalType.POSITION_CLOSE]}
                />
            )
        }
        default: {
            return null
        }
    }
}
