import { Icon } from "@chakra-ui/react"
import { MarketStatus } from "@perp/sdk-curie"
import { RiIndeterminateCircleFill, RiInformationFill } from "react-icons/ri"

interface StatusIconProps {
    marketStatus?: MarketStatus
}

export const StatusIndicator = ({ marketStatus }: StatusIconProps) => {
    switch (marketStatus) {
        case MarketStatus.PAUSED: {
            return <Icon color="yellow.400" as={RiInformationFill} boxSize={["20px"]} />
        }
        case MarketStatus.CLOSED: {
            return <Icon color="red.400" as={RiIndeterminateCircleFill} boxSize={["20px"]} />
        }
        default: {
            return null
        }
    }
}
