import { GasRebateProvider } from "module/pool/container/gasRebateProvider"
import { LiquidityProvider } from "module/pool/container/liquidityProvider"
import { formatNumber } from "util/format"

import { BIG_ZERO } from "@perp/sdk-curie"
import Big from "big.js"
import { useEffect, useState } from "react"
import { logger } from "service/bugsnag/logger"

const DEFAULT_VALUE = "---"

export function useReward() {
    const { totalClaimableGasRebate, isGetAccountLoading } = GasRebateProvider.useContainer()
    const { totalClaimableReward, claimableRewardOp, isTotalClaimableRewardLoading, isTotalClaimableRewardLoadingOp } =
        LiquidityProvider.useContainer()

    const [gasRebate, setGasRebate] = useState<string>(DEFAULT_VALUE)
    const [claimableReward, setClaimableReward] = useState<string>(DEFAULT_VALUE)
    const [totalClaimableRewardOp, setTotalClaimableRewardOp] = useState<string>(DEFAULT_VALUE)
    const [totalReward, setTotalReward] = useState<string | number>(DEFAULT_VALUE)

    useEffect(() => {
        const normalizedValue = totalClaimableGasRebate ? formatNumber(totalClaimableGasRebate, 2) : DEFAULT_VALUE
        setGasRebate(normalizedValue)
    }, [totalClaimableGasRebate])
    useEffect(() => {
        const normalizedValue = totalClaimableReward ? formatNumber(Big(totalClaimableReward), 2) : DEFAULT_VALUE
        setClaimableReward(normalizedValue)
    }, [totalClaimableReward])
    useEffect(() => {
        const normalizedValue = claimableRewardOp ? formatNumber(Big(claimableRewardOp), 2) : DEFAULT_VALUE
        setTotalClaimableRewardOp(normalizedValue)
    }, [claimableRewardOp])

    useEffect(() => {
        let total
        try {
            if (gasRebate === DEFAULT_VALUE && claimableReward === DEFAULT_VALUE) {
                total = DEFAULT_VALUE
            } else if (gasRebate !== DEFAULT_VALUE && claimableReward !== DEFAULT_VALUE) {
                total = Number(totalClaimableReward) + Number(totalClaimableGasRebate)
            } else {
                total =
                    (gasRebate === DEFAULT_VALUE ? 0 : Number(totalClaimableGasRebate)) +
                    (claimableReward === DEFAULT_VALUE ? 0 : Number(totalClaimableReward))
            }
            setTotalReward(total === DEFAULT_VALUE ? DEFAULT_VALUE : formatNumber(Big(total), 2))
        } catch (error) {
            logger.error(error)
            setTotalReward(formatNumber(BIG_ZERO, 2))
        }
    }, [gasRebate, claimableReward, totalClaimableReward, totalClaimableGasRebate])

    return {
        gasRebate,
        claimableReward,
        totalClaimableRewardOp,
        totalReward,
        isTotalClaimableRewardLoading,
        isGetAccountLoading,
        isTotalClaimableRewardLoadingOp,
    }
}
