import SegmentService, { EventGroup, EventTypeInteraction } from "module/analytics/service/SegmentService"
import { TrackingProps } from "module/analytics/types"

import {
    Tab,
    TabList,
    TabListProps,
    TabPanel,
    TabPanelProps,
    TabPanels,
    TabPanelsProps,
    TabProps,
    Tabs,
    TabsProps,
} from "@chakra-ui/react"
import React, { useCallback, useState } from "react"

type Renderer = { renderContent?: ({ isActive }: { isActive: boolean }) => React.ReactNode }

export interface TabData {
    tabProps: TabProps & Renderer
    tabPanelProps: TabPanelProps & Renderer
}

interface PerpTabsProps extends Omit<TabsProps, "children" | "onChange"> {
    tabs: TabData[]
    tabListProps?: TabListProps
    tabPanelsProps?: TabPanelsProps
    baseTabProps?: TabProps
    baseTabPanelProps?: TabPanelProps
    trackingProps: TrackingProps
    onChange?: (index: number, tabId: string) => void
}

const getTabId = ({
    tabProps,
    tabPanelProps,
}: {
    tabProps?: TabData["tabProps"]
    tabPanelProps?: TabData["tabPanelProps"]
}) => tabProps?.id || tabPanelProps?.id

export function PerpTabs({
    tabs,
    tabListProps,
    tabPanelsProps,
    baseTabProps,
    baseTabPanelProps,
    defaultIndex,
    onChange,
    trackingProps,
    ...tabsProps
}: PerpTabsProps) {
    const [focusedIndex, setFocusedIndex] = useState(defaultIndex)

    const handleChange = useCallback<(index: number) => void>(
        index => {
            setFocusedIndex(index)

            const { tabProps, tabPanelProps } = tabs[index] || {}
            const tabId = getTabId({ tabProps, tabPanelProps }) || String(index)
            onChange && onChange(index, tabId)

            SegmentService.track(EventGroup.INTERACTION, {
                eventType: EventTypeInteraction.TAB_FOCUSED,
                ...trackingProps,
                payload: { tabId, ...trackingProps?.payload },
            })
        },
        [onChange, tabs, trackingProps],
    )

    const appliedTabsProps: Partial<TabsProps> = {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        variant: "soft-rounded",
        colorScheme: "gray",
        size: "sm",
        ...tabsProps,
    }

    const isOnlySingleTab = tabs.length === 1
    const appliedTabListProps: Partial<TabListProps> = {
        display: isOnlySingleTab ? "none" : "flex",
        paddingX: 2,
        paddingBottom: 2,
        overflowX: "auto",
        ...tabListProps,
    }

    const appliedTabPanelsProps: Partial<TabPanelsProps> = {
        flex: 1,
        paddingX: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
        ...tabPanelsProps,
    }

    const appliedTabPanelProps: Partial<TabPanelProps> = {
        flex: 1,
        ...baseTabPanelProps,
    }

    return (
        <Tabs isLazy lazyBehavior="keepMounted" onChange={handleChange} index={focusedIndex} {...appliedTabsProps}>
            <TabList {...appliedTabListProps}>
                {tabs.map(({ tabProps }, index) => {
                    const { renderContent, children, ...overrideTabProps } = tabProps
                    return (
                        <Tab
                            key={`perp-tab-${getTabId({ tabProps }) || index}`}
                            {...baseTabProps}
                            {...overrideTabProps}
                        >
                            {renderContent ? renderContent({ isActive: focusedIndex === index }) : children}
                        </Tab>
                    )
                })}
            </TabList>
            <TabPanels {...appliedTabPanelsProps}>
                {tabs.map(({ tabPanelProps }, index) => {
                    const { renderContent, children, ...overrideTabPanelProps } = tabPanelProps
                    return (
                        <TabPanel
                            key={`perp-tab-${getTabId({ tabPanelProps }) || index}`}
                            {...appliedTabPanelProps}
                            {...overrideTabPanelProps}
                        >
                            {renderContent ? renderContent({ isActive: focusedIndex === index }) : children}
                        </TabPanel>
                    )
                })}
            </TabPanels>
        </Tabs>
    )
}
