/**
 * This component aims to have (almost) the same behavior as this: https://cchanxzy.github.io/react-currency-input-field/
 */
import SegmentService, { EventGroup, EventTypeInteraction } from "module/analytics/service/SegmentService"
import { TrackingProps } from "module/analytics/types"

import { NumberInput, NumberInputField, NumberInputFieldProps, NumberInputProps } from "@chakra-ui/react"
import { useCallback } from "react"

import { Formatter, defaultFormatter } from "./format"

const noopFn = (value: any) => null

type InputData = string | number

type OnChange = Exclude<NumberInputProps["onChange"], undefined>

export interface PerpNumberInputProps extends Omit<NumberInputProps, "onChange"> {
    defaultValue?: InputData
    value?: InputData
    onChange?: OnChange
    formatter?: Formatter
    trackingProps?: TrackingProps
    numberInputFieldProps?: Partial<NumberInputFieldProps>
}

export function PerpNumberInput({
    defaultValue,
    value,
    precision = 0,
    onChange = noopFn,
    formatter = defaultFormatter,
    onBlur,
    trackingProps,
    numberInputFieldProps,
    ...numberInputProps
}: PerpNumberInputProps) {
    const formattedValue = value === undefined ? undefined : formatter({ input: value || "", precision })

    const handleOnChange = useCallback<OnChange>(
        (valueString, valueAsNumber) => {
            // NOTE: NumberInput doesn't support scientific notation
            const isValidInput = !valueString.match(/e|E/g)
            if ((isValidInput && !isNaN(valueAsNumber)) || valueString === "" || valueString === ".") {
                // NOTE: only trigger onChange when input is valid
                onChange(formatter({ input: valueString || "", precision }), valueAsNumber)
            }
        },
        [onChange, formatter, precision],
    )
    const handleOnBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
        e => {
            onBlur && onBlur(e)
            trackingProps &&
                SegmentService.track(EventGroup.INTERACTION, {
                    eventType: EventTypeInteraction.INPUT_UNFOCUSED,
                    ...trackingProps,
                    payload: { ...trackingProps.payload },
                })
        },
        [onBlur, trackingProps],
    )

    return (
        <NumberInput
            clampValueOnBlur={false}
            precision={precision}
            value={formattedValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            {...numberInputProps}
        >
            <NumberInputField
                pr={3} // NOTE: Override NumberInputStepper spacing.
                pl={3} // NOTE: Override NumberInputStepper spacing.
                data-testid={"numberInputField"}
                {...numberInputFieldProps}
            />
        </NumberInput>
    )
}
