import { useQuery } from "@apollo/client"
import { DepositHistory } from "@perp/sdk-curie"
import Big from "big.js"
import { useCallback, useMemo } from "react"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

import { DEPOSITEDS_QUERY } from "../graphServer/graphQueries"
import { Web3Wallet } from "../PerpSDKProvider"

const PAGE_SIZE = 100

interface APIDepositHistory {
    id: string
    txHash: string
    trader: string
    collateralToken: string
    amount: string
    blockNumber: string
    timestamp: string
}

interface IDepositHistory {
    depositeds: APIDepositHistory[]
}

type DepositHistoryInitialState = Web3Wallet

export const DepositHistories = createContainer(useDepositHistories)

function useDepositHistories(initialState?: DepositHistoryInitialState) {
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { account } = initialState || {}
    const { data, loading, error, fetchMore } = useQuery<IDepositHistory>(DEPOSITEDS_QUERY, {
        variables: { trader: account, first: PAGE_SIZE, skip: 0 },
        skip: !account,
    })

    if (error) {
        onError(error)
    }

    const fetchNextPage = useCallback(() => {
        const skip = data?.depositeds.length ?? 0
        if (skip <= 0) {
            return
        }
        fetchMore({ variables: { skip } })
    }, [data?.depositeds.length, fetchMore])

    const depositHistories = useMemo(() => {
        if (loading || !data) {
            return []
        }
        const histories = data.depositeds || []

        try {
            return histories.map(history => {
                const amount = new Big(history.amount)
                const blockNumber = Number(history.blockNumber)
                const timestamp = Number(history.timestamp)

                return new DepositHistory({
                    id: history.id,
                    txHash: history.txHash,
                    trader: history.trader,
                    collateralToken: history.collateralToken,
                    amount,
                    blockNumber,
                    timestamp,
                })
            })
        } catch (e: any) {
            onError(e)
            return []
        }
    }, [loading, data, onError])

    return {
        isLoading: loading,
        depositHistories,
        fetchNextPage,
    }
}
