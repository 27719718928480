import { Box, BoxProps, Button, Icon, Link, Tooltip } from "@chakra-ui/react"
import {
    RiBookFill,
    RiDiscordFill,
    RiHome5Fill,
    RiMailFill,
    RiMediumFill,
    RiTelegramFill,
    RiTwitterFill,
} from "react-icons/ri"

export const FooterSection = (props: BoxProps) => {
    return (
        <Box {...props}>
            <Tooltip label="Documentation">
                <Box
                    as="span"
                    display="none"
                    // extra box necessary for tooltips to work
                >
                    <FooterLink href="#">
                        <Icon as={RiBookFill} boxSize={{ base: "24px", md: "20px" }} mr={1} />
                        {/* <span>Documentation</span> */}
                    </FooterLink>
                </Box>
            </Tooltip>

            <Tooltip label="Twitter">
                <Box
                    as="span"

                    // extra box necessary for tooltips to work
                >
                    <FooterLink href="https://twitter.com/perpprotocol">
                        <Icon as={RiTwitterFill} boxSize={{ base: "24px", md: "20px" }} mr={1} />
                        {/* <span>Twitter</span> */}
                    </FooterLink>
                </Box>
            </Tooltip>

            <Tooltip label="Medium">
                <Box
                    as="span"

                    // extra box necessary for tooltips to work
                >
                    <FooterLink href="https://perpetualprotocol.medium.com/">
                        <Icon as={RiMediumFill} boxSize={{ base: "24px", md: "20px" }} mr={1} />
                        {/* <span>Medium</span> */}
                    </FooterLink>
                </Box>
            </Tooltip>

            <Tooltip label="Discord">
                <Box
                    as="span"

                    // extra box necessary for tooltips to work
                >
                    <FooterLink href="https://discord.com/invite/mYKKRTn">
                        <Icon as={RiDiscordFill} boxSize={{ base: "24px", md: "20px" }} mr={1} />
                        {/* <span>Discord</span> */}
                    </FooterLink>
                </Box>
            </Tooltip>

            <Tooltip label="Telegram">
                <Box
                    as="span"

                    // extra box necessary for tooltips to work
                >
                    <FooterLink href="https://t.me/perpetualprotocol">
                        <Icon as={RiTelegramFill} boxSize={{ base: "24px", md: "20px" }} mr={1} />
                        {/* <span>Discord</span> */}
                    </FooterLink>
                </Box>
            </Tooltip>
            <Tooltip label="Telegram 中文社區">
                <Box
                    as="span"

                    // extra box necessary for tooltips to work
                >
                    <FooterLink href="https://t.me/perp_cn">
                        <Icon as={RiTelegramFill} boxSize={{ base: "24px", md: "20px" }} mr={1} />
                        {/* <span>Discord</span> */}
                    </FooterLink>
                </Box>
            </Tooltip>
            <Tooltip label="Newsletter">
                <Box
                    as="span"

                    // extra box necessary for tooltips to work
                >
                    <FooterLink href="https://www.getrevue.co/profile/perpprotocol/">
                        <Icon as={RiMailFill} boxSize={{ base: "24px", md: "20px" }} mr={1} />
                        {/* <span>Discord</span> */}
                    </FooterLink>
                </Box>
            </Tooltip>
            <Tooltip label="About Perpetual Protocol">
                <Box
                    as="span"
                    display="none"
                    // extra box necessary for tooltips to work
                >
                    <FooterLink href="https://perp.fi">
                        <Icon as={RiHome5Fill} boxSize={{ base: "24px", md: "20px" }} mr={1} />
                        {/* <span>About</span> */}
                    </FooterLink>
                </Box>
            </Tooltip>
        </Box>
    )
}
// https://perpetualprotocol.substack.com/
const FooterLink = (props: any) => {
    return (
        <Button
            as={Link}
            variant="link"
            ml={0}
            mr={3}
            size="xs"
            mb={3}
            fontWeight="normal"
            lineHeight="20px"
            {...props}
        >
            {props.children}
        </Button>
    )
}
