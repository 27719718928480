import { EventName } from "module/analytics/types"

import { HStack, NumberInput, NumberInputField, Skeleton, Spacer, VStack } from "@chakra-ui/react"
import { PerpNumberInputWithAddons } from "component/PerpNumberInput/PerpNumberInputWithAddons"
import { TokenIconSymbol } from "component/TokenIconSymbol"
import { TextLabel, TextNote } from "component/Typography"
import { USD_PRECISION } from "constant/number"
import { useCallback } from "react"

const LEVERAGE_MAX = 10
const LEVERAGE_MIN = 0.000001
const LEVERAGE_STEP = 0.01

const leverageFormat = (val: number) => `${Math.round(val * 100000) / 100000}×`
const leverageParse = (val: string) => Number(val.replace(/^×/, ""))

type LiquidityAmountInputSimpleProps = {
    totalAsQuoteAmount?: string
    leverage?: number
    buyingPowerStr?: string
    onTotalAsQuoteAmountChange: (value: string) => void
    onLeverageChange: (value: number) => void
    isDisabled?: boolean
    isInvalid?: boolean
}

export const LiquidityAmountInputSimple = ({
    totalAsQuoteAmount,
    leverage,
    buyingPowerStr,
    onTotalAsQuoteAmountChange,
    onLeverageChange,
    isDisabled,
    isInvalid,
}: LiquidityAmountInputSimpleProps) => {
    const handleQuoteAmountChange = useCallback(
        (quoteAmountNext: string) => {
            if (quoteAmountNext !== totalAsQuoteAmount) {
                onTotalAsQuoteAmountChange(quoteAmountNext)
            }
        },
        [onTotalAsQuoteAmountChange, totalAsQuoteAmount],
    )
    const handleLeverageChange = useCallback(
        (string: string) => {
            const leverageNext = leverageParse(string)
            if (leverageNext !== leverage) {
                onLeverageChange(leverageNext)
            }
        },
        [leverage, onLeverageChange],
    )

    return (
        <VStack align="stretch" w="100%">
            <HStack spacing={0}>
                <TextLabel>Amount</TextLabel>
                <Spacer />
                <Skeleton isLoaded={!!buyingPowerStr}>
                    <TextNote isTruncated whiteSpace="nowrap">
                        Buying Power ≈ ${buyingPowerStr}
                    </TextNote>
                </Skeleton>
            </HStack>
            <HStack>
                <PerpNumberInputWithAddons
                    precision={USD_PRECISION}
                    placeholder="Amount"
                    value={totalAsQuoteAmount}
                    onChange={handleQuoteAmountChange}
                    isDisabled={isDisabled}
                    isInvalid={!isDisabled && isInvalid}
                    leftElement={<TokenIconSymbol tokenSymbol={"usd"} paddingLeft={2} paddingRight={1} />}
                    trackingProps={{
                        eventName: EventName.POOL_ADD_LIQUIDITY_SIMPLE_QUOTE_UPDATED,
                        payload: { totalAsQuoteAmount: totalAsQuoteAmount || "" },
                    }}
                />
                <NumberInput
                    w={"50%"}
                    // isInvalid={!isDisabled && isInvalid} Commented out to make it read only
                    isDisabled={true}
                    precision={5}
                    max={LEVERAGE_MAX}
                    min={LEVERAGE_MIN}
                    step={LEVERAGE_STEP}
                    value={leverage ? leverageFormat(leverage) : ""}
                    onChange={handleLeverageChange}
                    // Make it readonly. No focus style
                    isReadOnly
                    focusInputOnChange={false}
                    variant="filled"
                >
                    <NumberInputField
                        placeholder="0.0×"
                        // Make it readonly. No focus style
                        _focus={{ borderColor: "transparent" }}
                        _disabled={{ opacity: 1 }}
                    />
                </NumberInput>
            </HStack>
        </VStack>
    )
}
