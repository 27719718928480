import { PerpetualProtocolInitialized } from "@perp/sdk-curie"
import { DependencyList, EffectCallback, useEffect, useRef } from "react"
import { Handlers } from "sdk-react/tools/useHandlers"

import { PerpSDK } from "./usePerpSDK"

type PerpInitializedEffect = (perp: PerpetualProtocolInitialized) => ReturnType<EffectCallback>

export function usePerpInitializedEffect(effect: PerpInitializedEffect, deps: DependencyList) {
    const { perp, isInitialized } = PerpSDK.useContainer()
    const savedEffect = useRef(effect)
    useEffect(() => {
        savedEffect.current = effect
    }, [effect])

    const { getHandlers } = Handlers.useContainer()

    useEffect(() => {
        const { onError } = getHandlers()

        if (!perp?.hasInitialized() || !isInitialized) {
            return
        }
        try {
            return savedEffect.current(perp)
        } catch (error) {
            onError(error)
        }
    }, [perp, isInitialized, ...deps]) // eslint-disable-line react-hooks/exhaustive-deps
}
