import { Button, ButtonGroup, Text, VStack } from "@chakra-ui/react"
import { PerpModal } from "component/PerpModal"
import { useCallback } from "react"

import { ModalRootProps } from "./type"

export interface ModalOnboardingDismissParams {
    onConfirm?: () => void
}

interface ModalOnboardingDismissProps extends ModalRootProps<ModalOnboardingDismissParams> {}
export function ModalOnboardingDismiss({ isOpen, close, params }: ModalOnboardingDismissProps) {
    const { onConfirm } = params
    const handleConfirm = useCallback(() => {
        if (onConfirm) {
            onConfirm()
        }
        close()
    }, [close, onConfirm])

    return (
        <PerpModal
            isOpen={isOpen}
            isCloseButtonHidden={true}
            onClose={() => {}}
            scrollBehavior="inside"
            motionPreset="slideInBottom"
            header="Skip Onboarding"
            body={
                <VStack>
                    <Text>Are you sure you want to dismiss the onboarding instructions?</Text>
                </VStack>
            }
            footer={
                <ButtonGroup w="100%">
                    <Button onClick={close} size="md" colorScheme="gray" width="full">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} size="md" colorScheme="perpetual" width="full">
                        Skip Onboarding
                    </Button>
                </ButtonGroup>
            }
        />
    )
}
