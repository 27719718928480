import {
    Badge,
    Button,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { RiQuestionLine } from "react-icons/ri"

import { Slippage } from "../container/slippage"

interface SlippageControlProps {
    hidden?: boolean
    isDisabled?: boolean
    onChanged?: (input: number) => void
}

const PERCENTAGE_VALUES = [1, 1.5, 2]

export const SlippageControl = ({ hidden, isDisabled = false, onChanged }: SlippageControlProps) => {
    const { slippagePercentage, updateSlippagePercentage } = Slippage.useContainer()

    const handleUpdate = useCallback(
        (input: number) => {
            updateSlippagePercentage(input)
            if (onChanged) {
                onChanged(input)
            }
        },
        [onChanged, updateSlippagePercentage],
    )

    const { t } = useTranslation()

    return hidden ? null : (
        <VStack spacing={2} align="stretch" w="100%">
            <Tooltip colorScheme="whiteAlpha" label={t("settings.slippage_warning")}>
                <Text variant="label">
                    {t("settings.slippage_tolerance")}{" "}
                    <Icon position="relative" top="4px" opacity="0.6" boxSize="16px" as={RiQuestionLine} />
                </Text>
            </Tooltip>
            <HStack>
                <InputGroup w={"auto"} size={"md"}>
                    <Input
                        borderColor="transparent"
                        bg="blackAlpha.500"
                        fontWeight="semibold"
                        type="number"
                        value={slippagePercentage.toNumber()}
                        onChange={e => handleUpdate(Number(e.target.value))}
                        isDisabled={isDisabled}
                    />
                    <InputRightElement mr={1} children={<Badge>%</Badge>} />
                </InputGroup>
                <HStack>
                    {PERCENTAGE_VALUES.map(value => (
                        <Button
                            isDisabled={isDisabled}
                            key={value}
                            variant="link"
                            size="sm"
                            onClick={() => handleUpdate(value)}
                        >
                            {value}%
                        </Button>
                    ))}
                </HStack>
            </HStack>
        </VStack>
    )
}
