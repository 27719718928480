import { IS_PRODUCTION } from "constant/envVariables"
import Pusher from "pusher-js"

export interface PusherConfig {
    apiKey: string
    cluster: string
}

class PusherService {
    getInstance({ apiKey, cluster }: PusherConfig) {
        Pusher.logToConsole = !IS_PRODUCTION
        return new Pusher(apiKey, { cluster })
    }
}

const pusherService = new PusherService()
export default pusherService
