import { Image, Text, VStack } from "@chakra-ui/react"
import { MotionBox, MotionCenter, MotionHStack } from "component/Motion"
import { COIN_METADATA_MAP } from "constant/coin"
import { useBaseAssetSymbolFromRouterParams } from "hook/useRouterParams"
import { useHistory } from "react-router-dom"

function TitleMarketDetail() {
    const title = useBaseAssetSymbolFromRouterParams()
    // TODO: update the coin image
    const coinInfo = COIN_METADATA_MAP[title]
    const subTitle = coinInfo?.FULL_NAME
    const imgUrl = coinInfo?.IMG_PATH
    const history = useHistory()

    function switchMode() {
        if (history.location.pathname.includes("/markets")) {
            history.push(history.location.pathname.replace("/markets/", "/pools/"))
        } else {
            history.push(history.location.pathname.replace("/pools/", "/markets/"))
        }
    }

    return (
        <MotionCenter flexShrink={0} h="40px" onClick={() => switchMode()}>
            <MotionHStack spacing={2} w={[null, "full"]}>
                <MotionBox animate={{ rotateY: 360, transition: { delay: 0.4, duration: 0.7 } }} mr="6px">
                    <Image htmlWidth="26px" htmlHeight="26px" borderRadius="full" src={imgUrl} />
                </MotionBox>
                <VStack align="flex-start" spacing={subTitle ? "-4px" : 0} h="100%">
                    <Text fontSize={"lg"} fontWeight="bold">
                        {title}
                    </Text>
                    <Text color="gray.500" fontSize="xs">
                        {subTitle}
                    </Text>
                </VStack>
            </MotionHStack>
        </MotionCenter>
    )
}

export { TitleMarketDetail }
