import { formatNumber } from "util/format"

import { Big } from "big.js"
import { useVirtualTickerSymbolFromLocationObject } from "hook/useRouterParams"
import { useEffect, useState } from "react"

import { LiquidityProvider } from "../container/liquidityProvider"

const PLACEHOLDER = "--"
const WEEKS_PER_YEAR = 52
// NOTE: reference table: https://www.notion.so/perp/Liquidity-Mining-918fb102b27e44c687fe6d1cd4b1bd56#a44c21c9ce6642ce98c5407503212dfb
const RISK_MAP: Record<number, "Low" | "Medium" | "High"> = {
    1: "High",
    2: "Medium",
    3: "Low",
}

const RISK_MAP_APR: Record<number, number> = {
    0: 2,
    1: 1,
    2: 0,
}
const getRiskForLeverage = (leverage: number) => (leverage < 0.5 ? 3 : leverage >= 0.5 && leverage < 1 ? 2 : 1)

const getOverallRiskLevel = (priceRangeDeltaStep: number, leverage: number | undefined) => {
    if (leverage === undefined) {
        return PLACEHOLDER
    }
    const riskLevelForLeverage = getRiskForLeverage(leverage)
    return RISK_MAP[Math.min(priceRangeDeltaStep, riskLevelForLeverage)] ?? PLACEHOLDER
}

const getAprRiskLevel = (priceRangeDeltaStep: number, leverage: number | undefined) => {
    if (leverage === undefined) {
        // NOTE; priceRangeDeltaStep start from 1 to 3
        // minus 1 make it start 0 to 2
        return priceRangeDeltaStep - 1
    }
    return Math.max(priceRangeDeltaStep, getRiskForLeverage(leverage)) - 1
}
export const useRiskLevel = (priceRangeDeltaStep: number, leverage: number | undefined, providedLiquidity: string) => {
    const [overallRisk, setOverallRisk] = useState(PLACEHOLDER)
    useEffect(() => {
        const riskLevel = getOverallRiskLevel(priceRangeDeltaStep, leverage)
        setOverallRisk(riskLevel)
    }, [priceRangeDeltaStep, leverage])

    const marketSymbol = useVirtualTickerSymbolFromLocationObject()
    const { getDefaultMarket } = LiquidityProvider.useContainer()
    const defaultMarket = getDefaultMarket(marketSymbol)
    const [estimatedWeeklyFees, setEstimatedWeeklyFees] = useState<string>(PLACEHOLDER)
    const [estimatedWeeklyRewards, setEstimatedWeeklyRewards] = useState<string>(PLACEHOLDER)
    useEffect(() => {
        if (!defaultMarket || !providedLiquidity.length) {
            return
        }
        const { riskLevelBaseAprs, riskLevelRewardAprs, riskLevelRewardOpAprs } = defaultMarket
        const riskLevel = getAprRiskLevel(priceRangeDeltaStep, leverage)
        try {
            const weeklyBaseAprs =
                +providedLiquidity * (riskLevelBaseAprs[RISK_MAP_APR[riskLevel]] / WEEKS_PER_YEAR / 100)
            setEstimatedWeeklyFees(formatNumber(new Big(weeklyBaseAprs), 2))
            const weeklyRewardAprs =
                +providedLiquidity *
                (riskLevelRewardAprs[RISK_MAP_APR[riskLevel]] / WEEKS_PER_YEAR / 100 +
                    riskLevelRewardOpAprs[RISK_MAP_APR[riskLevel]] / WEEKS_PER_YEAR / 100)
            setEstimatedWeeklyRewards(formatNumber(new Big(weeklyRewardAprs), 2))
        } catch (error) {
            console.error("Risk level", error)
        }
    }, [providedLiquidity, defaultMarket, leverage, priceRangeDeltaStep])

    return {
        overallRisk,
        estimatedWeeklyFees,
        estimatedWeeklyRewards,
    }
}
