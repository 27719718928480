import { ApolloQueryResult } from "@apollo/client"
import { GraphqlQueryError, errorGuardAsync } from "@perp/sdk-curie"

import { AppSyncService } from "./appSyncService"
import { GET_CLAIMABLE_REWARDS, LIST_MAKER_APRS, LIST_MARKET_APRS } from "./graphQueries"

export interface DefaultMarket {
    baseSymbol: string
    quoteSymbol: string
    marketSymbol: string
    lowerBaseApr: number
    upperBaseApr: number
    riskLevelBaseAprs: number[]
    lowerRewardApr: number
    upperRewardApr: number
    riskLevelRewardAprs: number[]
    lowerRewardOpApr: number
    upperRewardOpApr: number
    riskLevelRewardOpAprs: number[]
}

interface GetListMarketAprsResponse {
    listMarketAprs: {
        items: DefaultMarket[]
        nextToken: string | null
    }
}

export interface MakerMarket {
    baseSymbol: string
    quoteSymbol: string
    marketSymbol: string
    baseApr: number
    rewardApr: number
    netValue: number
    claimableRewardAmount: number
    claimableRewardUsd: number
    estimatedRewardAmount: number
    estimatedRewardUsd: number
    claimableRewardAmountOp: number
    claimableRewardUsdOp: number
    estimatedRewardOpAmount: number
    estimatedRewardOpUsd: number
    rewardOpApr: number
}

interface GetMakerMarketResponse {
    listMakerAprs: { items: MakerMarket[] }
}

interface ClaimableRewardsResponse {
    totalBalanceUsd: string
}

interface ClaimableRewardsResponse {
    getClaimableRewards: {
        totalBalance: string
        totalBalanceOp: string
    }
}
export class LiquidityProviderService extends AppSyncService {
    // this query is for users who provide liquidity already
    async listMakerAprs(account: string): Promise<MakerMarket[] | null> {
        return errorGuardAsync(
            async () => {
                const result: ApolloQueryResult<GetMakerMarketResponse> = await this._client.query({
                    query: LIST_MAKER_APRS,
                    variables: {
                        address: account,
                    },
                })

                return result.data.listMakerAprs.items
            },
            rawError =>
                new GraphqlQueryError({
                    functionName: "listMakerAprs",
                    query: "LIST_MAKER_APRS",
                    rawError,
                }),
        )
    }
    // this query is for APR generally
    async listMarketAprs(limit = 100, nextToken = ""): Promise<DefaultMarket[] | null> {
        return errorGuardAsync(
            async () => {
                const result: ApolloQueryResult<GetListMarketAprsResponse> = await this._client.query({
                    query: LIST_MARKET_APRS,
                    variables: { limit, nextToken },
                })
                return result.data.listMarketAprs.items
            },
            rawError =>
                new GraphqlQueryError({
                    functionName: "listMarketAprs",
                    query: "LIST_MARKET_APRS",
                    rawError,
                }),
        )
    }

    async getClaimableRewards(address: string): Promise<string | null> {
        return errorGuardAsync(
            async () => {
                const result: ApolloQueryResult<ClaimableRewardsResponse> = await this._client.query({
                    query: GET_CLAIMABLE_REWARDS,
                    variables: { address },
                })
                return result.data.getClaimableRewards.totalBalance
            },
            rawError =>
                new GraphqlQueryError({
                    functionName: "getClaimableRewards",
                    query: "GET_CLAIMABLE_REWARDS",
                    rawError,
                }),
        )
    }

    async getClaimableRewardsOp(address: string): Promise<string | null> {
        return errorGuardAsync(
            async () => {
                const result: ApolloQueryResult<ClaimableRewardsResponse> = await this._client.query({
                    query: GET_CLAIMABLE_REWARDS,
                    variables: { address },
                })
                return result.data.getClaimableRewards.totalBalanceOp
            },
            rawError =>
                new GraphqlQueryError({
                    functionName: "getClaimableRewards",
                    query: "GET_CLAIMABLE_REWARDS",
                    rawError,
                }),
        )
    }
}
