import { debounce } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { TxLoading } from "sdk-react/tools/useTxLoading"

import { PerpButton, PerpButtonProps } from "./PerpButton"

const DEBOUNCE_DELAY = 500

export function TxButton({
    onClick,
    children,
    isLoading: isLoadingProp,
    isDisabled,
    disabled,
    ...buttonProps
}: PerpButtonProps) {
    const { isTxLoading } = TxLoading.useContainer()
    const [isLoadingState, setIsLoading] = useState(false)

    const debouncedOnClick = useMemo(
        () =>
            onClick
                ? debounce(event => {
                      setIsLoading(true)
                      onClick(event)
                  }, DEBOUNCE_DELAY)
                : undefined,
        [onClick],
    )

    // NOTE: reset loading state when tx finishes.
    useEffect(() => {
        if (!isTxLoading) {
            setIsLoading(false)
        }
    }, [isTxLoading])

    const _isLoading = isLoadingState || isLoadingProp

    const isDisabledProp = isDisabled || disabled
    const _isDisabled = _isLoading || isTxLoading || isDisabledProp

    return (
        <PerpButton
            onClick={debouncedOnClick}
            isLoading={_isLoading}
            isDisabled={_isDisabled}
            width="full"
            colorScheme="perpetual"
            {...buttonProps}
        >
            {children}
        </PerpButton>
    )
}
