import { gql } from "@apollo/client"
import { ContractFunctionSignature } from "constant/contractFunctionSignatures"

export const POLLING_INTERVAL = 10_000

export const MARKETS_QUERY = gql`
    query Markets {
        markets {
            id
            pool
            baseToken
            quoteToken
            tradingVolume
            tradingFee
        }
    }
`

export const POSITION_CHANGEDS_QUERY = gql`
    query MarketTradeHistory($baseToken: String, $first: Int) {
        positionChangeds(
            first: $first
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: {
                baseToken: $baseToken
                fromFunctionSignature_not_in: [
                    "${ContractFunctionSignature.ADD_LIQUIDITY}"
                    "${ContractFunctionSignature.REMOVE_LIQUIDITY}"
                    "${ContractFunctionSignature.CANCEL_ALL_EXCESS_ORDERS}"
                    "${ContractFunctionSignature.CANCEL_EXCESS_ORDERS}"
                ]
            }
        ) {
            id
            blockNumberLogIndex
            exchangedPositionSize
            swappedPrice
            timestamp
        }
    }
`

export const POSITION_CHANGEDS_BY_TRADER_DEFAULT_QUERY = gql`
    query PersonalTradeHistory($trader: Bytes!, $blockNumberLogIndex_lt: String!, $first: Int, $skip: Int) {
        positionChangeds(
            first: $first
            skip: $skip
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: {
                trader: $trader
                fromFunctionSignature_not_in: [
                    "${ContractFunctionSignature.LIQUIDATE}"
                    "${ContractFunctionSignature.LIQUIDATE_V2}"
                    "${ContractFunctionSignature.LIQUIDATE_V3}"
                ]
                blockNumberLogIndex_lt: $blockNumberLogIndex_lt
            }
        ) {
            id
            blockNumberLogIndex
            trader
            baseToken
            exchangedPositionSize
            exchangedPositionNotional
            swappedPrice
            fee
            realizedPnl
            blockNumber
            fromFunctionSignature
            timestamp
        }
    }
`

export const POSITION_LIQUIDATION_BY_TRADER_QUERY = gql`
    query PositionLiquidations($trader: Bytes, $first: Int, $skip: Int) {
        positionChangeds(
            first: $first
            skip: $skip
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: {
                trader: $trader
                fromFunctionSignature_in: [
                    "${ContractFunctionSignature.LIQUIDATE}"
                    "${ContractFunctionSignature.LIQUIDATE_V2}"
                    "${ContractFunctionSignature.LIQUIDATE_V3}"
                ]
            }
        ) {
            id
            blockNumberLogIndex
            trader
            baseToken
            exchangedPositionSize
            exchangedPositionNotional
            swappedPrice
            fee
            realizedPnl
            blockNumber
            fromFunctionSignature
            timestamp
        }
    }
`

export const POSITION_CLOSEDS_BY_TRADER_QUERY = gql`
    query PositionCloseds($trader: Bytes, $first: Int, $skip: Int) {
        positionCloseds(
            first: $first
            skip: $skip
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: { trader: $trader }
        ) {
            id
            blockNumberLogIndex
            trader
            baseToken
            closedPositionSize
            closedPositionNotional
            closedPrice
            realizedPnl
            blockNumber
            timestamp
        }
    }
`

export const FUNDING_UPDATEDS_BY_BASE_TOKEN_QUERY = gql`
    query FundingUpdateds($baseToken: Bytes) {
        fundingUpdateds(
            first: 1
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: { baseToken: $baseToken }
        ) {
            id
            baseToken
            blockNumber
            dailyFundingRate
        }
    }
`

export const FUNDING_PAYMENT_SETTLEDS_QUERY = gql`
    query FundingPaymentSettleds($trader: Bytes, $first: Int, $skip: Int) {
        fundingPaymentSettleds(
            first: $first
            skip: $skip
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: { trader: $trader }
        ) {
            id
            blockNumberLogIndex
            trader
            baseToken
            fundingPayment
            timestamp
        }
    }
`

export const DEPOSITEDS_QUERY = gql`
    query Depositeds($trader: Bytes, $first: Int, $skip: Int) {
        depositeds(
            first: $first
            skip: $skip
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: { trader: $trader }
        ) {
            id
            blockNumberLogIndex
            txHash
            trader
            collateralToken
            amount
            blockNumber
            timestamp
        }
    }
`

export const WITHDRAWNS_QUERY = gql`
    query Withdrawns($trader: Bytes, $first: Int, $skip: Int) {
        withdrawns(
            first: $first
            skip: $skip
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: { trader: $trader }
        ) {
            id
            blockNumberLogIndex
            txHash
            trader
            collateralToken
            amount
            blockNumber
            timestamp
        }
    }
`

export const COLLATERAL_LIQUIDATEDS_QUERY = gql`
    query CollateralLiquidateds($trader: Bytes, $first: Int, $skip: Int) {
        collateralLiquidateds(
            first: $first
            skip: $skip
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: { trader: $trader }
        ) {
            id
            blockNumberLogIndex
            txHash
            trader
            collateralToken
            liquidator
            collateral
            repaidSettlementWithoutInsuranceFundFee
            insuranceFundFee
            discountRatio
            blockNumber
            timestamp
        }
    }
`

export const LIMIT_ORDER_FILLEDS_QUERY = gql`
    query LimitOrderFilleds($trader: Bytes!, $blockNumberLogIndex_lt: String!, $first: Int, $skip: Int) {
        limitOrderFilleds(
            first: $first
            skip: $skip
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: { trader: $trader, blockNumberLogIndex_lt: $blockNumberLogIndex_lt }
        ) {
            id
            blockNumberLogIndex
            txHash
            trader
            baseToken
            blockNumber
            blockNumberLogIndex
            orderType
            filledPrice
            exchangedPositionSize
            exchangedPositionNotional
            timestamp
        }
    }
`

export const LIMIT_ORDER_CANCELLEDS_QUERY = gql`
    query LimitOrderCancelleds($trader: Bytes!, $blockNumberLogIndex_lt: String!, $first: Int, $skip: Int) {
        limitOrderCancelleds(
            first: $first
            skip: $skip
            orderBy: blockNumberLogIndex
            orderDirection: desc
            where: { trader: $trader, blockNumberLogIndex_lt: $blockNumberLogIndex_lt }
        ) {
            id
            blockNumberLogIndex
            txHash
            trader
            baseToken
            blockNumber
            blockNumberLogIndex
            orderType
            limitPrice
            positionSize
            timestamp
        }
    }
`

export const SUBGRAPH_VERSION_QUERY = gql`
    query Protocols {
        protocols(first: 1) {
            id
            network
            chainId
            contractVersion
        }
    }
`
