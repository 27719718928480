import { TransactionReceipt } from "@ethersproject/providers"
import { RpcIntrinsicGasTooLowError, RpcRejectedError, SDKErrorContractWrite } from "@perp/sdk-curie"
import { ContractTransaction } from "ethers"
import { useCallback, useState } from "react"
import { createContainer } from "unstated-next"

export interface ITxNotifiers {
    onSent: (tx: ContractTransaction) => void
    onSuccess: (receipt: TransactionReceipt, contractFunctionName: string) => void
    onFailure: (error: SDKErrorContractWrite | RpcIntrinsicGasTooLowError) => void
    onRejected: (error: RpcRejectedError) => void
}

export const TxNotifiers = createContainer(useTxNotifiers)

function useTxNotifiers(initialState?: Partial<ITxNotifiers>) {
    const [presetTxNotifiers, setTxNotifiers] = useState<ITxNotifiers>({
        onSent: tx => console.log(`Transaction Sent. It might take a few minutes. Check txId: ${tx.hash}`),
        onSuccess: receipt =>
            console.log(
                `Transaction Succeed. Amounts should be updated shortly. Check txId: ${receipt.transactionHash}`,
            ),
        onFailure: error => console.log(`Transaction Failed: ${error}`),
        onRejected: error => console.log(`Transaction have been rejected: ${error}`),
        ...initialState,
    })

    const getTxNotifiers = useCallback(
        (customizeNotifiers: Partial<ITxNotifiers> = {}): ITxNotifiers => {
            return {
                ...presetTxNotifiers,
                ...customizeNotifiers,
                // TODO: pass presetNotifiers back to customizedNotifiers for composable interface
                // onSuccess: (...args) => {
                //     if (customizeNotifiers.onSuccess) {
                //         customizeNotifiers.onSuccess(...args, presetNotifiers.onSuccess)
                //     }
                // },
            }
        },
        [presetTxNotifiers],
    )

    const updateTxNotifiers = useCallback(
        (customized: Partial<ITxNotifiers> = {}) => {
            setTxNotifiers(getTxNotifiers(customized))
        },
        [getTxNotifiers],
    )

    return {
        getTxNotifiers,
        updateTxNotifiers,
    }
}
