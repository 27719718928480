import React from "react"
export const PageHome = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PageHome" */
            "module/home/component/PageHome"
        ),
)
export const PageWelcome = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PageWelcome" */
            "module/home/component/PageWelcome"
        ),
)

export const PageMarketDetail = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PageMarketDetail" */
            "module/market/component/PageMarketDetail"
        ),
)

export const MarketList = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "MarketList" */
            "module/market/component/MarketList"
        ),
)

export const PagePoolDetail = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PagePoolDetail" */
            "module/pool/component/PagePoolDetail"
        ),
)

export const PoolList = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PoolList" */
            "module/pool/component/PoolList"
        ),
)

export const PageHistory = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PageHistory" */
            "module/history/component/PageHistory"
        ),
)

export const PageOrders = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PageOrders" */
            "module/position/component/PageOrders"
        ),
)
export const PagePositions = React.lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "PagePositions" */
            "module/position/component/PagePositions"
        ),
)
