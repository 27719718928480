import { formatNumberPositionSize, formatNumberUSD } from "util/format"

import { Position } from "@perp/sdk-curie"
import { usePositionDetail } from "sdk-react/clearingHouse/usePositionDetail"

const PLACEHOLDER = "-,---"

export function usePositionWithRatioStrings(position: Position, ratio: number = 100) {
    const { unrealizedPnl, notionalValue } = usePositionDetail(position)
    const unrealizedPnlPrefix = unrealizedPnl && unrealizedPnl.gte(0) ? "+" : ""
    const ratioPercentage = ratio / 100
    const positionSizeStr = formatNumberPositionSize(position.sizeAbs.mul(ratioPercentage))
    const notionalValueStr = notionalValue ? formatNumberUSD({ num: notionalValue.mul(ratioPercentage) }) : PLACEHOLDER
    const unrealizedPnlStr = unrealizedPnl
        ? `${unrealizedPnlPrefix}${formatNumberUSD({ num: unrealizedPnl.mul(ratioPercentage) })}`
        : PLACEHOLDER
    return {
        positionSizeStr,
        notionalValueStr,
        unrealizedPnlStr,
        unrealizedPnl,
    }
}
