import { Liquidity, Market } from "@perp/sdk-curie"
import Big from "big.js"
import { useEffect, useMemo, useState } from "react"
import { Liquidities } from "sdk-react/liquidity/useLiquidities"
import { usePerpConnectedEffect } from "sdk-react/perpetualProtocol/usePerpConnectedEffect"

export function useLiquidity(tickerSymbol: string) {
    const { totalLiquidities, openLiquiditiesFromMarkets, totalPendingFees } = Liquidities.useContainer()
    const [market, setMarket] = useState<Market>()
    const [totalPendingFee, setTotalPendingFee] = useState<Big>()
    const [openLiquidities, setOpenLiquidities] = useState<Liquidity[]>([])
    const [totalLiquidity, setTotalLiquidity] = useState<Big>()

    usePerpConnectedEffect(
        ({ markets }) => {
            const market = markets.getMarket({ tickerSymbol })
            setMarket(market)
        },
        [tickerSymbol],
    )

    useEffect(() => {
        if (!market) {
            return
        }

        setTotalLiquidity(totalLiquidities[market.baseAddress])
    }, [market, totalLiquidities])

    useEffect(() => {
        if (!market) {
            return
        }

        setTotalPendingFee(totalPendingFees[market.baseAddress])
    }, [market, totalPendingFees])

    useEffect(() => {
        if (!market) {
            return
        }

        setOpenLiquidities(openLiquiditiesFromMarkets[market.baseAddress] || [])
    }, [market, openLiquiditiesFromMarkets])

    const hasOpenLiquidities = useMemo(() => {
        return openLiquidities.length > 0
    }, [openLiquidities])

    return {
        totalPendingFee,
        hasOpenLiquidities,
        totalLiquidity,
        openLiquidities,
    }
}
