import { CoinbaseWallet } from "@web3-react/coinbase-wallet"
import { Web3ReactHooks } from "@web3-react/core"
import { MetaMask } from "@web3-react/metamask"
import { Web3ReactStore } from "@web3-react/types"
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2"
import { ConnectorFactory } from "./ConnectorFactory"

export class ConnectorManager {
    static instance: ConnectorManager

    public walletConnectV2: [WalletConnectV2, Web3ReactHooks, Web3ReactStore]
    public metamask: [MetaMask, Web3ReactHooks, Web3ReactStore]
    public coinbaseWallet: [CoinbaseWallet, Web3ReactHooks, Web3ReactStore]

    private constructor(private readonly connectorFactory: ConnectorFactory) {
        this.walletConnectV2 = this.connectorFactory.createWalletConnectV2()
        this.metamask = this.connectorFactory.createMetamask()
        this.coinbaseWallet = this.connectorFactory.createCoinbaseWallet()
    }

    static getInstance() {
        if (!ConnectorManager.instance) {
            const connectorFactory = new ConnectorFactory()
            ConnectorManager.instance = new ConnectorManager(connectorFactory)
        }
        return ConnectorManager.instance
    }
}
