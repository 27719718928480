import { useQuery } from "@apollo/client"
import Big from "big.js"
import { useMemo, useState } from "react"
import { usePerpConnectedEffectAsync } from "sdk-react/perpetualProtocol/usePerpConnectedEffectAsync"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"
import { MARKETS_QUERY, POLLING_INTERVAL } from "../graphServer/graphQueries"

interface APIPool {
    id: string
    pool: string // NOTE: address
    baseToken: string
    quoteToken: string
    tradingVolume: string // NOTE: Total accumulated from day 1.
    tradingFee: string // NOTE: Total accumulated from day 1.
}

interface GraphDataPool {
    markets: APIPool[]
}

export const Pools = createContainer(usePools)
interface QuoteAndBaseAmountProps {
    [poolAddress: string]: {
        baseAmount: string
        quoteAmount: string
    }
}
function usePools() {
    const {
        data,
        loading: isLoading,
        error: queryError,
    } = useQuery<GraphDataPool>(MARKETS_QUERY, {
        pollInterval: POLLING_INTERVAL,
    })
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    if (queryError) {
        onError(queryError)
    }
    const [quoteAndBaseAmount, setQuoteAndBaseAmount] = useState<QuoteAndBaseAmountProps>()
    usePerpConnectedEffectAsync(
        // TODO: [429 issue] check if we can reduce contract calls
        async ({ markets }) => {
            if (data) {
                try {
                    const result = await markets.getMarketsBaseQuoteAmount(data.markets)
                    setQuoteAndBaseAmount(result)
                } catch (e) {
                    setQuoteAndBaseAmount(undefined)
                }
            }
        },
        [data],
    )

    const pools = useMemo(() => {
        if (!data || !quoteAndBaseAmount) {
            return []
        }

        return data.markets.map(({ id, pool, baseToken, quoteToken, tradingVolume, tradingFee }: APIPool) => {
            const { baseAmount = "0", quoteAmount = "0" } = quoteAndBaseAmount[pool] || {}
            return {
                id,
                poolAddress: pool,
                baseAddress: baseToken,
                quoteAddress: quoteToken,
                baseAmount: new Big(baseAmount),
                quoteAmount: new Big(quoteAmount),
                tradingVolume: new Big(tradingVolume),
                tradingFee: new Big(tradingFee),
            }
        })
    }, [data, quoteAndBaseAmount])

    return {
        isLoading,
        pools,
    }
}
