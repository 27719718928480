export enum RouteName {
    ROOT = "/",

    ORDERS = "/orders",
    POSITIONS = "/positions",

    MARKET_LIST = "/markets",
    MARKET_DETAIL = "/markets/:base::quote",

    POOL_LIST = "/pools",
    POOL_DETAIL = "/pools/:base::quote",

    HISTORY_ROOT = "/history",
    HISTORY_TRADE = "/history/trade",
    HISTORY_FUNDING_PAYMENT = "/history/funding-payment",
    HISTORY_FUNDING_PAYMENT_PENDING = "/history/pending-funding-payment",
    HISTORY_DEPOSIT = "/history/deposit",
    HISTORY_WITHDRAW = "/history/withdraw",
    HISTORY_POSITION_LIQUIDATION = "/history/position-liquidation",
    HISTORY_COLLATERAL_LIQUIDATION = "/history/collateral-liquidation",
    HISTORY_LIMIT_ORDER = "/history/limit-order",
    HISTORY = "/history/:tab(trade|funding-payment|pending-funding-payment|deposit|withdraw|position-liquidation|collateral-liquidation|limit-order)",
}
