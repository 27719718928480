import { Text } from "@chakra-ui/react"
import {
    AlreadyOverPriceLimitOnceError,
    CollateralDepositCapError,
    isSDKErrorContractRead,
    isSDKErrorContractWrite,
    MarketNumberExceedsError,
    NonExistentOpenOrderError,
    NotEnoughAccountValueByImRatioError,
    NotEnoughFreeCollateralError,
    NotEnoughLiquidityError,
    NotEnoughMinimumRequiredMarginError,
    OrdersNumberExceedsError,
    OverMaximumPriceSpreadError,
    OverPriceBandError,
    OverPriceLimitAfterSwapError,
    OverPriceLimitBeforeSwapError,
    PositionSizeIsZeroError,
    PriceSlippageCheckError,
    ReducePositionInvalidError,
    RpcIntrinsicGasTooLowError,
    UnableToQuoteError,
    UniswapBrokerInsufficientLiquidityError,
} from "@perp/sdk-curie"
import { PerpLink } from "component/PerpLink"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

function withContractErrorCode(msg: string, contractErrorCode?: string) {
    return `${msg} ${contractErrorCode ? `(${contractErrorCode})` : ""}`
}

type SDKErrorParser = (
    error: Error,
    params?: Record<string, string>,
) => {
    isSDKError: boolean
    contractErrorCode?: string
    message: string
    description?: React.ReactNode
}

export function useSDKErrorParser() {
    const { t } = useTranslation()

    const parseSdkErrorMessage = useCallback<SDKErrorParser>(
        (error, params) => {
            let message: string | undefined
            let description: React.ReactNode | undefined
            let contractErrorCode: string | undefined

            if (isSDKErrorContractRead(error)) {
                contractErrorCode = error.contractErrorCode

                if (error instanceof UnableToQuoteError) {
                    message = t("notification.error_unable_to_quote", params)
                } else if (error instanceof UniswapBrokerInsufficientLiquidityError) {
                    message = t("notification.error_uniswap_broker_insufficient_liquidity", params)
                } else if (error instanceof NotEnoughFreeCollateralError) {
                    message = t("notification.error_not_enough_free_collateral", params)
                } else if (error instanceof ReducePositionInvalidError) {
                    message = t("notification.error_reduce_position_too_much", params)
                }
            } else if (isSDKErrorContractWrite(error)) {
                contractErrorCode = error.contractErrorCode

                if (error instanceof PriceSlippageCheckError) {
                    message = t("notification.error_price_slippage_check", params)
                } else if (error instanceof AlreadyOverPriceLimitOnceError) {
                    message = t("notification.error_already_over_price_limit_once", params)
                } else if (error instanceof OverPriceLimitBeforeSwapError) {
                    message = t("notification.error_over_price_limit_before_swap", params)
                } else if (error instanceof OverPriceLimitAfterSwapError) {
                    message = t("notification.error_over_price_limit_after_swap", params)
                } else if (error instanceof OverPriceBandError) {
                    message = t("notification.error_over_price_band", params)
                } else if (error instanceof PositionSizeIsZeroError) {
                    message = t("notification.error_position_size_is_zero", params)
                } else if (error instanceof NotEnoughAccountValueByImRatioError) {
                    message = t("notification.error_not_enough_account_value_by_im_ratio", params)
                } else if (error instanceof OrdersNumberExceedsError) {
                    message = t("notification.error_orders_number_exceeds", params)
                } else if (error instanceof NotEnoughLiquidityError) {
                    message = t("notification.error_not_enough_liquidity", params)
                } else if (error instanceof NonExistentOpenOrderError) {
                    message = t("notification.error_non_existent_open_order", params)
                } else if (error instanceof CollateralDepositCapError) {
                    message = t("notification.error_collateral_deposit_cap", params)
                } else if (error instanceof MarketNumberExceedsError) {
                    message = t("notification.error_market_number_exceeds", params)
                } else if (error instanceof OverMaximumPriceSpreadError) {
                    message = t("notification.error_over_maximum_price_spread", params)
                } else if (error instanceof NotEnoughMinimumRequiredMarginError) {
                    message = t("notification.error_not_enough_minimum_required_margin", params)
                    description = (
                        <Text>
                            {message}{" "}
                            <PerpLink href="https://support.perp.com/hc/en-us/articles/8591702568217">
                                <Text color="red.600">Learn more</Text>
                            </PerpLink>
                        </Text>
                    )
                }
            } else if (error instanceof RpcIntrinsicGasTooLowError) {
                message = t("notification.error_intrinsic_gas_too_low", params)
            }

            return {
                isSDKError: !!message,
                contractErrorCode,
                message: message
                    ? withContractErrorCode(message, contractErrorCode)
                    : t("notification.error_something_went_wrong"),
                description,
            }
        },
        [t],
    )

    return { parseSdkErrorMessage }
}
