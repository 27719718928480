import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Button,
    ButtonProps,
    Heading,
    Icon,
    Image,
    Text,
    VStack,
    useColorModeValue,
} from "@chakra-ui/react"
import { PerpModal } from "component/PerpModal"
import { useTranslation } from "react-i18next"
import { RiArrowRightUpLine } from "react-icons/ri"

import { ModalRootProps } from "./Modal/type"

const faviconUrl = "https://s2.googleusercontent.com/s2/favicons?domain="

export function ModalTransfer({ isOpen, close }: ModalRootProps) {
    const colorSubText = useColorModeValue("gray.500", "gray.400")
    const { t } = useTranslation()
    return (
        <PerpModal
            isOpen={isOpen}
            onClose={close}
            scrollBehavior="inside"
            motionPreset="slideInBottom"
            // header="Transfer USDC"
            body={
                <>
                    <VStack spacing={4} align="stretch">
                        <VStack textAlign="center">
                            <Heading size="lg">{t("bridge.bridge_usdc")}</Heading>
                            <Text color={colorSubText} textAlign="center">
                                {t("bridge.from.introduction")}
                            </Text>
                        </VStack>
                        <Accordion allowToggle defaultIndex={[0]}>
                            <AccordionItem>
                                <Text fontWeight="bold">
                                    <AccordionButton px={0} py={4}>
                                        <Image
                                            borderRadius="full"
                                            boxSize="24px"
                                            mr={2}
                                            src="/assets/network/network-eth.svg"
                                        />
                                        <Box flex="1" textAlign="left">
                                            {t("bridge.from.ethereum")}
                                        </Box>
                                        <Badge mx={4} colorScheme="perpetual">
                                            {t("bridge.from.most_common")}
                                        </Badge>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </Text>
                                <AccordionPanel pb={4} pl={0}>
                                    <ExternalButton
                                        text="Router"
                                        faviconOverride="https://adjacent-rose-planarian.faviconkit.com/app.routerprotocol.com/16"
                                        url="https://app.routerprotocol.com/swap?fromToken=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&fromChain=1&toToken=0x7F5c764cBc14f9669B88837ca1490cCa17c31607&toChain=10"
                                    />
                                    <ExternalButton
                                        text="Hop Exchange"
                                        url="https://app.hop.exchange/send?token=USDC&sourceNetwork=ethereum&destNetwork=optimism"
                                    />

                                    <ExternalButton text="O3 Swap" url="https://o3swap.com/hub" />

                                    <ExternalButton text="Optimism Bridge" url="https://app.optimism.io/bridge" />
                                    <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <Text fontWeight="bold">
                                    <AccordionButton px={0} py={4}>
                                        <Image
                                            borderRadius="full"
                                            boxSize="24px"
                                            mr={2}
                                            src="/assets/network/network-arbitrum.svg"
                                        />
                                        <Box flex="1" textAlign="left">
                                            {t("bridge.from.arbitrum")}
                                        </Box>

                                        <AccordionIcon />
                                    </AccordionButton>
                                </Text>
                                <AccordionPanel pb={4} pl={0}>
                                    <ExternalButton
                                        text="Hop Exchange"
                                        url="https://app.hop.exchange/send?token=USDC&sourceNetwork=arbitrum&destNetwork=optimism"
                                    />
                                    <ExternalButton
                                        text="xPollinate"
                                        url="https://xpollinate.io/?fromChain=42161&fromToken=0xff970a61a04b1ca14834a43f5de4533ebddb5cc8&toChain=10&toToken=0x7f5c764cbc14f9669b88837ca1490cca17c31607&fromAmount=1000"
                                    />
                                    <ExternalButton text="O3 Swap" url="https://o3swap.com/hub" />{" "}
                                    <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <Text fontWeight="bold">
                                    <AccordionButton px={0} py={4}>
                                        <Image
                                            borderRadius="full"
                                            boxSize="24px"
                                            mr={2}
                                            src="/assets/network/network-xdai.svg"
                                        />
                                        <Box flex="1" textAlign="left">
                                            {t("bridge.from.xDai")}
                                        </Box>

                                        <AccordionIcon />
                                    </AccordionButton>
                                </Text>
                                <AccordionPanel pb={4} pl={0}>
                                    <ExternalButton
                                        text="Hop Exchange"
                                        url="https://app.hop.exchange/send?token=USDC&sourceNetwork=xdai&destNetwork=optimism"
                                    />

                                    <ExternalButton
                                        text="xPollinate"
                                        url="https://xpollinate.io/?fromChain=100&fromToken=0xddafbb505ad214d7b80b1f830fccc89b60fb7a83&toChain=10&toToken=0x7f5c764cbc14f9669b88837ca1490cca17c31607&fromAmount=1000"
                                    />

                                    <ExternalButton text="O3 Swap" url="https://o3swap.com/hub" />
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <Text fontWeight="bold">
                                    <AccordionButton px={0} py={4}>
                                        <Image
                                            borderRadius="full"
                                            boxSize="24px"
                                            mr={2}
                                            src="/assets/network/network-polygon.svg"
                                        />
                                        <Box flex="1" textAlign="left">
                                            {t("bridge.from.polygon")}
                                        </Box>

                                        <AccordionIcon />
                                    </AccordionButton>
                                </Text>
                                <AccordionPanel pb={4} pl={0}>
                                    <ExternalButton
                                        text="Router"
                                        url="https://app.routerprotocol.com/swap?toToken=0x7F5c764cBc14f9669B88837ca1490cCa17c31607&toChain=10&fromToken=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&fromChain=137"
                                    />
                                    <ExternalButton
                                        text="Hop Exchange"
                                        url="https://app.hop.exchange/send?token=USDC&sourceNetwork=polygon&destNetwork=optimism"
                                    />
                                    <ExternalButton
                                        text="xPollinate"
                                        url="https://xpollinate.io/?fromChain=137&fromToken=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&toChain=10&toToken=0x7f5c764cbc14f9669b88837ca1490cca17c31607&fromAmount=1000"
                                    />
                                    <ExternalButton text="O3 Swap" url="https://o3swap.com/hub" />
                                    <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <Text fontWeight="bold">
                                    <AccordionButton px={0} py={4}>
                                        <Image
                                            borderRadius="full"
                                            boxSize="24px"
                                            mr={2}
                                            src="/assets/network/network-bsc.svg"
                                        />
                                        <Box flex="1" textAlign="left">
                                            {t("bridge.from.bsc")}
                                        </Box>

                                        <AccordionIcon />
                                    </AccordionButton>
                                </Text>
                                <AccordionPanel pb={4} pl={0}>
                                    <ExternalButton
                                        text="Router"
                                        url="https://app.routerprotocol.com/swap?fromToken=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d&fromChain=56&toToken=0x7F5c764cBc14f9669B88837ca1490cCa17c31607&toChain=10"
                                    />
                                    <ExternalButton text="O3 Swap" url="https://o3swap.com/hub" />
                                    <ExternalButton
                                        text="xPollinate"
                                        url="https://xpollinate.io/?fromChain=56&fromToken=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&toChain=10&toToken=0x7f5c764cbc14f9669b88837ca1490cca17c31607&fromAmount=1000"
                                    />
                                    <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                                    <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <Text fontWeight="bold">
                                    <AccordionButton px={0} py={4}>
                                        <Image
                                            borderRadius="full"
                                            boxSize="24px"
                                            mr={2}
                                            src="/assets/network/network-fantom.svg"
                                        />
                                        <Box flex="1" textAlign="left">
                                            {t("bridge.from.fantom")}
                                        </Box>

                                        <AccordionIcon />
                                    </AccordionButton>
                                </Text>
                                <AccordionPanel pb={4} pl={0}>
                                    <ExternalButton
                                        text="Router"
                                        url="https://app.routerprotocol.com/swap?fromToken=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&fromChain=250&toToken=0x7F5c764cBc14f9669B88837ca1490cCa17c31607&toChain=10"
                                    />
                                    <ExternalButton
                                        text="xPollinate"
                                        url="https://xpollinate.io/?fromChain=250&fromToken=0x04068da6c83afcfa0e13ba15a6696662335d5b75&toChain=10&toToken=0x7f5c764cbc14f9669b88837ca1490cca17c31607&fromAmount=1000"
                                    />
                                    <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <Text fontWeight="bold">
                                    <AccordionButton px={0} py={4}>
                                        <Image
                                            borderRadius="full"
                                            boxSize="24px"
                                            mr={2}
                                            src="/assets/network/network-avax.svg"
                                        />
                                        <Box flex="1" textAlign="left">
                                            {t("bridge.from.avalanche")}
                                        </Box>

                                        <AccordionIcon />
                                    </AccordionButton>
                                </Text>
                                <AccordionPanel pb={4} pl={0}>
                                    <ExternalButton
                                        text="Router"
                                        url="https://app.routerprotocol.com/swap?fromToken=0xc7198437980c041c805A1EDcbA50c1Ce5db95118&fromChain=43114&toToken=0x94b008aA00579c1307B0EF2c499aD98a8ce58e58&toChain=10"
                                    />
                                    <ExternalButton text="O3 Swap" url="https://o3swap.com/hub" />
                                    <ExternalButton
                                        text="xPollinate"
                                        url="https://xpollinate.io/?fromChain=43114&fromToken=0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664&toChain=10&toToken=0x7f5c764cbc14f9669b88837ca1490cca17c31607&fromAmount=1000"
                                    />
                                    <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </VStack>
                </>
            }
        />
    )
}

/**
 * External button for bridges
 *
 * @param {{
 *     url?: string
 *     text?: string
 *     colorScheme?: string
 *     faviconOverride?: string
 * }} {
 *     url,
 *     text,
 *     colorScheme,
 *     faviconOverride,
 * }
 * @return {*}
 */

export interface ExternalButtonProps extends ButtonProps {
    url?: string
    text?: string
    faviconOverride?: string
}

export const ExternalButton = ({ url, text, colorScheme, faviconOverride, ...props }: ExternalButtonProps) => {
    return (
        <Button
            leftIcon={<Image boxSize="16px" src={faviconOverride || faviconUrl + url} />}
            mr={4}
            mb={4}
            rightIcon={<Icon opacity="0.6" as={RiArrowRightUpLine} />}
            as="a"
            variant={"outline"}
            size={"sm"}
            target="_blank"
            href={url}
            width={"auto"}
            {...props}
        >
            {text}
        </Button>
    )
}
