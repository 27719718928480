import { getNetwork } from "@ethersproject/providers"
import metadataOptimismGoerli from "@perp/curie-deployments/optimism-goerli/core/metadata.json"
import peripheryMetadataOptimismGoerli from "@perp/curie-deployments/optimism-goerli/periphery/metadata.json"
import metadataOptimism from "@perp/curie-deployments/optimism/core/metadata.json"
import peripheryMetadataOptimism from "@perp/curie-deployments/optimism/periphery/metadata.json"

import { JsonMetadataLoader, JsonPeripheryMetadataLoader } from "./JsonMetadataLoader"
import { IMetadataLoader, IPeripheryMetadataLoader, Metadata, PeripheryMetadata } from "./types"

export class MetadataLoaderFactory {
    getMetadataLoader(chainId: number): IMetadataLoader {
        switch (chainId) {
            case getNetwork("optimism").chainId:
                return this.getOptimismMetadataLoader()
            case getNetwork("optimism-goerli").chainId:
                return this.getOptimismGoerliMetadataLoader()
            default:
                throw new Error(`Unknown Chain Id in ${MetadataLoaderFactory.name}: ${chainId}`)
        }
    }

    getPeripheryMetadataLoader(chainId: number): IPeripheryMetadataLoader {
        switch (chainId) {
            case getNetwork("optimism").chainId:
                return this.getPeripheryOptimismMetadataLoader()
            case getNetwork("optimism-goerli").chainId:
                return this.getPeripheryOptimismGoerliMetadataLoader()
            default:
                throw new Error(`Unknown Chain Id in ${MetadataLoaderFactory.name}: ${chainId}`)
        }
    }

    getOptimismMetadataLoader(): IMetadataLoader {
        return new JsonMetadataLoader(metadataOptimism as unknown as Metadata)
    }

    getOptimismGoerliMetadataLoader(): IMetadataLoader {
        return new JsonMetadataLoader(metadataOptimismGoerli as unknown as Metadata)
    }

    getPeripheryOptimismMetadataLoader(): IPeripheryMetadataLoader {
        return new JsonPeripheryMetadataLoader(peripheryMetadataOptimism as unknown as PeripheryMetadata)
    }

    getPeripheryOptimismGoerliMetadataLoader(): IPeripheryMetadataLoader {
        return new JsonPeripheryMetadataLoader(peripheryMetadataOptimismGoerli as unknown as PeripheryMetadata)
    }
}
