import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"

import { FLAGSMITH_ENV_ID } from "constant/envVariables"
import flagsmith from "flagsmith"
import { IFlags } from "flagsmith/types"
import { useCallback, useEffect, useMemo, useState } from "react"
import { createContainer } from "unstated-next"

interface OnChangeProps {
    features: Record<FeatureFlag, boolean> | {}
    values: Record<FeatureFlag, any> | {}
}

interface FeatureToggleState {
    isLoading: boolean
    features: { [key in FeatureFlag]?: boolean }
    values: { [key in FeatureFlag]?: any }
}

export enum FeatureFlag {
    BICONOMY_WIDGET = "biconomy_widget",
    COLLATERAL_ENABLED = "collateral_enabled",
    EMBEDDED_BRIDGE = "embedded_bridge",
    FUNDING_RATE_CHART = "funding_rate_chart",
    LIMIT_ORDER = "limit_order",
    MAKER_UI = "maker_ui",
    MARKET_ENABLED = "market_enabled",
    REFERRAL = "referral",
    TRADING_COMPETITION = "trading_competition",
    ALL_POSITIONS_AND_ORDERS = "all_positions_and_orders",
}

const DEFAULT_FLAGS: IFlags<FeatureFlag> = {
    [FeatureFlag.BICONOMY_WIDGET]: { enabled: false },
    [FeatureFlag.COLLATERAL_ENABLED]: {
        enabled: true,
        value: JSON.stringify({
            "10": ["USDC", "WETH", "FRAX"],
        }),
    },
    [FeatureFlag.EMBEDDED_BRIDGE]: { enabled: true },
    [FeatureFlag.FUNDING_RATE_CHART]: { enabled: true },
    [FeatureFlag.LIMIT_ORDER]: { enabled: true },
    [FeatureFlag.MAKER_UI]: { enabled: true },
    [FeatureFlag.MARKET_ENABLED]: {
        enabled: true,
        value: JSON.stringify({
            "10": [
                "BTCUSD",
                "ETHUSD",
                "AVAXUSD",
                "SOLUSD",
                "CRVUSD",
                "SANDUSD",
                "LINKUSD",
                "ATOMUSD",
                "ONEUSD",
                "NEARUSD",
                "FTMUSD",
                "FLOWUSD",
                "BNBUSD",
                "PERPUSD",
                "MATICUSD",
                "AAVEUSD",
                "APEUSD",
            ],
        }),
    },
    [FeatureFlag.REFERRAL]: { enabled: true },
    [FeatureFlag.TRADING_COMPETITION]: { enabled: false },
    [FeatureFlag.ALL_POSITIONS_AND_ORDERS]: { enabled: false },
}

export const FeatureToggleContainer = createContainer(useFeatureToggle)

function useFeatureToggle() {
    const { account } = Web3WalletContainer.useContainer()
    const [state, setState] = useState<FeatureToggleState>({
        isLoading: true,
        features: {},
        values: {},
    })

    useEffect(() => {
        if (!FLAGSMITH_ENV_ID) {
            setState(prev => ({ ...prev, isLoading: false }))
            return
        }

        flagsmith.init({
            environmentID: FLAGSMITH_ENV_ID,
            cacheFlags: true,
            defaultFlags: DEFAULT_FLAGS,
            api: "https://edge.api.flagsmith.com/api/v1/", // NOTE: to use the new [Edge API](https://docs.flagsmith.com/advanced-use/edge-api#enabling-the-edge-api)
            onChange: () => {
                const { features, values } = Object.values(FeatureFlag).reduce(
                    ({ features, values }, flag): OnChangeProps => {
                        return {
                            features: { ...features, [flag]: flagsmith.hasFeature(flag) },
                            values: { ...values, [flag]: flagsmith.getValue(flag) || null },
                        }
                    },
                    {
                        features: {},
                        values: {},
                    },
                )
                setState({
                    isLoading: false,
                    features,
                    values,
                })
            },
            onError: () => setState(prev => ({ ...prev, isLoading: false })),
        })
    }, [])

    useEffect(() => {
        if (state.isLoading || !account) {
            return
        }
        // NOTE: make sure flagsmith call /identify endpoint after flagsmith.init()
        flagsmith.identify(account)
        return () => {
            flagsmith.logout()
        }
    }, [account, state.isLoading])

    const isMakerUIEnable = useMemo(() => {
        return !!state.features[FeatureFlag.MAKER_UI]
    }, [state])

    const isReferralEnable = useMemo(() => {
        return !!state.features[FeatureFlag.REFERRAL]
    }, [state])

    const getMarketEnabledTickerSymbolList = useCallback(
        (chainId: number): string[] => {
            try {
                const isEnabled = !!state.features[FeatureFlag.MARKET_ENABLED]
                const marketEnabledTickerSymbolList =
                    JSON.parse(flagsmith.getValue(FeatureFlag.MARKET_ENABLED) as string)[chainId] || []
                return isEnabled ? marketEnabledTickerSymbolList : []
            } catch (e) {
                console.error("Flagsmith JSON parse Error")
                return []
            }
        },
        [state],
    )

    const getCollateralEnabledListByChainId = useCallback(
        (chainId: number): string[] => {
            try {
                const isEnabled = !!state.features[FeatureFlag.COLLATERAL_ENABLED]
                const collateralEnabledList =
                    JSON.parse(flagsmith.getValue(FeatureFlag.COLLATERAL_ENABLED) as string)[chainId] || []
                return isEnabled ? collateralEnabledList : []
            } catch (e) {
                console.error("Flagsmith JSON parse Error")
                return []
            }
        },
        [state],
    )

    const isTradingCompetitionEnable = useMemo(() => {
        return !!state.features[FeatureFlag.TRADING_COMPETITION]
    }, [state])

    const isEmbeddedBridgeEnable = useMemo(() => {
        return !!state.features[FeatureFlag.EMBEDDED_BRIDGE]
    }, [state])

    const isFundingRateChartEnable = useMemo(() => {
        return !!state.features[FeatureFlag.FUNDING_RATE_CHART]
    }, [state])

    const isLimitOrderEnable = useMemo(() => {
        return !!state.features[FeatureFlag.LIMIT_ORDER]
    }, [state])

    const isBiconomyWidgetEnable = useMemo(() => {
        return !!state.features[FeatureFlag.BICONOMY_WIDGET]
    }, [state])
    const isAllPositionsAndOrdersEnable = useMemo(() => {
        return !!state.features[FeatureFlag.ALL_POSITIONS_AND_ORDERS]
    }, [state])

    return {
        ...state,
        isMakerUIEnable,
        isReferralEnable,
        isTradingCompetitionEnable,
        isEmbeddedBridgeEnable,
        isFundingRateChartEnable,
        getMarketEnabledTickerSymbolList,
        getCollateralEnabledListByChainId,
        isLimitOrderEnable,
        isBiconomyWidgetEnable,
        isAllPositionsAndOrdersEnable,
    }
}
