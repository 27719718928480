import { Web3Provider } from "@ethersproject/providers"
import { useWeb3React } from "@web3-react/core"
import { Connector } from "@web3-react/types"
import { StorageDataMap } from "constant/storage"
import SegmentService from "module/analytics/service/SegmentService"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocalStorage } from "react-use"
import { logger } from "service/bugsnag/logger"
import { createContainer } from "unstated-next"
import { ConnectorManager } from "../ConnectorManager"
import { WalletType } from "../constant"
import { cleanWalletStorage, getConnectorByWalletType, getSupportedWallets } from "../utils"

const { CONNECTOR_ID } = StorageDataMap

export const Web3WalletContainer = createContainer(useWeb3Wallet)

function useWeb3Wallet() {
    // states
    const { connector, isActive, account, chainId: accountChainId } = useWeb3React<Web3Provider>()
    const [isLoading, setIsLoading] = useState(false)
    const [hasTriedAutoLogin, setHasTriedAutoLogin] = useState(false)
    const signer = useMemo(
        // NOTE: depend on `account` since signer won't be updated when user switch account.
        () => connector.provider && new Web3Provider(connector.provider).getSigner(),
        [connector, account], // eslint-disable-line react-hooks/exhaustive-deps
    )

    // storages
    const [connectorId, setConnectorId] = useLocalStorage<WalletType | "">(
        CONNECTOR_ID.name,
        CONNECTOR_ID.defaultValue as WalletType,
    )

    // actions
    const login = useCallback(
        async (connector: Connector, connectorId: WalletType, onActivate?: Function) => {
            try {
                setIsLoading(true)
                await connector.activate()
                onActivate && onActivate()
                setConnectorId(connectorId)
                logger.info("connect success")
            } catch (error) {
                setConnectorId("")
                logger.error(error as Error)
            } finally {
                setIsLoading(false)
            }
        },
        [setConnectorId],
    )

    const logout = useCallback(() => {
        try {
            connector.deactivate ? connector.deactivate() : connector.resetState()
        } finally {
            cleanWalletStorage()
            setConnectorId("")
        }
    }, [connector, setConnectorId])

    // effects: auto login
    useEffect(() => {
        if (!hasTriedAutoLogin) {
            setHasTriedAutoLogin(true)
            if (!connectorId) {
                return
            } else if (connectorId === WalletType.WALLET_CONNECT) {
                cleanWalletStorage()
                setConnectorId("")
            } else {
                const supportedWallets = getSupportedWallets(ConnectorManager.getInstance())
                const connector = getConnectorByWalletType(supportedWallets, connectorId)
                connector && login(connector, WalletType.METAMASK)
            }
        }
    }, [connectorId, hasTriedAutoLogin, login, setConnectorId])

    useEffect(() => {
        if (account) {
            SegmentService.identify(account)
            logger.setUser(account)
        }
    }, [account])

    return {
        isLoading,
        isActive,
        signer,
        account,
        accountChainId,
        connectorId,
        actions: { login, logout },
    }
}
