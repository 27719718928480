import { Button, Code, Heading, Image, Text, VStack } from "@chakra-ui/react"
import { WalletFill } from "component/Icon"
import { MotionBox } from "component/Motion"
import { RouteName } from "constant/route"
import { useModal } from "container/modal"
import { Referral } from "container/Referral"
import { DEFAULT_APP_CHAIN_ID, VALID_APP_CHAIN_IDS } from "module/network/constant"
import { switchNetwork } from "module/network/util/switchNetwork"
import { useNotification } from "module/notification/hook/useNotification"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"
import { useCallback, useEffect, useMemo, useState } from "react"
import Confetti from "react-confetti"
import useDimensions from "react-cool-dimensions"
import { Trans, useTranslation } from "react-i18next"
import { RiCouponFill } from "react-icons/ri"
import { useHistory } from "react-router-dom"
import { useSearchParam } from "react-use"
import { TxLoading } from "sdk-react/tools/useTxLoading"
import {
    trackActivateReferralCode,
    trackCloseModalReferralWithoutActivate,
    trackConnectWalletAndSwitchNetworkOnModalRefrral,
    trackFailToActivateReferralCode,
    trackLandSiteWithReferralCode,
    trackSwitchNetworkOnModalReferral,
} from "service/segment/tracks"
import { PerpModal } from "../PerpModal"
import { ModalType } from "./type"

export function ModalReferral() {
    const history = useHistory()
    const { account, accountChainId } = Web3WalletContainer.useContainer()
    const { registerReferralCode } = Referral.useContainer()
    const { notifySuccess, notifyError } = useNotification()
    const { open: openConnectWalletModal } = useModal(ModalType.WALLET_CONNECTION)
    const referralCodeFormatA = useSearchParam("referral") || ""
    const referralCodeFormatB = useSearchParam("code") || ""
    const referralCode = referralCodeFormatA || referralCodeFormatB
    const { isTxLoading, setTxLoading } = TxLoading.useContainer()
    const { t } = useTranslation()
    const connectWalletAndSwitchNetworkHandler = useCallback(() => {
        openConnectWalletModal()
        trackConnectWalletAndSwitchNetworkOnModalRefrral()
    }, [openConnectWalletModal])

    const [isOpen, setIsOpen] = useState(false)
    const handleClose = useCallback(() => {
        setIsOpen(false)
        trackCloseModalReferralWithoutActivate()
    }, [setIsOpen])

    const activateReferralCodeHandler = useCallback(async () => {
        setTxLoading(true)
        const result = await registerReferralCode(referralCode)
        setTxLoading(false)
        handleClose()
        if (!(result instanceof Error)) {
            notifySuccess({
                title: t("referral.transaction_confirmed"),
                description: t("referral.referral_activation_success"),
            })
            history.replace(RouteName.ROOT)
            trackActivateReferralCode(referralCode)
        } else {
            notifyError({
                title: t("referral.transaction_failed"),
                description: t("referral.referral_activation_failed"),
            })
            trackFailToActivateReferralCode(referralCode)
        }
    }, [t, setTxLoading, history, notifySuccess, notifyError, handleClose, registerReferralCode, referralCode])

    useEffect(() => {
        if (!!referralCode) {
            setIsOpen(true)
            trackLandSiteWithReferralCode()
        }
        !!referralCode && setIsOpen(true)
    }, [referralCode, setIsOpen])

    const { observe, width, height } = useDimensions()

    // show confetti only after 2 seconds
    const [showConfetti, setShowConfetti] = useState(false)
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setShowConfetti(true)
            }, 2000)

            setTimeout(() => {
                setShowConfetti(false)
            }, 5000)
        }
    }, [isOpen, setShowConfetti])

    const isOnSupportedChain = useMemo(() => {
        return accountChainId ? VALID_APP_CHAIN_IDS.includes(accountChainId) : false
    }, [accountChainId])
    const handleSwitchNetwork = useCallback(() => {
        switchNetwork(DEFAULT_APP_CHAIN_ID)
        trackSwitchNetworkOnModalReferral()
    }, [])

    return (
        <>
            <PerpModal
                closeOnOverlayClick={false}
                isOpen={isOpen}
                isCloseButtonHidden={false}
                onClose={handleClose}
                scrollBehavior="inside"
                header="Referral Code Detected"
                motionPreset="slideInBottom"
                body={
                    <VStack ref={observe} spacing={4}>
                        <Confetti
                            recycle={showConfetti}
                            gravity={0.3}
                            numberOfPieces={50}
                            width={width}
                            height={height}
                        />

                        <MotionBox
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0, transition: { delay: 1, duration: 0.4 } }}
                        >
                            <Image borderRadius="xl" src={"/assets/referral_cover.png"} />
                        </MotionBox>
                        <Heading size="lg"></Heading>
                        <VStack pb={1} spacing={6} align="flex-start">
                            <Text>
                                <Trans
                                    i18nKey="referral.referral_context"
                                    defaults="Referral code <Code>{{referralCode}}</Code> detected. After activation, you'll enjoy the 20% transaction fees rebate in PERP!"
                                    values={{ referralCode }}
                                    components={{ Code: <Code /> }}
                                />
                            </Text>
                            {!account ? (
                                <Button
                                    variant="solid"
                                    colorScheme="perpetual"
                                    leftIcon={<WalletFill boxSize="20px" />}
                                    onClick={connectWalletAndSwitchNetworkHandler}
                                >
                                    {t("wallet.connect_wallet")}
                                </Button>
                            ) : (
                                <>
                                    {isOnSupportedChain ? (
                                        <Button
                                            variant="solid"
                                            colorScheme="perpetual"
                                            leftIcon={<RiCouponFill size="20px" />}
                                            isLoading={isTxLoading}
                                            onClick={activateReferralCodeHandler}
                                        >
                                            {t("referral.activate_referral_code")}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="solid"
                                            colorScheme="yellow"
                                            leftIcon={<RiCouponFill size="20px" />}
                                            isLoading={isTxLoading}
                                            onClick={handleSwitchNetwork}
                                        >
                                            {t("general.switch_network_to_continue")}
                                        </Button>
                                    )}

                                    <Text fontStyle="sm" opacity={0.65} mb={3} fontSize={"sm"}>
                                        {t("referral.referral_note")}
                                    </Text>
                                </>
                            )}
                        </VStack>
                    </VStack>
                }
            />
        </>
    )
}
