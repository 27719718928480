import Airtable from "airtable"
import { AIRTABLE_API_KEY } from "constant/envVariables"

const AIRTABLE_BASE_ID = "app4q6AcYwEAy2x3h"

class AirTableService {
    private readonly _service
    constructor() {
        const apiKey = AIRTABLE_API_KEY
        if (!apiKey) {
            console.warn("[AirTable] missing apiKey for airtable service")
        }
        this._service = new Airtable({ apiKey }).base(AIRTABLE_BASE_ID)
    }

    getTable(name: string) {
        return this._service(name).select({
            view: "Grid view",
        })
    }
}

const service = new AirTableService()
export const CMSQueryNews = service.getTable("News")
export const CMSQueryInfoCards = service.getTable("Info Cards")
export const CMSQueryOverviewNews = service.getTable("News in Overview")
export const CMSQueryVaultsOnGeoBlock = service.getTable("Vaults on Geoblock")
export const CMSAppTopBanner = service.getTable("App Top Banner")
