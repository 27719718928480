import SegmentService, { EventGroup, EventTypeInteraction } from "module/analytics/service/SegmentService"
import { TrackingProps } from "module/analytics/types"

import { Button, ButtonProps } from "@chakra-ui/react"
import React, { useCallback } from "react"

export interface PerpButtonProps extends ButtonProps {
    trackingProps?: TrackingProps
}

export function PerpButton({ onClick, trackingProps, children, ...props }: PerpButtonProps) {
    const handleOnClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        e => {
            onClick && onClick(e)
            trackingProps &&
                SegmentService.track(EventGroup.INTERACTION, {
                    eventType: EventTypeInteraction.BUTTON_CLICKED,
                    ...trackingProps,
                })
        },
        [onClick, trackingProps],
    )

    return (
        // NOTE: https://github.com/chakra-ui/chakra-ui/issues/6004
        <Button onClick={handleOnClick} {...props}>
            <span>{children}</span>
        </Button>
    )
}
