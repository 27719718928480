import { SlippageControl } from "module/settings/component/SlippageControl"

import {
    Box,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { RiSettings3Line } from "react-icons/ri"

export function SlippagePopover() {
    const { t } = useTranslation()
    return (
        <Popover placement="top" arrowSize={12} colorScheme="blackAlpha">
            <PopoverTrigger>
                <Box>
                    <IconButton
                        isRound
                        variant="link"
                        fontSize={["20px", "18px"]}
                        icon={<RiSettings3Line />}
                        aria-label={""}
                    />
                </Box>
            </PopoverTrigger>
            <PopoverContent maxWidth={280} mx={4}>
                <PopoverArrow />
                <PopoverBody>
                    <VStack spacing={4} py={2}>
                        <SlippageControl hidden={false} />
                        <Text fontSize="sm" opacity={0.65}>
                            {t("settings.slippage_warning")}
                        </Text>
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}
