import { useQuery } from "@apollo/client"
import { PositionHistory, PositionSide } from "@perp/sdk-curie"
import Big from "big.js"
import { ethers } from "ethers"
import { useCallback, useMemo } from "react"
import { MarketsContainer } from "sdk-react/markets/MarketsContainer"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"
import {
    POLLING_INTERVAL,
    POSITION_CHANGEDS_BY_TRADER_DEFAULT_QUERY,
    POSITION_CLOSEDS_BY_TRADER_QUERY,
} from "../graphServer/graphQueries"
import { Web3Wallet } from "../PerpSDKProvider"
import { getMarketMapByBaseAddress, getMarketMapByRawBaseAddress } from "./utils/getMarketMapByAddress"

const PAGE_SIZE = 20
const FIRST_BLOCK_NUMBER_LOG_INDEX = ethers.constants.MaxUint256.toString() // NOTE: A large value that will theoretically always be larger than existing data.

interface GraphPositionChanged {
    id: string
    blockNumberLogIndex: string
    baseToken: string
    openNotional: string
    openPrice: string
    positionSize: string
    exchangedPositionNotional: string
    exchangedPositionSize: string
    swappedPrice: string
    realizedPnl: string
    timestamp: string
    fee: string
    fromFunctionSignature: string
}

interface GraphPositionChangeds {
    positionChangeds: GraphPositionChanged[]
}

interface GraphPositionClosed {
    id: string
    blockNumberLogIndex: string
    baseToken: string
    openNotional: string
    closedPositionNotional: string
    closedPositionSize: string
    closedPrice: string
    realizedPnl: string
    timestamp: string
}

interface GraphPositionCloseds {
    positionCloseds: GraphPositionClosed[]
}

type PositionHistoryInitialState = Web3Wallet

export const PositionHistoryContainer = createContainer(usePositionHistories)

function usePositionHistories(initialState?: PositionHistoryInitialState) {
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { account } = initialState || {}
    const { marketMap, poolMetadataMap } = MarketsContainer.useContainer() // NOTE: Don't use whitelisted markets since all histories should be shown.

    // NOTE: Polling the first page for real-time update.
    const {
        data: dataPositionChanged,
        loading: loadingPositionChanged,
        error: errorPositionChanged,
        fetchMore: fetchMorePositionChanged,
        refetch: refetchPositionChanged,
    } = useQuery<GraphPositionChangeds>(POSITION_CHANGEDS_BY_TRADER_DEFAULT_QUERY, {
        skip: !account,
        pollInterval: POLLING_INTERVAL,
        variables: {
            trader: account,
            first: PAGE_SIZE,
            blockNumberLogIndex_lt: FIRST_BLOCK_NUMBER_LOG_INDEX,
        },
    })

    if (errorPositionChanged) {
        onError(errorPositionChanged)
    }

    // NOTE: Cursor-based pagination fetching.
    const lastBlockNumberLogIndex =
        dataPositionChanged?.positionChangeds[dataPositionChanged.positionChangeds.length - 1]?.blockNumberLogIndex
    const fetchNextPagePositionChanged = useCallback(() => {
        if (!lastBlockNumberLogIndex) {
            return
        }
        fetchMorePositionChanged({ variables: { blockNumberLogIndex_lt: lastBlockNumberLogIndex } })
    }, [fetchMorePositionChanged, lastBlockNumberLogIndex])

    const positionChangedList = useMemo(() => {
        if (loadingPositionChanged || !dataPositionChanged || !marketMap || !poolMetadataMap) {
            return []
        }
        const histories = dataPositionChanged.positionChangeds || []
        const marketMapByBaseAddress = getMarketMapByBaseAddress({ marketMap })
        const marketMapByRawBaseAddress = getMarketMapByRawBaseAddress({ marketMap, poolMetadataMap })

        try {
            return histories
                .map(history => {
                    const market =
                        marketMapByBaseAddress[history.baseToken] || marketMapByRawBaseAddress[history.baseToken]
                    if (!market) {
                        // NOTE: ignore when market not found.
                        return null
                    }

                    const size = new Big(history.exchangedPositionSize)
                    const positionNotional = new Big(history.exchangedPositionNotional)
                    const txId = history.id.split("-")[0] // NOTE: <tx hash>-<tx log index>
                    return new PositionHistory({
                        txId,
                        tickerSymbol: market.tickerSymbol,
                        baseSymbol: market.baseSymbol,
                        side: size.gte(0) ? PositionSide.LONG : PositionSide.SHORT,
                        size: size.abs(),
                        positionNotional,
                        baseTokenAddress: history.baseToken,
                        price: new Big(history.swappedPrice),
                        realizedPnl: new Big(history.realizedPnl),
                        tradingFee: new Big(history.fee),
                        timestamp: Number(history.timestamp),
                        fromFunctionSignature: history.fromFunctionSignature,
                    })
                })
                .filter((item): item is PositionHistory => !!item)
        } catch (error: any) {
            onError(error)
            return []
        }
    }, [loadingPositionChanged, dataPositionChanged, marketMap, poolMetadataMap, onError])

    /* NOTE: Subscribe PositionClosed, this will only be emitted when stop market */
    const {
        data: dataPositionClosed,
        loading: loadingPositionClosed,
        error: errorPositionClosed,
        fetchMore: fetchMorePositionClosed,
        refetch: refetchPositionClosed,
    } = useQuery<GraphPositionCloseds>(POSITION_CLOSEDS_BY_TRADER_QUERY, {
        variables: { trader: account, first: PAGE_SIZE, skip: 0 },
        skip: !account,
    })
    if (errorPositionClosed) {
        onError(errorPositionClosed)
    }

    const positionClosedList = useMemo(() => {
        if (loadingPositionClosed || !dataPositionClosed || !marketMap) {
            return []
        }
        const histories = dataPositionClosed.positionCloseds || []

        try {
            return histories.map(history => {
                const market = Object.values(marketMap).find(market => market.baseAddress === history.baseToken)
                if (!market) {
                    throw new Error(`Market not found by positionHistory ${history}`)
                }

                const size = new Big(history.closedPositionSize)
                const positionNotional = new Big(history.closedPositionNotional)
                const txId = history.id.split("-")[0] // NOTE: <tx hash>-<tx log index>

                return new PositionHistory({
                    txId,
                    tickerSymbol: market.tickerSymbol,
                    baseSymbol: market.baseSymbol,
                    side: size.gte(0) ? PositionSide.LONG : PositionSide.SHORT,
                    size: size.abs(),
                    positionNotional,
                    baseTokenAddress: history.baseToken,
                    price: new Big(history.closedPrice),
                    realizedPnl: new Big(history.realizedPnl),
                    tradingFee: new Big(0),
                    timestamp: Number(history.timestamp),
                    isClosed: true,
                    // orderType: OrderType.MARKET, // TODO: add orderType
                })
            })
        } catch (error: any) {
            onError(error)
            return []
        }
    }, [loadingPositionClosed, dataPositionClosed, marketMap, onError])

    const fetchNextPagePositionClosed = useCallback(() => {
        const skip = dataPositionClosed?.positionCloseds.length ?? 0
        if (skip <= 0) {
            return
        }
        fetchMorePositionClosed({ variables: { skip } })
    }, [dataPositionClosed?.positionCloseds.length, fetchMorePositionClosed])

    const fetchNextPage = useCallback(() => {
        fetchNextPagePositionChanged()
        fetchNextPagePositionClosed()
    }, [fetchNextPagePositionChanged, fetchNextPagePositionClosed])

    const refetch = useCallback(() => {
        refetchPositionChanged()
        refetchPositionClosed()
    }, [refetchPositionChanged, refetchPositionClosed])

    const positionHistories = useMemo(() => {
        return [...positionChangedList, ...positionClosedList].sort((a, b) => b.timestamp - a.timestamp)
    }, [positionChangedList, positionClosedList])

    return {
        isLoading: loadingPositionChanged || loadingPositionClosed,
        positionChangedList,
        positionClosedList,
        positionHistories,
        fetchNextPage,
        refetch,
    }
}
