import { MarketList } from "module/market/component/MarketList"
import { PoolList } from "module/pool/component/PoolList"
import { LanguageSwitcher } from "module/settings/component/LanguageSwitcher"
import { useVaultStrings } from "module/vault/hook/useVaultStrings"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"
import { getMarketDetailUrl, getPoolDetailUrl } from "util/route"

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Button,
    ButtonGroup,
    Center,
    Flex,
    HStack,
    Icon,
    LinkBox,
    LinkBoxProps,
    LinkOverlay,
    Spacer,
    Text,
    Tooltip,
    useColorModeValue,
} from "@chakra-ui/react"
import { FooterSection } from "component/FooterSection"
import {
    TabGlance,
    TabHistory,
    TabMarketToggle,
    TabOrders,
    TabPositions,
    TabRewardsStar,
    TabWelcome,
} from "component/Icon"
import { Jazzicon } from "component/JazzIdentity/JazzIdentity"
import { MotionBox, MotionFlex, MotionHStack, MotionVStack } from "component/Motion"
import { RouteName } from "constant/route"
import { AppConfig } from "container/appConfig"
import { FeatureToggleContainer } from "container/service/FeatureToggleContainer"
import { useMonitorProviderGraphServerConfig } from "hook/useMonitorProviderGraphServerConfig"
import { useReward } from "hook/useReward"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { RiBankFill, RiContrastDrop2Fill, RiCoupon2Fill, RiMagicFill } from "react-icons/ri"
import { Link as RouterLink, useHistory, useRouteMatch } from "react-router-dom"
import { useLocation } from "react-use"
import { scrollbarStyle } from "theme/scrollbarStyle"

import { TopBar } from "./TopBar"

export function DesktopSidebar() {
    const { account } = Web3WalletContainer.useContainer()
    const sidebarBg = useColorModeValue("sidebar.light", "sidebar.dark")
    const subBackground = useColorModeValue("subBackground.light", "subBackground.dark")
    const separatorBg = useColorModeValue("separator.light", "separator.dark")

    const { accountValueStr } = useVaultStrings()

    // NOTE: toggle sidebar mode will change routes to "markets" or "pools"
    const matchPoolDetailPage = useRouteMatch<{ base: string; quote: string }>(RouteName.POOL_DETAIL)
    const matchMarketDetail = useRouteMatch<{ base: string; quote: string }>(RouteName.MARKET_DETAIL)
    const [sidebarMode, setSidebarMode] = useState(!!matchPoolDetailPage ? "pools" : "markets")
    const history = useHistory()
    const { base, quote } = matchMarketDetail?.params || matchPoolDetailPage?.params || {}

    const jazzOverview = false

    const onTabClicked = (target: "markets" | "pools") => {
        setSidebarMode(target)
        if (!base || !quote) {
            return
        }
        if (target === "pools") {
            history.push(getPoolDetailUrl(base, quote))
        } else {
            history.push(getMarketDetailUrl(base, quote))
        }
    }
    const { t } = useTranslation()

    const { totalReward } = useReward()

    const { appConfig } = AppConfig.useContainer()
    const { rewardsUrl } = appConfig?.externalLinks || {}

    const currentPath = useLocation().pathname || ""
    const { isAllPositionsAndOrdersEnable } = FeatureToggleContainer.useContainer()

    // get useMonitorProviderGraphServerConfig hook
    const { isAppConfigUpdated } = useMonitorProviderGraphServerConfig()

    return (
        <MotionVStack
            userSelect="none"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            flexShrink={0}
            width={["280px"]}
            spacing="0"
            bg={[subBackground, sidebarBg]}
            h={["auto", "100vh"]} // NOTE: show as sidebar in desktop
            position={["relative", "fixed"]}
            left={[null, 0]}
            top={[null, 0]}
            bottom={[null, 0]}
            overscrollBehavior="contain"
            // ios safe area top padding
            paddingTop={{ base: 0, md: "calc(env(safe-area-inset-top) / 1)" }}
        >
            <TopBar />
            <MotionBox position={"relative"} display={["none", "block"]} width="100%" h="100%" overflowY="hidden">
                <MotionFlex h="100%" w="100%" align="center" direction="column" alignItems="stretch">
                    {/* TODO: All sidebar item needs "isActive" state */}

                    {/* NOTE: SECTION TOP */}
                    <MotionVStack w="100%" spacing={1} pb={2}>
                        {account ? (
                            <>
                                <SidebarListItem isActive={currentPath === RouteName.ROOT}>
                                    {jazzOverview ? (
                                        <Box borderRadius="full" overflow="hidden" p={1}>
                                            {account && <Jazzicon address={account} diameter={20} />}
                                        </Box>
                                    ) : (
                                        <TabGlance
                                            opacity={currentPath === RouteName.ROOT ? 1 : 0.5}
                                            boxSize={"28px"}
                                        />
                                    )}

                                    <LinkOverlay
                                        fontSize={"sm"}
                                        flexGrow={1}
                                        fontWeight={"semibold"}
                                        as={RouterLink}
                                        to="/"
                                    >
                                        <MotionHStack w="100%" spacing={1}>
                                            <Text lineHeight={1}>Overview</Text>
                                            <Spacer />
                                            {/* NOTE: @leo: Account balance value. Hidden for now  */}
                                            <MotionHStack display="none" layout>
                                                <Text
                                                    mr={2}
                                                    fontWeight={"normal"}
                                                    fontSize={["sm", "xs"]}
                                                    opacity={0.7}
                                                >
                                                    {accountValueStr !== "-,---" ? accountValueStr : "-"}
                                                </Text>
                                            </MotionHStack>
                                        </MotionHStack>
                                    </LinkOverlay>
                                </SidebarListItem>
                                {isAllPositionsAndOrdersEnable && (
                                    <>
                                        <SidebarListItem isActive={currentPath === RouteName.POSITIONS}>
                                            <TabPositions
                                                opacity={currentPath === RouteName.POSITIONS ? 1 : 0.5}
                                                boxSize={"28px"}
                                            />
                                            <LinkOverlay
                                                fontSize={"sm"}
                                                fontWeight={"semibold"}
                                                as={RouterLink}
                                                to={RouteName.POSITIONS}
                                            >
                                                Positions
                                            </LinkOverlay>
                                            <Badge>WIP</Badge>
                                        </SidebarListItem>
                                        <SidebarListItem isActive={currentPath === RouteName.ORDERS}>
                                            <TabOrders
                                                opacity={currentPath === RouteName.ORDERS ? 1 : 0.5}
                                                boxSize={"28px"}
                                            />
                                            <LinkOverlay
                                                fontSize={"sm"}
                                                fontWeight={"semibold"}
                                                as={RouterLink}
                                                to={RouteName.ORDERS}
                                            >
                                                Orders
                                            </LinkOverlay>
                                            <Badge>WIP</Badge>
                                        </SidebarListItem>
                                    </>
                                )}
                            </>
                        ) : (
                            <SidebarListItem isActive={currentPath === RouteName.ROOT}>
                                <TabWelcome opacity={currentPath === RouteName.ROOT ? 1 : 0.5} boxSize={"28px"} />
                                <LinkOverlay fontSize={"sm"} fontWeight={"semibold"} as={RouterLink} to="/">
                                    Welcome
                                </LinkOverlay>
                            </SidebarListItem>
                        )}
                    </MotionVStack>

                    {/* NOTE: SECTION MIDDLE */}
                    <Flex
                        borderY={"1px solid"}
                        borderColor={separatorBg}
                        w="100%"
                        display={["none", "block"]}
                        overflowY={"auto"}
                        overflowX={"hidden"}
                        sx={scrollbarStyle}
                        flexGrow={1}
                        // bg="red"
                        pr={{ base: 0, md: "10px" }}
                    >
                        <ButtonGroup
                            position="sticky"
                            zIndex={2}
                            top="0"
                            spacing={0}
                            w="100%"
                            pl={5}
                            pb={2}
                            pt={4}
                            pr={"8px"}
                            // w="calc(100% - 4px)"
                            bgGradient="linear(rgba(29,29,29,1) 0%, rgba(29,29,29,0.8) 80%, rgba(29,29,29,0) 100%)"
                            size="sm"
                        >
                            <Button
                                leftIcon={<TabMarketToggle boxSize="18px" />}
                                h="29px"
                                variant={"ghost"}
                                color={sidebarMode === "markets" ? "rgba(67,70,74,1)" : "gray.500"}
                                bg={sidebarMode === "markets" ? "rgb(221, 226, 234)" : "transparent"}
                                _hover={{ bg: sidebarMode === "markets" ? "rgb(221, 226, 234)" : "transparent" }}
                                width="full"
                                onClick={() => onTabClicked("markets")}
                            >
                                {t("general.trade")}
                            </Button>
                            <Button
                                leftIcon={<Icon boxSize="16px" as={RiContrastDrop2Fill} />}
                                h="29px"
                                variant={"ghost"}
                                color={sidebarMode === "pools" ? "rgba(67,70,74,1)" : "gray.500"}
                                bg={sidebarMode === "pools" ? "rgb(221, 226, 234)" : "transparent"}
                                _hover={{ bg: sidebarMode === "pools" ? "rgb(221, 226, 234)" : "transparent" }}
                                width="full"
                                onClick={() => onTabClicked("pools")}
                            >
                                {t("pool.pools")}
                            </Button>
                        </ButtonGroup>
                        <Box zIndex={1} aria-label="anchor" position="relative" w="100%" h="100%" flexGrow={1}>
                            <Box display={sidebarMode === "markets" ? "block" : "none"} w="100%" h="100%" flexGrow={1}>
                                <MarketList />
                            </Box>
                            <Box display={sidebarMode === "pools" ? "block" : "none"} w="100%" h="100%" flexGrow={1}>
                                <PoolList />
                            </Box>
                        </Box>
                    </Flex>

                    {/* NOTE: SECTION BOTTOM */}
                    <MotionVStack layout w="100%" spacing={0} pb={0} pt={2}>
                        <SidebarListItem isActive={currentPath.startsWith(RouteName.HISTORY_ROOT)}>
                            <TabHistory
                                opacity={currentPath.startsWith(RouteName.HISTORY_ROOT) ? 1 : 0.5}
                                boxSize={"28px"}
                            />
                            <LinkOverlay
                                _active={{ background: "transparent" }}
                                fontSize={"sm"}
                                fontWeight={"semibold"}
                                as={RouterLink}
                                to="/history/trade"
                            >
                                {t("history.history")}
                            </LinkOverlay>
                        </SidebarListItem>
                        <SidebarListItem isActive={currentPath.startsWith(RouteName.HISTORY_ROOT)}>
                            <Icon as={RiBankFill} opacity={0.5} boxSize={"28px"} padding="3px" />
                            <LinkOverlay
                                fontSize={"sm"}
                                fontWeight={"semibold"}
                                as={"a"}
                                target="_blank"
                                href="https://vaults.perp.com"
                            >
                                Vaults (Hot Tub) ↗
                            </LinkOverlay>
                        </SidebarListItem>
                        {/* NOTE: Sidebar expansion section. */}
                        <Accordion borderColor="transparent" allowToggle w="100%">
                            <AccordionItem>
                                <AccordionPanel px={0} pt={0} pb={0}>
                                    {/* insert sidebar list item below to expand */}

                                    <SidebarListItem isActive={false}>
                                        <TabRewardsStar opacity={0.5} boxSize={"28px"} />
                                        <LinkOverlay
                                            width="100%"
                                            isExternal={true}
                                            href={rewardsUrl}
                                            _active={{ background: "transparent" }}
                                            fontSize={"sm"}
                                            fontWeight={"semibold"}
                                        >
                                            <HStack width="100%">
                                                <Text>{t("general.rewards")} ↗</Text>
                                                <Spacer />
                                                <HStack spacing={1}>
                                                    <Text lineHeight={1} fontSize={"xs"} color="gray.300">
                                                        {totalReward}
                                                    </Text>
                                                </HStack>
                                            </HStack>
                                        </LinkOverlay>
                                    </SidebarListItem>
                                    <SidebarListItem isActive={false}>
                                        <Icon
                                            as={RiCoupon2Fill}
                                            opacity={0.5}
                                            // color="#D1C589"

                                            boxSize={"28px"}
                                            padding="3px"
                                        />
                                        <LinkOverlay
                                            fontSize={"sm"}
                                            fontWeight={"semibold"}
                                            as={"a"}
                                            target="_blank"
                                            href="https://token.perp.com/referral"
                                        >
                                            {t("general.referrals")} ↗
                                        </LinkOverlay>
                                    </SidebarListItem>

                                    {/* insert sidebar list item above to expand */}
                                    <Box>
                                        <LanguageSwitcher />
                                    </Box>
                                    <Center width="100%">
                                        <FooterSection w="100%" px={8} py={4} pb={0} />
                                    </Center>
                                </AccordionPanel>
                                <AccordionButton mb={1} p={0}>
                                    <SidebarListItem isActive={false}>
                                        <Center boxSize="28px">
                                            <AccordionIcon opacity={0.5} sx={{ transform: "scaleY(-1)" }} />
                                        </Center>

                                        <LinkOverlay
                                            _active={{ background: "transparent" }}
                                            fontSize={"sm"}
                                            fontWeight={"semibold"}
                                        >
                                            More
                                        </LinkOverlay>
                                    </SidebarListItem>
                                </AccordionButton>
                            </AccordionItem>
                        </Accordion>
                        {/* NOTE: Sidebar expansion section. Do not delete */}
                        {isAppConfigUpdated && (
                            <MotionBox pb={5}>
                                <Tooltip hasArrow label="Click to update RPC settings">
                                    <Button
                                        leftIcon={<Icon as={RiMagicFill} />}
                                        size={"sm"}
                                        colorScheme={"perpetual"}
                                        width="100%"
                                        onClick={() => window.location.reload()}
                                    >
                                        Update Available
                                    </Button>
                                </Tooltip>
                            </MotionBox>
                        )}
                    </MotionVStack>
                </MotionFlex>
            </MotionBox>
        </MotionVStack>
    )
}

interface Props extends LinkBoxProps {
    isActive?: boolean
    isDisabled?: boolean
    children: React.ReactNode
}

export function SidebarListItem({ isActive, isDisabled, children, ...props }: Props) {
    const highlightBg = useColorModeValue("blackAlpha.100", "whiteAlpha.100")
    return (
        <LinkBox _active={{ background: "transparent" }} w={"100%"} px={4} opacity={isDisabled ? 0.5 : 1} {...props}>
            <MotionHStack
                minH="44px"
                spacing={3}
                w={"100%"}
                borderRadius="xl"
                px={[1, 3]}
                py={[3, 1]}
                justify="align"
                {...(isActive && {
                    bg: highlightBg,
                })}
            >
                {children}
            </MotionHStack>
        </LinkBox>
    )
}
