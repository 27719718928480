import { DateTime } from "luxon"

/*
 *  1616445764 (unix timestamp secs) => return "2021/3/23"
 */
export function getDate(ts: number) {
    const date = new Date(ts * 1000)
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
}

export function formatTimeStamp(timestamp: number, format: string = "yyyy/M/d T"): string {
    return DateTime.fromSeconds(timestamp).toFormat(format)
}
