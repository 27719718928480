import { PositionSide } from "@perp/sdk-curie"
import Big from "big.js"

import { OrderType } from "../limitOrder/types"

type LimitOrderStatus = "filled" | "cancelled"

interface ILimitOrderHistory {
    id: string
    txId: string
    tickerSymbol: string
    baseSymbol: string
    baseTokenAddress: string
    side: PositionSide
    size: Big // NOTE: base asset amount
    orderType: OrderType
    limitPrice?: Big
    filledPrice?: Big
    timestamp: number
    status: LimitOrderStatus
    blockNumberLogIndex: number // for sort only
}

export class LimitOrderHistory implements ILimitOrderHistory {
    readonly id: string
    readonly txId: string
    readonly tickerSymbol: string
    readonly baseSymbol: string
    readonly baseTokenAddress: string
    readonly side: PositionSide
    readonly size: Big
    readonly orderType: OrderType
    readonly limitPrice?: Big
    readonly filledPrice?: Big
    readonly timestamp: number
    readonly status: LimitOrderStatus
    readonly blockNumberLogIndex: number

    constructor({
        id,
        txId,
        tickerSymbol,
        baseSymbol,
        baseTokenAddress,
        side,
        size,
        orderType,
        filledPrice,
        limitPrice,
        timestamp,
        status,
        blockNumberLogIndex,
    }: ILimitOrderHistory) {
        this.id = id
        this.txId = txId
        this.tickerSymbol = tickerSymbol
        this.baseSymbol = baseSymbol
        this.baseTokenAddress = baseTokenAddress
        this.side = side
        this.size = size
        this.orderType = orderType
        this.filledPrice = filledPrice
        this.limitPrice = limitPrice
        this.timestamp = timestamp
        this.status = status
        this.blockNumberLogIndex = blockNumberLogIndex
    }
}
