import { isSupportedChainId } from "@perp/sdk-curie"
import { APP_SUPPORTED_CHAIN_IDS } from "constant/envVariables"

export enum ChainId {
    ETHEREUM = 1,
    RINKEBY = 4,
    ARBITRUM_ONE = 42161,
    ARBITRUM_TESTNET_RINKEBY = 421611,
    OPTIMISTIC_ETHEREUM = 10,
    OPTIMISTIC_ETHEREUM_TESTNET_GOERLI = 420,
}

enum ChainName {
    ETHEREUM = "Ethereum",
    RINKEBY = "Rinkeby",
    ARBITRUM_ONE = "Arbitrum One",
    ARBITRUM_TESTNET_RINKEBY = "Arbitrum Testnet Rinkeby",
    OPTIMISTIC_ETHEREUM = "Optimistic Ethereum",
    OPTIMISTIC_ETHEREUM_TESTNET_GOERLI = "Optimistic Ethereum Testnet Goerli",
}

type UrlBuilder = (link: string, data: string, type: "transaction" | "token" | "address" | "block") => string

interface Chains {
    [chainId: number]: {
        id: number
        name: ChainName
        alias: string
        link: string
        urlBuilder: (chainName: string, data: string, type: "transaction" | "token" | "address" | "block") => string
        referralContractAddress?: string
    }
}

const explorers: Record<string, UrlBuilder> = {
    etherscan: (link: string, data: string, type: "transaction" | "token" | "address" | "block") => {
        switch (type) {
            case "transaction":
                return `${link}/tx/${data}`
            default:
                return `${link}/${type}/${data}`
        }
    },
    blockscout: (link: string, data: string, type: "transaction" | "token" | "address" | "block") => {
        switch (type) {
            case "transaction":
                return `${link}/tx/${data}`
            case "token":
                return `${link}/tokens/${data}`
            default:
                return `${link}/${type}/${data}`
        }
    },
}

export const CHAINS: Chains = {
    [ChainId.OPTIMISTIC_ETHEREUM]: {
        id: ChainId.OPTIMISTIC_ETHEREUM,
        name: ChainName.OPTIMISTIC_ETHEREUM,
        alias: "Optimism",
        link: "https://optimistic.etherscan.io",
        urlBuilder: explorers.etherscan,
        referralContractAddress: "0xbfBa3368d94b8b006A4fd59C13b9e9F9b071D106",
    },
    [ChainId.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI]: {
        id: ChainId.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI,
        name: ChainName.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI,
        alias: "Optimism Goerli",
        link: "https://goerli.optimism.io/",
        urlBuilder: explorers.etherscan,
        // TODO: import referral contract from metadata
        referralContractAddress: "0xDb6c7003C120D810D5e25ea09e2659F258687039",
    },
}

export const VALID_APP_CHAIN_IDS = APP_SUPPORTED_CHAIN_IDS.filter(isSupportedChainId)
export const DEFAULT_APP_CHAIN_ID = VALID_APP_CHAIN_IDS[0]
export const DEFAULT_APP_CHAIN = CHAINS[DEFAULT_APP_CHAIN_ID]
