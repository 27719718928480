import { Center, Portal, Spinner } from "@chakra-ui/react"

export function PageLoading() {
    return (
        <Portal>
            <Center
                overflow="hidden"
                zIndex={2}
                position="fixed"
                width="100vw"
                height="100vh"
                left="0"
                top="0"
                bottom="0"
                right="0"
            >
                <Spinner color="perpetual.500" boxSize="42px" />
            </Center>
        </Portal>
    )
}
