import { useQuery } from "@apollo/client"
import { PositionSide } from "@perp/sdk-curie"
import Big from "big.js"
import { StorageDataMap } from "constant/storage"
import { ethers } from "ethers"
import { useCallback, useEffect, useMemo } from "react"
import { useLocalStorage } from "react-use"
import { MarketsContainer } from "sdk-react/markets/MarketsContainer"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"
import { LIMIT_ORDER_CANCELLEDS_QUERY, LIMIT_ORDER_FILLEDS_QUERY, POLLING_INTERVAL } from "../graphServer/graphQueries"
import { OrderTypeMap } from "../limitOrder/types"
import { Web3Wallet } from "../PerpSDKProvider"
import { LimitOrderHistory } from "./LimitOrderHistory"
import { useLimitOrderFilledNotification } from "./useLimitOrderFilledNotification"
import { getMarketMapByBaseAddress, getMarketMapByRawBaseAddress } from "./utils/getMarketMapByAddress"

const { LATEST_FILLED_LIMIT_ORDER } = StorageDataMap

const PAGE_SIZE = 20
const FIRST_BLOCK_NUMBER_LOG_INDEX = ethers.constants.MaxUint256.toString() // NOTE: A large value that will theoretically always be larger than existing data.

type LimitOrderHistoryInitialState = Web3Wallet

export const LimitOrderHistoryContainer = createContainer(useLimitOrderHistory)

function useLimitOrderHistory(initialState?: LimitOrderHistoryInitialState) {
    const {
        isLoading: isLoadingFilledHistory,
        limitOrderFilledHistories,
        fetchNextPage: fetchNextPageFilled,
        refetch: refetchFilled,
    } = useLimitOrderFilledHistory(initialState)
    const {
        isLoading: isLoadingCancelledHistory,
        limitOrderCancelledHistories,
        fetchNextPage: fetchNextPageCancelled,
        refetch: refetchCancelled,
    } = useLimitOrderCancelledHistory(initialState)

    const isLoading = isLoadingCancelledHistory || isLoadingFilledHistory

    const limitOrderHistories = useMemo(() => {
        if (isLoading || !limitOrderCancelledHistories || !limitOrderFilledHistories) {
            return []
        }
        return limitOrderFilledHistories
            .concat(limitOrderCancelledHistories)
            .sort((a, b) => b.blockNumberLogIndex - a.blockNumberLogIndex)
    }, [limitOrderFilledHistories, limitOrderCancelledHistories, isLoading])

    // NOTE: notify when new orders filled
    const { notifyOrderFilled } = useLimitOrderFilledNotification()
    const [savedLatestFilledOrder, saveLatestFilledOrder] = useLocalStorage(
        LATEST_FILLED_LIMIT_ORDER.name,
        LATEST_FILLED_LIMIT_ORDER.defaultValue,
    )
    useEffect(() => {
        const { timestamp: savedLatestFilledOrderTimestamp }: { id?: string; timestamp?: string } = JSON.parse(
            savedLatestFilledOrder || "{}",
        )
        const newlyFilledOrders = savedLatestFilledOrderTimestamp
            ? limitOrderFilledHistories.filter(order => order.timestamp > Number(savedLatestFilledOrderTimestamp))
            : limitOrderFilledHistories

        if (newlyFilledOrders.length > 0) {
            newlyFilledOrders.forEach(orderHistory => {
                notifyOrderFilled(orderHistory)
            })

            saveLatestFilledOrder(
                JSON.stringify({ id: newlyFilledOrders[0].id, timestamp: newlyFilledOrders[0].timestamp }),
            )
        }
    }, [limitOrderFilledHistories, notifyOrderFilled, saveLatestFilledOrder, savedLatestFilledOrder])

    const fetchNextPage = useCallback(() => {
        fetchNextPageFilled()
        fetchNextPageCancelled()
    }, [fetchNextPageFilled, fetchNextPageCancelled])

    const refetch = useCallback(() => {
        refetchFilled()
        refetchCancelled()
    }, [refetchCancelled, refetchFilled])

    return {
        isLoading,
        limitOrderHistories,
        fetchNextPage,
        refetch,
    }
}

interface GraphLimitOrderFilled {
    id: string
    blockNumberLogIndex: string
    txHash: string
    trader: string
    baseToken: string
    blockNumber: string
    orderType: string // NOTE: 0 = LimitOrder, 1 = StopLossLimitOrder, 2 = TakeProfitLimitOrder
    filledPrice: string
    exchangedPositionSize: string
    exchangedPositionNotional: string
    timestamp: string
}
interface GraphLimitOrderFilleds {
    limitOrderFilleds: GraphLimitOrderFilled[]
}
function useLimitOrderFilledHistory(initialState?: LimitOrderHistoryInitialState) {
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { account } = initialState || {}
    const { marketMap, poolMetadataMap } = MarketsContainer.useContainer() // NOTE: Don't use whitelisted markets since all histories should be shown.

    // NOTE: Polling the first page for real-time update.
    const { data, loading, error, fetchMore, refetch } = useQuery<GraphLimitOrderFilleds>(LIMIT_ORDER_FILLEDS_QUERY, {
        skip: !account,
        pollInterval: POLLING_INTERVAL,
        variables: {
            trader: account,
            first: PAGE_SIZE,
            blockNumberLogIndex_lt: FIRST_BLOCK_NUMBER_LOG_INDEX,
        },
    })

    if (error) {
        onError(error)
    }

    // NOTE: Cursor-based pagination fetching.
    const lastBlockNumberLogIndex = data?.limitOrderFilleds[data.limitOrderFilleds.length - 1]?.blockNumberLogIndex
    const fetchNextPage = useCallback(() => {
        if (!lastBlockNumberLogIndex) {
            return
        }
        fetchMore({ variables: { blockNumberLogIndex_lt: lastBlockNumberLogIndex } })
    }, [fetchMore, lastBlockNumberLogIndex])

    const limitOrderFilledHistories = useMemo(() => {
        if (loading || !data || !marketMap || !poolMetadataMap) {
            return []
        }

        const histories = data.limitOrderFilleds || []
        const marketMapByBaseAddress = getMarketMapByBaseAddress({ marketMap })
        const marketMapByRawBaseAddress = getMarketMapByRawBaseAddress({ marketMap, poolMetadataMap })

        try {
            return histories
                .map(history => {
                    const market =
                        marketMapByBaseAddress[history.baseToken] || marketMapByRawBaseAddress[history.baseToken]
                    if (!market) {
                        // NOTE: ignore when market not found.
                        return null
                    }

                    const positionSize = new Big(history.exchangedPositionSize)
                    return new LimitOrderHistory({
                        id: history.id,
                        txId: history.txHash,
                        tickerSymbol: market.tickerSymbol,
                        baseSymbol: market.baseSymbol,
                        baseTokenAddress: history.baseToken,
                        side: positionSize.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
                        size: positionSize, // NOTE: base asset amount
                        orderType: OrderTypeMap[history.orderType],
                        filledPrice: new Big(history.filledPrice),
                        status: "filled",
                        timestamp: Number(history.timestamp),
                        blockNumberLogIndex: Number(history.blockNumberLogIndex),
                    })
                })
                .filter((item): item is LimitOrderHistory => !!item)
        } catch (e: any) {
            onError(e)
            return []
        }
    }, [loading, data, marketMap, poolMetadataMap, onError])

    return {
        isLoading: loading,
        limitOrderFilledHistories,
        fetchNextPage,
        refetch,
    }
}

interface GraphLimitOrderCancelled {
    id: string
    blockNumberLogIndex: string
    txHash: string
    trader: string
    baseToken: string
    blockNumber: string
    orderType: string // NOTE: 0 = LimitOrder, 1 = StopLossLimitOrder, 2 = TakeProfitLimitOrder
    limitPrice: string
    positionSize: string
    timestamp: string
}
interface GraphLimitOrderCancelleds {
    limitOrderCancelleds: GraphLimitOrderCancelled[]
}
function useLimitOrderCancelledHistory(initialState?: LimitOrderHistoryInitialState) {
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { account } = initialState || {}
    const { marketMap, poolMetadataMap } = MarketsContainer.useContainer() // NOTE: Don't use whitelisted markets since all histories should be shown.

    // NOTE: Polling the first page for real-time update.
    const { data, loading, error, fetchMore, refetch } = useQuery<GraphLimitOrderCancelleds>(
        LIMIT_ORDER_CANCELLEDS_QUERY,
        {
            skip: !account,
            pollInterval: POLLING_INTERVAL,
            variables: {
                trader: account,
                first: PAGE_SIZE,
                blockNumberLogIndex_lt: FIRST_BLOCK_NUMBER_LOG_INDEX,
            },
        },
    )

    if (error) {
        onError(error)
    }

    // NOTE: Cursor-based pagination fetching.
    const lastBlockNumberLogIndex =
        data?.limitOrderCancelleds[data.limitOrderCancelleds.length - 1]?.blockNumberLogIndex
    const fetchNextPage = useCallback(() => {
        if (!lastBlockNumberLogIndex) {
            return
        }
        fetchMore({ variables: { blockNumberLogIndex_lt: lastBlockNumberLogIndex } })
    }, [fetchMore, lastBlockNumberLogIndex])

    const limitOrderCancelledHistories = useMemo(() => {
        if (loading || !data || !marketMap || !poolMetadataMap) {
            return []
        }

        const histories = data.limitOrderCancelleds || []
        const marketMapByBaseAddress = getMarketMapByBaseAddress({ marketMap })
        const marketMapByRawBaseAddress = getMarketMapByRawBaseAddress({ marketMap, poolMetadataMap })

        try {
            return histories
                .map(history => {
                    const market =
                        marketMapByBaseAddress[history.baseToken] || marketMapByRawBaseAddress[history.baseToken]
                    if (!market) {
                        // NOTE: ignore when market not found.
                        return null
                    }

                    const positionSize = new Big(history?.positionSize || 0)
                    return new LimitOrderHistory({
                        id: history.id,
                        txId: history.txHash,
                        tickerSymbol: market.tickerSymbol,
                        baseSymbol: market.baseSymbol,
                        baseTokenAddress: history.baseToken,
                        side: positionSize ? PositionSide.LONG : PositionSide.SHORT,
                        size: positionSize, // NOTE: base asset amount
                        orderType: OrderTypeMap[history.orderType],
                        limitPrice: new Big(history?.limitPrice),
                        timestamp: Number(history.timestamp),
                        status: "cancelled",
                        blockNumberLogIndex: Number(history.blockNumberLogIndex),
                    })
                })
                .filter((item): item is LimitOrderHistory => !!item)
        } catch (e: any) {
            onError(e)
            return []
        }
    }, [loading, data, marketMap, poolMetadataMap, onError])

    return {
        isLoading: loading,
        limitOrderCancelledHistories,
        fetchNextPage,
        refetch,
    }
}
