import { AppConfig } from "container/appConfig"
import { useAsync } from "react-use"
import { CandleService } from "service/appSync/candleService"
import { createContainer } from "unstated-next"

export const CandleServiceContainer = createContainer(useCandleService)

function useCandleService() {
    const { appConfig } = AppConfig.useContainer()
    const { key, url, region } = appConfig?.candleServerConfig || {}

    const {
        value: candleService,
        loading,
        error,
    } = useAsync(async () => {
        if (!key || !url || !region) {
            return
        }
        const service = new CandleService({ key, url, region })
        await service.hydrated()
        return service
    }, [key, url, region])

    return {
        candleService,
        loading,
        error,
    }
}
