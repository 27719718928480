import { TransactionReceipt } from "@ethersproject/providers"
import Big from "big.js"
import { useMemo } from "react"
import { Vault } from "sdk-react/vault/useVault"

import { useCollateralStrings } from "./useCollateralStrings"

export interface IWithdrawOption {
    collateralSymbol: string
    freeCollateral?: Big
    freeCollateralStr: string
    withdraw: (amount: Big) => Promise<TransactionReceipt | undefined>
    withdrawAll: () => Promise<TransactionReceipt | undefined>
}

export function useWithdrawOptionList() {
    const { withdraw, withdrawETH, withdrawAll, withdrawAllETH } = Vault.useContainer()
    const { collateralStrList } = useCollateralStrings()

    const withdrawOptionList: IWithdrawOption[] = useMemo(() => {
        const options: IWithdrawOption[] = []

        collateralStrList?.forEach(({ token, symbol, freeCollateral, freeCollateralStr }, i) => {
            options.push({
                collateralSymbol: symbol,
                freeCollateral,
                freeCollateralStr,
                withdraw: amount => withdraw({ token, amount }),
                withdrawAll: () => withdrawAll({ token }),
            })

            // NOTE: insert ETH after WETH
            if (symbol === "WETH") {
                options.push({
                    collateralSymbol: "ETH",
                    freeCollateral,
                    freeCollateralStr,
                    withdraw: (amount: Big) => withdrawETH({ amount }),
                    withdrawAll: withdrawAllETH,
                })
            }
        })
        return options
    }, [collateralStrList, withdrawAllETH, withdrawAll, withdrawETH, withdraw])

    return {
        withdrawOptionList,
    }
}
