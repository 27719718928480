import "@fontsource/space-mono";
import "focus-visible/dist/focus-visible"; // NOTE: remove chakra's focus outline

import "./i18n";
import "./util/setupBig";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Fonts } from "component/Font";
import { AppConfig } from "container/appConfig";
import { GeoBlocking } from "container/geoBlocking";
import { Modals } from "container/modal";
import { CustomizedPerpSDKProvider } from "container/perpSDK";
import { Referral } from "container/Referral";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import Bugsnag from "service/bugsnag";

import { PageLoading } from "component/PageLoading";
import { CandleServiceContainer } from "container/service/CandleServiceContainer";
import { FeatureToggleContainer } from "container/service/FeatureToggleContainer";
import { FundingRateServiceContainer } from "container/service/FundingRateServiceContainer";
import { LimitOrderServiceContainer } from "container/service/LimitOrderServiceContainer/LimitOrderServiceContainer";
import { ActiveLimitOrdersContainer } from "container/service/LimitOrderServiceContainer/useActiveLimitOrder";
import { StatisticServiceContainer } from "container/service/StatisticsServiceContainer/StatisticsServiceContainer";
import { SegmentWatcher } from "module/analytics/container/SegmentWatcher";
import { AppNetwork } from "module/network/container/AppNetwork";
import { GasRebateProvider } from "module/pool/container/gasRebateProvider";
import { LiquidityProvider } from "module/pool/container/liquidityProvider";
import { Slippage } from "module/settings/container/slippage";
import { ConnectorProvider } from "module/wallet/ConnectorProvider";
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer";
import { DelegateApproval } from "sdk-react/limitOrder/useDelegateApproval";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";

Bugsnag.start()
const ErrorBoundary = Bugsnag.createErrorBoundary()

// // NOTE: experiment with Sentry
// Sentry.init({
//     dsn: "https://34a2974f971f4b508e5b5af10f88ea05@o4504230434242560.ingest.sentry.io/4504278986063872",
//     integrations: [new BrowserTracing()],
//     enabled: IS_PRODUCTION,
//     environment: Stage.PRODUCTION,
//     release: GITHUB_TAG,
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });


const Providers = (
    (...providers: any[]) =>
    ({ children }: { children: React.ReactNode }) => {
        return providers.reduceRight((providers, provider) => {
            const Provider = provider.component || provider
            const props = provider.props || {}
            return <Provider {...props}>{providers}</Provider>
        }, children)
    }
)(
    { component: ChakraProvider, props: { theme, reset: true } },
    ConnectorProvider,
    BrowserRouter,
    HelmetProvider,
    GeoBlocking.Provider,
    Modals.Provider,
    Web3WalletContainer.Provider,
    AppNetwork.Provider,
    AppConfig.Provider,
    FeatureToggleContainer.Provider,
    // NOTE: CustomizedPerpSDKProvider will render twice if auto connect wallet successfully
    // which makes its children render twice as well.
    CustomizedPerpSDKProvider,
    DelegateApproval.Provider,
    Referral.Provider,
    CandleServiceContainer.Provider,
    FundingRateServiceContainer.Provider,
    StatisticServiceContainer.Provider,
    Slippage.Provider,
    GasRebateProvider.Provider,
    LiquidityProvider.Provider,
    SegmentWatcher.Provider,
    LimitOrderServiceContainer.Provider,
    ActiveLimitOrdersContainer.Provider,
)

/**
 * NOTE:
 * Render the app in the `document.getElementById("root")`
 */
const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)
root.render(
    <React.StrictMode>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ErrorBoundary>
            <React.Suspense fallback={<PageLoading />}>
                <Providers>
                    <Fonts />
                    <App />
                </Providers>
            </React.Suspense >
        </ErrorBoundary>
    </React.StrictMode>,
)

/* NOTE:
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://cra.link/PWA
 **/
serviceWorker.unregister()

/* NOTE:
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 **/
reportWebVitals()
