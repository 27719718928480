import { EventName } from "module/analytics/types"
import { useWalletStrings } from "module/wallet/hook/useWalletStrings"
import { formatNumber, strToBig } from "util/format"

import { ChevronDownIcon } from "@chakra-ui/icons"
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Divider,
    HStack,
    Icon,
    Image,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Spacer,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react"
import Big from "big.js"
import { MarketListIcon } from "component/MarketListIcon"
import { ModalRootProps, ModalType } from "component/Modal/type"
import { PerpButton, PerpButtonProps } from "component/PerpButton"
import { PerpModal } from "component/PerpModal"
import { PerpNumberInput } from "component/PerpNumberInput/PerpNumberInput"
import { Stats } from "component/Stats"
import { TxButton } from "component/TxButton"
import { AppConfig } from "container/appConfig"
import { useModal } from "container/modal"
import { FeatureToggleContainer } from "container/service/FeatureToggleContainer"
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaFaucet } from "react-icons/fa"
import { Wallet } from "sdk-react/wallet/useWallet"
import {
    trackClickedApproveInDepositDialog,
    trackClickedDepositInDepositDialog,
    trackClickedMaxInDepositDialog,
} from "service/segment/tracks"

import { ICollateralStr } from "../hook/useCollateralStrings"
import { IDepositOption, useDepositOptionList } from "../hook/useDepositOptionList"
import { useIsDepositWithdrawAmountValid } from "../hook/useIsDepositWithdrawAmountValid"

const MINIMUM_ETH_SHOULD_BE_TRANSFERRED = 0.002

interface ContentDepositProps {
    amount?: string
    setAmount: Dispatch<SetStateAction<string | undefined>>
    isLowEth: boolean
    isLowUsdc: boolean
    setShowLowEthWarning: Dispatch<SetStateAction<boolean>>
    depositOptionIndex: number
    setDepositOptionIndex: Dispatch<SetStateAction<number>>
}

function ContentDeposit({
    amount,
    setAmount,
    isLowEth,
    isLowUsdc,
    setShowLowEthWarning,
    depositOptionIndex,
    setDepositOptionIndex,
}: ContentDepositProps) {
    const { open: openModalTransfer } = useModal(ModalType.TRANSFER)
    const { open: openModalGas } = useModal(ModalType.GAS)
    const { open: openLifiWidget } = useModal(ModalType.LIFI_WIDGET)
    const { open: openBiconomyWidget } = useModal(ModalType.BICONOMY_WIDGET)
    const { open: openSocketWidget } = useModal(ModalType.SOCKET_WIDGET)

    const { depositOptionList } = useDepositOptionList()

    const { balanceEthStr } = useWalletStrings()
    const shortBalanceEthStr = +balanceEthStr ? formatNumber(new Big(balanceEthStr), 3) : "0"

    const handleClickMax = useCallback(() => {
        const amount = depositOptionList[depositOptionIndex]?.balance
        trackClickedMaxInDepositDialog()
        amount && setAmount(amount.toString())
    }, [depositOptionIndex, depositOptionList, setAmount])

    const handleInputChange = useCallback(
        (value: string) => {
            setAmount(value)
        },
        [setAmount],
    )

    const { t } = useTranslation()

    const estimateTransactionCount = useMemo(() => {
        const transformedBalancedEth = +balanceEthStr || 0
        return Math.round(transformedBalancedEth / 0.00052)
    }, [balanceEthStr])

    const { isBiconomyWidgetEnable, isEmbeddedBridgeEnable } = FeatureToggleContainer.useContainer()

    // NOTE: toggle more bridge options
    // const { isOpen, onOpen, onClose } = useDisclosure()

    const { appConfig } = AppConfig.useContainer()
    const { faucetUrlETH } = appConfig?.externalLinks || {}
    return (
        <VStack spacing={6} align="stretch" pb={3}>
            {/* If ETH is low then users can do nothing and money will be stuck in bridges. Show warning here */}
            {isLowEth ? (
                <>
                    <VStack spacing={6} align={"flex-start"}>
                        <HStack spacing={4} width="100%">
                            <Box position="relative" boxSize="44px">
                                <MarketListIcon boxSize="full" coin="eth" />
                                <Image
                                    position="absolute"
                                    right={-1}
                                    bottom={-1}
                                    boxSize="50%"
                                    src="assets/network/network-op.svg"
                                />
                            </Box>
                            <Stats
                                size="2xl"
                                label={`${t("wallet.balance")}`}
                                number={shortBalanceEthStr}
                                helper={`≈ ${estimateTransactionCount} ${t("general.transactions")}`}
                            />
                        </HStack>
                        <Text fontSize="sm" color="gray.300">
                            Before you start trading, you need ETH on Optimism to pay for the gas fees.
                        </Text>

                        <Divider display={isBiconomyWidgetEnable ? "block" : "none"} />
                        <VStack spacing={4} align={"flex-start"} display={isBiconomyWidgetEnable ? "flex" : "none"}>
                            <Text fontWeight={"bold"}>USDC Express Bridge by Biconomy</Text>
                            <Text fontSize="sm" color="gray.300">
                                Bridge USDC and get USDC + some ETH in one transaction.
                            </Text>
                            <BiconomyButton onClick={() => openBiconomyWidget()}>Bridge with Hyphen</BiconomyButton>
                        </VStack>
                        <Divider display={isBiconomyWidgetEnable ? "block" : "none"} />
                        <Text display={isBiconomyWidgetEnable ? "block" : "none"} fontWeight={"bold"}>
                            Or bridge ETH manually
                        </Text>
                    </VStack>
                    <HStack spacing={4} width={"100%"}>
                        <Button
                            width="100%"
                            onClick={() => openModalGas()}
                            leftIcon={<MarketListIcon boxSize="20px" coin="eth" />}
                        >
                            {t("bridge.bridge_eth")}
                        </Button>

                        <Button
                            mt={4}
                            leftIcon={<Icon as={FaFaucet} boxSize="20px" color="teal.300" />}
                            width="100%"
                            as="a"
                            target="_blank"
                            href={faucetUrlETH}
                        >
                            {t("faucet.eth_faucet")}
                        </Button>
                    </HStack>
                </>
            ) : (
                <>
                    {/* NOTE: Multi deposit Section */}
                    <VStack align="stretch">
                        <HStack width="100%">
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    leftIcon={
                                        <MarketListIcon
                                            boxSize="24px"
                                            coin={depositOptionList[depositOptionIndex]?.collateralSymbol}
                                        />
                                    }
                                    rightIcon={<ChevronDownIcon />}
                                    iconSpacing={{ base: "2px", sm: 3 }}
                                >
                                    <Box display={{ base: "none", sm: "block" }}>
                                        {depositOptionList[depositOptionIndex]?.collateralSymbol.toUpperCase()}
                                    </Box>
                                </MenuButton>
                                <MenuList>
                                    <MenuOptionGroup type="radio">
                                        {depositOptionList.map((item, index) => (
                                            <MenuItemOption
                                                value={item.collateralSymbol}
                                                key={index}
                                                command={item.balanceStr}
                                                textTransform="uppercase"
                                                onClick={() => {
                                                    setDepositOptionIndex(index)
                                                }}
                                            >
                                                <HStack width="100%">
                                                    <MarketListIcon boxSize="24px" coin={item.collateralSymbol} />{" "}
                                                    <Text>{item.collateralSymbol}</Text>
                                                    <Spacer />
                                                    <Text opacity={0.65} fontSize="xs">
                                                        {item.balanceStr}
                                                    </Text>
                                                </HStack>
                                            </MenuItemOption>
                                        ))}
                                        <MenuDivider />
                                        <Box px={6} py={2}>
                                            <Text fontSize="xs" fontWeight="normal" opacity={0.65}>
                                                Both ETH and WETH are supported.
                                            </Text>
                                        </Box>
                                    </MenuOptionGroup>
                                </MenuList>
                            </Menu>
                            <Box flexGrow={1}>
                                <PerpNumberInput
                                    placeholder={"0"}
                                    textAlign="right"
                                    value={amount}
                                    onChange={v => handleInputChange(v)}
                                    trackingProps={{
                                        eventName: EventName.VAULT_DEPOSIT,
                                        payload: { amount: amount || "" },
                                    }}
                                />
                            </Box>
                        </HStack>
                        <HStack width="100%">
                            <Text opacity={0.65} fontSize="xs">
                                {depositOptionList[depositOptionIndex]?.balanceStr} available
                            </Text>
                            <Button
                                size="xs"
                                colorScheme="gray"
                                disabled={!depositOptionList[depositOptionIndex]?.balance}
                                onClick={handleClickMax}
                            >
                                {t("general.max")}
                            </Button>
                        </HStack>
                    </VStack>
                    <Divider />

                    {/* NOTE: Bridge Section */}
                    <Accordion allowToggle defaultIndex={0}>
                        <AccordionItem border="none">
                            <AccordionButton p={0}>
                                <Box flex="1" textAlign="left">
                                    <VStack align={"stretch"}>
                                        <Text fontWeight="bold">{t("bridge.bridge_funds")}</Text>
                                        <Text fontSize={"sm"} color="gray.300">
                                            {t("bridge.bridge_funds_description")}
                                        </Text>
                                    </VStack>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel px={0} pt={6} pb={0}>
                                <VStack align={"flex-start"} spacing={6}>
                                    <VStack spacing={4} align={"stretch"} w="full">
                                        <Tooltip
                                            isDisabled={!isBiconomyWidgetEnable}
                                            hasArrow
                                            label="Bridge USDC and get USDC + some ETH in one transaction."
                                        >
                                            <Box>
                                                <BiconomyButton
                                                    variant={"outline"}
                                                    onClick={() => openBiconomyWidget()}
                                                >
                                                    Bridge with Hyphen
                                                </BiconomyButton>
                                            </Box>
                                        </Tooltip>

                                        {isEmbeddedBridgeEnable && (
                                            <Tooltip hasArrow label="Advanced Bridge & DEX Aggregation.">
                                                <Box>
                                                    <LiFiButton
                                                        isDisabled={isLowEth}
                                                        onClick={() => {
                                                            // pass the current token to lifi as preset
                                                            openLifiWidget({
                                                                tickerSymbol:
                                                                    depositOptionList[depositOptionIndex]
                                                                        ?.collateralSymbol,
                                                            })
                                                        }}
                                                    >
                                                        Bridge with Li.Fi
                                                    </LiFiButton>
                                                </Box>
                                            </Tooltip>
                                        )}
                                        {isEmbeddedBridgeEnable && (
                                            <Tooltip
                                                hasArrow
                                                label="Swap and transfer funds across any-chain seamlessly."
                                            >
                                                <Box>
                                                    <SocketButton
                                                        isDisabled={isLowEth}
                                                        onClick={() => openSocketWidget()}
                                                    >
                                                        Bridge with Socket
                                                    </SocketButton>
                                                </Box>
                                            </Tooltip>
                                        )}

                                        <HStack spacing={4} w="100%" display={"flex"}>
                                            <PerpButton
                                                width="100%"
                                                isDisabled={isLowEth}
                                                variant={"outline"}
                                                leftIcon={<MarketListIcon boxSize="20px" coin="usdc" />}
                                                onClick={() => openModalTransfer()}
                                                trackingProps={{
                                                    eventName: EventName.BRIDGE_USDC,
                                                    description: `from component: ${ModalDeposit.name}`,
                                                }}
                                            >
                                                {t("bridge.bridge_usdc")}
                                            </PerpButton>
                                            <PerpButton
                                                width="100%"
                                                isDisabled={isLowEth}
                                                variant={"outline"}
                                                leftIcon={<MarketListIcon boxSize="20px" coin="eth" />}
                                                onClick={() => openModalGas()}
                                                trackingProps={{
                                                    eventName: EventName.BRIDGE_ETH,
                                                    description: `from component: ${ModalDeposit.name}`,
                                                }}
                                            >
                                                {t("bridge.bridge_eth")}
                                            </PerpButton>
                                        </HStack>
                                    </VStack>
                                </VStack>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </>
            )}
        </VStack>
    )
}

interface ButtonDepositProps {
    amountInput: Big | undefined
    depositOption?: IDepositOption
    onSuccess: () => void
}
function ButtonDeposit({ amountInput, depositOption, onSuccess }: ButtonDepositProps) {
    const { collateralSymbol, balance: balanceCollateral, approve, deposit } = depositOption || {}
    const { isApproveLoading, shouldApproveDeposit } = Wallet.useContainer()

    const isEnoughAllowance = useMemo(() => {
        if (!amountInput || !collateralSymbol) {
            return false
        }
        if (!approve) {
            return true
        }
        return !shouldApproveDeposit(new Big(amountInput), collateralSymbol)
    }, [amountInput, approve, shouldApproveDeposit, collateralSymbol])

    const isDepositAmountValid = useIsDepositWithdrawAmountValid(amountInput, balanceCollateral)

    // NOTE: handlers
    const handleApprove = useCallback(() => {
        if (!amountInput || !approve) {
            return
        }
        trackClickedApproveInDepositDialog()
        approve()
    }, [amountInput, approve])

    const handleDeposit = useCallback(async () => {
        if (!amountInput || !deposit) {
            return false
        }
        trackClickedDepositInDepositDialog(amountInput.toString())
        const receipt = await deposit(amountInput)
        if (receipt) {
            onSuccess()
        }
    }, [amountInput, deposit, onSuccess])

    const { t } = useTranslation()

    return (
        <TxButton
            colorScheme={"perpetual"}
            disabled={!isDepositAmountValid}
            isLoading={isApproveLoading}
            onClick={isEnoughAllowance ? handleDeposit : handleApprove}
        >
            {/* instead of red-border input, show insufficient funds text */}
            {!isDepositAmountValid
                ? "Insufficient Funds"
                : isEnoughAllowance
                ? t("vault.deposit")
                : t("general.button_approve")}
        </TxButton>
    )
}

export interface ModalDepositParams {
    collateralData?: ICollateralStr
    onSuccess?: () => void
}
export function ModalDeposit({ isOpen, close, params }: ModalRootProps<ModalDepositParams | undefined>) {
    const { collateralData, onSuccess } = params || {}
    const { symbol: collateralSymbol } = collateralData || {}
    const { depositOptionList } = useDepositOptionList()

    const defaultDepositOptionIndex = Math.max(
        depositOptionList.findIndex(option => option.collateralSymbol === collateralSymbol),
        0, // NOTE: default to first option when default not found (when findIndex return -1)
    )
    const [depositOptionIndex, setDepositOptionIndex] = useState(defaultDepositOptionIndex)
    const depositOption = depositOptionList[depositOptionIndex] || {}
    const { balance: balanceSelectedCollateral } = depositOption || {}

    const { balanceEth } = Wallet.useContainer()
    const isLowEth = !!balanceEth?.lt(MINIMUM_ETH_SHOULD_BE_TRANSFERRED)
    const isLowUsdc = !!balanceSelectedCollateral?.lt(50) // NOTE: check if Usdc is lower than 50
    const [showLowEthWarning, setShowLowEthWarning] = useState(isLowEth)

    const [amountString, setAmountString] = useState<string>()
    const amountBig = useMemo(() => strToBig(amountString || ""), [amountString])

    const handleSuccess = useCallback(() => {
        onSuccess && onSuccess()
        close()
    }, [close, onSuccess])

    const { t } = useTranslation()

    return (
        <PerpModal
            isOpen={isOpen}
            onClose={close}
            header={showLowEthWarning ? t("bridge.low_eth_header") : t("vault.deposit")}
            trackingPropsOpen={{ eventName: EventName.VAULT_DEPOSIT_MODAL_OPEN }}
            trackingPropsClose={{ eventName: EventName.VAULT_DEPOSIT_MODAL_CLOSE }}
            body={
                <ContentDeposit
                    amount={amountString}
                    setAmount={setAmountString}
                    isLowEth={showLowEthWarning}
                    isLowUsdc={isLowUsdc}
                    setShowLowEthWarning={setShowLowEthWarning}
                    depositOptionIndex={depositOptionIndex}
                    setDepositOptionIndex={setDepositOptionIndex}
                />
            }
            footer={
                showLowEthWarning ? (
                    <Button width="100%" onClick={() => setShowLowEthWarning(false)}>
                        Skip for now
                    </Button>
                ) : (
                    <ButtonDeposit amountInput={amountBig} onSuccess={handleSuccess} depositOption={depositOption} />
                )
            }
        />
    )
}

const LiFiButton = (props: PerpButtonProps) => {
    const faviconUrl = "https://s2.googleusercontent.com/s2/favicons?domain=https://li.finance"
    return (
        <PerpButton
            width="100%"
            size="md"
            variant="outline"
            leftIcon={<Image src={faviconUrl} />}
            style={{ whiteSpace: "normal", wordWrap: "break-word" }} // NOTE: for text wrap
            trackingProps={{
                eventName: EventName.BRIDGE_LIFI_OPEN,
                description: `from component: ${ModalDeposit.name}`,
            }}
            {...props}
        >
            {props.children}
        </PerpButton>
    )
}

export const BiconomyButton = (props: PerpButtonProps) => {
    const faviconUrl = "https://s2.googleusercontent.com/s2/favicons?domain=https://www.biconomy.io/"
    return (
        <PerpButton
            width="100%"
            size="md"
            variant="outline"
            leftIcon={<Image src={faviconUrl} />}
            style={{ whiteSpace: "normal", wordWrap: "break-word" }} // NOTE: for text wrap
            trackingProps={{
                eventName: EventName.BRIDGE_BICONOMY_OPEN,
                description: `from component: ${ModalDeposit.name}`,
            }}
            {...props}
        >
            {props.children}
        </PerpButton>
    )
}

const SocketButton = (props: PerpButtonProps) => {
    return (
        <PerpButton
            width="100%"
            size="md"
            variant="outline"
            style={{ whiteSpace: "normal", wordWrap: "break-word" }} // NOTE: for text wrap
            trackingProps={{
                eventName: EventName.BRIDGE_SOCKET_OPEN,
                description: `from component: ${ModalDeposit.name}`,
            }}
            {...props}
        >
            {props.children}
        </PerpButton>
    )
}
