import { PerpetualProtocolConnected } from "@perp/sdk-curie"
import { DependencyList, useCallback, useEffect, useRef } from "react"
import { Handlers } from "sdk-react/tools/useHandlers"

import { PerpSDK } from "./usePerpSDK"

export type PerpConnectedCallbackAsync<T extends Omit<{ [key: string]: any }, "options"> | undefined> = (
    perp: PerpetualProtocolConnected,
    args: T,
) => Promise<any>

export function usePerpConnectedCallbackAsync<T>(callback: PerpConnectedCallbackAsync<T>, deps: DependencyList) {
    const { perp, isConnected } = PerpSDK.useContainer()

    const savedCallback = useRef(callback)
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    const { getHandlers } = Handlers.useContainer()

    return useCallback(
        async (args?: T) => {
            const { onUnconnected, onError } = getHandlers()

            if (!perp?.hasConnected() || !isConnected) {
                onUnconnected()
                return
            }
            try {
                return await savedCallback.current(perp, args as T)
            } catch (error) {
                onError(error as Error)
            }
        },
        [perp, isConnected, ...deps], // eslint-disable-line react-hooks/exhaustive-deps
    )
}
