import { TrackEventProperties } from "module/analytics/service/SegmentService"

// NOTE: naming [module name]_[action]
export enum EventName {
    BRIDGE_USDC = "bridge_usdc",
    BRIDGE_ETH = "bridge_eth",
    BRIDGE_LIFI_OPEN = "bridge_lifi_open",
    BRIDGE_LIFI_CLOSE = "bridge_lifi_close",
    BRIDGE_BICONOMY_OPEN = "bridge_biconomy_open",
    BRIDGE_BICONOMY_CLOSE = "gasless_deposit_close",
    BRIDGE_SOCKET_OPEN = "bridge_socket_open",
    BRIDGE_SOCKET_CLOSE = "bridge_socket_close",

    MARKET_DETAIL_TAB_TOGGLE = "market_detail_tab_toggle",
    MARKET_DETAIL_FUNDING_RATE_INTERVAL_TOGGLE = "market_detail_funding_rate_interval_toggle",
    VAULT_DEPOSIT = "vault_deposit",
    VAULT_WITHDRAW = "vault_withdraw",
    VAULT_DEPOSIT_MODAL_OPEN = "vault_deposit_modal_open",
    VAULT_DEPOSIT_MODAL_CLOSE = "vault_deposit_modal_close",
    VAULT_BALANCE_COLLATERAL_UPDATED = "vault_balance_collateral_updated",
    VAULT_COLLATERAL_WITHDRAW_CLICK = "vault_collateral_withdraw_click",
    VAULT_COLLATERAL_DEPOSIT_CLICK = "vault_collateral_deposit_click",
    VAULT_COLLATERAL_OPTION_CLICK = "vault_collateral_option_click",
    WALLET_BALANCE_ETH_UPDATED = "wallet_balance_eth_updated",
    POOL_ADD_LIQUIDITY_MODE_TOGGLE = "pool_add_liquidity_mode_toggle",
    POOL_ADD_LIQUIDITY_ADVANCED_BASE_UPDATED = "pool_add_liquidity_advanced_base_updated",
    POOL_ADD_LIQUIDITY_ADVANCED_QUOTE_UPDATED = "pool_add_liquidity_advanced_quote_updated",
    POOL_ADD_LIQUIDITY_ADVANCED_PRICE_RANGE_MAX_UPDATED = "pool_add_liquidity_advanced_price_range_max_updated",
    POOL_ADD_LIQUIDITY_ADVANCED_PRICE_RANGE_MIN_UPDATED = "modal_add_liquidity_advanced_price_range_min_updated",
    POOL_ADD_LIQUIDITY_SIMPLE_QUOTE_UPDATED = "pool_add_liquidity_simple_quote_updated",
    POSITION_OPEN_QUOTE_AMOUNT_UPDATED = "position_open_quote_amount_updated",
    POSITION_OPEN_BASE_AMOUNT_UPDATED = "position_open_base_amount_updated",
    POSITION_OPEN_BUYING_POWER_CHANGE = "position_open_buying_power_change",
    POSITION_OPEN_PRICE_UPDATED = "position_open_price_updated",
    POSITION_OPEN_TRIGGER_PRICE_UPDATED = "position_open_trigger_price_updated",
    POSITION_OPEN_LIMIT_PRICE_UPDATED = "position_open_limit_price_updated",
    LIMIT_ORDER_APPROVE_CLICK = "limit_order_approve_click",
    LIMIT_ORDER_SUBMIT_CLICK = "limit_order_submit_click",
    LIMIT_ORDER_SUBMIT = "limit_order_submit",
    LIMIT_ORDER_CANCEL_CLICK = "limit_order_cancel_click",
    OTC_ORDER_APPROVE_CLICK = "otc_order_approve_click",
    OTC_ORDER_SUBMIT_CLICK = "otc_order_submit_click",
    OTC_ORDER_SUBMIT = "otc_order_submit",
    MARKET_GAS_REBATE_CLICK = "market_gas_rebate_click",
    MARKET_LIQUIDITY_MINING_CLICK = "market_liquidity_mining_click",
    MARKET_VIEW_ALL_REWARDS_CLICK = "market_view_all_rewards_click",
    AVATAR_WALLET_CLICK = "avatar_wallet_click",
    AVATAR_ACCOUNT_CLICK = "avatar_account_click",
    AVATAR_SETTINGS_CLICK = "avatar_settings_click",
    AVATAR_SUPPORT_CLICK = "avatar_support_click",
    AVATAR_BLOCKNUMBER_CLICK = "avatar_blocknumber_click",
    AVATAR_DISCONNECT_CLICK = "avatar_disconnect_click",
    LIQUIDITY_REMOVE_CHANGE = "liquidity_remove_change",
    LIQUIDITY_ADD_PRICE_RANGE_CHANGE = "liquidity_add_price_range_change",
    LINK_LEARN_MORE_ABOUT_MULTI_COLLATERAL = "link_learn_more_about_multi_collateral",
    LINK_LEARN_MORE_USDC_IS_TOO_LOW = "link_learn_more_usdc_is_too_low",
    LINK_DEPOSIT_HISTORY_TABLE_ROW_CLICK = "link_deposit_history_table_row_click",
    LINK_TRADER_HISTORY_TABLE_ROW_CLICK = "link_trader_history_table_row_click",
    LINK_WITHDRAW_HISTORY_TABLE_ROW_CLICK = "link_withdraw_history_table_row_click",
    LINK_TRADE_LIST_CLICK = "link_trade_list_click",
    LINK_TERMS_OF_SERVICE_CLICK = "link_terms_of_service_click",
    LINK_ONBOARDING_SERVICE_DISCORD_CLICK = "link_onboarding_service_discord_click",
    LINK_LIQUIDITY_MINING_CLICK = "link_liquidity_mining_click",
    LINK_SEEDLE_CLICK = "link_seedle_click",
    LINK_PERPSIM_CLICK = "link_perpsim_click",
    LINK_COLLATERAL_LIQUIDATION_CLICK = "link_collateral_liquidation_click",
    LINK_SUPPORT = "link_support",
    LINK_IMPERMANENT_LOSS = "link_impermanent_loss",
    LINK_DISCORD = "link_discord",
    LINK_TWITTER = "link_twitter",
    LINK_VAULT = "link_vault",
    LINK_MAKER_LIQUIDATION = "link_maker_liquidation",

    FIX_ANONYMOUS_ID = "FIX_ANONYMOUS_ID",
}

export interface TrackingProps
    extends Omit<
        TrackEventProperties,
        "eventType" | "versionFrontend" | "versionContractCore" | "versionContractPeriphery"
    > {
    eventName: EventName
}
