import { useQuery } from "@apollo/client"
import { PositionHistory, PositionSide } from "@perp/sdk-curie"
import Big from "big.js"
import { useCallback, useMemo } from "react"
import { MarketsContainer } from "sdk-react/markets/MarketsContainer"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

import { POSITION_LIQUIDATION_BY_TRADER_QUERY } from "../graphServer/graphQueries"
import { Web3Wallet } from "../PerpSDKProvider"
import { getMarketMapByBaseAddress, getMarketMapByRawBaseAddress } from "./utils/getMarketMapByAddress"

const PAGE_SIZE = 100
interface GraphPositionChanged {
    id: string
    baseToken: string
    openNotional: string
    openPrice: string
    positionSize: string
    exchangedPositionNotional: string
    exchangedPositionSize: string
    swappedPrice: string
    realizedPnl: string
    timestamp: string
    fee: string
    fromFunctionSignature: string
}

interface GraphPositionChangeds {
    positionChangeds: GraphPositionChanged[]
}

type PositionLiquidationHistoryInitialState = Web3Wallet

export const PositionLiquidationHistoryContainer = createContainer(usePositionLiquidationHistories)

function usePositionLiquidationHistories(initialState?: PositionLiquidationHistoryInitialState) {
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { account } = initialState || {}
    const { marketMap, poolMetadataMap } = MarketsContainer.useContainer() // NOTE: Don't use whitelisted markets since all histories should be shown.

    /* NOTE: Subscribe PositionChanged */
    const {
        data: dataPositionChanged,
        loading: loadingPositionChanged,
        error: errorPositionChanged,
        fetchMore: fetchMorePositionChanged,
    } = useQuery<GraphPositionChangeds>(POSITION_LIQUIDATION_BY_TRADER_QUERY, {
        variables: { trader: account, first: PAGE_SIZE, skip: 0 },
        skip: !account,
    })

    if (errorPositionChanged) {
        onError(errorPositionChanged)
    }
    const fetchNextPagePositionChanged = useCallback(() => {
        const skip = dataPositionChanged?.positionChangeds.length ?? 0
        if (skip <= 0) {
            return
        }
        fetchMorePositionChanged({ variables: { skip } })
    }, [dataPositionChanged?.positionChangeds.length, fetchMorePositionChanged])

    const positionChangedList = useMemo(() => {
        if (loadingPositionChanged || !dataPositionChanged || !marketMap || !poolMetadataMap) {
            return []
        }
        const histories = dataPositionChanged.positionChangeds || []
        const marketMapByBaseAddress = getMarketMapByBaseAddress({ marketMap })
        const marketMapByRawBaseAddress = getMarketMapByRawBaseAddress({ marketMap, poolMetadataMap })

        try {
            return histories
                .map(history => {
                    const market =
                        marketMapByBaseAddress[history.baseToken] || marketMapByRawBaseAddress[history.baseToken]
                    if (!market) {
                        // NOTE: ignore when market not found.
                        return null
                    }

                    const size = new Big(history.exchangedPositionSize)
                    const positionNotional = new Big(history.exchangedPositionNotional)
                    const txId = history.id.split("-")[0] // NOTE: <tx hash>-<tx log index>

                    return new PositionHistory({
                        txId,
                        tickerSymbol: market.tickerSymbol,
                        baseSymbol: market.baseSymbol,
                        side: size.gte(0) ? PositionSide.LONG : PositionSide.SHORT,
                        size: size.abs(),
                        positionNotional,
                        baseTokenAddress: history.baseToken,
                        price: new Big(history.swappedPrice),
                        realizedPnl: new Big(history.realizedPnl),
                        tradingFee: new Big(history.fee),
                        timestamp: Number(history.timestamp),
                        fromFunctionSignature: history.fromFunctionSignature,
                    })
                })
                .filter((item): item is PositionHistory => !!item)
        } catch (error: any) {
            onError(error)
            return []
        }
    }, [loadingPositionChanged, dataPositionChanged, marketMap, poolMetadataMap, onError])

    const fetchNextPage = useCallback(() => {
        fetchNextPagePositionChanged()
    }, [fetchNextPagePositionChanged])

    return {
        isLoading: loadingPositionChanged,
        positionHistories: positionChangedList,
        fetchNextPage,
    }
}
