import { useQuery } from "@apollo/client"
import { FundingPaymentHistory } from "@perp/sdk-curie"
import Big from "big.js"
import { useCallback, useMemo } from "react"
import { MarketsContainer } from "sdk-react/markets/MarketsContainer"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

import { FUNDING_PAYMENT_SETTLEDS_QUERY } from "../graphServer/graphQueries"
import { Web3Wallet } from "../PerpSDKProvider"
import { getMarketMapByBaseAddress, getMarketMapByRawBaseAddress } from "./utils/getMarketMapByAddress"

const PAGE_SIZE = 100

interface FundingPaymentSettled {
    id: string
    trader: string
    baseToken: string
    fundingPayment: string
    blockNumberLogIndex: string
    blockNumber: string
    timestamp: string
}

interface GraphFundingPaymentHistory {
    fundingPaymentSettleds: FundingPaymentSettled[]
}

type FundingPaymentHistoryInitialState = Web3Wallet

export const FundingPaymentHistoryContainer = createContainer(useFundingPaymentHistories)

function useFundingPaymentHistories(initialState?: FundingPaymentHistoryInitialState) {
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { account } = initialState || {}
    const { marketMap, poolMetadataMap } = MarketsContainer.useContainer() // NOTE: Don't use whitelisted markets since all histories should be shown.

    const { data, loading, error, fetchMore } = useQuery<GraphFundingPaymentHistory>(FUNDING_PAYMENT_SETTLEDS_QUERY, {
        variables: { trader: account, first: PAGE_SIZE, skip: 0 },
        skip: !account,
    })

    if (error) {
        onError(error)
    }

    const fetchNextPage = useCallback(() => {
        const skip = data?.fundingPaymentSettleds.length ?? 0
        if (skip <= 0) {
            return
        }
        fetchMore({ variables: { skip } })
    }, [data?.fundingPaymentSettleds.length, fetchMore])

    const fundingPaymentHistories = useMemo(() => {
        if (loading || !data || !marketMap || !poolMetadataMap) {
            return []
        }
        const histories = data.fundingPaymentSettleds || []
        const marketMapByBaseAddress = getMarketMapByBaseAddress({ marketMap })
        const marketMapByRawBaseAddress = getMarketMapByRawBaseAddress({ marketMap, poolMetadataMap })

        try {
            return histories
                .map(history => {
                    const market =
                        marketMapByBaseAddress[history.baseToken] || marketMapByRawBaseAddress[history.baseToken]
                    if (!market) {
                        // NOTE: ignore when market not found.
                        return null
                    }
                    return new FundingPaymentHistory({
                        id: history.id,
                        trader: history.trader,
                        fundingPayment: new Big(history.fundingPayment),
                        timestamp: Number(history.timestamp),
                        tickerSymbol: market.tickerSymbol,
                        baseSymbol: market.baseSymbol,
                    })
                })
                .filter((item): item is FundingPaymentHistory => !!item)
        } catch (error: any) {
            onError(error)
            return []
        }
    }, [loading, data, marketMap, poolMetadataMap, onError])

    return {
        isLoading: loading,
        fundingPaymentHistories,
        fetchNextPage,
    }
}
