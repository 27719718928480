import { useQuery } from "@apollo/client"
import { CollateralLiquidationHistory } from "@perp/sdk-curie"
import Big from "big.js"
import { useCallback, useMemo } from "react"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

import { COLLATERAL_LIQUIDATEDS_QUERY } from "../graphServer/graphQueries"
import { Web3Wallet } from "../PerpSDKProvider"

const PAGE_SIZE = 100

interface APICollateralLiquidationHistory {
    id: string
    txHash: string
    trader: string
    collateralToken: string
    liquidator: string
    collateral: string
    repaidSettlementWithoutInsuranceFundFee: string
    insuranceFundFee: string
    discountRatio: string
    blockNumberLogIndex: string
    blockNumber: string
    timestamp: string
}

interface ICollateralLiquidationHistory {
    collateralLiquidateds: APICollateralLiquidationHistory[]
}

type CollateralLiquidationHistoryInitialState = Web3Wallet

export const CollateralLiquidationHistories = createContainer(useCollateralLiquidationHistories)

function useCollateralLiquidationHistories(initialState?: CollateralLiquidationHistoryInitialState) {
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { account } = initialState || {}

    const { data, loading, error, fetchMore } = useQuery<ICollateralLiquidationHistory>(COLLATERAL_LIQUIDATEDS_QUERY, {
        variables: { trader: account, first: PAGE_SIZE, skip: 0 },
        skip: !account,
    })

    if (error) {
        onError(error)
    }

    const fetchNextPage = useCallback(() => {
        const skip = data?.collateralLiquidateds.length ?? 0
        if (skip <= 0) {
            return
        }
        fetchMore({ variables: { skip } })
    }, [data?.collateralLiquidateds.length, fetchMore])

    const collateralLiquidationHistories = useMemo(() => {
        if (loading || !data) {
            return []
        }
        const histories = data.collateralLiquidateds || []

        try {
            return histories.map(history => {
                const amount = new Big(history.collateral)
                const repayAmount = new Big(history.repaidSettlementWithoutInsuranceFundFee)
                const insuranceFundFee = new Big(history.insuranceFundFee)
                const discountRatio = new Big(history.discountRatio)
                const blockNumber = Number(history.blockNumber)
                const blockNumberLogIndex = Number(history.timestamp)
                const timestamp = Number(history.timestamp)

                return new CollateralLiquidationHistory({
                    id: history.id,
                    txHash: history.txHash,
                    trader: history.trader,
                    liquidator: history.liquidator,
                    collateralToken: history.collateralToken,
                    amount,
                    repayAmount,
                    insuranceFundFee,
                    discountRatio,
                    blockNumber,
                    blockNumberLogIndex,
                    timestamp,
                })
            })
        } catch (e) {
            onError(e)
            return []
        }
    }, [loading, data, onError])

    return {
        isLoading: loading,
        collateralLiquidationHistories,
        fetchNextPage,
    }
}
