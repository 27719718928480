interface CoinMetadataType {
    FULL_NAME: string
    IMG_PATH: string
}

// TODO: deprecate this
export const COIN_METADATA_MAP: Record<string, CoinMetadataType> = {
    BTC: {
        FULL_NAME: "Bitcoin",
        IMG_PATH: "/assets/coins/btc.svg",
    },
    ETH: {
        FULL_NAME: "Ethereum",
        IMG_PATH: "/assets/coins/eth.svg",
    },
    AVAX: {
        FULL_NAME: "Avalanche",
        IMG_PATH: "/assets/coins/avax.svg",
    },
    LUNA: {
        FULL_NAME: "Luna",
        IMG_PATH: "/assets/coins/luna.svg",
    },
    SOL: {
        FULL_NAME: "Solana",
        IMG_PATH: "/assets/coins/sol.svg",
    },
    ALPHA: {
        FULL_NAME: "Alpha",
        IMG_PATH: "/assets/coins/alpha.svg",
    },
    YFI: {
        FULL_NAME: "Yearn",
        IMG_PATH: "/assets/coins/yfi.svg",
    },
    DOT: {
        FULL_NAME: "Polkadot",
        IMG_PATH: "/assets/coins/dot.svg",
    },
    SNX: {
        FULL_NAME: "Synthetix",
        IMG_PATH: "/assets/coins/snx.svg",
    },
    LINK: {
        FULL_NAME: "Chainlink",
        IMG_PATH: "/assets/coins/link.svg",
    },
    SUSHI: {
        FULL_NAME: "Sushi",
        IMG_PATH: "/assets/coins/sushi.svg",
    },
    COMP: {
        FULL_NAME: "Compound",
        IMG_PATH: "/assets/coins/comp.svg",
    },
    REN: {
        FULL_NAME: "REN",
        IMG_PATH: "/assets/coins/ren.svg",
    },
    PERP: {
        FULL_NAME: "Perpetual Protocol",
        IMG_PATH: "/assets/coins/perp.svg",
    },
    UNI: {
        FULL_NAME: "Uniswap",
        IMG_PATH: "/assets/coins/uni.svg",
    },
    CRV: {
        FULL_NAME: "Curve DAO",
        IMG_PATH: "/assets/coins/crv.svg",
    },
    MKR: {
        FULL_NAME: "Maker",
        IMG_PATH: "/assets/coins/mkr.svg",
    },
    CREAM: {
        FULL_NAME: "Cream",
        IMG_PATH: "/assets/coins/cream.svg",
    },
    GRT: {
        FULL_NAME: "The Graph",
        IMG_PATH: "/assets/coins/grt.svg",
    },
    SAND: {
        FULL_NAME: "The Sandbox",
        IMG_PATH: "/assets/coins/sand.svg",
    },
    ATOM: {
        FULL_NAME: "Cosmos",
        IMG_PATH: "/assets/coins/atom.svg",
    },
    ONE: {
        FULL_NAME: "Harmony",
        IMG_PATH: "/assets/coins/one.svg",
    },
    NEAR: {
        FULL_NAME: "Near",
        IMG_PATH: "/assets/coins/near.svg",
    },
    FLOW: {
        FULL_NAME: "Flow",
        IMG_PATH: "/assets/coins/flow.svg",
    },
    MATIC: {
        FULL_NAME: "Polygon",
        IMG_PATH: "/assets/coins/matic.svg",
    },
    FTM: {
        FULL_NAME: "Fantom",
        IMG_PATH: "/assets/coins/ftm.svg",
    },
    BNB: {
        FULL_NAME: "BNB",
        IMG_PATH: "/assets/coins/bnb.svg",
    },
    LOOKS: {
        FULL_NAME: "LooksRare",
        IMG_PATH: "/assets/coins/looks.svg",
    },
    AAVE: {
        FULL_NAME: "Aave",
        IMG_PATH: "/assets/coins/aave.svg",
    },
    APE: {
        FULL_NAME: "ApeCoin",
        IMG_PATH: "/assets/coins/ape.svg",
    },
    IMX: {
        FULL_NAME: "Immutable X",
        IMG_PATH: "/assets/coins/imx.svg",
    },
    FXS: {
        FULL_NAME: "Frax Share",
        IMG_PATH: "/assets/coins/fxs.svg",
    },
    RUNE: {
        FULL_NAME: "THORChain",
        IMG_PATH: "/assets/coins/rune.svg",
    },
    ZIL: {
        FULL_NAME: "Zilliqa",
        IMG_PATH: "/assets/coins/zil.svg",
    },
    OP: {
        FULL_NAME: "Optimism",
        IMG_PATH: "/assets/coins/op.svg",
    },
    DOGE: {
        FULL_NAME: "Dogecoin",
        IMG_PATH: "/assets/coins/doge.svg",
    },
}
