import { AppNetwork } from "module/network/container/AppNetwork"

import { ProviderConfig } from "@perp/sdk-curie"
import { CONFIG_ENDPOINT, PROVIDER_GRAPH_SERVER_CONFIG_ENDPOINT } from "constant/envVariables"
import deepEqual from "fast-deep-equal"
import { useEffect, useMemo, useState } from "react"
import { useAsync, useInterval } from "react-use"
import { GraphServerConfig } from "sdk-react/graphServer"
import { AppSyncServerConfig } from "service/appSync/appSyncService"
import { PusherConfig } from "service/PusherService"
import { createContainer } from "unstated-next"

import { IAppProviderGraphServerConfigRaw, IProviderGraphServerConfigRaw } from "./types"

interface IProviderGraphServerConfig {
    // NOTE: SDK configs
    providerConfigs: ProviderConfig[]
    graphServerConfigs: GraphServerConfig[]
}

interface IAppConfig extends IProviderGraphServerConfig {
    // NOTE: App configs
    candleServerConfigs: AppSyncServerConfig[]
    fundingRateServerConfigs: AppSyncServerConfig[]
    statisticsServerConfigs: AppSyncServerConfig[]
    liquidityProviderServerConfigs: AppSyncServerConfig[]
    gasRebateServerConfigs: AppSyncServerConfig[]
    limitOrderServerConfigs: AppSyncServerConfig[]
    pusherConfig: PusherConfig
    externalLinks: {
        rewardsUrl: string
        gasRebateUrl: string
        liquidityMiningUrl: string
        faucetUrlETH: string
    }
}

interface IAppConfigs {
    [chainId: number]: IAppConfig
}

export interface IAppConfigExtended extends IAppConfig {
    candleServerConfig: AppSyncServerConfig
    fundingRateServerConfig: AppSyncServerConfig
    statisticsServerConfig: AppSyncServerConfig
    liquidityProviderServerConfig: AppSyncServerConfig
    gasRebateServerConfig: AppSyncServerConfig
    limitOrderServerConfig: AppSyncServerConfig
}

export const AppConfig = createContainer(useAppConfig)

const POLLING_TIME_CONFIG = 10 * 1000

function useAppConfig() {
    const [providerGraphServerConfigs, updateProviderGraphServerConfigs] = useState<IAppProviderGraphServerConfigRaw>()

    useInterval(async () => {
        if (!PROVIDER_GRAPH_SERVER_CONFIG_ENDPOINT) {
            return
        }
        const providerGraphServerConfigsLatest = await (await fetch(PROVIDER_GRAPH_SERVER_CONFIG_ENDPOINT)).json()
        if (!deepEqual(providerGraphServerConfigs, providerGraphServerConfigsLatest)) {
            updateProviderGraphServerConfigs(providerGraphServerConfigsLatest)
        }
    }, POLLING_TIME_CONFIG)

    // NOTE: Fetch data
    const {
        loading: isLoading,
        value: configs,
        error,
    } = useAsync(async (): Promise<[IAppConfigs, IAppProviderGraphServerConfigRaw] | undefined> => {
        if (!CONFIG_ENDPOINT || !PROVIDER_GRAPH_SERVER_CONFIG_ENDPOINT) {
            return
        }
        const response = await Promise.all(
            [CONFIG_ENDPOINT, PROVIDER_GRAPH_SERVER_CONFIG_ENDPOINT].map(endpoint => fetch(endpoint)),
        )
        const ret = await Promise.all(response.map(r => r.json()))
        return ret as [IAppConfigs, IAppProviderGraphServerConfigRaw]
    }, [])

    useEffect(() => {
        if (configs && configs.length >= 2) {
            updateProviderGraphServerConfigs(configs[1])
        }
    }, [configs])

    // NOTE: Get data for network
    const { appChainId } = AppNetwork.useContainer()

    const appConfig = useMemo<IAppConfigExtended | undefined>(() => {
        if (!configs) {
            return
        }

        const [appConfigs, _providerGraphServerConfigs] = configs
        const _appConfig: IAppConfig = appConfigs[appChainId]
        const _providerGraphServerConfigRaw: IProviderGraphServerConfigRaw =
            providerGraphServerConfigs?.[appChainId] || _providerGraphServerConfigs[appChainId]

        const config = {
            ..._appConfig,
            // NOTE: Default to use the first option. Add rotation when we have multiple servers available.
            candleServerConfig: _appConfig.candleServerConfigs?.[0] || {},
            fundingRateServerConfig: _appConfig.fundingRateServerConfigs?.[0] || {},
            statisticsServerConfig: _appConfig.statisticsServerConfigs?.[0] || {},
            liquidityProviderServerConfig: _appConfig.liquidityProviderServerConfigs?.[0] || {},
            gasRebateServerConfig: _appConfig.gasRebateServerConfigs?.[0] || {},
            limitOrderServerConfig: _appConfig.limitOrderServerConfigs?.[0] || {},
        }

        // NOTE: only overwrite provider and graph server when exist available endpoints
        const _providerGraphServerConfig = adapterForNewConfigFormat(_providerGraphServerConfigRaw)
        if (_providerGraphServerConfig.providerConfigs.length > 0) {
            config.providerConfigs = _providerGraphServerConfig.providerConfigs
        }
        if (_providerGraphServerConfig.graphServerConfigs.length > 0) {
            config.graphServerConfigs = _providerGraphServerConfig.graphServerConfigs
        }

        return config
    }, [appChainId, configs, providerGraphServerConfigs])

    return { isLoading, error, appConfig }
}

function adapterForNewConfigFormat(newConfigFormat: IProviderGraphServerConfigRaw): IProviderGraphServerConfig {
    const oldConfigFormat: IProviderGraphServerConfig = {
        providerConfigs: [],
        graphServerConfigs: [],
    }
    newConfigFormat.providerConfigs.forEach(config => {
        config.wsUrl && oldConfigFormat.providerConfigs.push({ rpcUrl: config.wsUrl })
        config.url && oldConfigFormat.providerConfigs.push({ rpcUrl: config.url })
    })
    newConfigFormat.graphServerConfigs.forEach(config => {
        oldConfigFormat.graphServerConfigs.push({
            name: config.subgraphName,
            url: config.url,
            wsUrl: config.wsUrl,
            healthUrl: config.healthUrl,
        })
    })
    return oldConfigFormat
}
