import { ModalLiquidityAddParams } from "module/pool/component/ModalLiquidityAdd"
import { ModalLiquidityRemoveParams } from "module/pool/component/ModalLiquidityRemove"
import { ModalPositionCloseParams } from "module/position/component/ModalPositionClose"
import { ModalDepositParams } from "module/vault/component/ModalDeposit"
import { ModalWithdrawParams } from "module/vault/component/ModalWithdraw"

import { ModalLifiWidgetParams } from "./../ModalLifiWidget"
import { ModalOnboardingDismissParams } from "./ModalOnboardingDismiss"

export enum ModalType {
    ONBOARDING_DISMISS = "ONBOARDING_DISMISS",
    WALLET_CONNECTION = "WALLET_CONNECTION",
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW",
    SETTINGS = "SETTINGS",
    POSITION_CLOSE = "POSITION_CLOSE",
    LIQUIDITY_ADD = "LIQUIDITY_ADD",
    LIQUIDITY_REMOVE = "LIQUIDITY_REMOVE",
    TRANSFER = "TRANSFER",
    GAS = "GAS",
    LIFI_WIDGET = "LIFI_WIDGET",
    BICONOMY_WIDGET = "BICONOMY_WIDGET",
    SOCKET_WIDGET = "SOCKET_WIDGET",
}

export type ModalParams = {
    [ModalType.ONBOARDING_DISMISS]: ModalOnboardingDismissParams
    [ModalType.WALLET_CONNECTION]: undefined
    [ModalType.DEPOSIT]: ModalDepositParams
    [ModalType.WITHDRAW]: ModalWithdrawParams
    [ModalType.SETTINGS]: undefined
    [ModalType.POSITION_CLOSE]: ModalPositionCloseParams // NOTE: Modal to show when a position is successfully closed.
    [ModalType.LIQUIDITY_ADD]: ModalLiquidityAddParams
    [ModalType.LIQUIDITY_REMOVE]: ModalLiquidityRemoveParams
    [ModalType.TRANSFER]: undefined
    [ModalType.GAS]: undefined
    [ModalType.LIFI_WIDGET]: ModalLifiWidgetParams
    [ModalType.BICONOMY_WIDGET]: undefined
    [ModalType.SOCKET_WIDGET]: undefined
}

export type ModalRootProps<Params extends ModalParams[ModalType] = undefined> = {
    isOpen: boolean
    close: () => void
} & (Params extends undefined ? { params?: never } : { params: Params }) // NOTE: Conditional Types: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
