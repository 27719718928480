import { AppConfig } from "container/appConfig"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"
import { useCallback, useRef } from "react"
import { useAsync } from "react-use"
import { DefaultMarket, LiquidityProviderService, MakerMarket } from "service/appSync/liquidityProviderService"
import { createContainer } from "unstated-next"

export const LiquidityProvider = createContainer(useLiquidityProvider)

function useLiquidityProvider() {
    const { account } = Web3WalletContainer.useContainer()
    const liquidityProviderServiceRef = useRef<LiquidityProviderService>()

    const { appConfig } = AppConfig.useContainer()
    const { key, url, region } = appConfig?.liquidityProviderServerConfig || {}

    useAsync(async () => {
        if (!key || !url || !region || liquidityProviderServiceRef.current) {
            return
        }

        const service = new LiquidityProviderService({ key, url, region })
        liquidityProviderServiceRef.current = service
        await service.hydrated()
        return service
    }, [key, url, region])

    const { value: totalClaimableReward, loading: isTotalClaimableRewardLoading } = useAsync(async () => {
        if (!account || !liquidityProviderServiceRef.current) {
            return null
        }
        return await liquidityProviderServiceRef.current.getClaimableRewards(account)
    }, [liquidityProviderServiceRef.current, account])

    const { value: claimableRewardOp, loading: isTotalClaimableRewardLoadingOp } = useAsync(async () => {
        if (!account || !liquidityProviderServiceRef.current) {
            return null
        }
        return await liquidityProviderServiceRef.current.getClaimableRewardsOp(account)
    }, [liquidityProviderServiceRef.current, account])

    const { value: makerMarkets } = useAsync(async () => {
        if (!account || !liquidityProviderServiceRef.current) {
            return null
        }
        return await liquidityProviderServiceRef.current.listMakerAprs(account)
    }, [liquidityProviderServiceRef.current, account])

    const { value: listMarket } = useAsync(async () => {
        if (!account || !liquidityProviderServiceRef.current) {
            return null
        }
        return await liquidityProviderServiceRef.current.listMarketAprs()
    }, [liquidityProviderServiceRef.current])

    const makerMarketSymbols = makerMarkets?.map(market => market.marketSymbol) || []
    const restMarkets = listMarket?.filter(({ marketSymbol }) => !makerMarketSymbols.includes(marketSymbol))

    const getMakerMarket = useCallback(
        (marketSymbolParam: string): MakerMarket | undefined => {
            return makerMarkets?.find(({ marketSymbol }) => marketSymbol === marketSymbolParam)
        },
        [makerMarkets],
    )
    const getDefaultMarket = useCallback<(marketSymbolParam: any) => DefaultMarket | undefined>(
        marketSymbolParam => {
            return listMarket?.find(({ marketSymbol }) => marketSymbol === marketSymbolParam)
        },
        [listMarket],
    )

    return {
        makerMarkets,
        restMarkets,
        getMakerMarket,
        getDefaultMarket,
        totalClaimableReward,
        isTotalClaimableRewardLoading,
        claimableRewardOp,
        isTotalClaimableRewardLoadingOp,
    }
}
