import { usePageView } from "module/analytics/hook/usePageView"

import { Portal } from "@chakra-ui/react"
import { ModalGeoBlocking } from "component/Modal/ModalGeoBlocking"
import { ModalGreeting } from "component/Modal/ModalGreeting"
import { ModalReferral } from "component/Modal/ModalReferral"
import { ModalRoot } from "component/Modal/ModalRoot"
import ScrollToTop from "component/ScrollToTop"
import { GeoBlocking } from "container/geoBlocking"
import { useMonitorProviderGraphServerConfig } from "hook/useMonitorProviderGraphServerConfig"
import useScrollToTop from "hook/useScrollToTop"
import { Layout } from "layout"
import { useMemo } from "react"

export const App = () => {
    const { isBlocking, countryCode } = GeoBlocking.useContainer()
    const pageTrackingProperty = useMemo(() => {
        return {
            countryCode,
        }
    }, [countryCode])
    usePageView(pageTrackingProperty)
    useScrollToTop()
    useMonitorProviderGraphServerConfig()

    return (
        <>
            {/*  NOTE: Add Portal to make sure ModalReferral is always behind ModalGreeting and ModalGeoBlocking */}
            <Portal>
                <ModalReferral />
            </Portal>
            <ModalGreeting />
            <ModalGeoBlocking isOpen={isBlocking} />
            <ScrollToTop />
            <Layout />
            <ModalRoot />
        </>
    )
}
