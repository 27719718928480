import SegmentService, { EventGroup, EventTypeInteraction } from "module/analytics/service/SegmentService"
import { TrackingProps } from "module/analytics/types"

import type { ComponentWithAs, MenuItemOptionProps, MenuItemProps } from "@chakra-ui/react"
import { MenuItem, MenuItemOption } from "@chakra-ui/react"
import { useCallback } from "react"

export { Menu, MenuButton, MenuDivider, MenuGroup, MenuList } from "@chakra-ui/react"

interface PerpMenuItemProps extends MenuItemProps {
    trackingProps: TrackingProps
}

export const PerpMenuItem: ComponentWithAs<"button", PerpMenuItemProps> = ({
    children,
    onClick,
    trackingProps,
    ...leftProps
}: PerpMenuItemProps) => {
    const handleOnClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        e => {
            onClick && onClick(e)
            SegmentService.track(EventGroup.INTERACTION, {
                eventType: EventTypeInteraction.MENU_ITEM_CLICKED,
                ...trackingProps,
                payload: { ...trackingProps.payload },
            })
        },
        [onClick, trackingProps],
    )
    return (
        <MenuItem bg={"transparent"} _hover={{ bg: "rgba(255,255,255,0.05)" }} onClick={handleOnClick} {...leftProps}>
            {children}
        </MenuItem>
    )
}

interface PerpMenuItemOptionProps extends MenuItemOptionProps {
    trackingProps: TrackingProps
}

export const PerpMenuItemOption: ComponentWithAs<"button", PerpMenuItemOptionProps> = ({
    children,
    onClick,
    trackingProps,
    ...leftProps
}: PerpMenuItemOptionProps) => {
    const handleOnClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        e => {
            onClick && onClick(e)
            SegmentService.track(EventGroup.INTERACTION, {
                eventType: EventTypeInteraction.MENU_ITEM_CLICKED,
                ...trackingProps,
                payload: { ...trackingProps.payload },
            })
        },
        [onClick, trackingProps],
    )
    return (
        <MenuItemOption onClick={handleOnClick} {...leftProps}>
            {children}
        </MenuItemOption>
    )
}
