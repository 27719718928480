import { getColor, mode, transparentize } from "@chakra-ui/theme-tools"

// custom component style

function variantSolid(props: Record<string, any>) {
    const { colorScheme: c, theme } = props
    const dark = transparentize(`${c}.400`, 0.9)(theme)
    return {
        bg: mode(`${c}.100`, dark)(props),
        color: mode(`white`, `whiteAlpha.800`)(props),
    }
}

function variantSubtle(props: Record<string, any>) {
    const { colorScheme: c, theme } = props
    const darkBg = transparentize(`${c}.200`, 0.16)(theme)
    return {
        bg: mode(`${c}.100`, darkBg)(props),
        color: mode(`${c}.800`, `${c}.200`)(props),
    }
}

function variantOutline(props: Record<string, any>) {
    const { colorScheme: c, theme } = props
    const darkColor = transparentize(`${c}.200`, 0.8)(theme)
    const lightColor = getColor(theme, `${c}.500`)
    const color = mode(lightColor, darkColor)(props)

    return {
        color,
        boxShadow: `inset 0 0 0px 1px ${color}`,
    }
}

function getDefaults(props: Record<string, any>) {
    const { focusBorderColor: fc, errorBorderColor: ec } = props
    return {
        focusBorderColor: fc || mode("blue.500", "blue.300")(props),
        errorBorderColor: ec || mode("red.500", "red.300")(props),
    }
}

function variantFlushedInline(props: Record<string, any>) {
    const { theme } = props
    const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props)

    return {
        field: {
            borderRadius: "2xl",
            border: "1px solid",
            borderColor: "inherit",
            bg: "transparent",
            _readOnly: {
                boxShadow: "none !important",
                userSelect: "all",
            },
            _invalid: {
                borderColor: getColor(theme, ec),
            },
            _focus: {
                borderColor: getColor(theme, fc),
            },
        },
    }
}

const components = {
    Modal: {
        // how to do color mode in theme https://youtu.be/1J8dUyeUWO4?t=2901
        baseStyle: (props: any) => ({
            header: {
                fontSize: "lg",
                fontWeight: "bold",
            },

            dialog: {
                bg: mode("white", "gray.800")(props),
                borderRadius: "3xl",
            },
            body: { pb: 5 },
            closeButton: {
                top: 3,
                insetEnd: 3,
                borderRadius: "full",
            },
            footer: {
                pb: 5,
                borderTopWidth: "1px",
                boxShadow: mode("0px -10px 39px -17px rgba(0,0,0,0.1)", "0px -10px 39px -17px rgba(0,0,0,0.4)")(props),
                boderTopColor: mode("blackAlpha.50", "whiteAlpha.50")(props),
            },
        }),
        // custom bottom sheet modal for mobile only
        variants: {
            // full content height
            fullBottomSheet: (props: any) => ({
                dialog: {
                    marginTop: 0,
                    marginBottom: 0,
                    bottom: 0,
                    borderRadius: 0,
                    position: "fixed",
                    maxHeight: "100%",
                    minHeight: "100%",
                    h: "100%",
                    top: 0,
                },
            }),
        },
    },
    Text: {
        variants: {
            label: {
                fontSize: ["xs", "xs"],
                fontWeight: "500",
                opacity: 0.85,
                textTransform: "capitalize",
            },

            subText: (props: any) => ({
                color: mode("gray.500", "gray.400")(props),
            }),
        },
    },
    Container: {
        baseStyle: {
            px: 6,
        },
    },
    Badge: {
        sizes: {
            md: {
                fontSize: "11px",
                px: 3,
                py: 1,
            },
        },
        baseStyle: {
            fontSize: "10px",
            px: 2,
            py: "3px",
            borderRadius: "2xl",
        },
        variants: {
            solid: variantSolid,
            subtle: variantSubtle,
            outline: variantOutline,
        },
    },
    Input: {
        baseStyle: {
            field: {
                fontVariantNumeric: "slashed-zero",
            },
        },
        sizes: {
            lg: {
                field: {
                    borderRadius: "2xl",
                },
            },

            md: {
                field: {
                    borderRadius: "2xl",
                },
            },

            sm: {
                field: {
                    borderRadius: "2xl",
                },
            },

            xs: {
                field: {
                    borderRadius: "2xl",
                },
            },
            inline: {
                field: {
                    // looks like a md button
                    lineHeight: "40px",
                    fontWeight: "semibold",
                    h: "40px",
                    px: 4,
                    py: 0,
                    fontSize: "md",
                    borderRadius: "2xl",
                },
            },
        },
        variants: {
            inline: variantFlushedInline,
        },
    },
    NumberInput: {
        sizes: {
            lg: {
                field: {
                    borderRadius: "2xl",
                },
            },

            md: {
                field: {
                    borderRadius: "2xl",
                },
            },

            sm: {
                field: {
                    borderRadius: "2xl",
                },
            },

            xs: {
                field: {
                    borderRadius: "2xl",
                },
            },
        },
        variants: {
            // filled: {
            //     field: {
            //         background: "red"
            //     }
            // }
        },
    },
    Alert: {
        baseStyle: {
            container: {
                borderRadius: "lg",
                fontSize: "sm",
            },
        },
    },
    InputGroup: {
        sizes: {
            lg: {
                field: {
                    borderRadius: "2xl",
                },
                addon: {
                    borderRadius: "2xl",
                },
            },

            md: {
                field: {
                    borderRadius: "2xl",
                },
                addon: {
                    borderRadius: "2xl",
                },
            },

            sm: {
                field: {
                    borderRadius: "2xl",
                },
                addon: {
                    borderRadius: "2xl",
                },
            },

            xs: {
                field: {
                    borderRadius: "2xl",
                },
                addon: {
                    borderRadius: "2xl",
                },
            },
        },
    },
    Menu: {
        baseStyle: (props: any) => ({
            groupTitle: {
                fontSize: "xs",
                fontWeight: "bold",
                textTransform: "uppercase",
                opacity: 0.55,
                mx: 3,
            },
            item: {
                fontSize: ["md", "sm"],
                // fontWeight: "semibold",
                py: "0.75rem",
            },
            list: {
                borderRadius: "2xl",
                bg: mode("cardBg.light", "cardBg.dark")(props),
            },
        }),
    },
    Avatar: {
        baseStyle: (props: any) => ({
            excessLabel: {
                fontSize: "10px",
                fontWeight: "semibold",
            },
        }),
    },

    Button: {
        sizes: {
            lg: {
                borderRadius: "2xl",
                fontSize: "lg",
            },
            md: {
                borderRadius: "2xl",
                fontSize: "md",
                minWidth: "44px",
                minHeight: "44px",
            },
            sm: {
                borderRadius: "2xl",
                fontSize: "sm",
            },
            xs: {
                borderRadius: "2xl",
                fontSize: "xs",
            },
            inline: {
                // looks like a md button
                display: "inline-flex",
                lineHeight: "40px",
                h: "40px",
                px: 4,
                py: 0,
                fontSize: "md",
                borderRadius: "2xl",
            },
            inlineFlushed: {
                // looks like a lg input
                display: "inline-flex",
                lineHeight: "48px",
                h: "48px",
                py: 0,
                pr: "0.5ch",
                fontSize: "lg",
                borderBottomWidth: "1px",
                borderRadius: 0,
            },
        },
        variants: {
            long: (props: any) => ({
                color: "white",
                bg: mode(transparentize("green.300", 0.2), transparentize("green.300", 0.2))(props),
            }),
            short: (props: any) => ({
                color: "white",
                bg: mode(transparentize("red.300", 0.2), transparentize("red.300", 0.2))(props),
            }),
            filled: (props: any) => ({
                bg: mode("gray.100", "whiteAlpha.50")(props),
            }),
            inline: (props: any) => ({
                borderBottomColor: mode("gray.200", "gray.700")(props),
            }),
            inlineLong: (props: any) => ({
                color: mode("green.600", "green.400")(props),
                borderBottomColor: mode(transparentize("green.600", 0.5), transparentize("green.600", 0.5))(props),
            }),
            inlineShort: (props: any) => ({
                color: mode("red.600", "red.400")(props),
                borderBottomColor: mode(transparentize("red.600", 0.5), transparentize("red.600", 0.5))(props),
            }),
            tabActive: (props: any) => ({
                bg: mode("whiteAlpha.900", "whiteAlpha.400")(props),
            }),
        },
    },
    Heading: {
        sizes: {
            "4xl": {
                fontWeight: 800,
            },
            "3xl": {
                fontWeight: 800,
            },
            "2xl": {
                fontWeight: 800,
            },
            xl: {
                fontWeight: 800,
            },

            lg: {
                letterSpacing: "tight",
            },
            md: {
                letterSpacing: "tight",
            },
            sm: {
                letterSpacing: "tighter",
            },
            xs: {
                letterSpacing: "tighter",
            },
        },
    },

    Table: {
        baseStyle: {
            table: {
                fontFamily: "body",
                fontVariantNumeric: "lining-nums tabular-nums slashed-zero",
            },
        },
        sizes: {
            sm: {
                th: {
                    fontSize: "11px",
                },
                td: {
                    px: "4",
                    py: "4",
                    lineHeight: "4",
                    fontSize: "xs",
                },
            },
            md: {
                th: {
                    fontSize: "11px",
                },
            },
        },
    },
    Stats: {
        parts: ["container", "label", "number"],
        baseStyle: {
            container: {
                display: "flex",
            },
            label: {
                fontSize: "xs",
                textTransform: "uppercase",
                fontWeight: "semibold",
                // lineHeight: "20px"
            },
            number: {
                fontWeight: "bold",
                lineHeight: 1.6,
            },
            helper: {
                fontSize: "xs",
                // lineHeight: "18px",
            },
        },
        sizes: {
            xs: {
                number: {
                    fontSize: "xs",
                },
            },
            sm: {
                number: {
                    fontSize: "sm",
                },
            },
            md: {
                number: {
                    fontSize: "md",
                    lineHeight: 1.4,
                },
            },
            lg: {
                number: {
                    fontSize: "lg",
                    lineHeight: 1.4,
                },
            },
            xl: {
                number: {
                    fontSize: "xl",
                    lineHeight: 1.4,
                },
            },
            "2xl": {
                number: {
                    fontSize: "2xl",
                    lineHeight: 1.3,
                },
            },
            "3xl": {
                number: {
                    fontSize: "3xl",
                    lineHeight: 1.2,
                },
            },
            "5xl": {
                number: {
                    fontSize: "5xl",
                    lineHeight: 1.1,
                },
            },
        },
        variants: {
            top: {
                container: {
                    flexDirection: "column",
                },
            },
            bottom: {
                container: {
                    flexDirection: "column-reverse",
                },
            },
            left: {
                container: {
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                },
                helper: {
                    display: "none",
                },
            },
            tile: {
                container: {
                    flexDirection: "column",
                    flexShrink: 0,
                },
                number: {
                    my: 1,
                },
            },
        },
        defaultProps: {
            size: "md",
            variant: "top",
        },
    },
}

export default components
