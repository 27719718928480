import { AppConfig, IAppConfigExtended } from "container/appConfig"
import { useMemo } from "react"
import { usePrevious } from "react-use"

function checkAppConfigUpdated(appConfig: IAppConfigExtended, previousAppConfig: IAppConfigExtended) {
    const { providerConfigs, graphServerConfigs } = appConfig
    const { providerConfigs: previousProviderConfigs, graphServerConfigs: previousGraphServerConfigs } =
        previousAppConfig
    let isProviderUpdated = false
    let isSubgraphUpdated = false
    if (providerConfigs.length > 0 && previousProviderConfigs.length > 0) {
        isProviderUpdated = providerConfigs[0].rpcUrl !== previousProviderConfigs[0].rpcUrl
    }

    if (graphServerConfigs.length > 0 && previousGraphServerConfigs.length > 0) {
        isSubgraphUpdated = graphServerConfigs[0].healthUrl !== previousGraphServerConfigs[0].healthUrl
    }

    return isProviderUpdated || isSubgraphUpdated
}

/**
 * A hook that returns a value that indicates whether the app config has been updated.
 * @returns {Object} An object with the following properties:
 * - isAppConfigUpdated: A boolean value that indicates whether the app config has been updated.
 */

export function useMonitorProviderGraphServerConfig() {
    const { appConfig } = AppConfig.useContainer()

    const previousAppConfig = usePrevious<IAppConfigExtended | undefined>(appConfig)

    const isAppConfigUpdated = useMemo(() => {
        if (!previousAppConfig || !appConfig) {
            return false
        }
        return checkAppConfigUpdated(appConfig, previousAppConfig)
    }, [appConfig, previousAppConfig])

    return {
        isAppConfigUpdated,
    }
}
