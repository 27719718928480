import { Positions } from "sdk-react/clearingHouse/usePositions"

export function useAccountBalance() {
    const { accountLeverage, accountMarginRatio } = Positions.useContainer()

    return {
        accountLeverage,
        accountMarginRatio,
    }
}
