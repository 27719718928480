import { MarketMap } from "@perp/sdk-curie"
import _ from "lodash"

import { PoolMetadataMap } from "../../markets/MarketsContainer"

export const getMarketMapByBaseAddress = ({ marketMap }: { marketMap: MarketMap }) =>
    _.mapKeys(marketMap, ({ baseAddress }) => baseAddress)

export const getMarketMapByRawBaseAddress = ({
    marketMap,
    poolMetadataMap,
}: {
    marketMap: MarketMap
    poolMetadataMap: PoolMetadataMap
}) => _.mapKeys(marketMap, (market, tickerSymbol) => poolMetadataMap[tickerSymbol]?.baseAddress)
