import { SwitchNetworkForMeBanner } from "module/network/component/SwitchNetworkForMeBanner"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"

import { RouteName } from "constant/route"
import { Route, Switch } from "react-router-dom"

import { Home } from "./Home"
import { NavStack } from "./Stack"

export function MobileHeader() {
    const { account } = Web3WalletContainer.useContainer()

    return (
        <>
            <SwitchNetworkForMeBanner />
            <Switch>
                <Route path={RouteName.MARKET_LIST} exact render={() => <NavStack hideBackButton />} />
                <Route path={RouteName.POOL_LIST} exact render={() => <NavStack hideBackButton />} />

                <Route path={RouteName.HISTORY} exact render={() => <NavStack hideBackButton />} />
                <Route path={RouteName.ROOT} exact component={account ? Home : undefined} />
            </Switch>
        </>
    )
}
