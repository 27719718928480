import { Image, ImageProps } from "@chakra-ui/react"

// Get icons from https://cryptologos.cc

interface MarketListIconProps extends ImageProps {
    coin?: string
    size?: string | number
}

/**
 * Render coin icons
 *
 * @param {MarketListIconProps} { coin, size, ...props }
 * - coin like "btc"
 * - size as in boxSize like 16 or "32px"
 * @return {*}
 */
export function MarketListIcon({ coin, size, ...props }: MarketListIconProps) {
    const imagePath = `/assets/coins/${coin?.toLowerCase()}.svg`
    return (
        <Image
            boxSize={size}
            flexShrink={0}
            borderRadius="full"
            src={imagePath}
            fallbackSrc="/assets/coins/default.svg"
            {...props}
        />
    )
}
