import { Big } from "big.js"
// NOTE: precision
export const USD_PRECISION = 2
export const BASE_ASSET_GENERAL_PRECISION = 4
export const BASE_ASSET_ADVANCED_PRECISION = 7
export const PRICE_IMPACT_PERCENTAGE_PRECISION = 4
export const PRICE_CHANGE_RATE_PRECISION = 2
export const FUNDING_RATE_PRECISION = 4
export const FUNDING_PAYMENT_PRECISION = 1
export const APR_PRECISION = 1
export const BIG_ZERO = new Big(0)

// NOTE: UI default value
export const DEFAULT_SLIPPAGE_PERCENTAGE = "0.5"
export const AMOUNT_PLACEHOLDER = "-,---"
