import {
    Position,
    PositionDataAll,
    PositionDataAllByMarket as PositionDataAllByMarketSDK,
    PositionSide,
} from "@perp/sdk-curie"
import Big from "big.js"
import segmentService from "module/analytics/service/SegmentService"
import { AppNetwork } from "module/network/container/AppNetwork"
import { OpenPositionService } from "module/otcMaker/OpenPositionService"
import { OtcMakerClient } from "module/otcMaker/OtcMakerClient"
import { useState } from "react"
import { usePerpConnectedEffect } from "sdk-react/perpetualProtocol/usePerpConnectedEffect"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

export const Positions = createContainer(usePositionsViaContract)

type PositionDataAllByMarket = PositionDataAllByMarketSDK & {
    [key: string]: {
        isTakerUsingOtcExitPrice: boolean
    }
}

interface IState {
    takerPositions: Position[]
    makerPositions: Position[]
    totalTakerPositionValue?: Big
    totalMakerPositionValue?: Big
    totalAbsPositionValue?: Big
    totalUnrealizedPnl?: Big
    totalTakerUnrealizedPnl?: Big
    totalMakerUnrealizedPnl?: Big
    totalPendingFundingPayments?: Record<string, Big>
    accountLeverage?: Big
    accountMarginRatio?: Big
    positionDataAllByMarket?: PositionDataAllByMarket
}

const initialState: IState = {
    takerPositions: [],
    makerPositions: [],
}

export function usePositionsViaContract() {
    // NOTE: why we wrap all variable into one state? because react 16 won't batch setState to
    // re-render in promise, it will re-render each setState instead.
    // https://stackoverflow.com/questions/53048495/does-react-batch-state-update-functions-when-using-hooks/53048903#53048903
    const [
        {
            totalTakerPositionValue,
            totalMakerPositionValue,
            totalAbsPositionValue,
            totalUnrealizedPnl,
            takerPositions,
            makerPositions,
            totalTakerUnrealizedPnl,
            totalMakerUnrealizedPnl,
            totalPendingFundingPayments,
            accountLeverage,
            accountMarginRatio,
            positionDataAllByMarket,
        },
        setState,
    ] = useState<IState>(initialState)
    const [isLoading, setIsLoading] = useState(true)
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()

    const { appChainId } = AppNetwork.useContainer()
    usePerpConnectedEffect(({ positions }) => {
        const unsubscribeUpdated = positions.on(
            "updated",
            async ({
                positionDataAllByMarket: positionDataAllByMarketSDK,
                positionDataAllCrossMarket,
            }: PositionDataAll) => {
                const takerPositions: Position[] = []
                const makerPositions: Position[] = []
                const totalPendingFundingPayments: Record<string, Big> = {}
                Object.entries(positionDataAllByMarketSDK).forEach(([tickerSymbol, posData]) => {
                    if (posData.takerPosition) {
                        takerPositions.push(posData.takerPosition)
                    }
                    if (posData.makerPosition) {
                        makerPositions.push(posData.makerPosition)
                    }
                    totalPendingFundingPayments[`${tickerSymbol}`] = posData.pendingFundingPayment
                })

                // NOTE: we only update takerPosExitPrice and takerPosExitPriceImpact by otc maker
                // other column in positionDataAllByMarket is based on core exit price
                const positionDataAllByMarket: PositionDataAllByMarket = {}
                await Promise.all(
                    Object.entries(positionDataAllByMarketSDK).map(async ([tickerSymbol, posData]) => {
                        positionDataAllByMarket[tickerSymbol] = {
                            ...posData,
                            isTakerUsingOtcExitPrice: false,
                        }
                        const takerPosition = posData.takerPosition
                        if (takerPosition && posData.takerPosExitPrice) {
                            const {
                                isUsingOtcExitPrice,
                                exitPrice: takerPosExitPrice,
                                priceImpact: takerPosExitPriceImpact,
                            } = await fetchExitPrice(
                                appChainId,
                                takerPosition,
                                posData.takerPosExitPrice,
                                posData.marketPrice,
                            )
                            positionDataAllByMarket[tickerSymbol].takerPosExitPrice = takerPosExitPrice
                            positionDataAllByMarket[tickerSymbol].takerPosExitPriceImpact = takerPosExitPriceImpact
                            positionDataAllByMarket[tickerSymbol].isTakerUsingOtcExitPrice = isUsingOtcExitPrice
                        }
                    }),
                )

                setState({
                    takerPositions,
                    makerPositions,
                    totalPendingFundingPayments,
                    totalTakerPositionValue: positionDataAllCrossMarket.totalTakerPositionValue,
                    totalMakerPositionValue: positionDataAllCrossMarket.totalMakerPositionValue,
                    totalUnrealizedPnl: positionDataAllCrossMarket.totalUnrealizedPnl,
                    totalTakerUnrealizedPnl: positionDataAllCrossMarket.totalTakerUnrealizedPnl,
                    totalMakerUnrealizedPnl: positionDataAllCrossMarket.totalMakerUnrealizedPnl,
                    totalAbsPositionValue: positionDataAllCrossMarket.accountPosValueAbs,
                    accountMarginRatio: positionDataAllCrossMarket.accountMarginRatio,
                    accountLeverage: positionDataAllCrossMarket.accountLeverage,
                    positionDataAllByMarket,
                })
                if (isLoading) {
                    setIsLoading(false)
                }
            },
        )
        const unsubscribeError = positions.on("updateError", ({ error }) => {
            onError(error)
        })
        return () => {
            unsubscribeUpdated()
            unsubscribeError()
        }
    }, [])

    return {
        isLoading,
        takerPositions,
        makerPositions,
        totalTakerPositionValue,
        totalMakerPositionValue,
        totalTakerUnrealizedPnl,
        totalMakerUnrealizedPnl,
        totalAbsPositionValue,
        totalUnrealizedPnl,
        totalPendingFundingPayments,
        accountMarginRatio,
        accountLeverage,
        positionDataAllByMarket,
    }
}

async function fetchOtcPrice(chainId: number, position: Position, isLong: boolean) {
    const otcMakerClient = new OtcMakerClient()
    const segmentUserId = segmentService.getUserId()
    const timeoutMs = 5000
    return otcMakerClient.quoteWithTimeout(
        chainId,
        position.market.baseAddress,
        isLong,
        position.sizeAbs,
        true,
        segmentUserId,
        timeoutMs,
    )
}

async function fetchExitPrice(chainId: number, position: Position, coreExitPrice: Big, marketPrice: Big) {
    let exitPrice = coreExitPrice
    let isUsingOtcExitPrice = false
    try {
        const isExitSideLong = !(position.side === PositionSide.LONG)
        const otcPriceResult = await fetchOtcPrice(chainId, position, isExitSideLong)
        if (otcPriceResult.isSuccess && otcPriceResult.quotePrice) {
            const otcExitPrice = Big(otcPriceResult.quotePrice)
            const openPositionService = new OpenPositionService()
            const isCoreExitPriceBetter = openPositionService.isCorePriceBetter(
                isExitSideLong,
                otcExitPrice,
                coreExitPrice,
            )
            if (!isCoreExitPriceBetter) {
                isUsingOtcExitPrice = true
                exitPrice = otcExitPrice
            }
        }
    } catch (err: any) {}
    return { isUsingOtcExitPrice, exitPrice, priceImpact: exitPrice.div(marketPrice).sub(1) }
}
