import { getTickerSymbol, getVirtualTickerSymbol } from "@perp/sdk-curie"
import { useParams } from "react-router-dom"

export function useBaseAssetSymbolFromRouterParams() {
    const { base } = useParams<{ base: string; quote: string }>()
    return base?.toUpperCase()
}

export function useQuoteAssetSymbolFromRouterParams() {
    const { quote } = useParams<{ base: string; quote: string }>()
    return quote?.toUpperCase()
}

export function useTickerSymbolFromRouterParams() {
    const { base, quote } = useParams<{ base: string; quote: string }>()
    return getTickerSymbol(base?.toUpperCase(), quote?.toUpperCase())
}

export function useVirtualTickerSymbolFromRouterParams() {
    const { base, quote } = useParams<{ base: string; quote: string }>()
    return getVirtualTickerSymbol(base?.toUpperCase() || "", quote?.toUpperCase() || "")
}

// NOTE: modal is out of react-router, so use original location object
export function useVirtualTickerSymbolFromLocationObject() {
    const [base, quote] = window.location.pathname?.split("/")[2]?.split(":")
    return getVirtualTickerSymbol(base?.toUpperCase() || "", quote?.toUpperCase() || "")
}
