import { Metadata as MetadataClass } from "@perp/sdk-curie"
import { useState } from "react"
import { usePerpInitializedEffect } from "sdk-react/perpetualProtocol/usePerpInitializedEffect"
import { createContainer } from "unstated-next"

export const Metadata = createContainer(useMetadata)

function useMetadata() {
    const [metadata, setMetadata] = useState<MetadataClass>()

    usePerpInitializedEffect(({ metadata }) => {
        setMetadata(metadata)
    }, [])

    return {
        metadata,
    }
}
