import { Position, PositionType } from "@perp/sdk-curie"
import { Big } from "big.js"
import { useMemo, useState } from "react"
import { useMarket } from "sdk-react/markets/useMarket"
import { usePerpInitializedEffect } from "sdk-react/perpetualProtocol/usePerpInitializedEffect"
import { Positions } from "./usePositions"

export function usePositionDetail(position: Position) {
    const isTaker = position.type === PositionType.TAKER
    const tickerSymbol = position.market.tickerSymbol
    const liquidationPrice = position.liquidationPrice

    const { accountMarginRatio, accountLeverage, positionDataAllByMarket } = Positions.useContainer()
    const { markPrice, indexTwapPrice } = useMarket(tickerSymbol)

    const posData = positionDataAllByMarket && positionDataAllByMarket[`${tickerSymbol}`]
    const unrealizedPnl = posData && (isTaker ? posData.takerPosUnrealizedPnl : posData.makerPosUnrealizedPnl)
    const exitPrice = posData && (isTaker ? posData.takerPosExitPrice : posData.makerPosExitPrice)
    const priceImpact = posData && (isTaker ? posData.takerPosExitPriceImpact : posData.makerPosExitPriceImpact)
    const isUsingOtcExitPrice = posData && (isTaker ? posData.isTakerUsingOtcExitPrice : false)
    const fee = posData && (isTaker ? posData.takerPosExitTxFee : posData.makerPosExitTxFee)

    // minium margin ratio
    const [mmRatio, setMMratio] = useState<Big>()
    usePerpInitializedEffect(({ clearingHouseConfig }) => {
        setMMratio(clearingHouseConfig.mmRatio)
    }, [])

    // notional value
    const notionalValue = useMemo(() => {
        if (!markPrice || markPrice.eq(0)) {
            return
        }
        return markPrice.mul(position.sizeAbs)
    }, [position, markPrice])

    return {
        unrealizedPnl,
        liquidationPrice,
        indexTwapPrice,
        exitPrice,
        fee,
        mmRatio,
        priceImpact,
        marginRatio: accountMarginRatio,
        leverage: accountLeverage,
        notionalValue,
        isUsingOtcExitPrice,
    }
}
