import { useNotification } from "module/notification/hook/useNotification"
import { formatNumberPositionSize, formatNumberUSD } from "util/format"
import { formatTimeStamp } from "util/time"

import { Link } from "@chakra-ui/react"
import { BIG_ZERO } from "@perp/sdk-curie"
import { RouteName } from "constant/route"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"

import { LimitOrderHistory } from "./LimitOrderHistory"

export const useLimitOrderFilledNotification = () => {
    const { notifySuccess } = useNotification()
    const history = useHistory()

    const notifyOrderFilled = useCallback(
        ({ baseSymbol, side, size, filledPrice, timestamp }: LimitOrderHistory) => {
            const sizeStr = formatNumberPositionSize(size)
            const priceStr = formatNumberUSD({ num: filledPrice || BIG_ZERO })
            const timeStr = formatTimeStamp(timestamp)

            notifySuccess({
                title: `Order Filled`,
                duration: 10000,
                description: (
                    <Link
                        color="gray.800"
                        onClick={() => {
                            history.push(RouteName.HISTORY_LIMIT_ORDER)
                        }}
                    >
                        {`Your ${side} order for ${sizeStr} ${baseSymbol} at $${priceStr} was filled on ${timeStr}.`}
                    </Link>
                ),
            })
        },
        [history, notifySuccess],
    )

    return {
        notifyOrderFilled,
    }
}
