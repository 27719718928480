// TODO: deprecate this file

import SegmentService, { EventGroup } from "module/analytics/service/SegmentService"
import { EventName } from "module/analytics/types"

import { PositionDraft } from "@perp/sdk-curie"

enum ActionType {
    BUTTON_CLICK = "button_click",
    LAND_PAGE = "land_page",
}

function uiTrack(eventName: string, payload: { [key: string]: string | number }) {
    SegmentService.track(EventGroup.INTERACTION, { ...payload, eventName })
}

export function trackLocationRestrictDialog() {
    uiTrack("Location Restriction Dialog - User Geo-Blocked", {
        path: "/",
        component: "LocationRestrictionDialog",
    })
}

// account dialog
export function trackShowedAccountDialog(sourceComponent: "Avatar" | "AccountCard" | "ButtonOnAccountCard") {
    uiTrack("Account Dialog - Show Account Dialog", {
        component: sourceComponent,
    })
}

// deposit dialog
export function trackClickedMaxInDepositDialog() {
    uiTrack("Deposit Dialog - Max Btn Clicked", {
        component: "ModalDeposit",
    })
}

export function trackClickedDepositInDepositDialog(amount: string) {
    uiTrack("Deposit Dialog - Deposit Btn Clicked", {
        component: "ModalDeposit",
        amount,
    })
}

export function trackClickedApproveInDepositDialog() {
    uiTrack("Deposit Dialog - Approve Btn Clicked", {
        component: "ModalDeposit",
    })
}

// withdrawal dialog
export function trackClickedWithdrawInWithdrawalDialog(amount: string) {
    uiTrack("Withdrawal Dialog - Withdraw Btn Clicked", {
        component: "ModalWithdraw",
        amount,
    })
}

export function trackClickedWithdrawAllInWithdrawalDialog() {
    uiTrack("Withdrawal Dialog - Withdraw All Btn Clicked", {
        component: "ModalWithdraw",
    })
}

// slippage option
export function trackToggledSlippageOptionWhenOpeningPosition(changeToOpen: boolean) {
    uiTrack("Slippage Option - PositionOpenPanel - Toggled", {
        component: "PositionOpenPanel",
        changeToOpen: `${changeToOpen}`,
    })
}

export function trackSetSlippageWhenOpeningPosition(value: number) {
    uiTrack("Slippage Option - PositionOpenPanel - Set Slippage Ratio", {
        component: "PositionOpenPanel",
        slippageRatio: value,
    })
}

export function trackToggledSlippageOptionWhenClosingPosition(changeToOpen: boolean) {
    uiTrack("Slippage Option - ModalPositionClose - Toggled", {
        component: "ModalPositionClose",
        changeToOpen: `${changeToOpen}`,
    })
}

export function trackSetSlippageWhenClosingPosition(value: number) {
    uiTrack("Slippage Option - ModalPositionClose - Set Slippage Ratio", {
        component: "ModalPositionClose",
        slippageRatio: value,
    })
}

// close position dialog
export function trackShowedClosePositionDialog() {
    uiTrack("Close Position Dialog - Show Close Position Dialog", {
        component: "ModalPositionClose",
    })
}

export function trackClosePositionButton(slippage: string) {
    uiTrack("Close Position Dialog - Close Btn Clicked", {
        component: "ModalPositionClose",
        slippage,
    })
}

// PositionOpenPanel
export function trackOpenPositionButtonClicked({
    slippage,
    positionDraft,
    buyingPowerRatio,
    isUsingOtcEntryPrice,
}: {
    slippage: string
    positionDraft: PositionDraft
    buyingPowerRatio: number
    isUsingOtcEntryPrice: boolean
}) {
    const payload = {
        component: "PositionOpenPanel",
        symbol: positionDraft.market.tickerSymbol,
        slippage,
        side: positionDraft.side,
        isExactInput: `${positionDraft.isExactInput}`,
        isBaseToQuote: `${positionDraft.isBaseToQuote}`,
        amountInput: positionDraft.amountInput.toString(),
        buyingPowerRatio,
    }
    if (isUsingOtcEntryPrice) {
        uiTrack(EventName.OTC_ORDER_SUBMIT, payload)
    } else {
        uiTrack("PositionOpenPanel - Open Position Btn Clicked", payload)
    }
}

// liquidity pool
export function trackLiquidityRemoveButtonClicked(ratio: string, slippage: string) {
    uiTrack("Liquidity Pool - Remove Btn Clicked", {
        component: "LiquidityPool",
        ratio,
        slippage,
    })
}

export function trackLiquidityCollectFeeButtonClicked() {
    uiTrack("Liquidity Pool - Collect Fee Btn Clicked", {
        component: "LiquidityPool",
    })
}

export function trackOpenConnectWalletModal() {
    uiTrack("Connect Button - Open Connect Wallet Modal", {
        component: "ConnectWalletButton",
    })
}

export function trackConnectWallet(wallet: string) {
    uiTrack("Connect Wallet Modal - Connect Wallet Button Clicked", {
        component: "WalletButton",
        wallet,
    })
}

export function trackSwitchForMeButton() {
    uiTrack("Switch For Me Button -Switch For Me Button Clicked", {
        component: "SwitchForMeButton",
    })
}

export function trackSwitchNetworkToContinue() {
    uiTrack("OnBoarding - Switch Network To Continue Button Clicked", {
        component: "SwitchForMeButton",
    })
}

export function trackTransferAndDeposit() {
    uiTrack("OnBoarding - Transfer And Deposit Button Clicked", {
        component: "OnBoarding",
    })
}
export function trackTransferUSDC() {
    uiTrack("OnBoarding - Transfer USDC Button Clicked", {
        component: "OnBoarding",
    })
}

export function trackTransferETH() {
    uiTrack("OnBoarding - Transfer ETH Button Clicked", {
        component: "OnBoarding",
    })
}

export function trackClickApproveAndDepositButton() {
    uiTrack("OnBoarding - Approve And Deposit button clicked", {
        component: "OnBoarding",
    })
}

export function trackActivateReferralCode(referralCode: string) {
    uiTrack(EventGroup.INTERACTION, {
        actionType: ActionType.BUTTON_CLICK,
        event: "activate_referral_code_successful",
        module: "referral",
        component: "ModalReferral",

        referralCode,
    })
}
export function trackFailToActivateReferralCode(referralCode: string) {
    uiTrack(EventGroup.INTERACTION, {
        actionType: ActionType.BUTTON_CLICK,
        event: "activate_referral_code_unsuccessful",
        module: "referral",
        component: "ModalReferral",

        referralCode,
    })
}

export function trackCloseModalReferralWithoutActivate() {
    uiTrack(EventGroup.INTERACTION, {
        actionType: ActionType.BUTTON_CLICK,
        event: "close_modal_referral_without_activiating_referral_code",
        module: "referral",
        component: "ModalReferral",
    })
}

export function trackLandSiteWithReferralCode() {
    uiTrack(EventGroup.INTERACTION, {
        actionType: ActionType.LAND_PAGE,
        event: "land_on_site_with_referral_code",
        module: "referral",
        component: "ModalReferral",
    })
}

export function trackSwitchNetworkOnModalReferral() {
    uiTrack(EventGroup.INTERACTION, {
        actionType: ActionType.BUTTON_CLICK,
        event: "switch_network_to_supported_chain",
        module: "referral",
        component: "ModalReferral",
    })
}

export function trackConnectWalletAndSwitchNetworkOnModalRefrral() {
    uiTrack(EventGroup.INTERACTION, {
        actionType: ActionType.BUTTON_CLICK,
        event: "connect_wallet_and_switch_network_to_supported_chain",
        module: "referral",
        component: "ModalReferral",
    })
}
