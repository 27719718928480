import { Box, Button } from "@chakra-ui/react"
import { Connector } from "@web3-react/types"
import { Checkmark, CoinbaseWallet, ImToken, Metamask, WalletConnect } from "component/Icon"
import { ModalType } from "component/Modal/type"
import { useModal } from "container/modal"
import { DEFAULT_APP_CHAIN_ID } from "module/network/constant"
import { switchNetwork } from "module/network/util/switchNetwork"
import { useCallback } from "react"
import { trackConnectWallet } from "service/segment/tracks"
import { WalletType } from "../../constant"
import { Web3WalletContainer } from "../../container/Web3WalletContainer"

const LeftIconMap = {
    [WalletType.METAMASK]: <Metamask boxSize="24px" />,
    [WalletType.WALLET_CONNECT]: <WalletConnect boxSize="24px" />,
    [WalletType.IM_TOKEN]: <ImToken boxSize="24px" borderRadius="full" />,
    [WalletType.WALLETLINK_CONNECT]: <CoinbaseWallet boxSize="24px" borderRadius="full" />,
}

export function WalletButton({ type, name, connector }: { type: WalletType; name: string; connector: Connector }) {
    const { close: closeModal } = useModal(ModalType.WALLET_CONNECTION)

    const {
        connectorId,
        actions: { login },
    } = Web3WalletContainer.useContainer()
    const isActiveConnector = type === connectorId

    const handleOnClick = useCallback(async () => {
        closeModal()
        if (!isActiveConnector) {
            trackConnectWallet(type)
            await login(connector, type)
            switchNetwork(DEFAULT_APP_CHAIN_ID)
        }
    }, [connector, login, closeModal, type, isActiveConnector])

    return (
        <Button
            iconSpacing={4}
            width="full"
            leftIcon={LeftIconMap[type]}
            rightIcon={<Checkmark boxSize="20px" opacity={isActiveConnector ? 1 : 0} />}
            onClick={handleOnClick}
        >
            <Box flexGrow={1} textAlign="left">
                {name}
            </Box>
        </Button>
    )
}
