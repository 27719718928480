import { Box, useColorModeValue } from "@chakra-ui/react"
import { PageLoading } from "component/PageLoading"
import { TopAppBanner } from "component/TopBanner"
import { RouteName } from "constant/route"
import { FeatureToggleContainer } from "container/service/FeatureToggleContainer"
import { useIsMd } from "hook/useBreakpoints"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"
import { Redirect, Route, Switch } from "react-router-dom"
import { DesktopSidebar } from "./DesktopSidebar"
import {
    MarketList,
    PageHistory,
    PageHome,
    PageMarketDetail,
    PageOrders,
    PagePoolDetail,
    PagePositions,
    PageWelcome,
    PoolList,
} from "./Loadable"
import { MobileHeader } from "./MobileHeader"
import { MobileMenu } from "./MobileMenu"

export function Layout() {
    const { isLoading: isLoadingAccount, account } = Web3WalletContainer.useContainer()
    const { isLoading: isLoadingFeatureToggle } = FeatureToggleContainer.useContainer()
    const isAppLoading = isLoadingAccount || isLoadingFeatureToggle

    const subBackground = useColorModeValue("subBackground.light", "subBackground.dark")
    const isMd = useIsMd()

    if (isAppLoading) {
        return <PageLoading />
    } else
        return (
            <Box width="100%" minH="100vh" bg={subBackground}>
                {isMd ? (
                    <>
                        <TopAppBanner />
                        <MobileHeader />
                        <MobileMenu />
                    </>
                ) : (
                    <DesktopSidebar />
                )}
                <Box pl={{ base: "0", md: "280px" }} minH="100vh">
                    {!isMd && <TopAppBanner />}
                    <Switch>
                        <Route exact path={RouteName.ROOT} component={account ? PageHome : PageWelcome} />
                        <Route exact path={RouteName.ORDERS} component={PageOrders} />
                        <Route exact path={RouteName.POSITIONS} component={PagePositions} />

                        <Route exact path={RouteName.MARKET_LIST} component={MarketList} />
                        <Route exact path={RouteName.MARKET_DETAIL} component={PageMarketDetail} />

                        <Route exact path={RouteName.POOL_LIST} component={PoolList} />
                        <Route exact path={RouteName.POOL_DETAIL} component={PagePoolDetail} />

                        <Route exact path={RouteName.HISTORY} component={PageHistory} />
                        <Redirect from={RouteName.HISTORY_ROOT} to={RouteName.HISTORY_TRADE} />

                        <Redirect to={RouteName.ROOT} />
                    </Switch>
                </Box>
            </Box>
        )
}
