import { Box, Text, useBreakpointValue } from "@chakra-ui/react"
import { PerpLink } from "component/PerpLink"
import { PerpModal } from "component/PerpModal"
import { EventName } from "module/analytics/types"
import { useEffect, useState } from "react"
import { useKonami } from "react-konami-code"
import { CMSQueryVaultsOnGeoBlock } from "service/airtable"
import "swiper/scss"
import "swiper/scss/navigation"
import "swiper/scss/pagination"
import "./style/swiper-override.css"

const DESC = {
    TITLE: "Service Not Available in Your Region",
    CONTEXT: `Sorry! For compliance reasons, this service is not accessible in your area. Use of VPN, Tor, proxies or other means to circumvent this restriction is a violation
    of our `,
    TERMS_OF_SERVICE: "Terms of Service.",
    URL: "https://support.perp.com/hc/en-us/articles/7529352712729-Terms-of-Service",
    VAULTS: "Third-party Vaults",
}

interface ModalGeoBlockingProps {
    isOpen: boolean
}
interface ThumbnailsProps {
    filename: string
    height: number
    id: string
    size: number
    type: string
    url: string
    width: number
    thumbnails: any
}
interface VaultGeoBlockingProps {
    Name: string
    Description: string
    URL: string
    Thumbnail: ThumbnailsProps[]
}

export function ModalGeoBlocking({ isOpen }: ModalGeoBlockingProps) {
    const [, setRecords] = useState<VaultGeoBlockingProps[]>([])

    // Konami

    const unlockGeoblock = () => {
        localStorage.setItem("ignore-geo-blocking", '"true"')
        window.location.reload()
    }
    useKonami(unlockGeoblock, { code: [69, 88, 67, 72, 65, 78, 71, 69] })
    // code is: `exchange`

    useEffect(() => {
        CMSQueryVaultsOnGeoBlock.eachPage(
            records => {
                const transformedRecords = records.map(record => record.fields) as unknown as VaultGeoBlockingProps[]
                setRecords(transformedRecords)
            },
            (err: any) => {
                if (err) {
                    console.error(`[Airtable] Vaults on GeoBlock: ${err}`)
                    return
                }
            },
        )
    }, [])
    const isBreakpointMdAndAbove = useBreakpointValue({ base: false, md: true })

    return (
        <PerpModal
            autoFocus={true}
            size="4xl"
            isOpen={isOpen}
            isFullScreen={!isBreakpointMdAndAbove}
            isCloseButtonHidden={true}
            onClose={() => {}}
            scrollBehavior="inside"
            motionPreset="slideInBottom"
            maxWidth={696}
            modalBodyProps={{ padding: 0, margin: 0 }}
            body={
                <>
                    <Box
                        tabIndex={0}
                        _focusVisible={{ outline: "none" }}
                        p={{ base: 6, md: 10 }}
                        overflow={"hidden"}
                        bg="blackAlpha.500"
                        display="flex"
                        alignItems="center"
                        fontWeight="semibold"
                        flexWrap={{ base: "wrap", md: "nowrap" }}
                    >
                        <Box
                            width={{ base: "auto", md: 48 }}
                            height={{ base: "auto", md: 24 }}
                            fontSize="2xl"
                            lineHeight={8}
                        >
                            <Text>{DESC.TITLE}</Text>
                        </Box>
                        <Box
                            width={{ base: "auto", md: "386px" }}
                            height={{ base: "auto", md: 24 }}
                            ml={{ base: 0, md: 10 }}
                            mt={{ base: 6, md: 0 }}
                            position="relative"
                            fontSize={16}
                        >
                            <Box fontWeight={"normal"}>
                                {DESC.CONTEXT}
                                <PerpLink
                                    trackingProps={{ eventName: EventName.LINK_TERMS_OF_SERVICE_CLICK }}
                                    href={DESC.URL}
                                >
                                    <Text color="perpetual.500" display="inline" decoration="underline">
                                        {DESC.TERMS_OF_SERVICE}
                                    </Text>
                                </PerpLink>
                            </Box>
                        </Box>
                    </Box>
                </>
            }
        />
    )
}
