import { useNotification } from "module/notification/hook/useNotification"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"

import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { createContainer } from "unstated-next"

import { CHAINS, DEFAULT_APP_CHAIN_ID, VALID_APP_CHAIN_IDS } from "../constant"

type ExplorerBuilder = (data: string, type: "transaction" | "token" | "address" | "block") => string

export const AppNetwork = createContainer(useAppNetwork)

function useAppNetwork() {
    const { notifyError } = useNotification()
    const { t } = useTranslation()

    // NOTE: Check if any valid appChainId exist.
    useEffect(() => {
        if (VALID_APP_CHAIN_IDS.length === 0) {
            notifyError({ title: t("notification.error_no_valid_app_chain_id"), duration: null })
        }
    }, [notifyError, t])

    const { accountChainId } = Web3WalletContainer.useContainer()

    // NOTE: accountChainId is and only is available when wallet is connected.
    // NOTE: Both returns false when account is not ready.
    const isAccountOnSupportedNetwork = !!accountChainId && VALID_APP_CHAIN_IDS.includes(accountChainId)
    const isAccountOnUnsupportedNetwork = !!accountChainId && !VALID_APP_CHAIN_IDS.includes(accountChainId)

    // FIXME: appChainId could change & cause glitch due to accountChainId becomes available after auto-login
    const appChainId = !!accountChainId && isAccountOnSupportedNetwork ? accountChainId : DEFAULT_APP_CHAIN_ID
    const appChainName = CHAINS[appChainId]?.name
    const appChainAlias = CHAINS[appChainId]?.alias
    const referralContractAddress = CHAINS[appChainId]?.referralContractAddress
    const explorerBuilder: ExplorerBuilder = (data, type) =>
        CHAINS[appChainId].urlBuilder(CHAINS[appChainId].link, data, type)

    return {
        isAccountOnSupportedNetwork,
        isAccountOnUnsupportedNetwork,
        appChainId,
        appChainName,
        appChainAlias,
        explorerBuilder,
        referralContractAddress,
    }
}
