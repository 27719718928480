import { LanguageSwitcher } from "module/settings/component/LanguageSwitcher"

import {
    Badge,
    Box,
    Center,
    Divider,
    HStack,
    Icon,
    LinkBox,
    LinkBoxProps,
    LinkOverlay,
    Spacer,
    StackDivider,
    StackProps,
    Text,
    useColorModeValue,
    useOutsideClick,
} from "@chakra-ui/react"
import { BadgeNew } from "component/BadgeNew"
import { FooterSection } from "component/FooterSection"
import { PerpPlus, TabGlance, TabHistory, TabMarket, TabOrders, TabPositions, TabRewardsStar } from "component/Icon"
import { MotionHStack, MotionSimpleGrid, MotionVStack } from "component/Motion"
import { RouteName } from "constant/route"
import { FeatureToggleContainer } from "container/service/FeatureToggleContainer"
import { motion } from "framer-motion"
import { useReward } from "hook/useReward"
import React, { useCallback, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    RiArrowDownSLine,
    RiArrowUpSLine,
    RiBankFill,
    RiContrastDrop2Fill,
    RiCoupon2Fill,
    RiPlantFill,
    RiVipCrown2Fill,
} from "react-icons/ri"
import { matchPath } from "react-router"
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom"

interface MobileMenuProps extends StackProps {
    isTab?: boolean
    separatorBg?: string
}
const iosSafeAreaBottomPadding = "calc(env(safe-area-inset-bottom) / 2.25)"

export function MobileMenu({ isTab, separatorBg }: MobileMenuProps) {
    const borderColor = useColorModeValue("blackAlpha.400", "whiteAlpha.400")
    const cardBg = useColorModeValue("cardBg.light", "cardBg.dark")
    const currentPath = useLocation().pathname
    const [expandMenu, setExpandMenu] = useState(false)
    const { t } = useTranslation()
    const { totalReward } = useReward()

    const { isTradingCompetitionEnable } = FeatureToggleContainer.useContainer()

    // make clickOutside setExpandMenu to false
    const ref = useRef<HTMLDivElement>(null)
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35572#issuecomment-498242139

    useOutsideClick({
        ref: ref,
        handler: () => setExpandMenu(false),
    })

    // make pools and pools/BTC:USD the same match
    const matchPools = matchPath(currentPath, {
        path: RouteName.POOL_LIST,
        exact: false,
        strict: false,
    })
    // make markets and markets/BTC:USD the same match
    const matchMarkets = matchPath(currentPath, {
        path: RouteName.MARKET_LIST,
        exact: false,
        strict: false,
    })

    // handle click on markets

    const history = useHistory()
    const matchPoolsExact = matchPath(currentPath, {
        path: RouteName.POOL_DETAIL,
        exact: true,
        strict: false,
    })
    const matchMarketsExact = matchPath(currentPath, {
        path: RouteName.MARKET_DETAIL,
        exact: true,
        strict: false,
    })
    const renderMarketClickStr = useCallback(() => {
        // if on pool detail but click on market
        const currentPathname = history.location.pathname
        const nextPathname = currentPathname.replace("/pools/", "/markets/")
        return matchPoolsExact ? nextPathname : RouteName.MARKET_LIST
    }, [matchPoolsExact, history.location.pathname])
    const renderPoolClickStr = useCallback(() => {
        // if on market but click on pool
        const currentPathname = history.location.pathname
        const nextPathname = currentPathname.replace("/markets/", "/pools/")
        return matchMarketsExact ? nextPathname : RouteName.POOL_LIST
    }, [matchMarketsExact, history.location.pathname])

    const { isAllPositionsAndOrdersEnable } = FeatureToggleContainer.useContainer()

    return (
        <MotionVStack
            userSelect={"none"}
            ref={ref}
            borderTopWidth="1px"
            borderBottomWidth="0"
            borderColor={borderColor}
            bg={cardBg}
            display={{ base: "flex", md: "none" }}
            position={"fixed"}
            zIndex={3}
            left={0}
            right={0}
            bottom={0}
            spacing={0}
            align={"stretch"}
            borderTopRadius={"2xl"}
            paddingBottom={iosSafeAreaBottomPadding}
        >
            <MotionSimpleGrid columns={5} px={2}>
                <TabItem isActive={currentPath === RouteName.ROOT}>
                    <TabGlance color={currentPath === RouteName.ROOT && "#3CEAAA"} boxSize={"34px"} />
                    <LinkOverlay fontSize={"10px"} as={RouterLink} to={RouteName.ROOT}>
                        {t("general.overview")}
                    </LinkOverlay>
                </TabItem>

                <TabItem isActive={!!matchMarkets}>
                    <TabMarket color={!!matchMarkets && "#CCAA77"} boxSize={"34px"} />
                    <LinkOverlay fontSize={"10px"} as={RouterLink} to={renderMarketClickStr()}>
                        {t("market.markets")}
                    </LinkOverlay>
                </TabItem>
                <TabItem isActive={!!matchPools}>
                    <Center boxSize={"34px"}>
                        <Icon
                            color={!!matchPools ? "perpetual.500" : undefined}
                            boxSize="26px"
                            as={RiContrastDrop2Fill}
                        />
                    </Center>
                    <LinkOverlay fontSize={"10px"} as={RouterLink} to={renderPoolClickStr()}>
                        {t("pool.pools")}
                    </LinkOverlay>
                </TabItem>
                <TabItem isActive={currentPath.startsWith("/history")}>
                    <TabHistory color={currentPath.startsWith("/history") && "#C1E33A"} boxSize={"34px"} />
                    <LinkOverlay fontSize={"10px"} as={RouterLink} to={"/history/trade"}>
                        {t("history.history")}
                    </LinkOverlay>
                </TabItem>
                <TabItem>
                    <Icon as={expandMenu ? RiArrowDownSLine : RiArrowUpSLine} boxSize={"34px"} />
                    <LinkOverlay
                        onClick={() => setExpandMenu(!expandMenu)}
                        fontSize={"10px"}
                        _hover={{ cursor: "pointer" }}
                    >
                        {expandMenu ? "Less" : "More"}
                    </LinkOverlay>
                </TabItem>
            </MotionSimpleGrid>
            {expandMenu && <Divider bg={separatorBg} />}
            <MotionVStack
                spacing={0}
                divider={<StackDivider bg={separatorBg} />}
                w="100%"
                align={"stretch"}
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: expandMenu ? 1 : 0, height: expandMenu ? "auto" : 0 }}
            >
                {isAllPositionsAndOrdersEnable && (
                    <>
                        <SidebarListItem>
                            <Icon as={TabPositions} boxSize={"30px"} padding="3px" mx={"2px"} />
                            <LinkOverlay fontSize={"sm"} as={RouterLink} to={RouteName.POSITIONS}>
                                Positions
                            </LinkOverlay>{" "}
                            <Badge>WIP</Badge>
                        </SidebarListItem>
                        <SidebarListItem>
                            <Icon as={TabOrders} boxSize={"30px"} padding="3px" mx={"2px"} />
                            <LinkOverlay fontSize={"sm"} as={RouterLink} to={RouteName.ORDERS}>
                                Orders
                            </LinkOverlay>{" "}
                            <Badge>WIP</Badge>
                        </SidebarListItem>

                        <SidebarListItem>
                            <TabRewardsStar boxSize={"34px"} />
                            <LinkOverlay
                                w="full"
                                fontSize={"md"}
                                as="a"
                                href="https://rewards.perp.com"
                                target="_blank"
                            >
                                <HStack width="100%">
                                    <Text fontSize={"sm"}>{t("general.rewards")} ↗</Text>
                                    <Spacer />
                                    <HStack spacing={2}>
                                        <PerpPlus color="rgba(116,225,170)" boxSize="32px" />
                                        <Text lineHeight={1} fontSize={"sm"} color="gray.300">
                                            {totalReward}
                                        </Text>
                                    </HStack>
                                </HStack>
                            </LinkOverlay>
                        </SidebarListItem>
                    </>
                )}

                {isTradingCompetitionEnable && (
                    <SidebarListItem isActive={false}>
                        <Icon as={RiVipCrown2Fill} boxSize={"34px"} padding="3px" />
                        <LinkOverlay
                            width="100%"
                            isExternal={true}
                            href="https://leaderboard.perp.com"
                            _active={{ background: "transparent" }}
                        >
                            {t("general.trading_competition")} ↗
                        </LinkOverlay>
                        <BadgeNew />
                    </SidebarListItem>
                )}

                <SidebarListItem>
                    <Icon as={RiBankFill} boxSize={"30px"} padding="3px" mx={"2px"} />
                    <LinkOverlay fontSize={"sm"} as="a" target="_blank" href="https://vaults.perp.com">
                        Vaults (Hot Tub) ↗
                    </LinkOverlay>
                </SidebarListItem>

                <SidebarListItem>
                    <Icon as={RiCoupon2Fill} boxSize={"30px"} padding="3px" mx={"2px"} />
                    <LinkOverlay fontSize={"sm"} as="a" href="https://token.perp.com/referral" target="_blank">
                        {t("general.referrals")} ↗
                    </LinkOverlay>
                </SidebarListItem>

                <SidebarListItem>
                    <Icon as={RiPlantFill} boxSize={"30px"} padding="3px" mx={"2px"} />
                    <LinkOverlay fontSize={"sm"} as="a" href="https://www.perp.com/ecosystem" target="_blank">
                        {t("general.ecosystem")} ↗
                    </LinkOverlay>
                </SidebarListItem>
                <Box>
                    <LanguageSwitcher />
                </Box>
                <FooterSection w="100%" px={6} py={6} />
            </MotionVStack>
        </MotionVStack>
    )
}

interface SidebarListItemProps extends LinkBoxProps {
    isActive?: boolean
    isDisabled?: boolean
    children: React.ReactNode
}

export function SidebarListItem({ isActive, isDisabled, children, ...props }: SidebarListItemProps) {
    return (
        <LinkBox _active={{ background: "transparent" }} w={"100%"} px={4} opacity={isDisabled ? 0.5 : 1} {...props}>
            <MotionHStack
                lineHeight={1}
                spacing={3}
                w={"100%"}
                borderRadius="xl"
                px={1}
                py={4}
                justify="align"
                color={isActive ? "whiteAlpha.900" : "whiteAlpha.700"}
            >
                {children}
            </MotionHStack>
        </LinkBox>
    )
}

const MotionLinkBox = motion(LinkBox)

export function TabItem({ isActive, isDisabled, children, ...props }: SidebarListItemProps) {
    return (
        <MotionLinkBox
            whileTap={{ scale: 0.95 }}
            _active={{ background: "transparent" }}
            opacity={isDisabled ? 0.5 : 1}
            py={2}
            pb={3}
            color={isActive ? "whiteAlpha.900" : "whiteAlpha.700"}
        >
            <MotionVStack spacing={0} w={"auto"} justify="align">
                {children}
            </MotionVStack>
        </MotionLinkBox>
    )
}
