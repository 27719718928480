export enum OrderType {
    MARKET = "MARKET",
    LIMIT = "LIMIT",
    STOP_LIMIT = "STOP_LIMIT",
    TAKE_PROFIT_LIMIT = "TAKE_PROFIT_LIMIT",
}

export const OrderTypeParamMap: { [key in OrderType]: undefined | number } = {
    [OrderType.MARKET]: undefined,
    [OrderType.LIMIT]: 0,
    [OrderType.STOP_LIMIT]: 1,
    [OrderType.TAKE_PROFIT_LIMIT]: 2,
}

export const OrderTypeMap: Record<string, OrderType> = {
    0: OrderType.LIMIT,
    1: OrderType.STOP_LIMIT,
    2: OrderType.TAKE_PROFIT_LIMIT,
}
