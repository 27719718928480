import { Divider, HStack, SimpleGrid, Text, useColorModeValue, VStack } from "@chakra-ui/react"
import { Position } from "@perp/sdk-curie"
import { ModalRootProps } from "component/Modal/type"
import { PerpModal } from "component/PerpModal"
import { Stats } from "component/Stats"
import { TxButton } from "component/TxButton"
import { Referral } from "container/Referral"
import { SlippagePopover } from "module/pool/component/LiquidityForms/SlippagePopover"
import { Slippage } from "module/settings/container/slippage"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { RiFlashlightFill } from "react-icons/ri"
import { usePositionClose } from "sdk-react/clearingHouse/usePositionClose"
import { DelegateApproval } from "sdk-react/limitOrder/useDelegateApproval"
import { trackClosePositionButton } from "service/segment/tracks"
import { usePositionDetailStrings } from "../hook/usePositionDetailStrings"

export interface ModalPositionCloseParams {
    position: Position
}

export interface ModalPositionCloseProps extends ModalRootProps<ModalPositionCloseParams> {}

export function ModalPositionClose({ isOpen, close, params }: ModalPositionCloseProps) {
    const { position } = params
    const { slippage } = Slippage.useContainer()
    const { referralCode } = Referral.useContainer()
    const { closePosition, isUsingOtcExitPrice } = usePositionClose(position)

    const { t } = useTranslation()
    const { unrealizedPnlStr, positionSizeStr, exitPriceStr, feeStr, priceImpactStr } =
        usePositionDetailStrings(position)

    const handleSubmitClosePosition = useCallback(async () => {
        if (position) {
            trackClosePositionButton(slippage.toString())
            closePosition({
                slippage,
                referralCode,
            })
        }
    }, [position, slippage, closePosition, referralCode])

    const { isApproved } = DelegateApproval.useContainer()
    const subText = useColorModeValue("gray.500", "gray.400")

    return (
        <PerpModal
            isOpen={isOpen}
            onClose={close}
            size="md"
            header={t("position.close_position")}
            body={
                <VStack spacing={5} align="stretch" pb={2}>
                    <SimpleGrid w="100%" columns={1} spacing={4}>
                        <Stats w="100%" size="2xl" label={t("general.profit_loss")} number={unrealizedPnlStr} />

                        <Divider />
                        <Text variant="label">Transaction Summary</Text>
                        <VStack spacing={2}>
                            <Stats
                                variant="left"
                                size="xs"
                                label={t("position.position_size")}
                                number={positionSizeStr}
                            />
                            <Stats variant="left" size="xs" label={t("position.exit_price")} number={exitPriceStr} />
                            <Stats
                                variant="left"
                                size="xs"
                                label={t("position.price_impact")}
                                number={priceImpactStr}
                            />
                            <Stats
                                variant="left"
                                size="xs"
                                label={t("general.transaction_fee")}
                                showTooltip
                                helper="Included in PnL"
                                number={feeStr}
                            />
                        </VStack>
                    </SimpleGrid>

                    <Divider />
                    <VStack w="100%" alignItems={"stretch"}>
                        <HStack>
                            <TxButton size="sm" onClick={handleSubmitClosePosition}>
                                {t("position.confirm_close_position")}
                            </TxButton>
                            <SlippagePopover />
                        </HStack>
                        {isUsingOtcExitPrice && (
                            <HStack>
                                <RiFlashlightFill size={20} style={{ flexShrink: 0 }} />
                                {/* TODO: add link */}
                                <Text fontSize="xs" color={subText}>
                                    Better price found via{" "}
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href="https://blog.perp.fi/introducing-smart-maker-b9bb9324ebf"
                                    >
                                        Smart Maker
                                    </a>
                                    . {!isApproved && "Approve to continue."}
                                </Text>
                            </HStack>
                        )}
                    </VStack>
                </VStack>
            }
        />
    )
}
