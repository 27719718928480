import { Box, Center, Grid, GridItem, Text } from "@chakra-ui/react"
import { MotionBox } from "component/Motion"

export enum Strategy {
    UP_DOWN = "UP_DOWN",
    DOWN_UP = "DOWN_UP",
}

type StrategyData = {
    key: Strategy
    label: string
    imgSrc: string
}

const StrategyDataList: StrategyData[] = [
    {
        key: Strategy.DOWN_UP,
        label: "Go down then up",
        imgSrc: "/assets/crab/1.svg",
    },
    {
        key: Strategy.UP_DOWN,
        label: "Go up then down",
        imgSrc: "/assets/crab/2.svg",
    },
]

type StrategyPickerProps = {
    selectedStrategy: Strategy
    onSelectStrategy: (strategy: Strategy) => void
}
export const StrategyPicker = ({ selectedStrategy, onSelectStrategy }: StrategyPickerProps) => {
    return (
        <Grid gap={4} templateColumns={{ base: "auto", sm: "1fr 1fr" }} w="100%">
            {StrategyDataList.map((item, index) => {
                const { key } = item
                return (
                    <GridItem key={`strategy-option-${key}`} onClick={() => onSelectStrategy(key)}>
                        <StrategyCard isSelected={selectedStrategy === key} item={item} />
                    </GridItem>
                )
            })}
        </Grid>
    )
}

interface StrategyCardProps {
    isSelected?: boolean
    item: StrategyData
}
function StrategyCard({ isSelected, item: { label, imgSrc } }: StrategyCardProps) {
    const chartVariant = {
        run: {
            scaleX: 0,
            transition: {
                duration: 3,
                repeat: Infinity,
                repeatDelay: 2,
            },
        },
        initial: { scaleX: 1 },
    }

    return (
        <MotionBox
            cursor="pointer"
            position="relative"
            borderRadius="xl"
            maxW="100%"
            bg="#151518"
            sx={{
                aspectRatio: "308 / 190",
                overflow: "hidden",
            }}
            whileTap={{ y: 3 }}
            borderWidth="1.5px"
            borderColor={isSelected ? "whiteAlpha.800" : "transparent"}
            boxShadow={isSelected ? "2xl" : "none"}
        >
            {/* NOTE: black overlay for animation */}
            <MotionBox
                position="absolute"
                zIndex={1}
                variants={chartVariant}
                animate={"run"}
                initial={"initial"}
                boxSize={"100%"}
                bg="#151518"
                left={0}
                right={0}
                top={0}
                bottom={0}
                transformOrigin={"right center"}
            />
            {/* NOTE: price line */}
            <Box
                position="absolute"
                boxSize={"100%"}
                bgRepeat="no-repeat"
                bgSize={"cover"}
                bgPosition="center"
                bgImage={imgSrc}
                left={0}
                right={0}
                top={0}
                bottom={0}
                zIndex={0}
                filter={isSelected ? "none" : "grayscale(100%)"}
            />
            {/* NOTE: current price line */}
            <Box
                zIndex={0}
                position="absolute"
                boxSize={"100%"}
                bgRepeat="repeat-x"
                bgSize={"cover"}
                bgPosition="center"
                bgImage={"/assets/crab/bg.svg"}
                left={0}
                right={0}
                top={0}
                bottom={0}
            />
            {/* NOTE: current price dot */}
            <Center textAlign="center" zIndex={1} left={0} top={0} bottom={0} pl={"12%"} position="absolute">
                <Box bg="#FFB459" borderRadius="full" boxSize="10px" borderColor="#151518" borderWidth="1px" />
            </Center>

            {/* NOTE: label */}
            <Box textAlign="center" zIndex={1} bottom={0} p={4} position="absolute" w={"100%"}>
                <Text fontSize="sm">{label}</Text>
            </Box>
        </MotionBox>
    )
}
