import { Global } from "@emotion/react"
import React from "react"

//load custom fonts

export const Fonts = () => (
    <Global
        styles={`
        @font-face{ 
            font-family: 'Modernist'; 
            src: url('/font/sk-modernist-bold-font/sk-modernist-bold-webfont.eot'); 
            src: url('/font/sk-modernist-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/font/sk-modernist-bold-webfont.woff') format('woff'), url('/font/sk-modernist-bold-webfont.ttf') format('truetype'), url('/font/sk-modernist-bold-webfont.svg#font/sk-modernist-bold-webfont') format('svg');
         }

         @font-face {
            font-family: "Inter";
            font-style: normal;
            font-weight: 100;
            font-display: swap;
            src: url("/font/Inter-Thin.woff2") format("woff2"), url("/font/Inter-Thin.woff") format("woff");
        }
        @font-face {
            font-family: "Inter";
            font-style: italic;
            font-weight: 100;
            font-display: swap;
            src: url("/font/Inter-ThinItalic.woff2") format("woff2"), url("/font/Inter-ThinItalic.woff") format("woff");
        }
        
        @font-face {
            font-family: "Inter";
            font-style: normal;
            font-weight: 200;
            font-display: swap;
            src: url("/font/Inter-ExtraLight.woff2") format("woff2"), url("/font/Inter-ExtraLight.woff") format("woff");
        }
        @font-face {
            font-family: "Inter";
            font-style: italic;
            font-weight: 200;
            font-display: swap;
            src: url("/font/Inter-ExtraLightItalic.woff2") format("woff2"),
                url("/font/Inter-ExtraLightItalic.woff") format("woff");
        }
        
        @font-face {
            font-family: "Inter";
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url("/font/Inter-Light.woff2") format("woff2"), url("/font/Inter-Light.woff") format("woff");
        }
        @font-face {
            font-family: "Inter";
            font-style: italic;
            font-weight: 300;
            font-display: swap;
            src: url("/font/Inter-LightItalic.woff2") format("woff2"), url("/font/Inter-LightItalic.woff") format("woff");
        }
        
        @font-face {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url("/font/Inter-Regular.woff2") format("woff2"), url("/font/Inter-Regular.woff") format("woff");
        }
        @font-face {
            font-family: "Inter";
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url("/font/Inter-Italic.woff2") format("woff2"), url("/font/Inter-Italic.woff") format("woff");
        }
        
        @font-face {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url("/font/Inter-Medium.woff2") format("woff2"), url("/font/Inter-Medium.woff") format("woff");
        }
        @font-face {
            font-family: "Inter";
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url("/font/Inter-MediumItalic.woff2") format("woff2"), url("/font/Inter-MediumItalic.woff") format("woff");
        }
        
        @font-face {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url("/font/Inter-SemiBold.woff2") format("woff2"), url("/font/Inter-SemiBold.woff") format("woff");
        }
        @font-face {
            font-family: "Inter";
            font-style: italic;
            font-weight: 600;
            font-display: swap;
            src: url("/font/Inter-SemiBoldItalic.woff2") format("woff2"), url("/font/Inter-SemiBoldItalic.woff") format("woff");
        }
        
        @font-face {
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url("/font/Inter-Bold.woff2") format("woff2"), url("/font/Inter-Bold.woff") format("woff");
        }
        @font-face {
            font-family: "Inter";
            font-style: italic;
            font-weight: 700;
            font-display: swap;
            src: url("/font/Inter-BoldItalic.woff2") format("woff2"), url("/font/Inter-BoldItalic.woff") format("woff");
        }
        
        
        @font-face {
            font-family: "Inter";
            font-style: normal;
            font-weight: 900;
            font-display: swap;
            src: url("/font/Inter-Black.woff2") format("woff2"), url("/font/Inter-Black.woff") format("woff");
        }
        @font-face {
            font-family: "Inter";
            font-style: italic;
            font-weight: 900;
            font-display: swap;
            src: url("/font/Inter-BlackItalic.woff2") format("woff2"), url("/font/Inter-BlackItalic.woff") format("woff");
        }
        
        /* -------------------------------------------------------
        Variable font.
        Usage:
        
          html { font-family: 'Inter', sans-serif; }
          @supports (font-variation-settings: normal) {
            html { font-family: 'Inter var', sans-serif; }
          }
        */
        @font-face {
            font-family: "Inter var";
            font-weight: 100 900;
            font-display: swap;
            font-style: normal;
            font-named-instance: "Regular";
            src: url("/font/Inter-roman.var.woff2") format("woff2");
        }
        @font-face {
            font-family: "Inter var";
            font-weight: 100 900;
            font-display: swap;
            font-style: italic;
            font-named-instance: "Italic";
            src: url("/font/Inter-italic.var.woff2") format("woff2");
        }
        
        /* --------------------------------------------------------------------------
        [EXPERIMENTAL] Multi-axis, single variable font.
        
        Slant axis is not yet widely supported (as of February 2019) and thus this
        multi-axis single variable font is opt-in rather than the default.
        
        When using this, you will probably need to set font-variation-settings
        explicitly, e.g.
        
          * { font-variation-settings: "slnt" 0deg }
          .italic { font-variation-settings: "slnt" 10deg }
        
        */
        @font-face {
            font-family: "Inter var experimental";
            font-weight: 100 900;
            font-display: swap;
            font-style: oblique 0deg 10deg;
            src: url("/font/Inter.var.woff2") format("woff2");
        }
        


        `}
    />
)
