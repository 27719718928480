import { ApolloQueryResult } from "@apollo/client"
import { GQL_REQUEST_AMOUNT } from "constant/aws"

import { AppSyncService } from "./appSyncService"
import { LIST_CANDLE_STICKS, ON_UPSERT_CANDLE_STICK } from "./graphQueries"

export interface CandleStick {
    market: string
    resolution: string
    startTime: number
    open: string
    high: string
    low: string
    close: string
    volume: string
    quoteAssetVol: string
    txCount: number
}

export interface GetCandleStickResponse {
    listCandleSticks: { items: CandleStick[] }
}

interface SubscribeCandleStickResponse {
    data: { onUpsertCandleStick: CandleStick }
}

type Subscribers = {
    [subscriberId: string]: ZenObservable.Subscription
}
/**
 * - Use when:
 *   1. We need to get a series of past candle data (for displaying chart)
 *   2. We need to {@link subscribeCandles | subscribe} / {@link unsubscribeCandles | unsubscribe} the newest (real time) candle data
 *      (Only one tickerSymbol can be subscribed at a time)
 *
 * - Init:
 *   1. New a CandleService with `key`, `url`, `region` ({@link constructor})
 *   2. Run {@link hydrated} function for local caching
 *
 */
export class CandleService extends AppSyncService {
    //  TODO: refactor this into AppSyncService
    private _subscribers: Subscribers = {}

    async getCandleSticks(
        ammAddress: string,
        resolution: string,
        between: number[],
        limit: number = GQL_REQUEST_AMOUNT,
    ): Promise<CandleStick[]> {
        const result: ApolloQueryResult<GetCandleStickResponse> = await this._client.query({
            query: LIST_CANDLE_STICKS,
            variables: {
                query: {
                    marketResolution: { eq: `${ammAddress}#${resolution}` },
                    startTime: { between },
                },
                limit,
            },
        })
        return result.data.listCandleSticks.items
    }

    subscribeCandles(
        subscriberId: string,
        ammAddress: string,
        resolution: string,
        callback: (data: SubscribeCandleStickResponse) => void,
    ) {
        const subscriber = this._subscribers[ammAddress]
        if (!!subscriber) {
            subscriber.unsubscribe()
        }
        const observable = this._client.subscribe<SubscribeCandleStickResponse>({
            query: ON_UPSERT_CANDLE_STICK,
            variables: {
                market: ammAddress,
                resolution,
            },
        })
        this._subscribers[subscriberId] = observable.subscribe(callback)
    }

    unsubscribeCandles(subscriberId: string) {
        const subscriber = this._subscribers[subscriberId]
        if (!subscriber) {
            return
        }
        subscriber.unsubscribe()
        delete this._subscribers[subscriberId]
    }
}
