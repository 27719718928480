import { useNotificationTx } from "module/notification/hook/useNotificationTx"
import { Slippage } from "module/settings/container/slippage"

import { MinusIcon } from "@chakra-ui/icons"
import { Alert, Divider, HStack, Icon, Text, VStack, useColorModeValue } from "@chakra-ui/react"
import { Liquidity, Position } from "@perp/sdk-curie"
import Big from "big.js"
import { ModalRootProps, ModalType } from "component/Modal/type"
import { PerpModal } from "component/PerpModal"
import { TxButton } from "component/TxButton"
import { useModal } from "container/modal"
import { useCallback, useState } from "react"
import { RiInformationLine } from "react-icons/ri"
import { useLiquidityRemove } from "sdk-react/liquidity/useLiquidityRemove"
import { trackLiquidityRemoveButtonClicked } from "service/segment/tracks"

import MakerPositionRealizing from "./LiquidityForms/MakerPositionRealizing"
import RemoveLiquidity from "./LiquidityForms/RemoveLiquidity"
import { SlippagePopover } from "./LiquidityForms/SlippagePopover"

export interface ModalLiquidityRemoveParams {
    tickerSymbol: string
    existingLiquidity: Liquidity
    makerPositionImpermanent?: Position
}

export function ModalLiquidityRemove({
    isOpen,
    close,
    params: { tickerSymbol, existingLiquidity, makerPositionImpermanent },
}: ModalRootProps<ModalLiquidityRemoveParams>) {
    const [ratio, setRatio] = useState(0)
    const handleSliderChange = useCallback((value: number) => {
        setRatio(value)
    }, [])

    const { close: closeSelf } = useModal(ModalType.LIQUIDITY_REMOVE)
    const { slippage } = Slippage.useContainer()
    const { notifyTxSucceed } = useNotificationTx()
    const { removeLiquidity } = useLiquidityRemove()
    const handleClickRemoveLiquidity = useCallback(() => {
        const _ratio = new Big(ratio / 100)
        trackLiquidityRemoveButtonClicked(_ratio.toString(), slippage.toString())
        removeLiquidity({
            liquidity: existingLiquidity,
            slippage,
            ratio: _ratio,
            options: {
                onSuccess: (receipt, contractFunctionName) => {
                    notifyTxSucceed(receipt, contractFunctionName)
                    closeSelf()
                },
            },
        })
    }, [closeSelf, existingLiquidity, notifyTxSucceed, ratio, removeLiquidity, slippage])

    const subText = useColorModeValue("gray.500", "gray.400")

    return (
        <PerpModal
            isOpen={isOpen}
            onClose={close}
            size="md"
            scrollBehavior="inside"
            motionPreset="slideInBottom"
            header={"Remove Liquidity"}
            body={
                <VStack spacing={4} align="stretch">
                    <RemoveLiquidity ratio={ratio} onUpdateSlider={handleSliderChange} />
                    <Divider />

                    {makerPositionImpermanent && (
                        <>
                            <MakerPositionRealizing position={makerPositionImpermanent} ratio={ratio} />
                            <Divider />
                        </>
                    )}

                    <Alert status="info" variant="subtle">
                        If your liquidity position is very large, the PnL of your permanent position may be different
                        from the estimate shown.
                    </Alert>

                    <HStack w="100%">
                        <TxButton
                            isDisabled={ratio === 0}
                            colorScheme="purple"
                            variant="solid"
                            leftIcon={<MinusIcon />}
                            onClick={handleClickRemoveLiquidity}
                        >
                            Remove Liquidity
                        </TxButton>
                        <SlippagePopover />
                    </HStack>

                    <Text textAlign="center" fontSize="xs" color={subText}>
                        <Icon boxSize="16px" as={RiInformationLine} position="relative" top={"3px"} /> Earned fees are
                        already added into Free Collateral.
                    </Text>
                </VStack>
            }
        />
    )
}
