import { ApolloQueryResult } from "@apollo/client"
import { DateTime } from "luxon"

import { AppSyncService } from "./appSyncService"
import { LIST_FUNDING_RATES } from "./graphQueries"

export enum FUNDING_RATE_RESOLUTION {
    HOUR_1 = "1h",
    HOUR_8 = "8h",
    YEAR_1 = "1y",
}

export interface FundingRate {
    /* NOTE:
     * combination of market symbol and resolution id
     * format: "${marketSymbol}#${resolutionId}"
     * ex: btc#1h
     **/
    marketResolution: string

    // NOTE: timestamp of the funding rate
    timestamp: number

    // NOTE: funding rate
    fundingRate: string

    // NOTE: count of funding update event included in this record
    eventCount: string

    // NOTE: timestamp of last funding update event
    lastEventTimestamp: string

    // NOTE: block number of last funding update event
    lastEventBlockNumber: string

    // NOTE: count of updated times
    version: string
}

export interface ListFundingRatesReturn {
    items: FundingRate[]
    nextToken: string
}

export interface ListFundingRatesResponse {
    listFundingRates: ListFundingRatesReturn
}
const EMPTY_FUNDING_RATE_LISTS = [
    {
        eventCount: "",
        fundingRate: "",
        lastEventBlockNumber: "",
        lastEventTimestamp: "",
        marketResolution: "",
        timestamp: DateTime.now().toSeconds(),
        version: "",
    },
]
const FUNDING_RATE_LIST_LIMIT = 1000
export class FundingRateService extends AppSyncService {
    async listFundingRates(
        tickerSymbol: string,
        resolution: string,
        timestampBetween: [number, number],
    ): Promise<FundingRate[]> {
        const marketResolution = `${tickerSymbol}#${resolution}`
        const lists = []
        const getParam = (nextToken: string = "") => ({
            query: LIST_FUNDING_RATES,
            variables: {
                query: {
                    marketResolution: { eq: marketResolution },
                    timestamp: { between: timestampBetween },
                },
                limit: FUNDING_RATE_LIST_LIMIT,
                nextToken,
            },
        })
        const result: ApolloQueryResult<ListFundingRatesResponse> = await this._client.query(getParam())
        const { items, nextToken } = result?.data?.listFundingRates
        lists.push(...items)
        let token = nextToken
        while (token) {
            const result: ApolloQueryResult<ListFundingRatesResponse> = await this._client.query(getParam(token))
            const { items, nextToken } = result?.data?.listFundingRates
            lists.push(...items)
            token = nextToken
        }

        return lists.length ? lists : EMPTY_FUNDING_RATE_LISTS
    }
}
