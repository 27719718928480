import { Liquidity } from "@perp/sdk-curie"
import Big from "big.js"
import { usePerpTransactionSender } from "sdk-react/perpetualProtocol/usePerpTransactionSender"
import { TxLoading } from "sdk-react/tools/useTxLoading"

export function useLiquidityRemove() {
    const { isTxLoading } = TxLoading.useContainer()
    const removeLiquidity = usePerpTransactionSender<{ slippage: Big; liquidity: Liquidity; ratio: Big }>(
        ({ clearingHouse }, { slippage, liquidity, ratio }) => {
            return clearingHouse.removeLiquidity(liquidity, ratio, slippage)
        },
        [],
    )
    return {
        isTxLoading,
        removeLiquidity,
    }
}
