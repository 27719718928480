import Bugsnag, { Client } from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { BUGSNAG_API_KEY, GITHUB_TAG, STAGE } from "constant/envVariables"
import { Stage } from "constant/stage"
import React from "react"

class BugsnagService {
    readonly APP_TYPE: string = "perp-exchange"
    client: Client | null = null

    start() {
        if (BUGSNAG_API_KEY) {
            this.client = Bugsnag.start({
                appType: this.APP_TYPE,
                apiKey: BUGSNAG_API_KEY,
                appVersion: GITHUB_TAG,
                releaseStage: STAGE,
                enabledReleaseStages: [Stage.PRODUCTION, Stage.STAGING],
                plugins: [new BugsnagPluginReact()],
            })
        }
    }

    createErrorBoundary() {
        if (this.client) {
            const plugin = Bugsnag.getPlugin("react")
            if (plugin) {
                return plugin.createErrorBoundary(React)
            }
        }
        return ({ children }: { children: React.ReactNode }) => <>{children}</>
    }
}

const bugsnagService = new BugsnagService()
export default bugsnagService
