import { EventName } from "module/analytics/types"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"

import { Text, VStack } from "@chakra-ui/react"
import { Bridge } from "@socket.tech/widget"
import { PerpModal } from "component/PerpModal"
import { APP_SUPPORTED_CHAIN_IDS, BRIDGE_SOCKET_API_KEY } from "constant/envVariables"

import { ModalRootProps } from "./Modal/type"

export function ModalSocketWidget({ isOpen, close }: ModalRootProps) {
    const { signer } = Web3WalletContainer.useContainer()

    return (
        <PerpModal
            isCentered={false}
            size={"xl"}
            isOpen={isOpen}
            onClose={close}
            scrollBehavior="outside"
            motionPreset="slideInBottom"
            header={<Text fontWeight="bold">Bridge with Socket</Text>}
            body={
                <VStack align="center">
                    {BRIDGE_SOCKET_API_KEY && (
                        <Bridge
                            provider={signer?.provider}
                            API_KEY={BRIDGE_SOCKET_API_KEY}
                            destNetworks={APP_SUPPORTED_CHAIN_IDS}
                        />
                    )}
                </VStack>
            }
            trackingPropsClose={{ eventName: EventName.BRIDGE_SOCKET_CLOSE }}
        />
    )
}
