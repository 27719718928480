import { useQuery } from "@apollo/client"
import { WithdrawHistory } from "@perp/sdk-curie"
import Big from "big.js"
import { useCallback, useMemo } from "react"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

import { WITHDRAWNS_QUERY } from "../graphServer/graphQueries"
import { Web3Wallet } from "../PerpSDKProvider"

const PAGE_SIZE = 100

interface APIWithdrawHistory {
    id: string
    txHash: string
    trader: string
    collateralToken: string
    amount: string
    blockNumber: string
    timestamp: string
}

interface IWithdrawHistory {
    withdrawns: APIWithdrawHistory[]
}

type WithdrawHistoryInitialState = Web3Wallet

export const WithdrawHistories = createContainer(useWithdrawHistories)

function useWithdrawHistories(initialState?: WithdrawHistoryInitialState) {
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { account } = initialState || {}

    const { data, loading, error, fetchMore } = useQuery<IWithdrawHistory>(WITHDRAWNS_QUERY, {
        variables: { trader: account, first: PAGE_SIZE, skip: 0 },
        skip: !account,
    })

    if (error) {
        onError(error)
    }

    const fetchNextPage = useCallback(() => {
        const skip = data?.withdrawns.length ?? 0
        if (skip <= 0) {
            return
        }
        fetchMore({ variables: { skip } })
    }, [data?.withdrawns.length, fetchMore])

    const withdrawHistories = useMemo(() => {
        if (loading || !data) {
            return []
        }
        const histories = data.withdrawns || []

        try {
            return histories.map(history => {
                // TODO: hardcoded to convert decimal to 6 if token address is USDT
                // will revise it once optimism subgraph finish sync
                const amount = new Big(history.amount)

                const blockNumber = Number(history.blockNumber)
                const timestamp = Number(history.timestamp)

                return new WithdrawHistory({
                    id: history.id,
                    txHash: history.txHash,
                    trader: history.trader,
                    collateralToken: history.collateralToken,
                    amount,
                    blockNumber,
                    timestamp,
                })
            })
        } catch (e) {
            onError(e)
            return []
        }
    }, [loading, data, onError])

    return {
        isLoading: loading,
        withdrawHistories,
        fetchNextPage,
    }
}
