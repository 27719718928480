import { Position, PositionSide } from "@perp/sdk-curie"
import { PRICE_IMPACT_PERCENTAGE_PRECISION } from "constant/number"
import { usePositionDetail } from "sdk-react/clearingHouse/usePositionDetail"
import { formatNumberPositionSize, formatNumberUSD, formatPercentage } from "util/format"

const PLACEHOLDER = "—"

export function usePositionDetailStrings(position: Position) {
    const { unrealizedPnl, exitPrice, liquidationPrice, fee, priceImpact, mmRatio, notionalValue } =
        usePositionDetail(position)

    const isLongPosition = position.side === PositionSide.LONG
    const entryPriceStr = formatNumberUSD({ num: position.entryPrice })
    const exitPriceStr = exitPrice ? formatNumberUSD({ num: exitPrice }) : PLACEHOLDER
    const priceImpactStr = priceImpact
        ? `${formatPercentage(priceImpact, true, false, PRICE_IMPACT_PERCENTAGE_PRECISION)}%`
        : PLACEHOLDER
    const unrealizedPnlPrefix = unrealizedPnl && unrealizedPnl.gte(0) ? "+" : ""
    const liquidationPriceStr =
        liquidationPrice && liquidationPrice.gt(0) ? formatNumberUSD({ num: liquidationPrice }) : PLACEHOLDER
    const mmRatioStr = mmRatio ? `${formatPercentage(mmRatio)}%` : PLACEHOLDER

    const positionSizeStr = formatNumberPositionSize(position.sizeAbs)
    const notionalValueStr = notionalValue ? formatNumberUSD({ num: notionalValue }) : PLACEHOLDER
    const feeStr = fee ? formatNumberUSD({ num: fee }) : PLACEHOLDER
    const unrealizedPnlStr = unrealizedPnl
        ? `${unrealizedPnlPrefix}${formatNumberUSD({ num: unrealizedPnl })}`
        : PLACEHOLDER

    return {
        isLongPosition,
        mmRatioStr,
        positionSizeStr,
        notionalValueStr,
        entryPriceStr,
        exitPriceStr,
        unrealizedPnlStr,
        liquidationPriceStr,
        feeStr,
        priceImpactStr,
    }
}
