export function getShortenAccount(address: string) {
    return address.substr(0, 6) + "..." + address.substr(-4)
}

export function getCreditCardLikeAccount(address: string) {
    return address.substr(0, 4) + "..." + address.substr(-4)
}
export function getFirstFourAccount(address: string) {
    return address.substr(0, 6)
}
export function getLastFourAccount(address: string) {
    return address.substr(-4)
}
