import { Box, Select } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

interface Language {
    key: string
    title: string
}

const LANGUAGE_LIST: Language[] = [
    { key: "en", title: "English" },
    { key: "zh-CN", title: "简体中文" },
    // { key: "zh-TW", title: "繁體中文" },
    // { key: "ja", title: "日本語 Japanese" },
    // { key: "ko", title: "한국어 Korean" },
    // { key: "fr", title: "Français French" },
    // { key: "es", title: "Español Spanish" },
    // { key: "id", title: "Bahasa Indonesian" },
    // { key: "ru", title: "Русский Russian" },
    // { key: "th", title: "ไทย Thai" },
    // { key: "tr", title: "Türkçe Turkish" },
    // { key: "vi", title: "Tiếng Việt Vietnamese" },
]

export function LanguageSwitcher() {
    const { i18n } = useTranslation()
    const [language, setLanguage] = useState(i18n.language)

    const handleChangeLanguage = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
        e => {
            i18n.changeLanguage(e.target.value)
            setLanguage(e.target.value)
        },
        [i18n],
    )

    return (
        <Box py={{ base: 3, md: 4 }} px={{ base: 6, md: 6 }}>
            <Select
                name="language"
                variant="filled"
                borderRadius="lg"
                size="sm"
                value={language}
                onChange={handleChangeLanguage}
            >
                {LANGUAGE_LIST.map(({ key, title }) => (
                    <option key={key} value={key}>
                        {title}
                    </option>
                ))}
            </Select>
        </Box>
    )
}
