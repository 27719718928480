import type {
    NonSettlementCollateralToken,
    SettlementToken,
    WalletDataAll,
    Wallet as WalletType,
} from "@perp/sdk-curie"
import Big from "big.js"
import { useCallback, useEffect, useMemo, useState } from "react"
import { usePerpConnectedEffect } from "sdk-react/perpetualProtocol/usePerpConnectedEffect"
import { usePerpConnectedEffectAsync } from "sdk-react/perpetualProtocol/usePerpConnectedEffectAsync"
import { usePerpTransactionSender } from "sdk-react/perpetualProtocol/usePerpTransactionSender"
import { Handlers } from "sdk-react/tools/useHandlers"
import { TxNotifiers } from "sdk-react/tools/useTxNotifiers"
import { createContainer } from "unstated-next"

interface IApproveDepositArgs {
    token: NonSettlementCollateralToken | SettlementToken
    amount?: Big
}

export interface ICollateralTokenInfo {
    token: NonSettlementCollateralToken | SettlementToken
    symbol: string
    name: string
    weight: number
    depositCap: Big | undefined
    depositAmount: Big
}

export const Wallet = createContainer(useWallet)

function useWallet() {
    // NOTE: states
    const [isLoading, setIsLoading] = useState(true)
    const [isApproveLoading, setIsApproveLoading] = useState(false)
    const [allowanceList, setAllowanceList] = useState<Big[]>()
    const [balanceList, setBalanceList] = useState<Big[]>()
    const [balanceEth, setBalanceEth] = useState<Big>()
    const [_collateralTokenPriceList, setCollateralTokenPriceList] = useState<number[]>()
    const [_collateralTokenInfoList, setCollateralTokenInfoList] = useState<ICollateralTokenInfo[]>()

    // NOTE: handlers
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()

    // NOTE: subscriptions
    usePerpConnectedEffect(({ wallet }) => {
        const unsubscribeUpdated = wallet.on("updatedWalletDataAll", (walletDataAll: WalletDataAll) => {
            const collateralTokenList = wallet.collateralTokenList
            const allowanceList = collateralTokenList.map(
                collateralToken => walletDataAll[`${collateralToken.address}`].allowance,
            )
            const balanceList = collateralTokenList.map(
                collateralToken => walletDataAll[`${collateralToken.address}`].balance,
            )
            const priceList = collateralTokenList.map(
                collateralToken => walletDataAll[`${collateralToken.address}`].price,
            )
            setAllowanceList(allowanceList)
            setIsApproveLoading(false)
            setBalanceList(balanceList)
            setCollateralTokenPriceList(priceList)
        })

        const unsubscribeError = wallet.on("updateError", ({ error }) => {
            onError(error)
        })

        const unsubscribeBalanceEth = wallet.on("balanceEthUpdated", async (wallet: WalletType) => {
            setBalanceEth(await wallet.getBalanceEth())
        })

        return () => {
            unsubscribeUpdated()
            unsubscribeError()
            unsubscribeBalanceEth()
        }
    }, [])

    usePerpConnectedEffectAsync(async ({ wallet, metadata }) => {
        const collateralTokenList = wallet.collateralTokenList
        const collateralTokenInfoList: ICollateralTokenInfo[] = []
        const walletDataAllCollateralInfo = await wallet.getWalletDataAllCollateralInfo()
        collateralTokenList.forEach(collateralToken => {
            const collateralTokenAddress = collateralToken.address
            const collateralInfo = walletDataAllCollateralInfo[`${collateralTokenAddress}`]
            collateralTokenInfoList.push({
                token: collateralToken,
                symbol: collateralInfo.symbol,
                name: collateralInfo.name,
                weight: collateralInfo.weight,
                depositCap: collateralInfo.depositCap,
                depositAmount: collateralInfo.depositedAmount,
            })
        })
        setCollateralTokenInfoList(collateralTokenInfoList)
    }, [])

    // NOTE: merge all collateral data.
    const collateralList = useMemo(() => {
        if (
            !_collateralTokenPriceList ||
            !_collateralTokenInfoList ||
            _collateralTokenPriceList.length !== _collateralTokenInfoList.length
        ) {
            return
        }
        return _collateralTokenInfoList.map((info, index) => ({
            ...info,
            price: _collateralTokenPriceList[index],
        }))
    }, [_collateralTokenInfoList, _collateralTokenPriceList])

    // NOTE: native effects
    useEffect(() => {
        if (!!balanceList) {
            setIsLoading(false)
        }
    }, [balanceList])

    const shouldApproveDeposit = useCallback(
        (amount: Big, collateralSymbol: string) => {
            if (!_collateralTokenInfoList) {
                return
            }
            const collateralIndex = _collateralTokenInfoList.findIndex(
                collateral => collateral.symbol === collateralSymbol,
            )
            if (collateralIndex === -1) {
                return
            }

            const allowance = allowanceList && allowanceList[collateralIndex]
            if (!allowance) {
                return
            }
            return amount.gt(allowance)
        },
        [allowanceList, _collateralTokenInfoList],
    )

    // NOTE: notifications
    const { getTxNotifiers } = TxNotifiers.useContainer()
    const { onFailure, onRejected } = getTxNotifiers()
    const _approveDeposit = usePerpTransactionSender<IApproveDepositArgs>(({ wallet }, args) => {
        setIsApproveLoading(true)
        const { token, amount } = args
        return wallet.approve(token, amount)
    }, [])
    // NOTE: Temporary solution to reset `isApproveLoading` when rejected/failed.
    // TODO: Invoke transaction `onSuccess` & reset `txLoading` by contract event instead of right after `tx.wait()` can eliminate this workaround.
    const approveDeposit = (args: IApproveDepositArgs) =>
        _approveDeposit({
            ...args,
            options: {
                onRejected: error => {
                    onRejected(error)
                    setIsApproveLoading(false)
                },
                onFailure: error => {
                    onFailure(error)
                    setIsApproveLoading(false)
                },
            },
        })
    const findCollateralTokenInfo = (tokenAddressWithLowercase: string) =>
        _collateralTokenInfoList?.find(info => info.token.address.toLocaleLowerCase() === tokenAddressWithLowercase)

    return {
        isLoading,
        isApproveLoading,
        allowanceList,
        balanceList,
        balanceEth,
        collateralList,
        approveDeposit,
        shouldApproveDeposit,
        findCollateralTokenInfo,
    }
}
