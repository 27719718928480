export enum Stage {
    DEV1 = "dev1",
    DEV2 = "dev2",
    STAGING = "staging",
    PRODUCTION = "production",
}

export function getStage(stage?: string): Stage {
    switch (stage) {
        case "production": {
            return Stage.PRODUCTION
        }
        case "dev1": {
            return Stage.DEV1
        }
        case "dev2": {
            return Stage.DEV2
        }
        case "staging":
        default: {
            return Stage.STAGING
        }
    }
}
