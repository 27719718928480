import {
    Badge,
    Center,
    Divider,
    Flex,
    HStack,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Spacer,
    Switch,
    Text,
    VStack,
    useColorModeValue,
} from "@chakra-ui/react"
import { ModalRootProps } from "component/Modal/type"
import { PerpModal } from "component/PerpModal"
import { StorageDataMap } from "constant/storage"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "react-use"

import { Slippage } from "../container/slippage"

const { DYNAMIC_FAVICON } = StorageDataMap

export function ModalSettings({ isOpen, close }: ModalRootProps) {
    const { t } = useTranslation()
    const subText = useColorModeValue("gray.500", "gray.400")

    const { slippagePercentage, updateSlippagePercentage } = Slippage.useContainer()
    const handleChangeGlobalSlippage = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        e => {
            updateSlippagePercentage(Number(e.target.value))
        },
        [updateSlippagePercentage],
    )

    const [dynamicFavicon, setDynamicFavicon] = useLocalStorage(DYNAMIC_FAVICON.name, DYNAMIC_FAVICON.defaultValue)

    return (
        <PerpModal
            isOpen={isOpen}
            onClose={close}
            header={t("settings.settings")}
            body={
                <VStack spacing={6} align="stretch">
                    {/* NOTE: Global Slippage */}
                    <VStack align="stretch" width="100%">
                        <HStack>
                            <Text>{t("settings.slippage_tolerance")}</Text>
                            <Spacer />
                            <InputGroup w={"120px"} size={"md"}>
                                <Input
                                    fontWeight="semibold"
                                    value={slippagePercentage.toNumber()}
                                    onChange={handleChangeGlobalSlippage}
                                    type="number"
                                />
                                <InputRightElement mr={1} children={<Badge>%</Badge>} />
                            </InputGroup>
                        </HStack>
                        <Text fontSize="xs" color={subText}>
                            {t("settings.slippage_warning")}
                        </Text>
                    </VStack>

                    {/* NOTE: Dynamic Favicon */}
                    <Divider />
                    <VStack align="stretch" width="100%">
                        <HStack>
                            <Text>{t("settings.dynamic_favicon")}</Text>
                            <Spacer />
                            <Switch
                                isChecked={dynamicFavicon === "true"}
                                onChange={() => setDynamicFavicon(dynamicFavicon === "true" ? "false" : "true")}
                                colorScheme={"perpetual"}
                                size="lg"
                            />
                        </HStack>
                        <Text fontSize="xs" color={subText}>
                            {t("settings.dynamic_favicon_desc")}
                        </Text>
                    </VStack>

                    {/* NOTE: Appearance */}
                    {/* <Divider />
                    <HStack>
                        <Text>{t("settings.appearance")}</Text>
                        <Spacer />
                        <Badge colorScheme="orange">In Development</Badge>
                        <ColorModeSwitcher />
                    </HStack> */}

                    {/* NOTE: Beta Program */}
                    {/* <Divider />
                    <VStack align="stretch" width="100%">
                        <HStack>
                            <Text>{t("settings.beta_program")}</Text>
                            <Spacer />
                            <Button onClick={() => alert("Not support yet")} size="sm">
                                {t("settings.beta_program_join")}
                            </Button>
                        </HStack>
                        <Text fontSize="xs" textAlign="left" color={subText}>
                            {t("settings.beta_program_content")}
                        </Text>
                    </VStack> */}

                    {/* NOTE: Logo */}
                    <Divider />
                    <Flex flexDirection={["column", "row"]}>
                        <Center>
                            <Image h={["auto", "90px"]} w={["200px", "auto"]} src="/v2cover.png" alt="v2 Curie" />
                        </Center>
                        <Center px={5}>
                            <Text fontSize="xs" textAlign="center" color={subText}>
                                {t("general.version_name_curie")}
                            </Text>
                        </Center>
                    </Flex>
                </VStack>
            }
        />
    )
}
