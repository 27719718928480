import { formatInput } from "util/format"

export type Formatter = typeof defaultFormatter

// NOTE: defaultFormatter might contain e in some edge cases
export function defaultFormatter({ input, precision = 0 }: { input: string | number; precision?: number }) {
    let value = `${input}`

    // NOTE: strip all invalid characters
    value = value.replace(/\+|-/g, "")

    // NOTE: strip leading 0s
    value = value.replace(/^0+(?=\d)/, "")

    // NOTE: if digits = 2, then convert "1.005" -> "1.00"
    let formattedValue = value
    if (precision > 0) {
        formattedValue = formatInput(value, precision)
    }

    return formattedValue
}
