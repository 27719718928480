const colors = {
    contrast: {
        50: "rgba(255, 255, 255, 0.44)",
        100: "rgba(255, 255, 255, 0.56)",
        200: "rgba(255, 255, 255, 0.68)",
        300: "rgba(255, 255, 255, 0.76)",
        400: "rgba(255, 255, 255, 0.84)",
        500: "rgba(255, 255, 255, 0.96)",
        600: "rgba(255, 255, 255, 0.98)",
        700: "rgba(255, 255, 255, 0.94)",
        800: "rgba(255, 255, 255, 0.90)",
        900: "rgba(255, 255, 255, 0.92)",
    },
    red: {
        500: "rgba(229,62,62,1)",
        600: "rgba(215,42,42,1)",
        700: "rgba(178,45,45,1)",
        800: "rgba(143,46,46,1)",
        900: "rgba(111,44,44,1)",
        400: "rgba(234,98,98,1)",
        300: "rgba(239,135,135,1)",
        200: "rgba(244,171,171,1)",
        100: "rgba(249,207,207,1)",
        50: "rgba(253,243,243,1)",
    },
    gray: {
        500: "rgba(123,130,141,1)",
        600: "rgba(95,100,108,1)",
        700: "rgba(67,70,74,1)",
        800: "rgba(38,40,42,1)",
        900: "rgba(9,9,10,1)",
        400: "rgba(145,153,167,1)",
        300: "rgba(169,177,191,1)",
        200: "rgba(194,201,213,1)",
        100: "rgba(221,226,234,1)",
        50: "rgba(250,251,252,1)",
    },
    green: {
        500: "rgba(56,161,105,1)",
        600: "rgba(50,133,89,1)",
        700: "rgba(43,107,73,1)",
        800: "rgba(35,81,57,1)",
        900: "rgba(26,56,40,1)",
        400: "rgba(64,200,127,1)",
        300: "rgba(94,217,151,1)",
        200: "rgba(127,231,175,1)",
        100: "rgba(163,242,200,1)",
        50: "rgba(202,250,224,1)",
    },
    teal: {
        500: "rgba(49,151,149,1)",
        600: "rgba(52,130,129,1)",
        700: "rgba(53,111,110,1)",
        800: "rgba(54,94,93,1)",
        900: "rgba(52,77,77,1)",
        400: "rgba(55,205,202,1)",
        300: "rgba(95,225,223,1)",
        200: "rgba(140,240,238,1)",
        100: "rgba(190,250,249,1)",
        50: "rgba(246,255,255,1)",
    },
    perpetual: {
        500: "rgba(0,181,216,1)",
        600: "rgba(12,150,177,1)",
        700: "rgba(20,122,142,1)",
        800: "rgba(25,96,110,1)",
        900: "rgba(27,72,81,1)",
        400: "rgba(8,215,255,1)",
        300: "rgba(54,222,255,1)",
        200: "rgba(101,230,255,1)",
        100: "rgba(147,238,255,1)",
        50: "rgba(194,245,255,1)",
    },
    orange: {
        500: "rgba(255,149,0,1)",
        400: "rgba(255,169,49,1)",
        300: "rgba(255,190,98,1)",
        200: "rgba(255,210,147,1)",
        100: "rgba(255,230,196,1)",
        600: "rgba(212,125,3,1)",
        700: "rgba(170,101,4,1)",
        800: "rgba(129,78,5,1)",
        900: "rgba(90,54,5,1)",
        50: "rgba(255,251,245,1)",
    },
    risk: {
        low: "#3CEAAA",
        medium: "#FFB459",
        high: "#F16769",
    },
    logo: {
        main: "rgba(194,201,213,1)",
        sub: "rgba(250,251,252,1)",
        accent: "rgba(221,226,234,1)",
        textMain: "rgba(250,251,252,1)",
        textSub: "rgba(194,201,213,1)",
    },
    sidebar: {
        light: "rgba(248,248,248,1)",
        // light: "rgba(221,226,234,0.2)",
        // dark: "#040404",
        dark: "rgba(29, 29, 29, 1.000)",
    },
    cardBg: {
        light: "rgba(255,255,255,1)",
        dark: "rgba(38,40,42,1)",
    },
    bottomSheetBg: {
        light: "rgba(255,255,255,0.95)",
        dark: "rgba(38,40,42,0.95)",
    },
    cardSubBg: {
        light: "rgba(250,251,252,1)",
        dark: "rgba(255, 255, 255, 0.08)",
    },
    separator: {
        light: "rgba(194,201,213,0.5)",
        dark: "rgba(67,70,74,0.8)",
    },
    dragBar: {
        light: "rgba(194,201,213,0.85)",
        dark: "rgba(255,255,255,0.63)",
    },
    subBackground: {
        light: "#f3f4f5",
        dark: "rgba(9,9,10,1)",
    },
    subText: {
        light: "rgba(123,130,141,1)",
        dark: "rgba(145,153,167,1)",
    },
    trade: {
        short: "#EA6262",
        long: "#48CCA0",
    },
}

export default colors
