import { IconButton } from "@chakra-ui/button"
import { useColorModeValue } from "@chakra-ui/color-mode"
import { VStack } from "@chakra-ui/react"
import { Back } from "component/Icon"
import { RouteName } from "constant/route"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"

import { Title } from "./Title"

interface StackProps {
    onClickBack?: () => void
    hideBackButton?: boolean
}

export function NavStack({ onClickBack, hideBackButton }: StackProps) {
    const history = useHistory()
    const backButtonBg = useColorModeValue("gray.100", "gray.700")

    const handleClickGoBack = useCallback(() => {
        /**
         * NOTE: Priority for go-back button:
         * 1. Go back to last page
         * 2. Handle by onClickBack
         * 3. Default to home page
         *
         * NOTE: The minimal situation for history.length would be 2.
         * (1. new tab blank page 2. our site)
         */
        if (history.length > 2) {
            history.goBack()
        } else {
            return onClickBack ? onClickBack() : history.push(RouteName.ROOT)
        }
    }, [history, onClickBack])

    return (
        <VStack position="relative" w="full" px={6} pb={0}>
            {!hideBackButton && (
                <IconButton
                    position="fixed"
                    top={"calc(env(safe-area-inset-top) + 12px)"}
                    left={6}
                    zIndex={2}
                    bg={backButtonBg}
                    overflow="hidden"
                    borderRadius="full"
                    icon={<Back boxSize="16px" />}
                    aria-label="Go Back"
                    size="sm"
                    onClick={handleClickGoBack}
                />
            )}
            <Title />
        </VStack>
    )
}
