import SegmentService, { EventGroup, EventTypeContract } from "module/analytics/service/SegmentService"

import { useCallback } from "react"
import { ITxNotifiers } from "sdk-react/tools/useTxNotifiers"

import { useNotification } from "./useNotification"
import { useSDKErrorParser } from "./useSDKErrorParser"

export function useNotificationTx() {
    const { notifySuccess, notifyInfo, notifyError } = useNotification()
    const { parseSdkErrorMessage: parser } = useSDKErrorParser()

    const notifyTxSent = useCallback<ITxNotifiers["onSent"]>(
        tx => {
            notifyInfo({
                title: "Transaction Sent",
                description: "It might take a few seconds",
            })
        },
        [notifyInfo],
    )

    const notifyTxSucceed = useCallback<ITxNotifiers["onSuccess"]>(
        (receipt, contractFunctionName) => {
            notifySuccess({
                title: "Transaction Confirmed",
                description: "Amounts should be updated shortly.",
            })
            SegmentService.track(EventGroup.CONTRACT, {
                eventType: EventTypeContract.TX_SUCCEEDED,
                eventName: contractFunctionName,
                transactionId: receipt.transactionHash,
            })
        },
        [notifySuccess],
    )
    // TODO: Decouple error from application layer and sdk error parser
    const notifyTxFailure = useCallback<ITxNotifiers["onFailure"]>(
        error => {
            const { description, message } = parser(error)
            notifyError({
                title: "Transaction Failed",
                description: description || message,
            })
            SegmentService.track(EventGroup.CONTRACT, {
                eventType: EventTypeContract.TX_FAILED,
                eventName: `${String(error.contractFunctionName)}`,
            })
        },
        [notifyError, parser],
    )

    const notifyTxRejected = useCallback<ITxNotifiers["onRejected"]>(
        error => {
            notifyError({
                title: "Transaction Rejected",
            })
            SegmentService.track(EventGroup.CONTRACT, {
                eventType: EventTypeContract.TX_REJECTED,
                eventName: `${String(error.contractFunctionName)}`,
            })
        },
        [notifyError],
    )

    return {
        notifyTxSent,
        notifyTxSucceed,
        notifyTxFailure,
        notifyTxRejected,
    }
}
