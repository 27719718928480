import { useEffect } from "react"
import { useLocation } from "react-router-dom"

// when url changes, scroll to top
// via https://v5.reactrouter.com/web/guides/scroll-restoration

export default function useScrollToTop() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}
