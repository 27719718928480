import { Text, VStack, useColorModeValue } from "@chakra-ui/react"
import { Position } from "@perp/sdk-curie"
import { useTranslation } from "react-i18next"

import { MakerPositionImpermanent } from "../PoolDetail/MakerPositionImpermanent"

interface MakerPositionRealizingProps {
    position?: Position
    ratio: number
}

export default function MakerPositionRealizing({ position, ratio }: MakerPositionRealizingProps) {
    const { t } = useTranslation()
    const subText = useColorModeValue("gray.500", "gray.400")

    return (
        <>
            <VStack align="stretch" spacing={2}>
                <Text fontSize="sm" fontWeight="semibold">
                    {t("pool.impermanent_to_permanent_position")}
                </Text>
            </VStack>

            <Text fontSize="sm" color={subText}>
                {t("pool.impermanent_to_permanent_position_warning")}
            </Text>
            {position && <MakerPositionImpermanent position={position} ratio={ratio} />}
        </>
    )
}
