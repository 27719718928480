import { useMarket } from "sdk-react/markets/useMarket"

import { Pools } from "./usePools"

interface UsePoolInitialState {
    tickerSymbol: string
}
export const usePool = ({ tickerSymbol }: UsePoolInitialState) => {
    const { isLoading, pools } = Pools.useContainer()
    const { market, marketPrice, indexPrice, status } = useMarket(tickerSymbol)
    const pool = pools.find(pool => market?.baseAddress === pool.baseAddress)

    return {
        isLoading,
        status,
        pool,
        marketPrice,
        indexPrice,
    }
}
