import { useEffect } from "react"
import { Wallet } from "sdk-react/wallet/useWallet"
import { createContainer } from "unstated-next"

import SegmentService, { EventGroup } from "../service/SegmentService"
import { EventName } from "../types"

export const SegmentWatcher = createContainer(useSegmentWatcher)

function useSegmentWatcher() {
    const { balanceEth, balanceList } = Wallet.useContainer()

    // NOTE: ETH balance updated
    const balanceEthStr = balanceEth?.toString()
    useEffect(() => {
        if (!!balanceEthStr) {
            SegmentService.track(EventGroup.UI_FEEDBACK, {
                eventName: EventName.WALLET_BALANCE_ETH_UPDATED,
                payload: { balanceEthStr },
            })
        }
    }, [balanceEthStr])

    // NOTE: Collateral balance updated
    // TODO(mc): temporary workaround, need to be refactored
    const firstBalance = balanceList?.[0]
    const balanceCollateralStr = firstBalance?.toString()
    useEffect(() => {
        if (!!balanceCollateralStr) {
            SegmentService.track(EventGroup.UI_FEEDBACK, {
                eventName: EventName.VAULT_BALANCE_COLLATERAL_UPDATED,
                payload: { balanceCollateralStr },
            })
        }
    }, [balanceCollateralStr])
}
