import { ChainStatus, RetryProvider } from "@perp/sdk-curie"
import { useEffect, useState } from "react"
import { usePerpConnectedEffect } from "sdk-react/perpetualProtocol/usePerpConnectedEffect"

const NETWORK_STATUS_INTERVAL = 5000

export default function useNetworkStatus() {
    const [provider, setProvider] = useState<RetryProvider>()
    const [status, setStatus] = useState<ChainStatus>()

    usePerpConnectedEffect(({ provider }) => {
        setProvider(provider)
    }, [])

    useEffect(() => {
        if (!provider) {
            return
        }

        const tid = setInterval(async () => {
            const latestStatus = await provider.getChainStatus()
            setStatus(latestStatus)
        }, NETWORK_STATUS_INTERVAL)

        return () => {
            clearInterval(tid)
        }
    }, [provider])

    return {
        blockNumber: status?.blockNumber,
        isSyncing: status?.isSyncing,
    }
}
