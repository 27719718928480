import { Box } from "@chakra-ui/layout"
import jazzicon from "@metamask/jazzicon"
import { useEffect, useRef } from "react"

function iconForAddress(address: string, diameter: number) {
    const numericRepresentation = jsNumberForAddress(address)
    const identicon = jazzicon(diameter, numericRepresentation)
    return identicon
}

function jsNumberForAddress(address: string) {
    const addr = address.slice(2, 10)
    const seed = parseInt(addr, 16)
    return seed
}

const Jazzicon = function ({ address, diameter = 46 }: { address: string; diameter?: number }) {
    const container = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (container.current) {
            const image = iconForAddress(address, diameter)
            container.current.appendChild(image)
        }

        const node = container.current
        return () => {
            if (node) {
                node.innerHTML = ""
            }
        }
    }, [address, diameter])

    return <Box display="flex" alignItems="center" ref={container} />
}

export { Jazzicon }
