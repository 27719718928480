import { CURIE_DEPLOYMENTS_VERSION, GITHUB_TAG, SDK_CURIE_VERSION, SEGMENT_API_KEY } from "constant/envVariables"
import { EventName } from "../types"

export enum EventGroup {
    CONTRACT = "CONTRACT", // NOTE: for things that happens on-chain.
    INTERACTION = "INTERACTION", // NOTE: for things that user does actively to the user interface.
    UI_FEEDBACK = "UI_FEEDBACK", // NOTE: for changes on the user interface passively.
}

export enum EventTypeContract {
    TX_SENT = "TX_SENT",
    TX_SUCCEEDED = "TX_SUCCEEDED",
    TX_REJECTED = "TX_REJECTED",
    TX_FAILED = "TX_FAILED",
}

export enum EventTypeInteraction {
    BUTTON_CLICKED = "BUTTON_CLICKED",
    INPUT_FOCUSED = "INPUT_FOCUSED",
    INPUT_UNFOCUSED = "INPUT_UNFOCUSED",
    FORM_SUBMITTED = "FORM_SUBMITTED",
    TAB_FOCUSED = "TAB_FOCUSED",
    RADIO_GROUP_SELECTED = "RADIO_GROUP_SELECTED",
    MODAL_OPENED = "MODAL_OPENED",
    MODAL_CLOSED = "MODAL_CLOSED",
    LINK_CLICKED = "LINK_CLICKED",
    MENU_ITEM_CLICKED = "MENU_ITEM_CLICKED",
    SLIDER_CHANGED_ENDED = "SLIDER_CHANGED_ENDED",
    OTHERS = "OTHERS",
}

export enum EventTypeUIFeedback {
    SUCCESS_DISPLAYED = "SUCCESS_DISPLAYED",
    WARNING_DISPLAYED = "WARNING_DISPLAYED",
    ERROR_DISPLAYED = "ERROR_DISPLAYED",
    INFO_DISPLAYED = "INFO_DISPLAYED",
}

export type EventType = EventTypeContract | EventTypeInteraction | EventTypeUIFeedback

export interface TrackEventProperties {
    eventName: string // NOTE: UID for the event.
    eventType?: EventType
    module?: string // NOTE: Feature grouping.
    component?: string // NOTE: UI component.
    description?: string // NOTE: In case multiple instance exists on the same url, add this to differentiate.
    versionFrontend?: string
    versionContractCore?: string
    versionSDK?: string
    payload?: { [key: string]: string | number | boolean } // NOTE: Custom fields for the event.
}

interface TrackEventPropertiesContract extends TrackEventProperties {
    eventType?: EventTypeContract
    transactionId?: string
}
interface TrackEventPropertiesInteraction extends TrackEventProperties {
    eventType?: EventTypeInteraction
}
interface TrackEventPropertiesUIFeedback extends TrackEventProperties {
    eventType?: EventTypeUIFeedback
}

export interface TrackingProperties {
    [attribute: string]: number | string
}
class SegmentService {
    constructor(
        readonly writeKey?: string,
        readonly versionFrontend?: string,
        readonly versionContractCore?: string,
        readonly versionSDK?: string,
    ) {
        if (!this.writeKey) {
            console.warn("Segment writeKey is not provided.")
            return
        }
        analytics.load(this.writeKey)
        analytics.track("App Start", { version: this.versionFrontend })
        this.tryFixAnonymousId()
    }

    identify(account: string) {
        analytics.identify(account)
    }

    getUserId() {
        // Some ad blockers will remove `user()` function.
        return analytics.user?.().id?.() || analytics.user?.().anonymousId?.()
    }

    page(pageInfo?: TrackingProperties) {
        analytics.page(pageInfo)
    }

    track(eventGroup: EventGroup.CONTRACT, properties: TrackEventPropertiesContract): void
    track(eventGroup: EventGroup.INTERACTION, properties: TrackEventPropertiesInteraction): void
    track(eventGroup: EventGroup.UI_FEEDBACK, properties: TrackEventPropertiesUIFeedback): void
    track(eventGroup: EventGroup, properties: TrackEventProperties) {
        const props: TrackEventProperties = {
            versionFrontend: this.versionFrontend,
            versionContractCore: this.versionContractCore,
            versionSDK: this.versionSDK,
            ...properties,
        }
        analytics.track(eventGroup, props)
    }

    /**
     * NOTE: This cleans up the anonymous_id in local storage if it is different from the one in the cookie.
     * They are normally kept in-sync by the SDK but is made possible due to incorrectly calling `analytics.reset()` previously.
     * (PR that fixed it: https://github.com/perpetual-protocol/perp-exchange/pull/927/files)
     *
     * Calling `reset()` regenerates the anonymous_id in both cookie and local storage but since cookie is shared across subdomains,
     * the anonymous_id in cookie and local storage will be different when the cookie is updated by another subdomain.
     *
     * Since when the anonymous_id does not exist in local storage the SDK will sync the anonymous_id from the cookie,
     * by clearing it from the local storage we can ensure the anonymous_id across all subdomains will eventually be synced via the shared cookie.
     */
    private tryFixAnonymousId() {
        const cookie_anonymous_id = document.cookie
            .split("; ")
            .find(row => row.startsWith("ajs_anonymous_id="))
            ?.split("=")[1]

        const local_storage_anonymous_id = localStorage.getItem("ajs_anonymous_id")?.replace(/^"(.*)"$/, "$1")

        const id_diff = cookie_anonymous_id !== local_storage_anonymous_id

        if (id_diff) {
            localStorage.removeItem("ajs_anonymous_id")
            this.track(EventGroup.INTERACTION, {
                eventName: EventName.FIX_ANONYMOUS_ID,
                payload: {
                    cookie_anonymous_id: `${cookie_anonymous_id}`,
                    local_storage_anonymous_id: `${local_storage_anonymous_id}`,
                },
            })
        }
    }
}

const segmentService = new SegmentService(SEGMENT_API_KEY, GITHUB_TAG, CURIE_DEPLOYMENTS_VERSION, SDK_CURIE_VERSION)
export default segmentService
