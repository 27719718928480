import { formatNumberUSD } from "util/format"

import Big from "big.js"
import { useMemo } from "react"
import { ICollateralTokenInfo, Wallet } from "sdk-react/wallet/useWallet"

const PLACEHOLDER = "-,---"

export function useWalletStrings() {
    const { isLoading, allowanceList, balanceList, balanceEth, collateralList } = Wallet.useContainer()

    const balanceEthStr = balanceEth ? balanceEth.round(3).toString() : PLACEHOLDER

    const balanceStrList = useMemo(() => {
        return _createWalletRelatedStrList(collateralList, balanceList)
    }, [collateralList, balanceList])

    const allowanceStrList = useMemo(() => {
        return _createWalletRelatedStrList(collateralList, allowanceList)
    }, [collateralList, allowanceList])

    return {
        isLoading,
        allowanceStrList,
        balanceStrList,
        balanceEthStr,
    }
}

function _createWalletRelatedStrList(
    collateralList: ICollateralTokenInfo[] | undefined,
    rawList: Big[] | undefined,
): string[] {
    const cLength = collateralList && collateralList.length
    if (!cLength) {
        return []
    }
    const arr = Array(cLength).fill(PLACEHOLDER)
    for (let i = 0; i < cLength; i++) {
        const data = rawList && rawList[i]
        data && (arr[i] = formatNumberUSD({ num: data, displayLength: 2, minimal: 2 }))
    }
    return arr
}
