import { Box, Button, Divider, Text, useColorModeValue, VStack } from "@chakra-ui/react"
import { Optimism } from "component/Icon"
import { ModalRootProps } from "component/Modal/type"
import { PerpModal } from "component/PerpModal"
import { useIsMd } from "hook/useBreakpoints"
import { DEFAULT_APP_CHAIN } from "module/network/constant"
import { AppNetwork } from "module/network/container/AppNetwork"
import { switchNetwork } from "module/network/util/switchNetwork"
import { ConnectorManager } from "module/wallet/ConnectorManager"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Web3WalletContainer } from "../../container/Web3WalletContainer"
import { getSupportedWallets } from "../../utils"
import { DisconnectButton } from "./DisconnectButton"
import { WalletButton } from "./WalletButton"

export function ModalConnectWallet({ isOpen, close }: ModalRootProps) {
    const { account } = Web3WalletContainer.useContainer()
    const { isAccountOnUnsupportedNetwork } = AppNetwork.useContainer()
    const handleSwitchNetwork = useCallback(() => {
        switchNetwork(DEFAULT_APP_CHAIN.id)
    }, [])

    const subText = useColorModeValue("gray.500", "gray.400")
    const { t } = useTranslation()
    const isMobile = useIsMd()

    return (
        <PerpModal
            isOpen={isOpen}
            onClose={close}
            header={t("wallet.connect")}
            body={
                <VStack spacing={4} w="100%" align="stretch">
                    <VStack spacing={4}>
                        {getSupportedWallets(ConnectorManager.getInstance()).map(
                            ({ type, name, connector, platform }) => {
                                if (
                                    (isMobile && platform.includes("mobile")) ||
                                    (!isMobile && platform.includes("desktop"))
                                ) {
                                    return <WalletButton key={type} type={type} name={name} connector={connector} />
                                }
                                return null
                            },
                        )}
                        {account && (
                            <>
                                <Divider />
                                <DisconnectButton />
                            </>
                        )}
                    </VStack>

                    {/* TODO: reuse component in network module */}
                    {isAccountOnUnsupportedNetwork && (
                        <>
                            <Text variant="label" textTransform="uppercase" color={subText}>
                                Network
                            </Text>
                            <Button
                                onClick={handleSwitchNetwork}
                                iconSpacing={4}
                                leftIcon={<Optimism boxSize="24px" />}
                                width="full"
                                colorScheme="yellow"
                            >
                                <Box flexGrow={1} textAlign="left">
                                    Switch to {DEFAULT_APP_CHAIN.alias}
                                </Box>
                            </Button>
                        </>
                    )}
                </VStack>
            }
        />
    )
}
