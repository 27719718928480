import { Button, ButtonProps } from "@chakra-ui/react"
import { WalletFill } from "component/Icon"
import { ModalType } from "component/Modal/type"
import { useModal } from "container/modal"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { trackOpenConnectWalletModal } from "service/segment/tracks"

interface ConnectWalletButtonProps extends ButtonProps {
    text?: string
}

export function ConnectWalletButton({ text, ...buttonProps }: ConnectWalletButtonProps) {
    const { t } = useTranslation()
    const { open: openConnectWalletModal } = useModal(ModalType.WALLET_CONNECTION)
    const handleConnectWalletModal = useCallback(() => {
        openConnectWalletModal()
        trackOpenConnectWalletModal()
    }, [openConnectWalletModal])
    return (
        <Button
            // TODO: Deep link to MetaMask when user inside normal browser.
            // as="a"
            // href="https://metamask.app.link/dapp/perp-exchange-sillyleo.vercel.app/"
            onClick={handleConnectWalletModal}
            leftIcon={<WalletFill boxSize="20px" />}
            size="md"
            colorScheme="perpetual"
            {...buttonProps}
        >
            {text || t("wallet.connect_wallet")}
        </Button>
    )
}
