import SegmentService, { EventGroup, EventTypeInteraction } from "module/analytics/service/SegmentService"
import { TrackingProps } from "module/analytics/types"

import { Slider, SliderProps } from "@chakra-ui/react"
import { useCallback } from "react"

export { SliderFilledTrack, SliderMark, SliderThumb, SliderTrack } from "@chakra-ui/react"
export type { SliderMarkProps } from "@chakra-ui/react"

interface PerpSliderProps extends SliderProps {
    trackingProps: TrackingProps
}

export const PerpSlider = (props: PerpSliderProps) => {
    const { children, onChangeEnd, trackingProps, ...leftProps } = props
    const handleOnChangeEnd = useCallback(
        (value: number) => {
            onChangeEnd && onChangeEnd(value)
            SegmentService.track(EventGroup.INTERACTION, {
                eventType: EventTypeInteraction.SLIDER_CHANGED_ENDED,
                ...trackingProps,
                payload: {
                    ...trackingProps.payload,
                },
            })
        },
        [onChangeEnd, trackingProps],
    )
    return (
        <Slider {...leftProps} onChangeEnd={handleOnChangeEnd}>
            {children}
        </Slider>
    )
}
