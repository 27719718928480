import { Heading, Text, useColorModeValue } from "@chakra-ui/react"

interface TitleTextProps {
    title: string
    subtitle?: string
}

function TitleText({ title, subtitle }: TitleTextProps) {
    const subText = useColorModeValue("gray.500", "gray.400")

    return (
        <Heading w="full" size={"xl"} mt={3}>
            {title}
            {subtitle && (
                <Text fontWeight="normal" fontSize="md" color={subText}>
                    {subtitle}
                </Text>
            )}
        </Heading>
    )
}

export { TitleText }
