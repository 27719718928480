import { AppNetwork } from "module/network/container/AppNetwork"
import { useNotification } from "module/notification/hook/useNotification"
import { useNotificationTx } from "module/notification/hook/useNotificationTx"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"

import { InitSDKError } from "@perp/sdk-curie"
import { useMemo } from "react"
import { PerpSDKProviderInitialState } from "sdk-react/PerpSDKProvider"
import { logger } from "service/bugsnag/logger"

import { AppConfig } from "../appConfig"

export function useInitState() {
    const { notifyError } = useNotification()
    const { notifyTxSucceed, notifyTxFailure, notifyTxRejected, notifyTxSent } = useNotificationTx()

    const { signer, account } = Web3WalletContainer.useContainer()
    const { appChainId, isAccountOnSupportedNetwork } = AppNetwork.useContainer()
    const { appConfig, error } = AppConfig.useContainer()
    const env = useMemo(() => {
        const { providerConfigs, graphServerConfigs } = appConfig || {}
        if (!providerConfigs || !graphServerConfigs) {
            // TODO Why null?
            return null
        }
        return {
            chainId: appChainId,
            appChainId,
            providerConfigs,
            graphServerConfigs,
        }
    }, [appChainId, appConfig])

    const handlers = useMemo(
        () => ({
            onUnconnected: () => {
                notifyError({
                    title: "Please connect wallet first",
                    isClosable: false,
                })
            },
            // TODO: should be able to control showing "switch network" here?
            onCheckNetwork: () => {},
            // TODO: turn on notifyError when the error popping times is controllable
            // TODO: display info for better customer support reference (e.g. bugsnag error ID so user can share screenshot for us to find the corresponding error log)
            // TODO: remove type any
            onError: (error: any) => {
                if (error instanceof InitSDKError) {
                    notifyError({
                        title: "Please refresh your browser or contact the dev team.",
                        isClosable: false,
                    })
                }
                logger.error(error)
            },
        }),
        [notifyError],
    )

    const txNotifiers = useMemo(
        () => ({
            onSent: notifyTxSent,
            onSuccess: notifyTxSucceed,
            // TODO: remove type any
            onFailure: (error: any) => {
                notifyTxFailure(error)
                logger.error(error)
            },
            onRejected: notifyTxRejected,
        }),
        [notifyTxFailure, notifyTxRejected, notifyTxSent, notifyTxSucceed],
    )

    const web3Wallet = useMemo(
        () => ({
            signer: isAccountOnSupportedNetwork ? signer : undefined,
            account: isAccountOnSupportedNetwork ? account : undefined,
        }),
        [account, isAccountOnSupportedNetwork, signer],
    )

    const state: PerpSDKProviderInitialState | null = useMemo(() => {
        return (
            env && {
                env,
                handlers,
                txNotifiers,
                web3Wallet,
            }
        )
    }, [env, handlers, txNotifiers, web3Wallet])

    return {
        state,
        error,
    }
}
