import { MarketsContainer } from "./MarketsContainer"

export function useMarket(tickerSymbol: string) {
    const { marketMap, marketDataMap, poolMetadataMap } = MarketsContainer.useContainer()
    const market = marketMap?.[tickerSymbol]
    const marketData = marketDataMap?.[tickerSymbol]
    const poolMetadata = poolMetadataMap?.[tickerSymbol]

    return {
        market,
        poolMetadata,
        ...marketData,
    }
}
