import SegmentService, { EventGroup, EventTypeInteraction } from "module/analytics/service/SegmentService"
import { TrackingProps } from "module/analytics/types"

import { IconButton, IconButtonProps } from "@chakra-ui/react"
import React, { useCallback } from "react"

export interface PerpIconButtonProps extends IconButtonProps {
    trackingProps?: TrackingProps
}

export function PerpIconButton({ onClick, trackingProps, ...props }: PerpIconButtonProps) {
    const handleOnClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        e => {
            onClick && onClick(e)
            trackingProps &&
                SegmentService.track(EventGroup.INTERACTION, {
                    eventType: EventTypeInteraction.BUTTON_CLICKED,
                    ...trackingProps,
                })
        },
        [onClick, trackingProps],
    )

    return <IconButton onClick={handleOnClick} {...props} />
}
