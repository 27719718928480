import { createContainer } from "unstated-next"
import { useState } from "react"

export const TxLoading = createContainer(useTxLoading)

function useTxLoading() {
    const [isTxLoading, setTxLoading] = useState(false)

    return {
        isTxLoading,
        setTxLoading,
    }
}
