import { Box, Button } from "@chakra-ui/react"
import { Bolt } from "component/Icon"
import { MotionBox } from "component/Motion"
import { useCallback } from "react"
import { trackSwitchForMeButton } from "service/segment/tracks"
import { DEFAULT_APP_CHAIN_ID } from "../constant"
import { switchNetwork } from "../util/switchNetwork"

export function SwitchNetworkForMeButton() {
    const handleClicked = useCallback(() => {
        trackSwitchForMeButton()
        switchNetwork(DEFAULT_APP_CHAIN_ID)
    }, [])

    return (
        <Button flexShrink={0} colorScheme="yellow" size="sm" fontSize="xs" onClick={handleClicked}>
            <Box boxSize="22px" mr="6px">
                <MotionBox
                    initial={{ rotateY: 0 }}
                    animate={{
                        rotateY: 180,
                        transition: {
                            repeat: Infinity,
                            duration: 0.8,
                            repeatType: "reverse",
                        },
                    }}
                >
                    <Bolt color={"yellow.900"} boxSize={"22px"} />
                </MotionBox>
            </Box>
            <span style={{ marginTop: "2px" }}>Switch for me</span>
        </Button>
    )
}
