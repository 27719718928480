import { EventName } from "module/analytics/types"
import { strToBig } from "util/format"

import { ChevronDownIcon } from "@chakra-ui/icons"
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    ButtonProps,
    Divider,
    HStack,
    Menu,
    MenuButton,
    MenuDivider,
    MenuList,
    MenuOptionGroup,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react"
import { Big } from "big.js"
import { MarketListIcon } from "component/MarketListIcon"
import { ModalRootProps } from "component/Modal/type"
import { PerpLink } from "component/PerpLink"
import { PerpMenuItemOption } from "component/PerpMenu"
import { PerpModal } from "component/PerpModal"
import { PerpNumberInput } from "component/PerpNumberInput/PerpNumberInput"
import { TxButton } from "component/TxButton"
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    trackClickedWithdrawAllInWithdrawalDialog,
    trackClickedWithdrawInWithdrawalDialog,
} from "service/segment/tracks"

import { ICollateralStr } from "../hook/useCollateralStrings"
import { useIsDepositWithdrawAmountValid } from "../hook/useIsDepositWithdrawAmountValid"
import { useVaultStrings } from "../hook/useVaultStrings"
import { IWithdrawOption, useWithdrawOptionList } from "../hook/useWithdrawOptionList"

interface ContentWithdrawProps {
    amount?: string
    setAmount: Dispatch<SetStateAction<string | undefined>>
    withdrawOption?: IWithdrawOption
    setWithdrawOptionIndex: Dispatch<SetStateAction<number>>
}

function ContentWithdraw({ amount, setAmount, withdrawOption, setWithdrawOptionIndex }: ContentWithdrawProps) {
    const { collateralSymbol, freeCollateralStr } = withdrawOption || {}
    const { withdrawOptionList } = useWithdrawOptionList()

    // NOTE: handlers
    const handleInputChange = useCallback(
        (value: string) => {
            setAmount(value)
        },
        [setAmount],
    )

    const { t } = useTranslation()
    const { isSettlementNegative } = useVaultStrings()

    return (
        <VStack spacing={6} align="stretch" py={3}>
            <VStack align="stretch">
                <HStack width="100%">
                    <Menu>
                        <MenuButton
                            as={Button}
                            leftIcon={<MarketListIcon boxSize="24px" coin={collateralSymbol} />}
                            rightIcon={<ChevronDownIcon />}
                            iconSpacing={{ base: "2px", sm: 3 }}
                        >
                            <Box display={{ base: "none", sm: "block" }}>{collateralSymbol?.toUpperCase()}</Box>
                        </MenuButton>
                        <MenuList>
                            <MenuOptionGroup type="radio">
                                {withdrawOptionList.map((item, index) => (
                                    <PerpMenuItemOption
                                        value={item.collateralSymbol}
                                        key={index}
                                        command={item.freeCollateralStr}
                                        textTransform="uppercase"
                                        onClick={() => {
                                            setWithdrawOptionIndex(index)
                                        }}
                                        trackingProps={{ eventName: EventName.VAULT_COLLATERAL_OPTION_CLICK }}
                                    >
                                        <HStack width="100%">
                                            <MarketListIcon boxSize="24px" coin={item.collateralSymbol} />{" "}
                                            <Text>{item.collateralSymbol}</Text>
                                            <Spacer />
                                            <Text opacity={0.65} fontSize="xs">
                                                {item.freeCollateralStr}
                                            </Text>
                                        </HStack>
                                    </PerpMenuItemOption>
                                ))}
                                <MenuDivider />
                                <Box px={6} py={2}>
                                    <Text fontSize="xs" fontWeight="normal" opacity={0.65}>
                                        ETH can also be withdrawn as WETH.
                                    </Text>
                                </Box>
                            </MenuOptionGroup>
                        </MenuList>
                    </Menu>
                    <Box flexGrow={1}>
                        <PerpNumberInput
                            placeholder={"0"}
                            textAlign="right"
                            value={amount?.toString()}
                            onChange={v => handleInputChange(v)}
                            trackingProps={{
                                eventName: EventName.VAULT_WITHDRAW,
                                payload: { amount: amount?.toString() || "" },
                            }}
                        />
                    </Box>
                </HStack>
                <HStack width="100%">
                    <Text opacity={0.65} fontSize="xs">
                        {t("vault.available_to_withdraw_amount", { amount: freeCollateralStr })}
                    </Text>
                </HStack>
            </VStack>
            <Alert display={isSettlementNegative ? "flex" : "none"} status="warning" mb={4} flexDirection={"column"}>
                <AlertIcon />
                <AlertTitle mb={2}>Withdrawal Disabled</AlertTitle>
                <AlertDescription>
                    Your USDC balance is in debt. Please deposit enough USDC to make the balance positive to enable
                    withdrawal.{" "}
                    <PerpLink
                        href="https://support.perp.com/hc/en-us/articles/5257432076569#heading-5"
                        trackingProps={{ eventName: EventName.LINK_LEARN_MORE_USDC_IS_TOO_LOW }}
                    >
                        <Text as="span" color="perpetual.500">
                            Learn more ↗
                        </Text>
                    </PerpLink>
                </AlertDescription>
            </Alert>
            <Divider />
            <Text fontSize={"sm"} opacity={0.65}>
                {t("vault.withdraw_hint")}
            </Text>
        </VStack>
    )
}

interface ButtonWithdrawProps extends ButtonProps {
    amountInput: Big | undefined
    withdrawOption?: IWithdrawOption
    onSuccess: () => void
}
function ButtonWithdraw({ amountInput, withdrawOption, onSuccess, ...meow }: ButtonWithdrawProps) {
    const { withdraw, freeCollateral } = withdrawOption || {}
    const isWithdrawAmountValid = useIsDepositWithdrawAmountValid(amountInput, freeCollateral)

    const handleWithdraw = useCallback(async () => {
        if (!amountInput || !withdraw || !isWithdrawAmountValid) {
            return false
        }
        trackClickedWithdrawInWithdrawalDialog(amountInput.toString())
        const receipt = await withdraw(amountInput)
        if (receipt) {
            onSuccess()
        }
    }, [amountInput, isWithdrawAmountValid, onSuccess, withdraw])

    const { t } = useTranslation()

    return (
        <TxButton colorScheme={"green"} disabled={!isWithdrawAmountValid} onClick={handleWithdraw} {...meow}>
            {t("vault.withdrawal")}
        </TxButton>
    )
}

interface ButtonWithdrawAllProps extends ButtonProps {
    withdrawOption: IWithdrawOption
    onSuccess: () => void
}
function ButtonWithdrawAll({ withdrawOption, onSuccess, ...rest }: ButtonWithdrawAllProps) {
    const { withdrawAll } = withdrawOption || {}

    const handleWithdrawAll = useCallback(async () => {
        if (!withdrawAll) {
            return false
        }
        trackClickedWithdrawAllInWithdrawalDialog()
        const receipt = await withdrawAll()
        if (receipt) {
            onSuccess()
        }
    }, [onSuccess, withdrawAll])

    const { t } = useTranslation()

    const { isSettlementNegative } = useVaultStrings()

    return (
        <TxButton colorScheme={"gray"} disabled={isSettlementNegative} onClick={handleWithdrawAll} {...rest}>
            {t("vault.withdrawalAll")}
        </TxButton>
    )
}

export interface ModalWithdrawParams {
    collateralData?: ICollateralStr
}

export function ModalWithdraw({ isOpen, close, params }: ModalRootProps<ModalWithdrawParams | undefined>) {
    const { collateralData } = params || {}
    const { symbol: collateralSymbol } = collateralData || {}
    const { withdrawOptionList } = useWithdrawOptionList()

    const defaultWithdrawOptionIndex = Math.max(
        withdrawOptionList.findIndex(option => option.collateralSymbol === collateralSymbol),
        0, // NOTE: default to first option when default not found (when findIndex return -1)
    )
    const [withdrawOptionIndex, setWithdrawOptionIndex] = useState(defaultWithdrawOptionIndex)
    const withdrawOption = withdrawOptionList[withdrawOptionIndex]

    const [amountString, setAmountString] = useState<string>()
    const amountBig = useMemo(() => strToBig(amountString || ""), [amountString])

    const { t } = useTranslation()

    return (
        <PerpModal
            useInert={false} // so the slippage popover can be clicked
            isOpen={isOpen}
            onClose={close}
            header={t("vault.withdraw")}
            body={
                <ContentWithdraw
                    amount={amountString}
                    setAmount={setAmountString}
                    withdrawOption={withdrawOption}
                    setWithdrawOptionIndex={setWithdrawOptionIndex}
                />
            }
            footer={
                <HStack width="100%">
                    <ButtonWithdraw
                        amountInput={amountBig}
                        withdrawOption={withdrawOption}
                        onSuccess={close}
                        flexGrow={0}
                        flexShrink={1}
                    />
                    <ButtonWithdrawAll width="1fr" flexGrow={1} withdrawOption={withdrawOption} onSuccess={close} />
                </HStack>
            }
        />
    )
}
