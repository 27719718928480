import { useState } from "react"
import { usePerpConnectedCallbackAsync } from "sdk-react/perpetualProtocol/usePerpConnectedCallbackAsync"
import { usePerpConnectedEffect } from "sdk-react/perpetualProtocol/usePerpConnectedEffect"
import { usePerpTransactionSender } from "sdk-react/perpetualProtocol/usePerpTransactionSender"
import { Handlers } from "sdk-react/tools/useHandlers"
import { TxLoading } from "sdk-react/tools/useTxLoading"
import { createContainer } from "unstated-next"

export const DelegateApproval = createContainer(useDelegateApproval)

export function useDelegateApproval() {
    const { getHandlers } = Handlers.useContainer()
    const { isTxLoading } = TxLoading.useContainer()
    const { onError } = getHandlers()
    const [isChecking, setIsChecking] = useState(true)
    const [isApproved, setIsApproved] = useState(false)

    const checkApproval = usePerpConnectedCallbackAsync(async ({ delegateApproval }) => {
        try {
            const isApproved = await delegateApproval.delegateApprovalForOpenPosition({ cache: false })
            setIsApproved(isApproved)
            setIsChecking(false)
        } catch (error) {
            onError(error)
        }
    }, [])

    usePerpConnectedEffect(({ delegateApproval }) => {
        // TODO: delegateApproval.on() is not working normally
        checkApproval()

        // TODO: [429 issue] check if we can reduce contract calls
        const unsubscribe = delegateApproval.on("delegateApprovalUpdated", async delegateApproval => {
            checkApproval()
        })

        const unsubscribeUpdateError = delegateApproval.on("updateError", ({ error }) => {
            onError(error)
            setIsChecking(false)
        })

        return () => {
            unsubscribe()
            unsubscribeUpdateError()
        }
    }, [])

    const approve = usePerpTransactionSender(({ delegateApproval }) => {
        return delegateApproval.approveOpenPosition()
    }, [])

    const revoke = usePerpTransactionSender(({ delegateApproval }) => {
        return delegateApproval.revokeOpenPosition()
    }, [])

    return {
        isTxLoading,
        isChecking,
        isApproved,
        approve,
        revoke,
    }
}
