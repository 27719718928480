import {
    InputGroup,
    InputGroupProps,
    InputLeftAddon,
    InputLeftElement,
    InputRightAddon,
    InputRightElement,
} from "@chakra-ui/react"
import React, { useRef } from "react"

import { PerpNumberInput, PerpNumberInputProps } from "./PerpNumberInput"

interface PerpNumberInputWithAddonsProps extends PerpNumberInputProps {
    leftAddon?: React.ReactNode
    rightAddon?: React.ReactNode
    leftElement?: React.ReactNode
    rightElement?: React.ReactNode
    inputGroupProps?: InputGroupProps
    numberInputFieldProps?: Partial<PerpNumberInputProps["numberInputFieldProps"]>
}

export function PerpNumberInputWithAddons({
    leftAddon,
    rightAddon,
    leftElement,
    rightElement,
    inputGroupProps,
    numberInputFieldProps,
    ...perpNumberInputProps
}: PerpNumberInputWithAddonsProps) {
    const leftElementRef = useRef<HTMLDivElement>(null)
    const rightElementRef = useRef<HTMLDivElement>(null)

    const textAlignProp: typeof numberInputFieldProps = {
        textAlign: "left", // NOTE: default
        ...(leftElement && { textAlign: "right" }), // NOTE: override when left element exists
        ...(leftElement && rightElement && { textAlign: "center" }), // NOTE: override when both left & right element exist
    }

    return (
        <InputGroup w="full" {...inputGroupProps}>
            {leftAddon && <InputLeftAddon children={leftAddon} />}
            {rightAddon && <InputRightAddon children={rightAddon} />}
            {leftElement && <InputLeftElement width={"auto"} children={leftElement} ref={leftElementRef} />}
            {rightElement && <InputRightElement width={"auto"} children={rightElement} ref={rightElementRef} />}
            <PerpNumberInput
                w="100%"
                size="md"
                numberInputFieldProps={{
                    ...(leftElementRef.current && { paddingLeft: `${leftElementRef.current.offsetWidth}px` }),
                    ...(rightElementRef.current && { paddingRight: `${rightElementRef.current.offsetWidth}px` }),
                    ...textAlignProp,
                    ...(leftAddon && { borderLeftRadius: 0 }),
                    ...(rightAddon && { borderRightRadius: 0 }),
                    ...numberInputFieldProps,
                }}
                {...perpNumberInputProps}
            />
        </InputGroup>
    )
}
