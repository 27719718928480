import Big from "big.js"
import { DEFAULT_SLIPPAGE_PERCENTAGE } from "constant/number"
import { StorageDataMap } from "constant/storage"
import { useCallback, useMemo } from "react"
import { useLocalStorage } from "react-use"
import { createContainer } from "unstated-next"

const { GLOBAL_SLIPPAGE_PERCENTAGE } = StorageDataMap

export const Slippage = createContainer(useSlippageControl)

function useSlippageControl() {
    const [globalSlippagePercentage, setGlobalSlippagePercentage] = useLocalStorage(
        GLOBAL_SLIPPAGE_PERCENTAGE.name,
        GLOBAL_SLIPPAGE_PERCENTAGE.defaultValue,
    )

    const updateSlippagePercentage = useCallback(
        (value: number) => {
            setGlobalSlippagePercentage(`${value}`)
        },
        [setGlobalSlippagePercentage],
    )

    const slippage = useMemo(() => {
        if (!globalSlippagePercentage) {
            return new Big(DEFAULT_SLIPPAGE_PERCENTAGE).div(100)
        }

        return new Big(globalSlippagePercentage).div(100)
    }, [globalSlippagePercentage])

    const slippagePercentage = useMemo(() => {
        if (!globalSlippagePercentage) {
            return new Big(DEFAULT_SLIPPAGE_PERCENTAGE)
        }
        return new Big(globalSlippagePercentage)
    }, [globalSlippagePercentage])
    return { slippage, slippagePercentage, updateSlippagePercentage }
}
