import { MarketListContainer } from "module/market/container/MarketListContainer"

import { Box, Stack, useColorModeValue } from "@chakra-ui/react"
import React from "react"

import { PairItem } from "./PairItem"

export function PairList() {
    const { list } = MarketListContainer.useContainer()
    return <List list={list} />
}

function areEqual(prev: ListProps, current: ListProps) {
    return (
        current.list.length === prev.list.length &&
        current.list.every((item, index) => {
            return (
                item.baseSymbol === prev.list[index]?.baseSymbol &&
                item.quoteSymbol === prev.list[index]?.quoteSymbol &&
                item.tickerSymbol === prev.list[index]?.tickerSymbol
            )
        })
    )
}
interface ListProps {
    list: { tickerSymbol: string; baseSymbol: string; quoteSymbol: string }[]
}
const List = React.memo(function List({ list }: ListProps) {
    const separatorBg = useColorModeValue("separator.light", "separator.dark")
    const cardBg = useColorModeValue("cardBg.light", "cardBg.dark")
    return (
        <Stack
            w="100%"
            divider={<Box m={8} h="1px" w="100%" opacity={0.35} display={["block", "none"]} bg={separatorBg} />}
            spacing={0}
            borderRadius={["2xl", "none"]}
            bg={[cardBg, "none"]}
        >
            {list.map((data, index) => {
                return (
                    <Box key={data.tickerSymbol}>
                        <PairItem data={data} />
                    </Box>
                )
            })}
        </Stack>
    )
}, areEqual)
