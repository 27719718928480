import { formatNumberUSD, formatPercentage } from "util/format"
import { getMarketDetailUrl } from "util/route"

import { Badge, LinkBox, LinkOverlay, Skeleton, Text, VStack, useColorModeValue } from "@chakra-ui/react"
import { PositionSide } from "@perp/sdk-curie"
import { MarketListIcon } from "component/MarketListIcon"
import { MotionBox, MotionHStack } from "component/Motion"
import { PRICE_CHANGE_RATE_PRECISION } from "constant/number"
import { ActiveLimitOrdersContainer } from "container/service/LimitOrderServiceContainer/useActiveLimitOrder"
import { useAmmStatistics } from "container/service/StatisticsServiceContainer/useAmmStatistics"
import { useMemo } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { usePositionTaker } from "sdk-react/clearingHouse/usePositionTaker"
import { useMarket } from "sdk-react/markets/useMarket"

import { StatusIndicator } from "../StatusIndicator"

interface PairItemProps {
    data: {
        tickerSymbol: string
        baseSymbol: string
        quoteSymbol: string
    }
}

export function PairItem({ data }: PairItemProps) {
    const { baseSymbol, quoteSymbol, tickerSymbol } = data
    const { marketPrice, status: marketStatus } = useMarket(tickerSymbol)
    const { activeLimitOrdersMap } = ActiveLimitOrdersContainer.useContainer()
    const orders = activeLimitOrdersMap[tickerSymbol] || []
    const orderCount = orders.length
    const targetUrl = useMemo(() => {
        if (!quoteSymbol || !baseSymbol) {
            return ""
        }
        return getMarketDetailUrl(baseSymbol, quoteSymbol)
    }, [baseSymbol, quoteSymbol])

    const highlightBg = useColorModeValue("blackAlpha.100", "whiteAlpha.100")
    const location = useLocation()

    const isActive = location.pathname === targetUrl
    const position = usePositionTaker(tickerSymbol)
    const { priceChangeRate24h } = useAmmStatistics({ tickerSymbol })
    const priceChangeRate24hStr = priceChangeRate24h
        ? `${formatPercentage(priceChangeRate24h, true, false, PRICE_CHANGE_RATE_PRECISION)}%`
        : "—"

    return (
        <LinkBox _active={{ background: "transparent" }} w={"100%"} px={4} pr={0}>
            <LinkOverlay as={RouterLink} to={targetUrl}>
                <MotionHStack
                    {...(isActive && { bg: highlightBg })}
                    w={"100%"}
                    pl={[0, "14px"]}
                    pr={2}
                    py={[4, 3]}
                    borderRadius="xl"
                    spacing={1}
                >
                    <MotionHStack direction="row" w="100%" spacing={4} px={1}>
                        <MarketListIcon boxSize={["28px", "20px"]} coin={baseSymbol} />

                        <VStack flexGrow={1} spacing={"-3px"} align="flex-start">
                            <Text
                                _groupHover={{ opacity: 1 }}
                                textTransform="uppercase"
                                opacity={[1, isActive ? 1 : 0.6]}
                                fontSize={["md", "sm"]}
                                fontWeight={"semibold"}
                            >
                                {baseSymbol}
                            </Text>
                        </VStack>
                        <StatusIndicator marketStatus={marketStatus} />
                        <Skeleton textAlign="right" isLoaded={!!marketPrice && !!priceChangeRate24hStr}>
                            <MotionHStack mr={1} minWidth="60px" justifyContent={"flex-end"}>
                                {!!orderCount && (
                                    <Badge
                                        ml={2}
                                        color={"blackAlpha.800"}
                                        colorScheme={"perpetual"}
                                        variant={"solid"}
                                        size={"sm"}
                                    >
                                        {orderCount}
                                    </Badge>
                                )}
                                <VStack align={"flex-end"} spacing={"-2px"}>
                                    <Text fontSize={["sm", "xs"]} opacity={0.7}>
                                        {marketPrice ? formatNumberUSD({ num: marketPrice }) : "-"}
                                    </Text>
                                    <Text
                                        fontSize={"11px"}
                                        color={priceChangeRate24hStr.startsWith("-") ? "red.400" : "green.300"}
                                    >
                                        {priceChangeRate24hStr}
                                    </Text>
                                </VStack>
                                {!!position && (
                                    <MotionBox
                                        w="3px"
                                        h="26px"
                                        bg={position?.side === PositionSide.LONG ? "green.300" : "red.400"}
                                        borderRadius="20px"
                                        overflow="hidden"
                                    />
                                )}
                            </MotionHStack>
                        </Skeleton>
                    </MotionHStack>
                </MotionHStack>
            </LinkOverlay>
        </LinkBox>
    )
}
