import Big from "big.js"
import { useMemo } from "react"

export const useIsDepositWithdrawAmountValid = (amount?: Big, limit?: Big) => {
    const isGreaterThanZero = useMemo(() => {
        if (!amount) {
            return false
        }
        return amount.gt(0)
    }, [amount])

    const isEnoughFreeCollateral = useMemo(() => {
        if (!amount) {
            return false
        }
        return limit?.gte(amount)
    }, [limit, amount])

    return isGreaterThanZero && isEnoughFreeCollateral
}
