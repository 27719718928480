import { Signer, TypedDataSigner } from "@ethersproject/abstract-signer"
import Big from "big.js"
import { ethers } from "ethers"
import { EndpointLoader } from "./EndpointLoader"
import { MetadataLoaderFactory } from "./MetadataLoaderFactory"
import { httpPost, retryHttpGet } from "./utils/http"

export interface QuoteResponse {
    isSuccess: boolean
    quotePrice?: string
    message?: string
}

export interface OpenPositionResponse {
    isSuccess: boolean
    txHash?: string
    message?: string
}

enum AmountType {
    BASE = "base",
    QUOTE = "quote",
}

const ORDER_TYPE = {
    EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
    ],
    LimitOrder: [
        { name: "orderType", type: "uint8" },
        { name: "salt", type: "uint256" },
        { name: "trader", type: "address" },
        { name: "baseToken", type: "address" },
        { name: "isBaseToQuote", type: "bool" },
        { name: "isExactInput", type: "bool" },
        { name: "amount", type: "uint256" },
        { name: "oppositeAmountBound", type: "uint256" },
        { name: "deadline", type: "uint256" },
        { name: "sqrtPriceLimitX96", type: "uint160" },
        { name: "referralCode", type: "bytes32" },
        { name: "reduceOnly", type: "bool" },
        { name: "roundIdWhenCreated", type: "uint80" },
        { name: "triggerPrice", type: "uint256" },
    ],
}

interface OpenPositionParams {
    orderType: number
    salt: number
    trader: string
    baseToken: string
    isBaseToQuote: boolean
    isExactInput: boolean
    amount: Big
    oppositeAmountBound: Big
    deadline: number
    sqrtPriceLimitX96: Big
    referralCode: string
    reduceOnly: boolean
    roundIdWhenCreated: Big
    triggerPrice: Big
}

export class OtcMakerClient {
    // ----- State -----
    private readonly metadataLoaderFactory: MetadataLoaderFactory

    // ----- Constructor -----
    constructor() {
        this.metadataLoaderFactory = new MetadataLoaderFactory()
    }

    // ----- Action -----
    async openPosition(chainId: number, params: OpenPositionParams, signature: string): Promise<OpenPositionResponse> {
        const urlOpenPositionParams = {
            order: this.toLimitOrderStruct(params),
            signature,
        }
        const endpoint = EndpointLoader.getOtcMakerEndpoint(chainId)
        return httpPost<OpenPositionResponse>(`${endpoint}/openPosition`, urlOpenPositionParams)
    }

    async sign(signer: Signer, params: OpenPositionParams): Promise<string> {
        const chainId = await signer.getChainId()
        const EIP_712_NAME = "PerpCurieLimitOrder"
        const EIP_712_VERSION = "1"
        const typesWithoutDomain = {
            LimitOrder: ORDER_TYPE.LimitOrder,
        }
        const peripheryMetadataLoader = this.metadataLoaderFactory.getPeripheryMetadataLoader(chainId)
        return (signer as unknown as TypedDataSigner)._signTypedData(
            {
                name: EIP_712_NAME,
                version: EIP_712_VERSION,
                chainId,
                verifyingContract: peripheryMetadataLoader.getContractAddress("LimitOrderBook"),
            },
            typesWithoutDomain,
            this.toLimitOrderStruct(params),
        )
    }

    // ----- Getter -----
    async quote(
        chainId: number,
        baseToken: string,
        isLong: boolean,
        amount: Big,
        isAmountBase: boolean,
        userId: string,
        timeoutMs?: number,
    ) {
        const endpoint = EndpointLoader.getOtcMakerEndpoint(chainId)
        const params = new URLSearchParams({
            baseToken,
            amount: (isLong ? amount : amount.mul(-1)).toFixed(),
            amountType: isAmountBase ? AmountType.BASE : AmountType.QUOTE,
            userId,
        })
        return retryHttpGet<QuoteResponse>(`${endpoint}/quote?${params}`, timeoutMs)
    }

    async quoteWithTimeout(
        chainId: number,
        baseToken: string,
        isLong: boolean,
        amount: Big,
        isAmountBase: boolean,
        userId: string,
        timeoutMs: number,
    ): Promise<QuoteResponse> {
        try {
            const response = await this.quote(chainId, baseToken, isLong, amount, isAmountBase, userId, timeoutMs)
            return response
        } catch (err: any) {
            return { isSuccess: false, message: err.toString() }
        }
    }

    // ----- Helper -----
    toLimitOrderStruct(params: OpenPositionParams) {
        return {
            ...params,
            // otc maker api requires address with checksum
            baseToken: ethers.utils.getAddress(params.baseToken),
            orderType: params.orderType.toString(),
            salt: params.salt.toString(),
            amount: params.amount.toFixed(),
            oppositeAmountBound: params.oppositeAmountBound.toFixed(),
            deadline: params.deadline.toString(),
            sqrtPriceLimitX96: params.sqrtPriceLimitX96.toFixed(),
            roundIdWhenCreated: params.roundIdWhenCreated.toFixed(),
            triggerPrice: params.triggerPrice.toFixed(),
        }
    }
}
