import { CoinbaseWallet } from "@web3-react/coinbase-wallet"
import { initializeConnector } from "@web3-react/core"
import { MetaMask } from "@web3-react/metamask"
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2"

export class ConnectorFactory {
    private readonly chainIds = process.env.REACT_APP_SUPPORTED_CHAIN_IDS!.split(",").map(Number)
    private readonly walletConnectProjectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!
    private readonly opProviderUrl = "https://mainnet.optimism.io"

    createWalletConnectV2() {
        return initializeConnector<WalletConnectV2>(
            actions =>
                new WalletConnectV2({
                    actions,
                    options: {
                        projectId: this.walletConnectProjectId,
                        chains: this.chainIds,
                        showQrModal: true,
                        qrModalOptions: {
                            chainImages: undefined,
                            themeVariables: {
                                "--w3m-z-index": "16777272", // to overwrite mava's z-index
                            },
                        },
                    },
                }),
        )
    }

    createMetamask() {
        return initializeConnector<MetaMask>(actions => new MetaMask({ actions }))
    }

    createCoinbaseWallet() {
        return initializeConnector<CoinbaseWallet>(
            actions =>
                new CoinbaseWallet({
                    actions,
                    options: {
                        url: this.opProviderUrl,
                        appName: "PERP Exchange",
                    },
                }),
        )
    }
}
