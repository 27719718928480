import { Web3ReactProvider } from "@web3-react/core"
import { ReactNode } from "react"
import { ConnectorManager } from "./ConnectorManager"

interface IConnectorProviderProps {
    children: ReactNode | JSX.Element
}

const connectorManager = ConnectorManager.getInstance()
const connectors = [connectorManager.walletConnectV2, connectorManager.metamask, connectorManager.coinbaseWallet]

export function ConnectorProvider({ children }: IConnectorProviderProps) {
    return <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>
}
