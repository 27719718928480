import { JsonRpcSigner } from "@ethersproject/providers"
import { ProviderConfig } from "@perp/sdk-curie"
import React, { useMemo } from "react"
import { ContainerProviderPropsMandatory } from "unstated-next"

import { CollateralLiquidationHistories } from "./accountBalance/useCollateralLiquidationHistory"
import { DepositHistories } from "./accountBalance/useDepositHistory"
import { WithdrawHistories } from "./accountBalance/useWithdrawHistory"
import { Positions } from "./clearingHouse/usePositions"
import { GraphServerConfig, GraphServerProvider } from "./graphServer"
import { FundingPaymentHistoryContainer } from "./history/FundingPaymentHistoryContainer"
import { LimitOrderHistoryContainer } from "./history/LimitOrderHistoryContainer"
import { PositionHistoryContainer } from "./history/PositionHistoryContainer"
import { PositionLiquidationHistoryContainer } from "./history/PositionLiquidationHistoryContainer"
import { Liquidities } from "./liquidity/useLiquidities"
import { MarketsContainer } from "./markets/MarketsContainer"
import { Metadata } from "./metadata/useMetadata"
import { PerpSDK } from "./perpetualProtocol/usePerpSDK"
import { Pools } from "./pools/usePools"
import { Handlers, IHandlers } from "./tools/useHandlers"
import { TxLoading } from "./tools/useTxLoading"
import { ITxNotifiers, TxNotifiers } from "./tools/useTxNotifiers"
import { Vault } from "./vault/useVault"
import { Wallet } from "./wallet/useWallet"

export interface Web3Wallet {
    signer?: JsonRpcSigner
    account?: string | null
}

interface Env {
    chainId?: number
    appChainId: number
    providerConfigs: ProviderConfig[]
    graphServerConfigs: GraphServerConfig[]
}

export interface PerpSDKProviderInitialState {
    env: Env
    web3Wallet?: Web3Wallet
    handlers?: Partial<IHandlers>
    txNotifiers?: Partial<ITxNotifiers>
}

const ProvidersFactory = (...providers: any[]) => {
    return providers.reduceRight((providers, provider) => {
        const Provider = provider.component || provider
        const props = provider.props || {}
        return <Provider {...props}>{providers}</Provider>
    })
}

interface PerpSDKProviderProps extends ContainerProviderPropsMandatory<PerpSDKProviderInitialState> {
    LoadingUI: React.ReactElement
    ErrorUI: React.ReactElement
}
export function PerpSDKProvider({ children, initialState, LoadingUI, ErrorUI }: PerpSDKProviderProps) {
    const { chainId, appChainId } = initialState.env

    const sdkInitialState = useMemo(
        () => ({
            ...initialState.web3Wallet,
            ...initialState.env,
        }),
        [initialState],
    )

    // TODO: to see if we can refactor these key props in the future (https://github.com/perpetual-protocol/perp-exchange/pull/194#discussion_r728838625)
    // NOTE: keyForChainId and keyForAddressAndChainId are for resetting all the providers
    // chainId might be undefined when the page just start to load (so use appChainId as default value)
    // address might be undefined when the page just start to load (not sure how to prevent the rerender)
    const keyForChainId = chainId || appChainId
    const keyForAddress = initialState.web3Wallet?.account || ""
    const keyForAddressAndChainId = `${keyForAddress}@${keyForChainId}`

    return (
        <GraphServerProvider configs={initialState.env.graphServerConfigs} LoadingUI={LoadingUI}>
            {ProvidersFactory(
                {
                    component: Handlers.Provider,
                    props: { initialState: initialState.handlers, key: keyForChainId },
                },
                {
                    component: TxNotifiers.Provider,
                    props: { initialState: initialState.txNotifiers, key: keyForAddressAndChainId },
                },
                {
                    component: TxLoading.Provider,
                    props: { key: keyForAddressAndChainId },
                },
                {
                    component: PerpSDK.Provider,
                    props: { initialState: sdkInitialState, key: keyForAddressAndChainId },
                },
                {
                    component: Metadata.Provider,
                    props: { key: keyForChainId },
                },
                {
                    component: Wallet.Provider,
                    props: { key: keyForAddressAndChainId },
                },
                {
                    component: Vault.Provider,
                    props: { key: keyForAddressAndChainId },
                },
                {
                    component: MarketsContainer.Provider,
                    props: { key: keyForChainId },
                },
                {
                    component: Liquidities.Provider,
                    props: { key: keyForAddressAndChainId },
                },
                {
                    component: Positions.Provider,
                    props: { initialState: initialState.web3Wallet, key: keyForAddressAndChainId },
                },
                {
                    component: Pools.Provider,
                    props: { key: keyForAddressAndChainId },
                },
                {
                    component: PositionHistoryContainer.Provider,
                    props: { initialState: initialState.web3Wallet, key: keyForAddressAndChainId },
                },
                {
                    component: FundingPaymentHistoryContainer.Provider,
                    props: { initialState: initialState.web3Wallet, key: keyForAddressAndChainId },
                },
                {
                    component: DepositHistories.Provider,
                    props: { initialState: initialState.web3Wallet, key: keyForAddressAndChainId },
                },
                {
                    component: WithdrawHistories.Provider,
                    props: { initialState: initialState.web3Wallet, key: keyForAddressAndChainId },
                },
                {
                    component: PositionLiquidationHistoryContainer.Provider,
                    props: { initialState: initialState.web3Wallet, key: keyForAddressAndChainId },
                },
                {
                    component: CollateralLiquidationHistories.Provider,
                    props: { initialState: initialState.web3Wallet, key: keyForAddressAndChainId },
                },
                {
                    component: LimitOrderHistoryContainer.Provider,
                    props: { initialState: initialState.web3Wallet, key: keyForAddressAndChainId },
                },
                children,
            )}
        </GraphServerProvider>
    )
}
