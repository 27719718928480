import { getNetwork } from "@ethersproject/providers"

const OP_OTC_MAKER_ENDPOINT = "https://fjeusbi5j1.execute-api.ap-southeast-1.amazonaws.com/production"
const OPG_OTC_MAKER_ENDPOINT = "https://o6atyu9109.execute-api.ap-northeast-1.amazonaws.com/staging"

export class EndpointLoader {
    static getOtcMakerEndpoint(chainId: number) {
        return chainId === getNetwork("optimism").chainId ? OP_OTC_MAKER_ENDPOINT : OPG_OTC_MAKER_ENDPOINT
    }
}
