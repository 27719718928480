import { EventName } from "module/analytics/types"

import { Box, Text, VStack } from "@chakra-ui/react"
import { PerpModal } from "component/PerpModal"

import { ModalRootProps } from "./Modal/type"

export interface ModalLifiWidgetParams {
    tickerSymbol?: string
}

export function ModalLifiWidget({ isOpen, close, params }: ModalRootProps<ModalLifiWidgetParams | undefined>) {
    return (
        <PerpModal
            isCentered={false}
            size={"xl"}
            isOpen={isOpen}
            onClose={close}
            scrollBehavior="outside"
            motionPreset="slideInBottom"
            header={
                <VStack spacing={0} align="stretch">
                    <Text fontWeight={"bold"} fontSize="lg">
                        Bridge & Swap with Li.finance
                    </Text>
                </VStack>
            }
            body={<Box bg="gray.100" as="iframe" w="100%" h={"680px"} src={"https://widgets.perp.com/lifi"} />}
            trackingPropsClose={{ eventName: EventName.BRIDGE_LIFI_CLOSE }}
        />
    )
}
