import { Signer } from "@ethersproject/abstract-signer"
import { ChainId, ErrorName, PerpetualProtocol, ProviderConfig } from "@perp/sdk-curie"
import { useEffect, useRef, useState } from "react"
import { usePrevious } from "react-use"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

export interface PerpSDKInitialState {
    chainId: number
    providerConfigs: ProviderConfig[]
    signer?: Signer
}

export const PerpSDK = createContainer(usePerpSDK)

async function initPerp(chainId: ChainId, providerConfigs: ProviderConfig[]) {
    const perp = new PerpetualProtocol({
        chainId,
        providerConfigs,
    })

    await perp.init()
    return perp
}

function usePerpSDK(initialState?: PerpSDKInitialState) {
    const { chainId, providerConfigs, signer } = initialState || {}

    const perpRef = useRef<PerpetualProtocol>()
    const prevSigner = usePrevious(signer)
    const prevChainId = usePrevious(chainId)
    const [isInitialized, setIsInitialized] = useState(false)
    const [isConnected, setIsConnected] = useState(false)

    const { getHandlers } = Handlers.useContainer()
    const { onError, onCheckNetwork } = getHandlers()

    useEffect(() => {
        let ignore = false

        async function init() {
            // Delete the perp instance if the chainId has changed
            const hasChainIdChanged = prevChainId !== chainId
            if (hasChainIdChanged && perpRef.current !== undefined) {
                perpRef.current.destroy()
                perpRef.current = undefined
                setIsInitialized(false)
                setIsConnected(false)
                return
            }

            // Delete the perp instance if the signer has logged out
            if (signer === undefined && perpRef.current !== undefined && perpRef.current?.hasConnected()) {
                perpRef.current.destroy()
                perpRef.current = undefined
                setIsInitialized(false)
                setIsConnected(false)
                return
            }

            // Initialize the perp instance if it has not been initialized
            if (perpRef.current === undefined && chainId !== undefined && providerConfigs !== undefined) {
                try {
                    const instance = await initPerp(chainId, providerConfigs)

                    if (ignore || perpRef.current) {
                        instance.destroy()
                        return
                    }

                    perpRef.current = instance

                    setIsInitialized(true)
                    setIsConnected(false)
                } catch (error: any) {
                    if (error.name === ErrorName.UNSUPPORTED_CHAIN) {
                        onCheckNetwork({ isUnsupported: true, isChecking: false, chainId })
                        return
                    }
                    onError(error)
                    return
                }
            }

            // Connect the perp instance if the signer has changed
            const hasSignerChanged = prevSigner !== signer

            if (
                perpRef.current !== undefined &&
                signer !== undefined &&
                (hasSignerChanged || !perpRef.current.hasConnected())
            ) {
                try {
                    await perpRef.current.connect({ signer })

                    setIsConnected(true)
                } catch (error: any) {
                    onError(error)
                }
            }
        }

        init()

        return () => {
            ignore = true
        }
        // TODO should we include prevChainId and prevSigner in the dependency array?
    }, [chainId, prevChainId, signer, prevSigner, onCheckNetwork, onError, providerConfigs])

    return {
        perp: perpRef.current,
        isInitialized,
        isConnected,
    }
}
