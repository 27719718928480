import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"

import { BIG_ZERO } from "@perp/sdk-curie"
import { AppConfig } from "container/appConfig"
import { formatEther } from "ethers/lib/utils"
import { useMemo } from "react"
import { useAsync } from "react-use"
import { ClaimableGasRebate, GasRebateService } from "service/appSync/gasRebateService"
import { createContainer } from "unstated-next"

export const GasRebateProvider = createContainer(useGasRebateProvider)

function useGasRebateProvider() {
    const { account } = Web3WalletContainer.useContainer()

    const { appConfig } = AppConfig.useContainer()
    const { key, url, region } = appConfig?.gasRebateServerConfig || {}

    const { value: gasRebateService } = useAsync(async () => {
        if (!key || !url || !region) {
            return
        }
        const service = new GasRebateService({ key, url, region })
        await service.hydrated()
        return service
    }, [key, url, region])

    const { value: trader, loading: isGetAccountLoading } = useAsync(async () => {
        if (!account || !gasRebateService) {
            return
        }
        return gasRebateService.getAccount(account) as Promise<ClaimableGasRebate>
    }, [gasRebateService, account])

    const totalClaimableGasRebate = useMemo(() => {
        return trader?.claimableGasRebates.reduce((acc, { balance }) => {
            return acc.add(formatEther(balance))
        }, BIG_ZERO)
    }, [trader])

    return {
        totalClaimableGasRebate,
        isGetAccountLoading,
    }
}
