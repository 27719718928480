import { FieldPolicy, Reference } from "@apollo/client"

export const getMergeListDedupe: (
    keyFieldName: string,
    sorter?: Parameters<Array<string>["sort"]>[0],
) => FieldPolicy<Reference[], Reference[]>["merge"] =
    (keyFieldName, sorter) =>
    (existing = [], incoming = [], { readField }) => {
        // NOTE: Dedupe data with a map.
        const mergedMap = [...existing, ...incoming].reduce(
            (acc, item): Record<string, Reference> => ({
                ...acc,
                [readField(keyFieldName, item) as string]: item,
            }),
            {} as { [key: string]: Reference },
        )
        // NOTE: Convert back to a sorted by key array, default desc.
        return Object.keys(mergedMap)
            .sort(sorter ? sorter : (a, b) => +b - +a)
            .map(key => mergedMap[key])
    }
