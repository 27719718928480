import { formatNumberUSD } from "util/format"

import Big from "big.js"
import { useMemo } from "react"
import { Vault } from "sdk-react/vault/useVault"
import { Wallet } from "sdk-react/wallet/useWallet"

// TODO: deprecate this and return undefined for UI layer to handle.
const PLACEHOLDER = "-,---"

export function useVaultStrings() {
    const { collateralList } = Wallet.useContainer()
    const { isLoading, accountValue, balanceList, buyingPower } = Vault.useContainer()

    const accountValueStr = accountValue
        ? formatNumberUSD({ num: accountValue, displayLength: 1, minimal: 1 })
        : PLACEHOLDER

    const buyingPowerStr = buyingPower
        ? formatNumberUSD({ num: buyingPower, displayLength: 1, minimal: 1 })
        : PLACEHOLDER

    const isSettlementNegative = useMemo(() => {
        if (!balanceList || !collateralList || collateralList.length === 0) {
            return false
        }

        for (let i = 0; i < collateralList.length; i++) {
            if (collateralList[i].symbol === "USDC") {
                const balance = balanceList[i]
                if (balance.lt(0)) {
                    return true
                }
                break
            }
        }

        return false
    }, [collateralList, balanceList])

    const balanceDataStrList = useMemo(() => {
        if (!collateralList || !balanceList) {
            return
        }
        return collateralList.map(({ price, weight }, index) => {
            const balance = balanceList[index]
            const balanceUsd = balance.mul(price)
            const balanceUsdWeighted = balanceUsd.mul(weight)

            return {
                balanceStr: formatNumberUSD({ num: balance, displayLength: 1, minimal: 1 }),
                balanceUsdStr: formatNumberUSD({ num: balanceUsd, displayLength: 1, minimal: 1 }),
                balanceUsdWeightedStr: formatNumberUSD({
                    num: balanceUsdWeighted,
                    displayLength: 1,
                    minimal: 1,
                }),
            }
        })
    }, [balanceList, collateralList])

    const netValueUsd = useMemo(() => {
        if (!balanceList || !collateralList) {
            return
        }
        return collateralList.reduce((accum, { price }, index) => {
            const balance = balanceList[index]
            const balanceUsd = balance.mul(price)
            return accum.add(balanceUsd)
        }, new Big(0))
    }, [balanceList, collateralList])
    const netValueUsdStr = netValueUsd ? formatNumberUSD({ num: netValueUsd, displayLength: 1, minimal: 1 }) : undefined

    return {
        isLoading,
        isSettlementNegative,
        accountValueStr,
        buyingPowerStr,
        balanceDataStrList,
        netValueUsd,
        netValueUsdStr,
    }
}
