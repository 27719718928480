import { PageError } from "component/PageError"
import { PageLoading } from "component/PageLoading"
import React, { useMemo } from "react"
import { PerpSDKProvider } from "sdk-react/PerpSDKProvider"

import { useInitState } from "./useInitState"

interface ProviderProps {
    children: React.ReactNode
}

export function CustomizedPerpSDKProvider({ children }: ProviderProps) {
    const { state, error } = useInitState()

    return useMemo(() => {
        if (error) {
            // TODO: remove UI out of container
            return <PageError />
        } else if (state) {
            return (
                <PerpSDKProvider initialState={state} LoadingUI={<PageLoading />} ErrorUI={<PageError />}>
                    {children}
                </PerpSDKProvider>
            )
        } else {
            return <PageLoading />
        }
    }, [children, error, state])
}
