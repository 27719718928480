import { ToastId, UseToastOptions, useToast } from "@chakra-ui/react"
import { useCallback } from "react"

const DURATION_DEFAULT = 3000
const DURATION_DEFAULT_ERROR = 10000

const DEFAULT_OPTIONS: UseToastOptions = {
    containerStyle: {
        maxWidth: "360px",
        borderRadius: "12px",
        overflow: "hidden",
    },
    position: "top",
    duration: DURATION_DEFAULT,
    isClosable: true,
}

export type NotificationOptions = Partial<UseToastOptions>

export function useNotification() {
    const toast = useToast()

    const notify = useCallback(
        (options: NotificationOptions) => {
            toast({
                ...DEFAULT_OPTIONS,
                ...options,
            })
        },

        [toast],
    )

    const notifyInfo = useCallback((options: NotificationOptions) => notify({ ...options, status: "info" }), [notify])

    const notifySuccess = useCallback(
        (options: NotificationOptions) => notify({ ...options, status: "success" }),
        [notify],
    )

    const notifyError = useCallback(
        (options: NotificationOptions) => notify({ duration: DURATION_DEFAULT_ERROR, ...options, status: "error" }),
        [notify],
    )

    const closeNotify = useCallback(
        (toastRef: ToastId) => {
            toast.close(toastRef)
        },
        [toast],
    )

    return {
        notify,
        notifyInfo,
        notifySuccess,
        notifyError,
        closeNotify,
    }
}
