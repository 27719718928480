import { AppNetwork } from "module/network/container/AppNetwork"

import { FeatureToggleContainer } from "container/service/FeatureToggleContainer"
import { useMemo } from "react"
import { MarketsContainer } from "sdk-react/markets/MarketsContainer"

function whitelistMarketByKey<T extends Record<string, any>>(data: T, whitelist: string[]) {
    return Object.keys(data)
        .filter(key => whitelist.includes(key))
        .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
}

/* NOTE:
 * This hook filters the markets by the whitelist of feature flag service.
 * App layer should always use this hook to retrieve markets instead of from the Markets container of SDK-react.
 */
export function useMarketsEnabled(): ReturnType<typeof MarketsContainer.useContainer> {
    const { marketMap, marketDataMap, poolMetadataMap } = MarketsContainer.useContainer()

    const { getMarketEnabledTickerSymbolList } = FeatureToggleContainer.useContainer()
    const { appChainId } = AppNetwork.useContainer()
    const marketEnabledTickerSymbolList = useMemo(
        () => getMarketEnabledTickerSymbolList(appChainId),
        [appChainId, getMarketEnabledTickerSymbolList],
    )

    const marketEnabledMap = useMemo(
        () => (!marketMap ? marketMap : whitelistMarketByKey(marketMap, marketEnabledTickerSymbolList)),
        [marketEnabledTickerSymbolList, marketMap],
    )

    const marketDataEnabledMap = useMemo(
        () => (!marketDataMap ? marketDataMap : whitelistMarketByKey(marketDataMap, marketEnabledTickerSymbolList)),
        [marketDataMap, marketEnabledTickerSymbolList],
    )

    const poolMetadataEnabledMap = useMemo(
        () =>
            !poolMetadataMap ? poolMetadataMap : whitelistMarketByKey(poolMetadataMap, marketEnabledTickerSymbolList),
        [marketEnabledTickerSymbolList, poolMetadataMap],
    )

    return {
        marketMap: marketEnabledMap,
        marketDataMap: marketDataEnabledMap,
        poolMetadataMap: poolMetadataEnabledMap,
    }
}
