import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Avatar,
    AvatarGroup,
    Badge,
    Box,
    Button,
    Heading,
    HStack,
    Icon,
    Image,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react"
import { PerpModal } from "component/PerpModal"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"
import { useTranslation } from "react-i18next"
import { RiArrowRightUpLine } from "react-icons/ri"
import { ModalRootProps } from "./Modal/type"

const faviconUrl = "https://s2.googleusercontent.com/s2/favicons?domain="

export function ModalGas({ isOpen, close }: ModalRootProps) {
    const colorSubText = useColorModeValue("gray.500", "gray.400")
    const { account } = Web3WalletContainer.useContainer()
    const { t } = useTranslation()
    return (
        <PerpModal
            isOpen={isOpen}
            onClose={close}
            scrollBehavior="inside"
            motionPreset="slideInBottom"
            body={
                <VStack spacing={4} align="stretch">
                    <VStack textAlign="center">
                        <Heading size="lg">{t("bridge.bridge_eth")}</Heading>
                        <HStack>
                            <Text color={colorSubText} textAlign="center">
                                {t("bridge.from.introduction")}
                            </Text>
                        </HStack>
                    </VStack>
                    <Accordion defaultIndex={[0]}>
                        <AccordionItem>
                            <Text fontWeight="bold">
                                <AccordionButton px={0} py={4}>
                                    <Image
                                        borderRadius="full"
                                        boxSize="24px"
                                        mr={2}
                                        src="/assets/network/network-eth.svg"
                                    />
                                    <Box flex="1" textAlign="left">
                                        {t("bridge.from.ethereum")}
                                    </Box>
                                    <Badge mx={4} colorScheme="perpetual">
                                        {t("bridge.from.most_common")}
                                    </Badge>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Text>

                            <AccordionPanel pb={4} pl={0}>
                                <ExternalButton text="Optimism Bridge" url="https://app.optimism.io/bridge" />
                                <ExternalButton
                                    text="Synapse"
                                    url="https://synapseprotocol.com/?inputCurrency=ETH&outputCurrency=ETH&outputChain=10"
                                />
                                <ExternalButton
                                    text="Hop Exchange"
                                    url="https://app.hop.exchange/send?token=ETH&sourceNetwork=ethereum&destNetwork=optimism"
                                />
                                <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <Text fontWeight="bold">
                                <AccordionButton px={0} py={4}>
                                    <AvatarGroup mr={2} max={4}>
                                        <Avatar boxSize="28px" src={"/assets/cex/binance.png"} />
                                        <Avatar boxSize="28px" src={"/assets/cex/coinbase.png"} />
                                        <Avatar boxSize="28px" src={"/assets/cex/huobi.png"} />
                                    </AvatarGroup>
                                    <Box flex="1" textAlign="left">
                                        {t("bridge.from.binance_ftx_coinbase_huobi")}
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </Text>

                            <AccordionPanel pb={4} pl={0}>
                                <ExternalButton
                                    text="Binance"
                                    url={
                                        account
                                            ? `https://www.layerswap.io/?destNetwork=optimism_mainnet&sourceExchangeName=binance&destAddress=${account}`
                                            : ""
                                    }
                                />

                                <ExternalButton
                                    text="Coinbase"
                                    url={
                                        account
                                            ? `https://www.layerswap.io/?destNetwork=optimism_mainnet&sourceExchangeName=coinbase&destAddress=${account}`
                                            : ""
                                    }
                                />
                                <ExternalButton
                                    text="Huobi"
                                    url={
                                        account
                                            ? `https://www.layerswap.io/?destNetwork=optimism_mainnet&sourceExchangeName=huobi&destAddress=${account}`
                                            : ""
                                    }
                                />
                                <Text opacity={0.5} variant="label">
                                    {t("bridge.from.poweredByLayerSwap")}
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Text fontWeight="bold">
                                <AccordionButton px={0} py={4}>
                                    <Image
                                        borderRadius="full"
                                        boxSize="24px"
                                        mr={2}
                                        src="/assets/network/network-arbitrum.svg"
                                    />
                                    <Box flex="1" textAlign="left">
                                        {t("bridge.from.arbitrum")}
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </Text>
                            <AccordionPanel pb={4} pl={0}>
                                <ExternalButton
                                    text="Hop Exchange"
                                    url="https://app.hop.exchange/send?token=ETH&sourceNetwork=arbitrum&destNetwork=optimism"
                                />
                                <ExternalButton
                                    text="xPollinate"
                                    url="https://xpollinate.io/?fromChain=42161&fromToken=0x0000000000000000000000000000000000000000&toChain=10&toToken=0x0000000000000000000000000000000000000000&fromAmount=0.5"
                                />
                                <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Text fontWeight="bold">
                                <AccordionButton px={0} py={4}>
                                    <Image
                                        borderRadius="full"
                                        boxSize="24px"
                                        mr={2}
                                        src="/assets/network/network-xdai.svg"
                                    />
                                    <Box flex="1" textAlign="left">
                                        {t("bridge.from.xDai")}
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </Text>
                            <AccordionPanel pb={4} pl={0}>
                                <ExternalButton
                                    text="Hop Exchange"
                                    url="https://app.hop.exchange/send?token=ETH&sourceNetwork=xdai&destNetwork=optimism"
                                />
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <Text fontWeight="bold">
                                <AccordionButton px={0} py={4}>
                                    <Image
                                        borderRadius="full"
                                        boxSize="24px"
                                        mr={2}
                                        src="/assets/network/network-polygon.svg"
                                    />
                                    <Box flex="1" textAlign="left">
                                        {t("bridge.from.polygon")}
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </Text>
                            <AccordionPanel pb={4} pl={0}>
                                <ExternalButton
                                    text="Hop Exchange"
                                    url="https://app.hop.exchange/send?token=ETH&sourceNetwork=polygon&destNetwork=optimism"
                                />
                                <ExternalButton
                                    text="xPollinate"
                                    url="https://xpollinate.io/?fromChain=137&fromToken=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&toChain=10&toToken=0x0000000000000000000000000000000000000000&fromAmount=0.5"
                                />{" "}
                                <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Text fontWeight="bold">
                                <AccordionButton px={0} py={4}>
                                    <Image
                                        borderRadius="full"
                                        boxSize="24px"
                                        mr={2}
                                        src="/assets/network/network-bsc.svg"
                                    />
                                    <Box flex="1" textAlign="left">
                                        {t("bridge.from.bsc")}
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </Text>
                            <AccordionPanel pb={4} pl={0}>
                                <ExternalButton
                                    text="xPollinate"
                                    url="https://xpollinate.io/?fromChain=56&fromToken=0x2170ed0880ac9a755fd29b2688956bd959f933f8&toChain=10&toToken=0x0000000000000000000000000000000000000000&fromAmount=0.5"
                                />{" "}
                                <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <Text fontWeight="bold">
                                <AccordionButton px={0} py={4}>
                                    <Image
                                        borderRadius="full"
                                        boxSize="24px"
                                        mr={2}
                                        src="/assets/network/network-fantom.svg"
                                    />
                                    <Box flex="1" textAlign="left">
                                        {t("bridge.from.fantom")}
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </Text>
                            <AccordionPanel pb={4} pl={0}>
                                <ExternalButton
                                    text="xPollinate"
                                    url="https://xpollinate.io/?fromChain=250&fromToken=0x74b23882a30290451a17c44f4f05243b6b58c76d&toChain=10&toToken=0x0000000000000000000000000000000000000000&fromAmount=0.5"
                                />
                                <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <Text fontWeight="bold">
                                <AccordionButton px={0} py={4}>
                                    <Image
                                        borderRadius="full"
                                        boxSize="24px"
                                        mr={2}
                                        src="/assets/network/network-avax.svg"
                                    />
                                    <Box flex="1" textAlign="left">
                                        {t("bridge.from.avalanche")}
                                    </Box>

                                    <AccordionIcon />
                                </AccordionButton>
                            </Text>
                            <AccordionPanel pb={4} pl={0}>
                                <ExternalButton
                                    text="xPollinate"
                                    url="https://xpollinate.io/?fromChain=43114&fromToken=0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab&toChain=10&toToken=0x0000000000000000000000000000000000000000&fromAmount=0.5"
                                />
                                <ExternalButton text="cBridge" url="https://cbridge.celer.network/#/transfer" />
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </VStack>
            }
        />
    )
}

const ExternalButton = ({ url, text, colorScheme }: { url?: string; text?: string; colorScheme?: string }) => {
    return (
        <Button
            leftIcon={<Image src={faviconUrl + url} />}
            mr={4}
            mb={4}
            rightIcon={<Icon opacity="0.6" as={RiArrowRightUpLine} />}
            as="a"
            size="sm"
            target="_blank"
            href={url}
            colorScheme={colorScheme}
        >
            {text}
        </Button>
    )
}
