import { EventName } from "module/analytics/types"
import { AppNetwork } from "module/network/container/AppNetwork"
import useNetworkStatus from "module/network/hook/useNetwrokStatus"
import useSubgraphNetworkStatus from "module/network/hook/useSubgraphNetworkStatus"
import { ConnectWalletButton } from "module/wallet/component/ConnectWalletButton"
import { DisconnectButton } from "module/wallet/component/ModalConnectWallet/DisconnectButton"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"
import { getCreditCardLikeAccount, getShortenAccount } from "util/getShortenAddress"

import { ChevronDownIcon } from "@chakra-ui/icons"
import {
    Box,
    Button,
    ButtonGroup,
    HStack,
    Icon,
    Portal,
    Spacer,
    Text,
    VStack,
    useBreakpointValue,
} from "@chakra-ui/react"
import { ChevRight, DDSetting, DDSupport, DDWallet } from "component/Icon"
import { Jazzicon } from "component/JazzIdentity/JazzIdentity"
import { MotionHStack, MotionText } from "component/Motion"
import { useModal } from "container/modal"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BiCheckCircle, BiCopy, BiLinkExternal } from "react-icons/bi"
import { RiCheckboxCircleFill, RiErrorWarningFill } from "react-icons/ri"

import { ModalType } from "./Modal/type"
import { Menu, MenuButton, MenuDivider, MenuGroup, MenuList, PerpMenuItem } from "./PerpMenu"

export function Avatar() {
    const { t } = useTranslation()
    const [, setWindowHeight] = useState(window.innerHeight)
    const [isAccountCopied, setIsAccountCopied] = useState(false)
    const { open: openModalSettings } = useModal(ModalType.SETTINGS)
    const { open: openModalWalletConnection } = useModal(ModalType.WALLET_CONNECTION)

    const { blockNumber, isSyncing } = useNetworkStatus()
    const { isOutOfSync, indexedBlockNumber } = useSubgraphNetworkStatus()

    const { account } = Web3WalletContainer.useContainer()

    const handleOpenWallet = useCallback(() => {
        openModalWalletConnection()
    }, [openModalWalletConnection])

    const handleOpenSetting = useCallback(() => {
        openModalSettings()
    }, [openModalSettings])

    const handleCopyAccount = useCallback(() => {
        if (account) {
            navigator.clipboard.writeText(account)
            setIsAccountCopied(true)
        }
    }, [account])

    const { explorerBuilder } = AppNetwork.useContainer()
    const accountExplorerLink = account ? explorerBuilder(account, "address") : undefined

    useEffect(() => {
        function handleResize() {
            setWindowHeight(window.innerHeight)
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [setWindowHeight])

    const jazzSize = useBreakpointValue({ base: 24, md: 20 })
    return !account ? (
        <ConnectWalletButton text={t("wallet.connect")} size="sm" variant="ghost" />
    ) : (
        <Menu autoSelect={false} onClose={() => setIsAccountCopied(false)} closeOnSelect>
            {({ isOpen }) => (
                <>
                    <MenuButton px={2} role="group" as={Button} size="sm" fontSize="xs" rightIcon={<ChevronDownIcon />}>
                        <MotionHStack spacing={0} width="auto" overflow="hidden">
                            {account && <Jazzicon address={account} diameter={jazzSize} />}
                            <MotionText
                                isTruncated
                                fontSize="xs"
                                fontFamily="body"
                                sx={{ fontVariantNumeric: "slashed-zero" }}
                                boxSizing="border-box"
                                pl={2}
                            >
                                {account && getCreditCardLikeAccount(account)}
                            </MotionText>
                        </MotionHStack>
                    </MenuButton>
                    {isOpen ? (
                        <CustomMenuList
                            account={account}
                            accountExplorerLink={accountExplorerLink}
                            isAccountCopied={isAccountCopied}
                            handleOpenWallet={handleOpenWallet}
                            handleOpenSetting={handleOpenSetting}
                            handleCopyAccount={handleCopyAccount}
                            isSyncing={isSyncing}
                            blockNumber={blockNumber}
                            isOutOfSync={isOutOfSync}
                            indexedBlockNumber={indexedBlockNumber}
                        />
                    ) : null}
                </>
            )}
        </Menu>
    )
}

interface CustomMenuListProps {
    account: string
    accountExplorerLink?: string
    isAccountCopied: boolean
    handleOpenWallet: () => void
    handleOpenSetting: () => void
    handleCopyAccount: () => void
    isSyncing?: boolean
    blockNumber?: number
    isOutOfSync: boolean
    indexedBlockNumber: number
}

function CustomMenuList({
    account,
    accountExplorerLink,
    isAccountCopied,
    handleOpenWallet,
    handleOpenSetting,
    handleCopyAccount,
    isSyncing,
    blockNumber,
    isOutOfSync,
    indexedBlockNumber,
}: CustomMenuListProps) {
    const { t } = useTranslation()
    return (
        <Portal>
            <MenuList minWidth="260px" sx={{ fontVariantNumeric: "slashed-zero" }}>
                <MenuGroup title="Wallet">
                    {/* NOTE: Account Info */}
                    <PerpMenuItem
                        icon={<DDWallet opacity={0.5} boxSize="22px" />}
                        onClick={handleOpenWallet}
                        trackingProps={{ eventName: EventName.AVATAR_WALLET_CLICK }}
                    >
                        <HStack>
                            <VStack align="stretch" spacing={0}>
                                <Text sx={{ fontVariantNumeric: "slashed-zero" }} isTruncated>
                                    {account && getShortenAccount(account)}
                                </Text>
                            </VStack>
                            <Spacer />
                            <Icon as={ChevRight} />
                        </HStack>
                    </PerpMenuItem>
                    <ButtonGroup w="full" px={3} py={2}>
                        <Button
                            flex={1.3}
                            size="xs"
                            iconSpacing={1}
                            variant="solid"
                            onClick={handleCopyAccount}
                            leftIcon={
                                isAccountCopied ? (
                                    <Icon as={BiCheckCircle} color="green.400" boxSize="14px" />
                                ) : (
                                    <Icon as={BiCopy} boxSize="14px" />
                                )
                            }
                        >
                            {isAccountCopied ? "Copied" : "Copy"}
                        </Button>
                        <Button
                            flex={2}
                            as={"a"}
                            _hover={{ textTransform: "none" }}
                            target="_blank"
                            href={accountExplorerLink}
                            width="full"
                            size="xs"
                            iconSpacing={1}
                            variant="solid"
                            leftIcon={<Icon as={BiLinkExternal} boxSize="14px" />}
                        >
                            {t("general.explorer")}
                        </Button>
                    </ButtonGroup>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup>
                    <PerpMenuItem
                        trackingProps={{ eventName: EventName.AVATAR_SETTINGS_CLICK }}
                        onClick={handleOpenSetting}
                        icon={<DDSetting opacity={0.5} boxSize="22px" />}
                    >
                        {t("settings.settings")}
                    </PerpMenuItem>

                    <PerpMenuItem
                        as="a"
                        target="_blank"
                        href="https://support.perp.com/"
                        icon={<DDSupport opacity={0.5} boxSize="22px" />}
                        trackingProps={{ eventName: EventName.AVATAR_SUPPORT_CLICK }}
                    >
                        {t("settings.support")}
                    </PerpMenuItem>
                </MenuGroup>
                <MenuDivider />

                <Box p={4} py={2} color={"gray"} fontSize={"sm"}>
                    <HStack>
                        {blockNumber && !isSyncing ? (
                            <RiCheckboxCircleFill size="16px" color="rgba(64,200,127,1)" />
                        ) : (
                            <RiErrorWarningFill size="16px" color="rgba(234,98,98,1)" />
                        )}{" "}
                        <Box>Block: {blockNumber ? `${blockNumber}` : "Connecting..."}</Box>
                    </HStack>
                    <HStack>
                        {" "}
                        {indexedBlockNumber && !isOutOfSync ? (
                            <RiCheckboxCircleFill size="16px" color="rgba(64,200,127,1)" />
                        ) : (
                            <RiErrorWarningFill size="16px" color="rgba(234,98,98,1)" />
                        )}
                        <Box> Subgraph: {indexedBlockNumber ? `${indexedBlockNumber}` : "Connecting..."}</Box>
                    </HStack>
                </Box>
                <MenuDivider />
                <MenuGroup>
                    <PerpMenuItem
                        trackingProps={{ eventName: EventName.AVATAR_DISCONNECT_CLICK }}
                        borderRadius={0}
                        _active={{ bg: "none" }}
                        variant="ghost"
                        as={DisconnectButton}
                    />
                </MenuGroup>
            </MenuList>
        </Portal>
    )
}
