import { HStack, StackProps, Text, useColorModeValue } from "@chakra-ui/react"

import { MarketListIcon } from "./MarketListIcon"

interface TokenIconSymbolProps extends StackProps {
    tokenSymbol: string
}

export const TokenIconSymbol = ({ tokenSymbol, ...props }: TokenIconSymbolProps) => {
    const subText = useColorModeValue("gray.500", "gray.400")

    return (
        <HStack spacing={1} {...props}>
            <MarketListIcon boxSize={["18px", "20px"]} coin={tokenSymbol} />
            <Text variant="label" color={subText} textTransform="uppercase">
                {tokenSymbol}
            </Text>
        </HStack>
    )
}
