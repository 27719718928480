import { ApolloQueryResult } from "@apollo/client"
import { GraphqlQueryError, errorGuardAsync } from "@perp/sdk-curie"

import { AppSyncService } from "./appSyncService"
import { GET_ACCOUNT } from "./graphQueries"

export interface ClaimableReward {
    week: number
    balance: string // wei
    merkleProof: string[]
}

export interface ClaimableGasRebate {
    claimableGasRebates: ClaimableReward[]
}
interface GetAccountResponse {
    getAccount: ClaimableGasRebate
}

export class GasRebateService extends AppSyncService {
    async getAccount(account: string): Promise<ClaimableGasRebate> {
        return errorGuardAsync(
            async () => {
                const result: ApolloQueryResult<GetAccountResponse> = await this._client.query({
                    query: GET_ACCOUNT,
                    variables: {
                        address: account,
                    },
                })
                return result.data.getAccount
            },
            rawError =>
                new GraphqlQueryError({
                    functionName: "getAccount",
                    query: "GET_ACCOUNT",
                    rawError,
                }),
        )
    }
}
