import { TransactionReceipt } from "@ethersproject/providers"
import {
    ContractWriteError,
    PerpetualProtocolConnected,
    RpcRejectedError,
    getTransaction,
    isSDKErrorContractWrite,
} from "@perp/sdk-curie"
import { DependencyList, useCallback, useEffect, useRef } from "react"
import { Handlers } from "sdk-react/tools/useHandlers"
import { TxLoading } from "sdk-react/tools/useTxLoading"
import { ITxNotifiers, TxNotifiers } from "sdk-react/tools/useTxNotifiers"

import { PerpSDK } from "./usePerpSDK"

type TxOptions = Partial<ITxNotifiers>

type Options = { options?: TxOptions }
type TxSenderParams<T> = T & Options

type ContractCall<T extends Omit<{ [key: string]: any }, "options"> | undefined> = (
    perp: PerpetualProtocolConnected,
    args: T,
) => ReturnType<typeof getTransaction> | undefined

export function usePerpTransactionSender<T extends Omit<{ [key: string]: any }, "options"> | undefined>(
    contractCall: ContractCall<T>,
    deps: DependencyList,
) {
    const { perp, isConnected } = PerpSDK.useContainer()

    const savedContractCall = useRef(contractCall)
    useEffect(() => {
        savedContractCall.current = contractCall
    }, [contractCall])

    const { getTxNotifiers } = TxNotifiers.useContainer()
    const { getHandlers } = Handlers.useContainer()
    const { setTxLoading } = TxLoading.useContainer()

    return useCallback(
        async (params?: TxSenderParams<T>): Promise<TransactionReceipt | undefined> => {
            const { options, ...args } = params || {}
            const { onSent, onSuccess, onFailure, onRejected } = getTxNotifiers(options)
            const { onUnconnected } = getHandlers()

            if (!perp?.hasConnected() || !isConnected) {
                onUnconnected()
                return
            }

            let txConfirmed
            let txMetadata
            let txGasLimit
            try {
                setTxLoading(true)
                // NOTE: get transaction
                const result = await savedContractCall.current(perp, args as T)
                if (!result) {
                    setTxLoading(false)
                    return
                }

                const { transaction, metadata, gasLimit } = result
                txConfirmed = transaction
                txMetadata = metadata
                txGasLimit = gasLimit
                onSent(txConfirmed)

                // NOTE: send transaction
                const receipt = await txConfirmed.wait()
                onSuccess(receipt, metadata.contractFunctionName as string)
                setTxLoading(false)
                return receipt
            } catch (error) {
                if (error instanceof RpcRejectedError) {
                    onRejected(error)
                } else {
                    const sdkErrorContractWrite = isSDKErrorContractWrite(error)
                        ? error
                        : // NOTE: for send transaction error. Currently RPC providers do not provide sufficient error info so we default to the basic `ContractWriteError`.
                          new ContractWriteError({
                              rawError: error as Error,
                              contractName: txMetadata?.contractName || "",
                              contractFunctionName: txMetadata?.contractFunctionName || "",
                              args: { ...txMetadata?.args },
                              gasLimit: txGasLimit,
                              txPayload: { ...txMetadata?.txPayload },
                          })
                    onFailure(sdkErrorContractWrite)
                }
                setTxLoading(false)
            }
        },
        [perp, isConnected, ...deps], // eslint-disable-line react-hooks/exhaustive-deps
    )
}
