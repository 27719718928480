import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import SegmentService, { TrackingProperties } from "../service/SegmentService"

export const usePageView = (pageInfo: TrackingProperties) => {
    const location = useLocation()

    useEffect(() => {
        SegmentService.page(pageInfo)
    }, [location, pageInfo])
}
