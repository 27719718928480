import { EventName } from "module/analytics/types"

import { Box, HStack, Skeleton, Spacer, VStack } from "@chakra-ui/react"
import { RangeType } from "@perp/sdk-curie"
import { PerpNumberInputWithAddons } from "component/PerpNumberInput/PerpNumberInputWithAddons"
import { TokenIconSymbol } from "component/TokenIconSymbol"
import { TextLabel, TextNote } from "component/Typography"
import { BASE_ASSET_ADVANCED_PRECISION, USD_PRECISION } from "constant/number"

interface LiquidityAmountInputAdvancedProps {
    baseAssetSymbol: string
    rangeType?: RangeType
    isDisabled?: boolean
    isInvalid?: boolean
    buyingPowerStr?: string
    baseTokenAmount?: string
    quoteTokenAmount?: string
    totalAmountAsQuote?: string
    onBaseAmountChange: (v: string) => void
    onQuoteAmountChange: (v: string) => void
}

export function LiquidityAmountInputAdvanced({
    baseAssetSymbol,
    rangeType,
    isDisabled,
    isInvalid,
    buyingPowerStr,
    baseTokenAmount,
    quoteTokenAmount,
    totalAmountAsQuote,
    onBaseAmountChange,
    onQuoteAmountChange,
}: LiquidityAmountInputAdvancedProps) {
    const disableBase = isDisabled || rangeType === RangeType.RANGE_AT_LEFT
    const disableQuote = isDisabled || rangeType === RangeType.RANGE_AT_RIGHT

    return (
        <VStack w="full" align="stretch">
            <HStack spacing={0}>
                <TextLabel>Amount</TextLabel>
                <Spacer />
                <Skeleton isLoaded={!!buyingPowerStr}>
                    <TextNote isTruncated whiteSpace="nowrap">
                        Buying Power ≈ ${buyingPowerStr}
                    </TextNote>
                </Skeleton>
            </HStack>
            <Box>
                <PerpNumberInputWithAddons
                    numberInputFieldProps={{ borderBottomRadius: 0 }}
                    precision={BASE_ASSET_ADVANCED_PRECISION}
                    placeholder={disableBase ? "Out of range" : "Amount"}
                    value={disableBase ? "" : baseTokenAmount}
                    onChange={onBaseAmountChange}
                    isDisabled={disableBase}
                    isInvalid={!disableBase && isInvalid}
                    trackingProps={{
                        eventName: EventName.POOL_ADD_LIQUIDITY_ADVANCED_BASE_UPDATED,
                        payload: { disableBase, baseAmount: disableBase ? "" : baseTokenAmount || "" },
                    }}
                    leftElement={<TokenIconSymbol tokenSymbol={baseAssetSymbol} paddingLeft={2} paddingRight={1} />}
                />
                <PerpNumberInputWithAddons
                    numberInputFieldProps={{ borderTopRadius: 0 }}
                    precision={USD_PRECISION}
                    placeholder={disableQuote ? "Out of range" : "Amount"}
                    value={disableQuote ? "" : quoteTokenAmount}
                    onChange={onQuoteAmountChange}
                    isDisabled={disableQuote}
                    isInvalid={!disableQuote && isInvalid}
                    trackingProps={{
                        eventName: EventName.POOL_ADD_LIQUIDITY_ADVANCED_QUOTE_UPDATED,
                        payload: { disableQuote, quoteAmount: disableQuote ? "" : quoteTokenAmount || "" },
                    }}
                    leftElement={<TokenIconSymbol tokenSymbol={"usd"} paddingLeft={2} paddingRight={1} />}
                />
            </Box>
            <TextNote align="right">Total cost: ${totalAmountAsQuote}</TextNote>
        </VStack>
    )
}
