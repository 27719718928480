import { extendTheme } from "@chakra-ui/react"

import breakpoints from "./breakpoints"
import colors from "./colors"
import components from "./components"
import config from "./config"
import fonts from "./fonts"
import radii from "./radii"
import styles from "./styles"

export default extendTheme({ colors, styles, fonts, components, radii, breakpoints, config })
