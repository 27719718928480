import { ConnectorManager } from "./ConnectorManager"
import { WalletType } from "./constant"
import { ISupportedWallet } from "./types"

export function getSupportedWallets(instance: ConnectorManager): ISupportedWallet[] {
    const [metamaskConnector] = instance.metamask
    const [walletConnectV2] = instance.walletConnectV2
    const [coinbaseWallet] = instance.coinbaseWallet
    return [
        {
            type: WalletType.METAMASK,
            name: "Metamask",
            description: "Easy-to-use browser extension.",
            connector: metamaskConnector,
            platform: ["desktop", "mobile"],
        },
        {
            type: WalletType.WALLET_CONNECT,
            name: "Wallet Connect",
            description: "universal integration via qr code",
            connector: walletConnectV2,
            platform: ["desktop", "mobile"],
        },
        {
            type: WalletType.IM_TOKEN,
            name: "imToken",
            description: "imToken is an easy and secure digital wallet trusted by millions",
            connector: metamaskConnector,
            platform: ["mobile"],
        },
        {
            type: WalletType.WALLETLINK_CONNECT,
            name: "Coinbase Wallet",
            description: "",
            connector: coinbaseWallet,
            platform: ["desktop", "mobile"],
        },
    ]
}

export function getConnectorByWalletType(supportedWallets: ISupportedWallet[], walletType: WalletType) {
    const supportedWallet = supportedWallets.find(wallet => wallet.type === walletType)
    return supportedWallet && supportedWallet.connector
}

export function cleanWalletStorage() {
    localStorage.removeItem("walletconnect")
    localStorage.removeItem("wc@2:client:0.3//session")
    localStorage.removeItem("connector-id")
}
