import {
    Box,
    BoxProps,
    Center,
    HStack,
    Icon,
    Skeleton,
    Text,
    TextProps,
    Tooltip,
    useColorModeValue,
    useMultiStyleConfig,
} from "@chakra-ui/react"
import { RiQuestionLine } from "react-icons/ri"

interface StatsProps extends BoxProps {
    number?: string // TODO: rename to something not confused with number type
    label?: string
    variant?: string
    size?: string
    helper?: string
    helperProps?: TextProps
    numberProps?: TextProps
    labelProps?: TextProps
    numberColor?: string
    isLoading?: boolean
    helperColor?: string
    showTooltip?: boolean // show helper text as tooltip
    icon?: React.ReactElement
    rightIcon?: React.ReactElement
    numberComponent?: React.ReactElement
    labelColor?: string
}

export function Stats({
    number,
    label,
    variant,
    size,
    helper,
    numberColor,
    numberProps,
    labelProps,
    helperColor,
    labelColor,
    isLoading,
    showTooltip,
    helperProps,
    icon,
    rightIcon,
    numberComponent,
    ...boxProps
}: StatsProps) {
    const styles = useMultiStyleConfig("Stats", { variant, size })
    const colorSubText = useColorModeValue("subText.light", "subText.dark")
    const colorProfit = useColorModeValue("green.500", "green.300")
    const colorLoss = useColorModeValue("red.500", "red.400")

    return (
        <Box __css={styles.container} {...boxProps}>
            {!!label && (
                <Box __css={styles.label} color={colorSubText}>
                    <Text variant="label" color={labelColor} {...labelProps}>
                        {label}
                        <Tooltip hasArrow label={helper} isDisabled={!!helper && !showTooltip}>
                            <Box ml={1} as="span">
                                {helper && showTooltip && (
                                    <Icon
                                        data-html2canvas-ignore
                                        position="relative"
                                        top="4px"
                                        ml={"4px"}
                                        opacity="1"
                                        boxSize="16px"
                                        as={RiQuestionLine}
                                    />
                                )}
                            </Box>
                        </Tooltip>
                    </Text>
                </Box>
            )}
            <Box __css={styles.number} color={getNumberColor(numberColor, number, colorProfit, colorLoss)}>
                <Skeleton isLoaded={!isLoading}>
                    <HStack spacing={size === "xs" ? 1 : 2}>
                        {icon && <Center transform="scale(110%)">{icon}</Center>}
                        {numberComponent ? numberComponent : <Text {...numberProps}>{number}</Text>}
                        {rightIcon && <Center transform="scale(95%)">{rightIcon}</Center>}
                    </HStack>
                </Skeleton>
            </Box>
            {helper && !showTooltip && (
                <Box __css={styles.helper} color={helperColor || colorSubText}>
                    <Text {...helperProps}>{helper}</Text>
                </Box>
            )}
        </Box>
    )
}

export function getNumberColor(numberColor?: string, number?: string, colorProfit?: string, colorLoss?: string) {
    if (numberColor) {
        return numberColor
    } else if (number !== undefined) {
        if (number.startsWith("+")) {
            return colorProfit
        } else if (number.startsWith("-")) {
            return colorLoss
        }
    } else {
        return "white"
    }
}
