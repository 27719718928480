import { Liquidity, LiquidityDataAll } from "@perp/sdk-curie"
import Big from "big.js"
import { useState } from "react"
import { usePerpConnectedEffect } from "sdk-react/perpetualProtocol/usePerpConnectedEffect"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

interface IState {
    totalPendingFees: { [baseTokenAddress: string]: Big }
    totalLiquidities: { [baseTokenAddress: string]: Big }
    openLiquiditiesFromMarkets: { [baseTokenAddress: string]: Liquidity[] }
}

const initialState: IState = {
    totalPendingFees: {},
    totalLiquidities: {},
    openLiquiditiesFromMarkets: {},
}

export const Liquidities = createContainer(useLiquidities)

function useLiquidities() {
    const [{ totalPendingFees, openLiquiditiesFromMarkets, totalLiquidities }, setState] =
        useState<IState>(initialState)
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()

    usePerpConnectedEffect(({ liquidities, markets }) => {
        const unsubscribeUpdated = liquidities.on("updatedLiquidityDataAll", (liquidityDataAll: LiquidityDataAll) => {
            const totalPendingFees: IState["totalPendingFees"] = {}
            const openLiquiditiesFromMarkets: IState["openLiquiditiesFromMarkets"] = {}
            const totalLiquidities: IState["totalLiquidities"] = {}
            Object.entries(liquidityDataAll).forEach(([tickerSymbol, liquidityData]) => {
                const market = markets.marketMap[tickerSymbol]
                totalPendingFees[market.baseAddress] = liquidityData.totalPendingFee
                openLiquiditiesFromMarkets[market.baseAddress] = liquidityData.openLiquidities
                totalLiquidities[market.baseAddress] = liquidityData.totalLiquidityValue
            })
            setState({
                totalPendingFees,
                openLiquiditiesFromMarkets,
                totalLiquidities,
            })
        })
        const unsubscribeError = liquidities.on("updateError", ({ error }) => {
            onError(error)
        })
        return () => {
            unsubscribeUpdated()
            unsubscribeError()
        }
    }, [])

    return {
        totalPendingFees,
        openLiquiditiesFromMarkets,
        totalLiquidities,
    }
}
