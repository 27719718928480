import { EventName } from "module/analytics/types"

import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import { Button, HStack, Skeleton, Spacer, Stack, VStack } from "@chakra-ui/react"
import { RangeType } from "@perp/sdk-curie"
import { PerpIconButton } from "component/PerpIconButton"
import { PerpNumberInputWithAddons } from "component/PerpNumberInput/PerpNumberInputWithAddons"
import { TextLabel } from "component/Typography"

export type RangeSide = "lower" | "upper"
export interface PriceRangePickerAdvancedProps {
    isDisabled?: boolean
    rangeType?: RangeType
    lowerPrice: string
    upperPrice: string
    onRangeChange: (params: { lowerPrice: string; upperPrice: string }) => void
    onBlur: (side: RangeSide) => void
    onIncrease: (side: RangeSide) => void
    onDecrease: (side: RangeSide) => void
    onFullRangeClick: () => void
    marketPriceStr?: string
}

export function PriceRangePickerAdvanced({
    isDisabled,
    rangeType,
    lowerPrice,
    upperPrice,
    onRangeChange,
    onBlur,
    onIncrease,
    onDecrease,
    onFullRangeClick,
    marketPriceStr,
}: PriceRangePickerAdvancedProps) {
    const isLoaded = !!lowerPrice || !!upperPrice
    const isInvalid = rangeType === RangeType.RANGE_INVALID

    return (
        <VStack w="full" align="stretch">
            <Skeleton isLoaded={isLoaded}>
                <HStack spacing={0}>
                    <TextLabel>Market Price: ${marketPriceStr}</TextLabel>
                    <Spacer />
                    <Button
                        size={"xs"}
                        colorScheme="purple"
                        variant="outline"
                        isDisabled={isDisabled}
                        onClick={onFullRangeClick}
                    >
                        {"Full Range"}
                    </Button>
                </HStack>
            </Skeleton>

            <Stack w="100%" align="stretch" direction={{ base: "column", sm: "row" }}>
                <Skeleton isLoaded={isLoaded} w="100%">
                    <PerpNumberInputWithAddons
                        placeholder="Min Price"
                        w="100%"
                        value={lowerPrice}
                        isDisabled={isDisabled}
                        isInvalid={isInvalid}
                        onChange={string => onRangeChange({ lowerPrice: string, upperPrice })}
                        onBlur={() => onBlur("lower")}
                        // TODO: check why we need to use dynamic precision
                        // precision={getLeastSignificantDigit(lowerPrice)}
                        precision={8}
                        leftElement={
                            <HStack paddingLeft={2} paddingRight={1}>
                                <PerpIconButton
                                    icon={<MinusIcon />}
                                    size={"xs"}
                                    onClick={() => onDecrease("lower")}
                                    aria-label={"Decrease"}
                                    borderRadius="full"
                                />
                                <TextLabel marginLeft={1}>Min</TextLabel>
                            </HStack>
                        }
                        rightElement={
                            <HStack paddingLeft={1} paddingRight={2}>
                                <PerpIconButton
                                    size={"xs"}
                                    onClick={() => onIncrease("lower")}
                                    icon={<AddIcon />}
                                    aria-label={"Increase"}
                                    borderRadius="full"
                                />
                            </HStack>
                        }
                        trackingProps={{
                            eventName: EventName.POOL_ADD_LIQUIDITY_ADVANCED_PRICE_RANGE_MIN_UPDATED,
                            payload: { lowerPrice },
                        }}
                    />
                </Skeleton>

                <Skeleton isLoaded={isLoaded} w="100%">
                    <PerpNumberInputWithAddons
                        w="100%"
                        placeholder="Max Price"
                        value={upperPrice}
                        isDisabled={isDisabled}
                        isInvalid={isInvalid}
                        onChange={string => onRangeChange({ lowerPrice, upperPrice: string })}
                        onBlur={() => onBlur("upper")}
                        // TODO: check why we need to use dynamic precision
                        // precision={getLeastSignificantDigit(upperPrice)}
                        precision={8}
                        leftElement={
                            <HStack paddingLeft={2} paddingRight={1}>
                                <PerpIconButton
                                    icon={<MinusIcon />}
                                    size={"xs"}
                                    onClick={() => onDecrease("upper")}
                                    aria-label={"Decrease"}
                                    borderRadius="full"
                                />

                                <TextLabel marginLeft={1}>Max</TextLabel>
                            </HStack>
                        }
                        rightElement={
                            <HStack paddingLeft={1} paddingRight={2}>
                                <PerpIconButton
                                    size={"xs"}
                                    onClick={() => onIncrease("upper")}
                                    icon={<AddIcon />}
                                    aria-label={"Increase"}
                                    borderRadius="full"
                                />
                            </HStack>
                        }
                        trackingProps={{
                            eventName: EventName.POOL_ADD_LIQUIDITY_ADVANCED_PRICE_RANGE_MAX_UPDATED,
                            payload: { lowerPrice },
                        }}
                    />
                </Skeleton>
            </Stack>
        </VStack>
    )
}
