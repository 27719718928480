import fetch from "cross-fetch"

import { sleep } from "./time"

export async function httpGet<T>(url: string, timeoutMs = 60_000): Promise<T> {
    const controller = new AbortController()
    const timeout = setTimeout(() => {
        controller.abort()
    }, timeoutMs)
    try {
        const res = await fetch(url, { signal: controller.signal })
        if (res.status >= 400) {
            try {
                // todo save error response to sentry
            } catch (e) {
                // do nothing
            }
            throw new Error(`Bad ${res.status} response from server`)
        }
        return await res.json()
    } catch (err) {
        throw err
    } finally {
        clearTimeout(timeout)
    }
}

export async function retryHttpGet<T>(url: string, timeoutMs?: number): Promise<T> {
    try {
        return await httpGet(url, timeoutMs)
    } catch (e) {
        await sleep(100)
        return await httpGet(url, timeoutMs)
    }
}

export async function httpPost<T>(url: string, body: unknown): Promise<T> {
    const res = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    })
    if (res.status >= 400) {
        try {
            // todo save error response to sentry
        } catch (e) {
            // do nothing
        }
        throw new Error(`Bad ${res.status} response from server`)
    }
    return await res.json()
}
