import { IS_PRODUCTION } from "constant/envVariables"
import { StorageDataMap } from "constant/storage"
import { useEffect, useState } from "react"
import { useLocalStorage } from "react-use"
import { trackLocationRestrictDialog } from "service/segment/tracks"
import { createContainer } from "unstated-next"

const { IGNORE_GEO_BLOCKING } = StorageDataMap

const GEOLOCATION_API = "https://api.perp.exchange/geolocation-block"

export const GeoBlocking = createContainer(useGeoBlocking)

interface APIGeoBlockingResponse {
    isBlocked: boolean
    countryCode: string
}

function useGeoBlocking() {
    const [isBlocking, setIsBlocking] = useState(false)
    const [isIgnoreGeoBlocking] = useLocalStorage(IGNORE_GEO_BLOCKING.name)
    const [countryCode, setCountryCode] = useState("")

    useEffect(() => {
        if (isBlocking) {
            trackLocationRestrictDialog()
        }
    }, [isBlocking])
    useEffect(() => {
        // NOTE: storage's value would be the string type
        if (IS_PRODUCTION && isIgnoreGeoBlocking !== "true") {
            try {
                fetch(GEOLOCATION_API)
                    .then(res => res.json())
                    .then((res: APIGeoBlockingResponse) => {
                        setCountryCode(res.countryCode)
                        setIsBlocking(res.isBlocked)
                    })
            } catch {
                console.error("[Fetch API Failed] GEOLOCATION_API")
            }
        }
    }, [isIgnoreGeoBlocking, setIsBlocking, setCountryCode])

    return { isBlocking, countryCode }
}
