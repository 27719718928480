import { formatNumberUSD } from "util/format"

import { useMemo } from "react"
import { useMarket } from "sdk-react/markets/useMarket"

const PLACEHOLDER = "-,---"

export function useMarketStrings(tickerSymbol: string) {
    const { marketPrice, indexPrice } = useMarket(tickerSymbol)

    const marketPriceStr = useMemo(() => {
        return marketPrice ? formatNumberUSD({ num: marketPrice }) : PLACEHOLDER
    }, [marketPrice])

    const indexPriceStr = useMemo(() => {
        return indexPrice ? formatNumberUSD({ num: indexPrice }) : PLACEHOLDER
    }, [indexPrice])

    return {
        marketPriceStr,
        indexPriceStr,
    }
}
