import { EventName } from "module/analytics/types"
import { formatNumberUSD } from "util/format"

import {
    Box,
    BoxProps,
    Skeleton,
    SliderFilledTrack,
    SliderMark,
    SliderMarkProps,
    SliderThumb,
    SliderTrack,
    Text,
} from "@chakra-ui/react"
import { BIG_ZERO, TickPriceMode } from "@perp/sdk-curie"
import Big from "big.js"
import { PerpSlider } from "component/PerpSlider"
import { useCallback } from "react"

import { PriceRangeStepList } from "../../util/priceRange"

export const getPriceRange = ({
    tickPriceStepList,
    deltaStep,
}: {
    tickPriceStepList: PriceRangeStepList
    deltaStep: number
}) => {
    const tickPriceFirst = tickPriceStepList[0].tickPrice
    const tickPriceDelta = tickPriceStepList[deltaStep].tickPrice

    if (tickPriceFirst.lt(tickPriceDelta)) {
        return { lowerPrice: tickPriceFirst, upperPrice: tickPriceDelta }
    }
    return { lowerPrice: tickPriceDelta, upperPrice: tickPriceFirst }
}

export interface PriceRangePickerSimpleProps extends BoxProps {
    tickPriceMode: TickPriceMode
    tickPriceRangeStepList: PriceRangeStepList
    deltaStep: number
    onRangeChange: (params: { lowerPrice: Big; upperPrice: Big; deltaStep: number }) => void
    marketPriceStr: string
}

export const PriceRangePickerSimple = ({
    tickPriceMode,
    tickPriceRangeStepList,
    deltaStep,
    onRangeChange,
    marketPriceStr,
    ...boxProps
}: PriceRangePickerSimpleProps) => {
    const marks = tickPriceRangeStepList.length
    const isLoaded = tickPriceRangeStepList.length > 0
    const intervals = marks - 1
    const isLowerRange = tickPriceMode === TickPriceMode.LOWER

    const handleChange = useCallback(
        (deltaStepNext: number) => {
            const deltaStepNextBound = Math.max(deltaStepNext, 1)

            const { lowerPrice, upperPrice } = getPriceRange({
                tickPriceStepList: tickPriceRangeStepList,
                deltaStep: deltaStepNextBound,
            })

            onRangeChange({ lowerPrice, upperPrice, deltaStep: deltaStepNextBound })
        },
        [onRangeChange, tickPriceRangeStepList],
    )

    const pickedPriceDeltaRatio = tickPriceRangeStepList[deltaStep]?.deltaRatio
    const positivePickedPriceDeltaPercentageStr = `${Math.round(Math.abs(pickedPriceDeltaRatio) * 100)}%`

    // get the nearest tick price
    const nearestTickPrice = tickPriceRangeStepList[0]
        ? formatNumberUSD({ num: tickPriceRangeStepList[0].tickPrice })
        : "—"

    return (
        <Box {...boxProps}>
            <Skeleton isLoaded={isLoaded} mt={0}>
                <Text fontSize={"sm"} opacity={0.65}>
                    {deltaStep === 1 && "Narrow"}
                    {deltaStep === 2 && "Medium"}
                    {deltaStep === 3 && "Wide"} {" ("}
                    {isLowerRange
                        ? `${positivePickedPriceDeltaPercentageStr} below the current price`
                        : `${positivePickedPriceDeltaPercentageStr} above the current price`}
                    {")"}
                </Text>
            </Skeleton>
            <Skeleton isLoaded={isLoaded} mt={4} px={{ base: 3, md: 0 }} pb={8} pt={0}>
                <PerpSlider
                    colorScheme="green"
                    aria-label={"range"}
                    isReversed={isLowerRange}
                    step={1}
                    min={0}
                    max={intervals}
                    value={deltaStep}
                    onChange={handleChange}
                    trackingProps={{
                        eventName: EventName.LIQUIDITY_ADD_PRICE_RANGE_CHANGE,
                        payload: {
                            priceMode: tickPriceMode,
                            pricePercentage: positivePickedPriceDeltaPercentageStr,
                            lowerPrice: formatNumberUSD({
                                num: tickPriceRangeStepList[isLowerRange ? deltaStep : 0]?.tickPrice || BIG_ZERO,
                            }),
                            upperPrice: formatNumberUSD({
                                num: tickPriceRangeStepList[isLowerRange ? 0 : deltaStep]?.tickPrice || BIG_ZERO,
                            }),
                        },
                    }}
                >
                    {tickPriceRangeStepList.map(({ tickPrice, deltaRatio }, index) => {
                        const isHead = index === 0
                        const isTail = index === marks - 1

                        const isFirst = isLowerRange ? isTail : isHead
                        const isLast = isLowerRange ? isHead : isTail

                        const sliderMarkProps: Partial<SliderMarkProps> = isFirst
                            ? { textAlign: "left" }
                            : isLast
                            ? { textAlign: "right" }
                            : { textAlign: "center" }

                        const contentContainerProps: Partial<BoxProps> = isFirst
                            ? {}
                            : isLast
                            ? { left: "-100%" }
                            : { left: "-50%" }

                        // highlight the current range
                        const isRangeCurrent = index === deltaStep
                        return (
                            <SliderMark value={index} position="relative" pt={4} {...sliderMarkProps}>
                                <Box position="relative" {...contentContainerProps}>
                                    <Text fontSize="sm" position="relative">
                                        {deltaRatio === 0 && "Current"}

                                        {deltaRatio !== 0 &&
                                            `${isLowerRange ? "" : "+"}${Math.round(deltaRatio * 100)}%`}
                                    </Text>
                                    <Text
                                        fontSize="xs"
                                        mt={"2px"}
                                        position="relative"
                                        opacity={isHead || isRangeCurrent ? 1 : 0.5}
                                        fontWeight={isHead || isRangeCurrent ? "bold" : undefined}
                                    >
                                        {formatNumberUSD({ num: tickPrice })}
                                    </Text>
                                </Box>
                            </SliderMark>
                        )
                    })}

                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb boxSize={5} />
                </PerpSlider>
            </Skeleton>
            <Skeleton isLoaded={isLoaded} mt={6}>
                <Text fontSize={"xs"} opacity={0.65}>
                    {isLowerRange
                        ? `I speculate the price will go down as much as ${positivePickedPriceDeltaPercentageStr} below $${nearestTickPrice}. But ultimately it will go above $${nearestTickPrice}.`
                        : `I speculate the price will go up as much as ${positivePickedPriceDeltaPercentageStr} above $${nearestTickPrice}. But ultimately it will go below $${nearestTickPrice}.`}
                </Text>
            </Skeleton>
        </Box>
    )
}
