import { TitleMarketDetail } from "component/Title/TitleMarketDetail"
import { TitleText } from "component/Title/TitleText"
import { RouteName } from "constant/route"
import { useTranslation } from "react-i18next"
import { Route, Switch } from "react-router-dom"

export function Title() {
    const { t } = useTranslation()
    return (
        <Switch>
            <Route path={RouteName.MARKET_LIST} exact render={() => <TitleText title={t("market.markets")} />} />
            <Route path={RouteName.POOL_LIST} exact render={() => <TitleText title={t("pool.pools")} />} />
            <Route path={RouteName.HISTORY} exact render={() => <TitleText title={t("history.history")} />} />
            <Route path={RouteName.MARKET_DETAIL} exact component={TitleMarketDetail} />
            <Route path={RouteName.POOL_DETAIL} exact component={TitleMarketDetail} />
        </Switch>
    )
}
