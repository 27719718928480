import { EventName } from "module/analytics/types"

import { Box, Text, VStack } from "@chakra-ui/react"
import { PerpModal } from "component/PerpModal"
import { FeatureToggleContainer } from "container/service/FeatureToggleContainer"

import { ModalRootProps } from "./Modal/type"

export function ModalBiconomyWidget({ isOpen, close }: ModalRootProps) {
    const { isBiconomyWidgetEnable } = FeatureToggleContainer.useContainer()

    return (
        <PerpModal
            isCentered={false}
            size={"xl"}
            isOpen={isOpen}
            onClose={close}
            scrollBehavior="outside"
            motionPreset="slideInBottom"
            header={
                <VStack align={"stretch"}>
                    <Text fontWeight="bold">Bridge with Hyphen</Text>
                    {isBiconomyWidgetEnable && (
                        <Text fontWeight="normal" fontSize={"sm"} color={"gray.400"}>
                            When bridging using Hyphen, there's no need to bridge ETH separately! Simply enter an amount
                            and check the
                            <Text color="purple.200" fontWeight={"bold"} as="span">
                                "GET GAS TOKEN ON DESTINATION?"
                            </Text>{" "}
                            option below to receive{" "}
                            <Text color="white" as="span">
                                USDC and some ETH
                            </Text>
                            .
                        </Text>
                    )}
                </VStack>
            }
            // modalBodyProps={{ position: "relative", minWidth: 540, minHeight: 680 }}
            // the minHeight is to accomedate widget height
            body={<Box as="iframe" w="100%" h={"660px"} src={"https://widgets.perp.com/biconomy"} />}
            trackingPropsClose={{ eventName: EventName.BRIDGE_BICONOMY_CLOSE }}
        />
    )
}
