import { ApolloQueryResult } from "@apollo/client"

import { AppSyncService } from "./appSyncService"
import { GET_STATISTICS, ON_UPSERT_STATISTICS } from "./graphQueries"

interface Statistics {
    volume24h?: string
    priceChangeRate24h?: string
    priceHigh24h?: string
    priceLow24h?: string
}

interface GetStatisticsResponse {
    getStatistics: Statistics
}

interface SubscribeStatisticsResponse {
    data: { onUpsertStatistics: Statistics }
}

type Subscribers = {
    [subscriberId: string]: ZenObservable.Subscription
}

export class StatisticsService extends AppSyncService {
    //  TODO: refactor this into AppSyncService
    private _subscribers: Subscribers = {}

    async getAmmStatistics(ammAddress: string): Promise<Statistics | undefined> {
        try {
            const result: ApolloQueryResult<GetStatisticsResponse> = await this._client.query({
                query: GET_STATISTICS,
                variables: { ammAddress },
            })
            return result?.data?.getStatistics || null
        } catch (e) {
            // NOTE: we don't support statistic service in rinkeby network
            console.error("graphql error in statistic service:", e)
        }
    }

    subscribe(ammAddress: string, callback: (data: Statistics) => void) {
        const subscriber = this._subscribers[ammAddress]
        if (!!subscriber) {
            subscriber.unsubscribe()
        }
        try {
            const observable = this._client.subscribe<SubscribeStatisticsResponse>({
                query: ON_UPSERT_STATISTICS,
                variables: { ammAddress },
            })
            this._subscribers[ammAddress] = observable.subscribe(result => {
                const onUpsertStatistics = result?.data?.onUpsertStatistics
                if (onUpsertStatistics) {
                    callback(onUpsertStatistics)
                }
            })
        } catch (e) {
            // NOTE: we don't support statistic service in rinkeby network
            console.error("graphql error in statistic service:", e)
        }
    }

    unsubscribe(ammAddress: string) {
        const subscriber = this._subscribers[ammAddress]
        if (!subscriber) {
            return
        }
        subscriber.unsubscribe()
        delete this._subscribers[ammAddress]
    }
}
