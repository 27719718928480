import type { NonSettlementCollateralToken, SettlementToken, VaultDataAll } from "@perp/sdk-curie"
import Big from "big.js"
import { useEffect, useState } from "react"
import { usePerpConnectedEffect } from "sdk-react/perpetualProtocol/usePerpConnectedEffect"
import { usePerpTransactionSender } from "sdk-react/perpetualProtocol/usePerpTransactionSender"
import { Handlers } from "sdk-react/tools/useHandlers"
import { createContainer } from "unstated-next"

interface IState {
    accountValue?: Big
    freeCollateral?: Big
    buyingPower?: Big
    balanceList?: Big[]
    freeCollateralList?: Big[]
}

export const Vault = createContainer(useVault)

function useVault() {
    // NOTE: states
    const [isLoading, setIsLoading] = useState(true)
    const [{ accountValue, freeCollateral, buyingPower, balanceList, freeCollateralList }, setState] = useState<IState>(
        {},
    )

    // NOTE: handlers
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()

    // NOTE: subscriptions
    usePerpConnectedEffect(({ wallet, vault, clearingHouseConfig }) => {
        const unsubscribeUpdated = vault.on(
            "updatedVaultDataAll",
            ({ vaultDataAllByCollateral, vaultDataAllCrossCollateral }: VaultDataAll) => {
                const accountValue = vaultDataAllCrossCollateral.accountBalance
                const freeCollateral = vaultDataAllCrossCollateral.accountFreeCollateral
                const buyingPower = freeCollateral.div(clearingHouseConfig.imRatio)
                const collateralTokenList = wallet.collateralTokenList
                const balanceList = collateralTokenList.map(
                    collateralToken => vaultDataAllByCollateral[`${collateralToken.address}`].balance,
                )
                const freeCollateralList = collateralTokenList.map(
                    collateralToken => vaultDataAllByCollateral[`${collateralToken.address}`].freeCollateral,
                )
                setState({
                    accountValue,
                    freeCollateral,
                    buyingPower,
                    balanceList,
                    freeCollateralList,
                })
            },
        )
        const unsubscribeError = vault.on("updateError", ({ error }) => {
            onError(error)
        })
        return () => {
            unsubscribeUpdated()
            unsubscribeError()
        }
    }, [])

    // NOTE: native effects
    useEffect(() => {
        if (!!freeCollateral && !!accountValue) {
            setIsLoading(false)
        }
    }, [accountValue, freeCollateral])

    // NOTE: actions
    const deposit = usePerpTransactionSender<{ token: NonSettlementCollateralToken | SettlementToken; amount: Big }>(
        ({ vault }, { token, amount }) => vault.deposit(token, amount),
        [],
    )

    const depositETH = usePerpTransactionSender<{ amount: Big }>(
        ({ vault }, { amount }) => vault.depositETH(amount),
        [],
    )

    const withdraw = usePerpTransactionSender<{ token: NonSettlementCollateralToken | SettlementToken; amount: Big }>(
        ({ vault }, { token, amount }) => vault.withdraw(token, amount),
        [],
    )

    const withdrawETH = usePerpTransactionSender<{ amount: Big }>(
        ({ vault }, { amount }) => vault.withdrawETH(amount),
        [],
    )

    const withdrawAll = usePerpTransactionSender<{ token: NonSettlementCollateralToken | SettlementToken }>(
        ({ vault }, { token }) => vault.withdrawAll(token),
        [],
    )

    const withdrawAllETH = usePerpTransactionSender<{}>(({ vault }) => vault.withdrawAllEther(), [])

    return {
        isLoading,
        accountValue,
        balanceList,
        buyingPower,
        freeCollateral,
        freeCollateralList,
        deposit,
        depositETH,
        withdraw,
        withdrawETH,
        withdrawAll,
        withdrawAllETH,
    }
}
