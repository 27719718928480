import { MarketListContainer } from "module/market/container/MarketListContainer"

import { Input, InputGroup, InputLeftElement, useBreakpointValue, useColorModeValue } from "@chakra-ui/react"
import { SearchIcon } from "component/Icon"
import { useDebounceValue } from "hook/useDebounceValue"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export function Search() {
    const { t } = useTranslation()
    const buttonSize = useBreakpointValue(["md", "sm"])
    const cardBg = useColorModeValue("cardBg.light", "cardBg.dark")
    const { setSearchValue } = MarketListContainer.useContainer()
    const [value, setValue] = useState<string>("")
    const _debouncedValue = useDebounceValue({ value, delay: 300 })

    useEffect(() => {
        setSearchValue(_debouncedValue)
    }, [_debouncedValue, setSearchValue])

    const handleOnChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => {
        const value = e.target.value
        setValue(value)
    }, [])

    return (
        <InputGroup size={buttonSize} variant="filled">
            <InputLeftElement pointerEvents="none" children={<SearchIcon opacity={0.5} />} />
            <Input bg={cardBg} placeholder={t("pool.search_pools")} onChange={handleOnChange} />
        </InputGroup>
    )
}
