import { useEffect, useState } from "react"

const DEFAULT_DELAY = 200

interface UseDebounceValueArgs<T> {
    value: T
    delay?: number
    onBeforeDebounce?: Function
    onAfterDebounce?: Function
}

/**
 * - Use when: We need a value for the last updating in a certain time
 *
 * - Parameters:
 *
 * ```typescript
 * interface UseDebounceValueArgs<T> {
 *   value: T
 *   delay?: number // default delay 200 ms
 *   onBeforeDebounce?: Function
 *   onAfterDebounce?: Function
 * }
 * ```
 *
 * - NOTE:
 * We might need to install [typedoc-plugin-not-exported](https://github.com/tomchen/typedoc-plugin-not-exported) for listing DebounceArgs on TypeDoc.
 * Because `UseDebounceValueArgs` is not exported and TypeDoc doesn't list non-export variables.
 *
 * @returns value<T>
 */
export function useDebounceValue<T>({
    value,
    delay = DEFAULT_DELAY,
    onBeforeDebounce,
    onAfterDebounce,
}: UseDebounceValueArgs<T>) {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        if (onBeforeDebounce) {
            onBeforeDebounce(value)
        }

        const handler = setTimeout(() => {
            setDebouncedValue(value)
            if (onAfterDebounce) {
                onAfterDebounce(value)
            }
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [value, delay, onBeforeDebounce, onAfterDebounce])

    return debouncedValue
}
