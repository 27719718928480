import { useWalletStrings } from "module/wallet/hook/useWalletStrings"

import { TransactionReceipt } from "@ethersproject/providers"
import Big from "big.js"
import { useMemo } from "react"
import { Vault } from "sdk-react/vault/useVault"
import { Wallet } from "sdk-react/wallet/useWallet"

import { useCollateralStrings } from "./useCollateralStrings"

export interface IDepositOption {
    collateralSymbol: string
    balance?: Big
    balanceStr: string
    approve?: () => void
    deposit: (amount: Big) => Promise<TransactionReceipt | undefined>
}

export function useDepositOptionList() {
    const { deposit, depositETH } = Vault.useContainer()
    const { balanceEth, approveDeposit } = Wallet.useContainer()
    const { balanceEthStr } = useWalletStrings()
    const { collateralStrList } = useCollateralStrings()

    const depositOptionList: IDepositOption[] = useMemo(() => {
        const options: IDepositOption[] = []
        collateralStrList?.forEach(({ token, symbol, walletBalance, walletBalanceStr }, i) => {
            options.push({
                collateralSymbol: symbol,
                balance: walletBalance,
                balanceStr: walletBalanceStr,
                approve: () => approveDeposit({ token }),
                deposit: amount => deposit({ token, amount }),
            })

            // NOTE: insert ETH after WETH
            if (symbol === "WETH") {
                options.push({
                    collateralSymbol: "ETH",
                    balance: balanceEth,
                    balanceStr: balanceEthStr,
                    deposit: amount => depositETH({ amount }),
                })
            }
        })
        return options
    }, [approveDeposit, balanceEth, balanceEthStr, collateralStrList, depositETH, deposit])

    return {
        depositOptionList,
    }
}
