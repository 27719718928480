import { useMemo, useState } from "react"
import { createContainer } from "unstated-next"

import { useMarketsEnabled } from "../hook/useMarketsEnabled"

const HIDDEN_MARKETS = ["LUNA", "ONE", "DOGE2"]

export const MarketListContainer = createContainer(useMarketList)

function useMarketList() {
    const { marketMap } = useMarketsEnabled()
    const [searchValue, setSearchValue] = useState<string | null>(null)

    const sourceList = useMemo(() => {
        if (!marketMap) {
            return []
        }
        const sortedList = Object.values(marketMap)
            .map(market => ({
                tickerSymbol: market.tickerSymbol,
                baseSymbol: market.baseSymbol,
                quoteSymbol: market.quoteSymbol,
            }))
            .filter(({ baseSymbol }) => !HIDDEN_MARKETS.includes(baseSymbol))
            .sort((a, b) => a.baseSymbol.localeCompare(b.baseSymbol))

        if (searchValue === null) {
            return sortedList
        }

        return sortedList.filter(market => {
            return market.baseSymbol.includes(searchValue.toUpperCase())
        })
    }, [marketMap, searchValue])

    // sort list in the order of: btc, eth, doge, ...rest
    const list = useMemo(() => {
        const btcList = sourceList.filter(({ baseSymbol }) => baseSymbol === "BTC")
        const ethList = sourceList.filter(({ baseSymbol }) => baseSymbol === "ETH")
        const dogeList = sourceList.filter(({ baseSymbol }) => baseSymbol === "DOGE")

        const restList = sourceList.filter(
            ({ baseSymbol }) => baseSymbol !== "BTC" && baseSymbol !== "ETH" && baseSymbol !== "DOGE",
        )

        return [...btcList, ...ethList, ...dogeList, ...restList]
    }, [sourceList])

    return {
        list,
        setSearchValue,
    }
}
