import { Text, TextProps, useColorModeValue } from "@chakra-ui/react"

interface TypographyProps extends TextProps {}

export const TextTitle = (props: TypographyProps) => <Text fontSize="lg" fontWeight="bold" {...props} />

export const TextLabel = (props: TypographyProps) => <Text variant="label" {...props} />

export const TextNote = (props: TypographyProps) => {
    const subText = useColorModeValue("gray.500", "gray.400")
    return <Text fontSize="xs" color={subText} {...props} />
}
