import SegmentService, { EventGroup, EventTypeInteraction } from "module/analytics/service/SegmentService"
import { TrackingProps } from "module/analytics/types"

import {
    Modal,
    ModalBody,
    ModalBodyProps,
    ModalCloseButton,
    ModalContent,
    ModalContentProps,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    useBreakpointValue,
} from "@chakra-ui/react"
import { ReactNode, useCallback, useEffect } from "react"

export interface ModalCenterProps extends Omit<ModalProps, "children"> {
    header?: ReactNode
    body?: ReactNode
    footer?: ReactNode
    modalBodyProps?: ModalBodyProps
    isCloseButtonHidden?: boolean
    isFullScreen?: boolean
    trackingPropsOpen?: TrackingProps
    trackingPropsClose?: TrackingProps
    maxWidth?: number
    minWidth?: number
    minHeight?: number
}

export function PerpModal({
    header,
    body,
    footer,
    isCloseButtonHidden,
    isFullScreen,
    size,
    modalBodyProps,
    trackingPropsOpen,
    trackingPropsClose,
    isOpen,
    onClose,
    maxWidth,
    minWidth,
    minHeight,
    ...modalProps
}: ModalCenterProps) {
    const defaultModalSize = useBreakpointValue(["xs", "md"])

    const controlledModalProps: Partial<ModalProps> = {
        isCentered: true,
        ...(isFullScreen ? { size: "full" } : { size: size || defaultModalSize }),
    }
    const controlledModalContentProps: Partial<ModalContentProps> = {
        bg: "gray.800",
        ...(isFullScreen && { borderRadius: 0 }),
        maxW: maxWidth,
    }
    const controlledModalBodyProps: Partial<ModalBodyProps> = {
        ...(!header && { mt: 16 }),
        ...modalBodyProps,
    }

    // NOTE: Track modal opened
    useEffect(() => {
        if (isOpen && !!trackingPropsOpen) {
            SegmentService.track(EventGroup.INTERACTION, {
                eventType: EventTypeInteraction.MODAL_OPENED,
                ...trackingPropsOpen,
            })
        }
    }, [isOpen, trackingPropsOpen])

    // NOTE: Track modal closed
    const handleOnClose = useCallback(() => {
        onClose()
        if (!!trackingPropsClose) {
            SegmentService.track(EventGroup.INTERACTION, {
                eventType: EventTypeInteraction.MODAL_CLOSED,
                ...trackingPropsClose,
            })
        }
    }, [onClose, trackingPropsClose])

    return (
        <Modal isOpen={isOpen} onClose={handleOnClose} {...controlledModalProps} {...modalProps}>
            <ModalOverlay />
            <ModalContent {...controlledModalContentProps}>
                {!!header && (
                    <ModalHeader fontWeight="bold" fontSize="lg" mt={isFullScreen ? "env(safe-area-inset-top)" : 0}>
                        {header}
                    </ModalHeader>
                )}
                {!isCloseButtonHidden && (
                    <ModalCloseButton
                        top={isFullScreen ? "calc(env(safe-area-inset-top) + 12px)" : undefined}
                        onClick={handleOnClose}
                    />
                )}
                <ModalBody {...controlledModalBodyProps}>{body}</ModalBody>
                {!!footer && <ModalFooter>{footer}</ModalFooter>}
            </ModalContent>
        </Modal>
    )
}
