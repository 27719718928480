import AWSAppSyncClient from "aws-appsync"
import { AppSyncClient } from "type/aws"

export interface AppSyncServerConfig {
    key: string
    url: string
    region: string
}

export class AppSyncService {
    _client!: AppSyncClient

    constructor({ key, url, region }: AppSyncServerConfig) {
        this._client = new AWSAppSyncClient({
            url: url,
            region: region,
            disableOffline: true,
            auth: {
                type: "API_KEY",
                apiKey: key,
            },
        })
    }

    hydrated() {
        return this._client.hydrated()
    }
}
