import { ModalParams, ModalType } from "component/Modal/type"
import { useCallback } from "react"
import { createContainer } from "unstated-next"
import { Reducer, useImmerReducer } from "use-immer"

enum ActionType {
    MODAL_CLOSE = "MODAL_CLOSE",
    MODAL_OPEN = "MODAL_OPEN",
}

type ActionPayload = {
    modalTarget: ModalType
    params?: ModalParams[ModalType]
}

type Action = {
    type: ActionType
    payload?: ActionPayload
}

interface State {
    modalOpened?: ModalType
    params?: ActionPayload["params"]
}

const initialState: State = {}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.MODAL_OPEN: {
            const { modalTarget, params } = action.payload || {}
            return { ...state, modalOpened: modalTarget, params }
        }
        case ActionType.MODAL_CLOSE: {
            const { modalTarget } = action.payload || {}
            const isTargetOpened = modalTarget === state.modalOpened
            // NOTE: if different type is opened then do nothing, else, close and reset all data.
            return isTargetOpened ? initialState : state
        }
        default:
            throw new Error("Modals container reducer error.")
    }
}

function useModals() {
    const [state, dispatch] = useImmerReducer<State, Action>(reducer as Reducer<State, Action>, initialState)

    const modalOpen = useCallback(
        (typeOpened: ModalType, params?: ModalParams[ModalType]) =>
            dispatch({ type: ActionType.MODAL_OPEN, payload: { modalTarget: typeOpened, params } }),
        [dispatch],
    )

    const modalClose = useCallback(
        (typeOpened: ModalType) => dispatch({ type: ActionType.MODAL_CLOSE, payload: { modalTarget: typeOpened } }),
        [dispatch],
    )

    return {
        state,
        actions: {
            modalOpen,
            modalClose,
        },
    }
}

export const Modals = createContainer(useModals)

// NOTE: Encapsulate toggleModal with ModalType for easier usage.
export function useModal<T extends ModalType>(targetModalType: T) {
    const {
        actions: { modalClose, modalOpen },
    } = Modals.useContainer()

    const open = useCallback(
        (params?: ModalParams[T]) => {
            modalOpen(targetModalType, params)
        },
        [modalOpen, targetModalType],
    )

    const close = useCallback(() => {
        modalClose(targetModalType)
    }, [modalClose, targetModalType])

    return {
        open,
        close,
    }
}
