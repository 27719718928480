import { useAccountBalanceStrings } from "module/vault/hook/useAccountBalanceStrings"

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    HStack,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react"
import { Position, PositionSide } from "@perp/sdk-curie"
import { MarketListIcon } from "component/MarketListIcon"
import { Stats } from "component/Stats"

import { usePositionWithRatioStrings } from "../../../position/hook/usePositionWithRatioStrings"

interface Props {
    position: Position
    ratio?: number
}

const DECIMAL_SIX_STR = "0.000000"

export function MakerPositionImpermanent({ position, ratio }: Props) {
    const { side } = position
    const { marginRatioStr, leverageStr } = useAccountBalanceStrings()
    const { positionSizeStr, notionalValueStr, unrealizedPnlStr } = usePositionWithRatioStrings(position, ratio)
    const baseSymbol = position?.market.baseSymbol || ""
    const badgeColor = side === PositionSide.LONG ? "green" : "red"

    return (
        <Accordion allowToggle w="100%">
            <AccordionItem border="none" p={0}>
                <AccordionButton p={0}>
                    <HStack flexGrow={1} spacing={2} m={0} w="100%">
                        <VStack spacing={0}>
                            <Box boxSize="28px">
                                <MarketListIcon coin={baseSymbol.toLocaleLowerCase()} boxSize="28px" />
                            </Box>
                        </VStack>
                        <VStack
                            textAlign={"left"}
                            align="flex-start"
                            width="1fr"
                            spacing={-1}
                            maxW="100%"
                            overflow={"hidden"}
                        >
                            <Text fontSize={"xl"} fontWeight="bold">
                                {positionSizeStr} {baseSymbol}
                            </Text>
                            <Text color="gray.500" fontSize="sm" w={"100%"} isTruncated>
                                {`${notionalValueStr.startsWith(DECIMAL_SIX_STR) ? "≈$0" : notionalValueStr}`}
                            </Text>
                        </VStack>
                        <Spacer />
                        <Badge size="md" variant="subtle" colorScheme={badgeColor}>
                            {side}
                        </Badge>
                        <AccordionIcon />
                    </HStack>
                </AccordionButton>

                <AccordionPanel px={0} pb={2} pt={5}>
                    <VStack w="100%">
                        <Stats
                            variant="left"
                            size="xs"
                            label={"Profit / Loss"}
                            number={
                                unrealizedPnlStr.startsWith(DECIMAL_SIX_STR) ||
                                unrealizedPnlStr.startsWith(`-${DECIMAL_SIX_STR}`)
                                    ? "≈0"
                                    : unrealizedPnlStr
                            }
                        />
                        <Stats variant="left" size="xs" label="Account Margin Ratio" number={marginRatioStr} />
                        <Stats variant="left" size="xs" label="Account Leverage" number={leverageStr} />
                    </VStack>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}
