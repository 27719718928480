import { StatusIndicator } from "module/market/component/StatusIndicator"
import { getPoolDetailUrl } from "util/route"

import { Icon, LinkBox, LinkOverlay, Text, VStack, useColorModeValue } from "@chakra-ui/react"
import { MarketListIcon } from "component/MarketListIcon"
import { MotionHStack } from "component/Motion"
import { useMemo } from "react"
import { RiContrastDrop2Fill } from "react-icons/ri"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { usePool } from "sdk-react/pools/usePool"

import { useLiquidity } from "../../hook/useLiquidity"

interface PairItemProps {
    data: {
        tickerSymbol: string
        baseSymbol: string
        quoteSymbol: string
    }
}
export function PairItem({ data }: PairItemProps) {
    const { baseSymbol, quoteSymbol, tickerSymbol } = data
    const { status: marketStatus } = usePool({ tickerSymbol })
    const { hasOpenLiquidities } = useLiquidity(tickerSymbol)

    const targetUrl = useMemo(() => {
        if (!quoteSymbol || !baseSymbol) {
            return ""
        }
        return getPoolDetailUrl(baseSymbol, quoteSymbol)
    }, [baseSymbol, quoteSymbol])

    const highlightBg = useColorModeValue("blackAlpha.100", "whiteAlpha.100")
    const location = useLocation()

    const isActive = location.pathname === targetUrl

    return (
        <LinkBox _active={{ background: "transparent" }} w={"100%"} px={4} pr={0}>
            <LinkOverlay as={RouterLink} to={targetUrl}>
                <MotionHStack
                    {...(isActive && { bg: highlightBg })}
                    w={"100%"}
                    pl={[0, "14px"]}
                    pr={2}
                    py={[4, 3]}
                    borderRadius="xl"
                >
                    <MotionHStack direction="row" w="100%" spacing={4} px={1} mr={{ base: 3, lg: 0 }}>
                        <MarketListIcon boxSize={["28px", "20px"]} coin={baseSymbol} />

                        <VStack flexGrow={1} spacing={0} align="flex-start">
                            <Text
                                _groupHover={{ opacity: 1 }}
                                textTransform="uppercase"
                                opacity={[1, isActive ? 1 : 0.6]}
                                fontSize={["md", "sm"]}
                                fontWeight={"semibold"}
                            >
                                {baseSymbol}
                            </Text>
                        </VStack>
                        <StatusIndicator marketStatus={marketStatus} />
                        {hasOpenLiquidities && (
                            <Icon
                                boxSize={{ base: "18px", sm: "14px" }}
                                color="perpetual.500"
                                as={RiContrastDrop2Fill}
                            />
                        )}
                    </MotionHStack>
                </MotionHStack>
            </LinkOverlay>
        </LinkBox>
    )
}
