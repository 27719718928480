// custom fonts

const fonts = {
    brand: "Modernist,system-ui, sans-serif",
    body: "'Inter','InterVariable',sans-serif",
    heading: "'Inter','InterVariable',,sans-serif",
    account: "Space Mono",
    mono: "'Space Mono', monospace",
}

export default fonts
