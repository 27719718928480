import { ChainId } from "module/network/constant"
import { AppNetwork } from "module/network/container/AppNetwork"

import { AppConfig } from "container/appConfig"
import { useCallback } from "react"
import { useAsync } from "react-use"
import { LimitOrderService } from "service/appSync/LimitOrderService"
import PusherService from "service/PusherService"
import { createContainer } from "unstated-next"

enum PusherStatus {
    FILLED = "filled",
    CANCELLED = "cancelled",
    CREATED = "created",
}

export interface PusherOrderData {
    trader: string
    baseToken: string // NOTE: address
    orderHash: string
    status: PusherStatus
}

const PUSHER_EVENT_NAME_UPDATED = "limit-order-updated"

enum PusherServiceStage {
    PRODUCTION = "production",
    STAGING = "staging",
    DEV1 = "dev1",
    DEV2 = "dev2",
    TEST = "test",
}

const StageToPusherServiceStageMap: { [chainId: number]: PusherServiceStage } = {
    [ChainId.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI]: PusherServiceStage.STAGING,
    [ChainId.OPTIMISTIC_ETHEREUM]: PusherServiceStage.PRODUCTION,
}

function getChannelName(account: string, chainId: number) {
    return `perp-trader-${StageToPusherServiceStageMap[chainId]}-${account}`
}

export const LimitOrderServiceContainer = createContainer(useLimitOrderService)
function useLimitOrderService() {
    const { appConfig } = AppConfig.useContainer()
    /* ========== AppSync Service ========== */
    const { key, url, region } = appConfig?.limitOrderServerConfig || {}
    const {
        value: limitOrderService,
        loading,
        error,
    } = useAsync(async () => {
        if (!key || !url || !region) {
            return
        }
        const service = new LimitOrderService({ key, url, region })
        await service.hydrated()
        return service
    }, [key, url, region])

    /* ========== Pusher Service ========== */
    const { appChainId } = AppNetwork.useContainer()
    const pusherServiceSubscribe = useCallback(
        (account: string, handler: (data: PusherOrderData) => void) => {
            if (!appConfig?.pusherConfig) {
                return
            }
            const pusher = PusherService.getInstance(appConfig.pusherConfig)
            const channelName = getChannelName(account, appChainId)
            const channel = pusher.subscribe(channelName)
            return channel.bind(PUSHER_EVENT_NAME_UPDATED, handler)
        },
        [appChainId, appConfig?.pusherConfig],
    )

    return {
        limitOrderService,
        pusherServiceSubscribe,
        loading,
        error,
    }
}
