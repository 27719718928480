import { Text } from "@chakra-ui/react"
import { MotionBox } from "component/Motion"

// TODO: work on how to know which one is NEW
export function BadgeNew() {
    return (
        <MotionBox
            transformOrigin={"right"}
            initial={{ scale: 0.75 }}
            animate={{ scale: [0.75, 1, 0.75], transition: { delay: 3, repeat: 2 } }}
        >
            <Text
                fontSize="xs"
                color="blackAlpha.900"
                fontWeight="semibold"
                bg="perpetual.300"
                px={"6px"}
                py={"2px"}
                borderRadius="2xl"
            >
                NEW
            </Text>
        </MotionBox>
    )
}
