import { get } from "lodash"

import { IMetadataLoader, IPeripheryMetadataLoader, Metadata, PeripheryMetadata, PoolMetadata } from "./types"

export class JsonMetadataLoader implements IMetadataLoader {
    constructor(readonly metadata: Metadata) {}

    getContractAddress(contract: "ClearingHouse" | "OrderBook" | "Vault"): string {
        return get(this.metadata, `contracts.${contract}.address`) as unknown as string
    }

    getPool(baseSymbol: string) {
        const pools = get(this.metadata, "pools") as PoolMetadata[]
        for (const pool of pools) {
            if (pool.baseSymbol === baseSymbol) {
                return pool
            }
        }
        throw new Error("pool not found")
    }

    getChainId(): number {
        return this.metadata.chainId
    }

    getExternalContractAddress(name: "USDC"): string {
        return get(this.metadata, `externalContracts.${name}`) as unknown as string
    }

    getPoolByBaseToken(baseToken: string): PoolMetadata {
        const pools = get(this.metadata, "pools") as PoolMetadata[]
        for (const pool of pools) {
            if (pool.baseAddress.toLowerCase() === baseToken.toLowerCase()) {
                return pool
            }
        }
        throw new Error("pool not found")
    }
}

export class JsonPeripheryMetadataLoader implements IPeripheryMetadataLoader {
    constructor(readonly metadata: PeripheryMetadata) {}

    getContractAddress(contract: "LimitOrderBook"): string {
        return get(this.metadata, `contracts.${contract}.address`) as unknown as string
    }

    getChainId(): number {
        return this.metadata.chainId
    }

    getExternalContractAddress(name: "PERP"): string {
        return get(this.metadata, `externalContracts.${name}`) as unknown as string
    }
}
