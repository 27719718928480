import { useBreakpointValue } from "@chakra-ui/react"

export function useIsLg() {
    return useBreakpointValue({ base: true, lg: false })
}

export function useIsMd() {
    return useBreakpointValue({ base: true, md: false })
}

export function useIsSm() {
    return useBreakpointValue({ base: true, sm: false })
}
