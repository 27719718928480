import { Box, Text, VStack } from "@chakra-ui/react"
import { Bolt } from "component/Icon"
import { MotionBox, MotionHStack } from "component/Motion"
import { useCallback } from "react"
import { trackSwitchForMeButton } from "service/segment/tracks"
import { DEFAULT_APP_CHAIN_ID } from "../constant"
import { AppNetwork } from "../container/AppNetwork"
import { switchNetwork } from "../util/switchNetwork"

export function SwitchNetworkForMeBanner() {
    const { isAccountOnUnsupportedNetwork, appChainName } = AppNetwork.useContainer()
    const handleClicked = useCallback(() => {
        trackSwitchForMeButton()
        switchNetwork(DEFAULT_APP_CHAIN_ID)
    }, [])

    return (
        <MotionHStack
            as="button"
            _hover={{
                bg: "yellow.400",
            }}
            initial={{ height: 0 }}
            animate={{ height: isAccountOnUnsupportedNetwork ? 70 : 0 }}
            bgGradient="linear(yellow.300 0%, yellow.400 100%)"
            w="100%"
            px={7}
            overflow="hidden"
            spacing={3}
            onClick={handleClicked}
        >
            <Box boxSize="32px">
                <MotionBox
                    initial={{ rotateY: 0 }}
                    animate={{
                        rotateY: 180,
                        transition: {
                            repeat: Infinity,
                            duration: 0.8,
                            repeatType: "reverse",
                        },
                    }}
                >
                    <Bolt color={"yellow.100"} boxSize={"24px"} />
                </MotionBox>
            </Box>
            <VStack width="100%" align={["flex-end", "flex-start"]} spacing={0} textAlign={["center", "left"]}>
                <Text width="100%" fontWeight="bold" color={"yellow.900"} fontSize={["md", "sm"]}>
                    Switch to {appChainName}
                </Text>
                <Text width="100%" color={"yellow.700"} lineHeight={1} fontSize={["sm", "xs"]}>
                    {/* TODO: use i18n translation */}
                    Click to switch now
                </Text>
            </VStack>
            <Box boxSize="32px" display={["block", "none"]} />
        </MotionHStack>
    )
}
