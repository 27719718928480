import { useMarketsEnabled } from "module/market/hook/useMarketsEnabled"
import { Web3WalletContainer } from "module/wallet/container/Web3WalletContainer"

import { PositionSide, big2BigNumberAndScaleUp } from "@perp/sdk-curie"
import Big from "big.js"
import { constants } from "ethers"
import { formatBytes32String } from "ethers/lib/utils"
import { useCallback, useEffect, useState } from "react"
import { useAsync } from "react-use"
import { OrderType, OrderTypeParamMap } from "sdk-react/limitOrder/types" // FIXME: direct import from "sdk-react" cause circular dependency
import { usePerpInitializedEffect } from "sdk-react/perpetualProtocol/usePerpInitializedEffect"
import { Handlers } from "sdk-react/tools/useHandlers"
import { ActiveLimitOrder, ICreateLimitOrderParams } from "service/appSync/LimitOrderService"
import { createContainer } from "unstated-next"

import { Referral } from "../../Referral"
import { FeatureToggleContainer } from "../FeatureToggleContainer"
import { LimitOrderServiceContainer } from "./LimitOrderServiceContainer"

interface CreateLimitOrderParams {
    tickerSymbol: string
    orderType: OrderType
    side: PositionSide
    baseAmount: Big
    quoteAmount: Big
    isReduceOnly: boolean
    deadline: number
    triggerPrice?: Big
    limitPriceForDisplay: Big
    roundIdWhenCreated?: string
}

interface IActiveLimitOrderMap {
    [tickerSymbol: string]: ActiveLimitOrder[]
}

export const ActiveLimitOrdersContainer = createContainer(useActiveLimitOrder)

export function useActiveLimitOrder() {
    const { isLimitOrderEnable } = FeatureToggleContainer.useContainer()

    const { account, signer } = Web3WalletContainer.useContainer()
    const { getHandlers } = Handlers.useContainer()
    const { onError } = getHandlers()
    const { referralCode } = Referral.useContainer()
    const { marketMap, poolMetadataMap } = useMarketsEnabled()

    const [limitOrderBookAddress, setLimitOrderBookAddress] = useState<string>()
    usePerpInitializedEffect(({ metadata }) => {
        setLimitOrderBookAddress(metadata.contracts.LimitOrderBook.address)
    }, [])

    const { limitOrderService, pusherServiceSubscribe } = LimitOrderServiceContainer.useContainer()
    const [activeLimitOrdersMap, setActiveLimitOrdersMap] = useState<IActiveLimitOrderMap>({})
    const [isLoading, setIsLoading] = useState(true)
    const [isCreating, setIsCreating] = useState(false)

    const updateOrdersOfAllMarkets = useCallback(async () => {
        if (!isLimitOrderEnable || !marketMap || !poolMetadataMap || !limitOrderService || !account) {
            return
        }
        const fetchPromises = Object.values(marketMap).map(async ({ tickerSymbol }) => {
            const rawBaseAddress = poolMetadataMap[tickerSymbol]?.baseAddress
            if (!rawBaseAddress) {
                return { tickerSymbol, result: null }
            }
            const result = await limitOrderService.getActiveLimitOrders(account, rawBaseAddress)
            return { tickerSymbol, result }
        })
        try {
            setIsLoading(true)
            const limitOrdersOfMarkets = await Promise.all(fetchPromises)
            const activeLimitOrdersMap = limitOrdersOfMarkets.reduce(
                (accumulator, { tickerSymbol, result }) => ({
                    ...accumulator,
                    [tickerSymbol]: result,
                }),
                {},
            )
            setActiveLimitOrdersMap(activeLimitOrdersMap)
        } catch (error) {
            onError(error as Error)
        }
        setIsLoading(false)
    }, [account, isLimitOrderEnable, limitOrderService, marketMap, onError, poolMetadataMap])

    // NOTE: load initial orders.
    useAsync(async () => {
        updateOrdersOfAllMarkets()
    }, [updateOrdersOfAllMarkets])

    // NOTE: Manage pusher subscription.
    useEffect(() => {
        if (!isLimitOrderEnable || !account) {
            return
        }
        const channel = pusherServiceSubscribe(account, ({ status }) => {
            updateOrdersOfAllMarkets() // NOTE: consider to refetch just the market that has changed.
        })

        return () => {
            channel?.unsubscribe()
        }
    }, [account, isLimitOrderEnable, pusherServiceSubscribe, updateOrdersOfAllMarkets])

    const createLimitOrder = useCallback(
        async ({
            tickerSymbol,
            orderType,
            side,
            baseAmount,
            quoteAmount,
            limitPriceForDisplay,
            triggerPrice,
            isReduceOnly,
            deadline,
            roundIdWhenCreated,
        }: CreateLimitOrderParams) => {
            setIsCreating(true)
            try {
                const orderTypeParam = OrderTypeParamMap[orderType]
                const rawBaseAddress = poolMetadataMap?.[tickerSymbol]?.baseAddress

                if (
                    !account ||
                    !signer ||
                    !limitOrderService ||
                    !rawBaseAddress ||
                    !limitOrderBookAddress ||
                    orderTypeParam === undefined
                ) {
                    return Promise.reject()
                }

                const isStopOrder = orderType === OrderType.STOP_LIMIT || orderType === OrderType.TAKE_PROFIT_LIMIT
                const stopOrderParams = {
                    roundIdWhenCreated: isStopOrder && !!roundIdWhenCreated ? roundIdWhenCreated : "0",
                    triggerPrice:
                        isStopOrder && !!triggerPrice ? big2BigNumberAndScaleUp(triggerPrice).toString() : "0",
                }
                const params: ICreateLimitOrderParams = {
                    orderType: orderTypeParam,
                    trader: account,
                    baseToken: rawBaseAddress,
                    isBaseToQuote: side === PositionSide.SHORT,
                    isExactInput: side === PositionSide.SHORT, // NOTE: we always use base token as input unit
                    amount: big2BigNumberAndScaleUp(baseAmount).toString(),
                    oppositeAmountBound: big2BigNumberAndScaleUp(quoteAmount).toString(),
                    reduceOnly: isReduceOnly,
                    deadline: `${deadline}`,
                    sqrtPriceLimitX96: 0,
                    referralCode: referralCode ? formatBytes32String(referralCode) : constants.HashZero,
                    salt: Math.floor(Math.random() * 10000),
                    limitPriceForDisplay: big2BigNumberAndScaleUp(limitPriceForDisplay).toString(),
                    ...stopOrderParams,
                }

                const result = await limitOrderService.createLimitOrder(signer, params, limitOrderBookAddress)
                setIsCreating(false)
                return result
            } catch (error) {
                setIsCreating(false)
                throw error
            }
        },
        [poolMetadataMap, account, signer, limitOrderService, limitOrderBookAddress, referralCode],
    )

    return {
        isLoading,
        isCreating,
        activeLimitOrdersMap,
        createLimitOrder,
    }
}
